import React, { useCallback } from 'react'
import { StyleSheet, ScrollView, SafeAreaView,
  Platform,
  TouchableOpacity } from 'react-native'

import OrComponent from '@reveel/components/ui/OrComponent'
import View from '@reveel/components/ui/View'
import logo from 'app/assets/images/logo_square_background.png'
import { Text } from 'native-base'
import QRCode from 'react-native-qrcode-svg'

import track from '../analytics/index'
import { useAuth } from '../stores/useAuth'
import { webAppHost } from '@reveel/helpers/urls';
import Clipboard from 'expo-clipboard'

const ShareAppScreen = () => {
  const { user } = useAuth()
  const joinUrl = `${webAppHost}?rev=el&ref=${user.id}&src=referral`

  const mailTo = `mailto:?subject=${encodeURIComponent(i18n.t('invite.mail.subject'))}&body=${encodeURIComponent(i18n.t('invite.mail.body', { url: joinUrl }))}`
  const copyToClipboard = useCallback(() => {
    Clipboard.setString(joinUrl)
    track('Shared App', {
      channel: 'copy',
    })
  }, [joinUrl])

  const shareZone = Platform.OS === 'web'
    ? (
      <View row>
        <input type="text" readOnly value={joinUrl} onClick={({ target }) => target.select()} style={{ marginRight: '1rem' }} />
        <TouchableOpacity onPress={copyToClipboard} style={{ marginRight: '1rem' }}>
          <Text primary>Copy Link</Text>
        </TouchableOpacity>
        <a
          href={mailTo}
          onClick={() => track('Shared App', {
            channel: 'own_email',
          })}
        >
          <Text primary>
            Send by email
          </Text>
        </a>
      </View>
    )
    : null
  return (
    <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <ScrollView contentContainerStyle={{ flex: 1 }}>
        <View style={styles.half}>
          <View>
            <Text style={styles.title} primary bold center>Invite a friend</Text>
            <Text style={styles.details} small center>You can use the camera app to scan this QR code</Text>
          </View>
          <QRCode size={200} value={joinUrl} logo={logo} logoSize={50} />
        </View>
        <OrComponent />
        <View style={[styles.half, styles.bottomHalf]}>
          {shareZone}
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  title: {
    marginBottom: 15,
  },
  details: {
    marginBottom: 30,
  },
  half: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottomHalf: {
    width: '100%',
    justifyContent: 'space-around',
    paddingBottom: 20,
  },
  button: {
    marginTop: 10,
    alignSelf: 'stretch',
  },
})

export default ShareAppScreen
