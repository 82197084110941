import Colors from '@reveel/constants/Colors'
import React from 'react'

function getBaseUrlFromDOMInput(input, callback){
  const file = input.files[0]
  const filename = file.name
  const reader = new FileReader()
  reader.onloadend = () => {
    const base64 = reader.result
    callback({
      data: base64,
      filename,
      uri: `${base64}`,
    })
  }
  reader.readAsDataURL(file)
}

const FilePicker = ({ name, onSelect, children }) => {
  const onChange = (e) => {
    getBaseUrlFromDOMInput(e.target, onSelect)
  }
  return (
    <label style={styles.container} htmlFor={name}>
      { children }
      <input onChange={onChange} type="file" id={name} style={styles.input} />
    </label>
  )
}

export default FilePicker

const styles = ({
  container: {
    cursor: 'pointer',
  },
  placeholder: {
    width: 75,
    height: 75,
    backgroundColor: Colors.lightBackground,
    borderRadius: 37.6,
    marginBottom: 15,
    marginHorizontal: 'auto',
  },
  input: {
    width: 0,
    height: 0,
    opacity: 0,
    pointerEvents: 'none',
    display: 'block',
  },
})
