import React from 'react'
import { TouchableOpacity } from 'react-native'

import { FontAwesome5 } from '@expo/vector-icons'
import SearchUser from '@reveel/components/core/SearchUser'
import { Text } from '@reveel/components/ui'
import Colors from '@reveel/constants/Colors'
import routes from '@reveel/helpers/routes'
import MenuItemWithDropdown from '@reveel/navigation/createScreenHeader/MenuWithDropdown'
import { useAuth } from '@reveel/stores/useAuth'
import axios from 'axios'

export const ImpersonateUI = () => {
  const {
    masquerade: { impersonating, impersonate, desimpersonate },
  } = useAuth()

  const onUserPress = async (selectedUser) => {
    const { data: masque } = await axios.get(`${routes.api.user.show()}?impersonate_id=${selectedUser.id}`)
    impersonate(masque)
  }
  if(impersonating){
    return (
      <TouchableOpacity onPress={desimpersonate}>
        <Text py px>
          {' '}
          Stop impersonating
          { impersonating.first_name }
        </Text>
      </TouchableOpacity>
    )
  }
  return (
    <SearchUser
      placeholder="Impersonate someone"
      onSelect={onUserPress}
      resourceType="users"
    />
  )
}

const Impersonate = () => {
  const { impersonating } = useAuth()
  const color = impersonating ? Colors.primary : Colors.lightBlack
  return (
    <MenuItemWithDropdown
      renderTrigger={() => <FontAwesome5 name="mask" size={24} color={color} />}
      large
      shifted
    >
      <ImpersonateUI />
    </MenuItemWithDropdown>
  )
}

export default React.memo(Impersonate)
