import { useAsync } from '@reveel/hooks/useRemoteState'
import { dateOnly } from '@reveel/utils/dateFormatter'
import routes from '../../helpers/routes'
import NavigationService from '../../helpers/NavigationService'
import track from '../../analytics/index'
import { useNavigation } from '@react-navigation/native';

export const replaceNavigationStateWithSession = (creativeSession, { navigation, ...params }) => navigation.reset({
  index: 2,
  routes: [
    { name: 'Home' },
    { name: 'TrackShow', params: { trackID: creativeSession.track_id } },
    {
      name: 'Session', params: { trackID: creativeSession.track_id, id: creativeSession.id, ...params },
    }],
})
export const useStartSessionForNewTrack = () => {
  const navigation = useNavigation()
  const { run, ...hook } = useAsync({
    url: routes.api.creativeSession.create,
    method: 'post',
    handleResponse: ({ data: creativeSession }) => {
      replaceNavigationStateWithSession(creativeSession, { navigation })
      track('Created Track', {
        id: track.id,
        with_session: true,
      })
    },
  })
  return {
    ...hook,
    createAndStartSession: ({ track: { title = `Track ${dateOnly(new Date())}` } }) => run({
      creative_session: { track_attributes: { title } },
    }),
  }
}

export const useStartSessionFor = () => {
  const navigation = useNavigation()
  const { run, ...hook } = useAsync({
    url: (id) => routes.api.tracks.createSession(id),
    method: 'post',
    handleResponse: ({ data: creativeSession }) => {
      replaceNavigationStateWithSession(creativeSession, { navigation })
    },
  })
  return {
    ...hook,
    startSessionFor: ({ id }, creativeSessionsParams) => run({
      urlParams: id,
      creative_session: {
        track_id: id,
        ...creativeSessionsParams,
      },
    }),
  }
}
