import { Text } from '@reveel/components/ui';
import View from '@reveel/components/ui/View';
import React from "react";

export const Title = ({ title, style }) => title ? <Text mb-2 larger bold style={style}>{title}</Text> : null

export const Box = ({ style, children, title, ...props }) => <View style={style} px py box>
  <Title title={title} />
  {children}
</View>;
