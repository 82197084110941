import React from 'react'
import { connectActionSheet } from '@expo/react-native-action-sheet'
import { TouchableOpacity } from 'react-native'
import { Entypo } from '@expo/vector-icons'
import { PropTypes } from 'prop-types'

import mapOptionsToActionSheet from '../../../../helpers/mapOptionsToActionSheet'

const HeaderMore = ({ options, showActionSheetWithOptions, style }) => {
  const showMoreMenu = options.length > 0
  if(!showMoreMenu){
    return null
  }
  const cancel = { label: 'Cancel', cancel: true }
  const menuOptions = mapOptionsToActionSheet([...options, cancel])
  const openMenu = () => showActionSheetWithOptions(...menuOptions)
  return (
    <TouchableOpacity style={style} onPress={openMenu}>
      <Entypo name="dots-three-vertical" size={15} style={{ paddingHorizontal: 10 }} />
    </TouchableOpacity>
  )
}

HeaderMore.props = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default connectActionSheet(HeaderMore)
