import React from "react"
import {
  TouchableOpacity,
  FlatList,
  StyleSheet,
  View,
  Platform,
} from "react-native"
import KeyboardSpacerIOS from '@reveel/components/KeyboardSpacerIOS'
import {
  ListItem, Left, Right, Icon, Body, Thumbnail,
} from "native-base"
import Colors from "@reveel/constants/Colors"
import { Text } from "@reveel/components/ui"
import { PropTypes } from 'prop-types'
import { Link } from "@react-navigation/native"
import SearchInput from "./SearchInput"
import Loading from "../Loading"
import Separator from "./Separator"

const wrapInTouchable = (elem, onPress) => (
  onPress ? (
    <TouchableOpacity onPress={onPress}>{elem}</TouchableOpacity>
  ) : (
    elem
  )
)

const renderIcon = (name, color) => <Icon style={{ color }} name={name} />

const renderItemDefault = (withBorder) => ({
  item,
  titleStyle,
}) => {
  if(__DEV__ && !item.key){
    console.warn("key missing for ", item)
  }
  if(item.render){
    return item.render(item)
  }
  let { rightIconName, rightIconColor } = item
  const { avatar, right } = item
  if(item.selected){
    rightIconName = Platform.OS === "ios" ? "ios-checkmark" : "checkmark"
    rightIconColor = Colors.tintColor
  }
  if(item.canEnter){
    rightIconName = Platform.OS === "chevron-forward"
  }

  if(item.divider){
    return (
      <ListItem noBorder key={item.key || item.title} itemDivider style={item.style}>
        <Text style={styles.text}>{item.divider}</Text>
      </ListItem>
    )
  }

  const left = (avatar || item.left) && (
    <Left>
      {item.left && <Text style={titleStyle}>{item.left}</Text>}
      {avatar && <Thumbnail small source={{ uri: avatar }} />}
    </Left>
  )

  let body = (
    <Text primary={item.selected} style={titleStyle}>
      {item.title}
    </Text>
  )
  if(item.href){
    body = (
      <Link to={item.href} style={[tw.mL3]}>
        {body}
      </Link>
    )
  }
  return wrapInTouchable(
    <ListItem
      noIndent
      noBorder={withBorder}
      avatar={avatar}
      onPress={item.onPress || (() => {})}
      style={item.style}
    >
      {left}
      <Body>
        {body}
        {item.subTitle && <Text note>{item.subTitle}</Text>}
      </Body>
      {rightIconName && (
        <Right>
          {rightIconName && renderIcon(rightIconName, rightIconColor)}
        </Right>
      )}
      {right && <Right>{right}</Right>}
    </ListItem>,
    item.onPress,
  )
}
const List = ({
  data,
  emptyMessage = "List is currently empty",
  emptyComponent,
  withBorder = true,
  ItemSeparatorComponent = withBorder && (() => <Separator ml />),
  renderItem = renderItemDefault(withBorder),
  ListHeaderComponent,
  onSearch,
  searchParams,
  placeholder = "Search",
  loading,
  style,
  keyboardShouldPersistTaps = 'handled',
  withKeyboardSpacer,
  ListFooterComponent,
  contentContainerStyle = { flexGrow: 1 },
  ...otherProps
}) => {
  if(loading){
    return (
      <View style={styles.loadingContainer}>
        {ListHeaderComponent}
        <Loading />
      </View>
    )
  }

  const showEmptyList = !data.length && !searchParams?.length

  const searchComponent = (
    <SearchInput
      style={styles.searchContainer}
      placeholder={placeholder}
      onChangeText={onSearch}
    />
  )

  const headerComponent = (
    <>
      {ListHeaderComponent}
      {!showEmptyList && onSearch && searchComponent}
    </>
  )

  const footerComponent = (
    <>
      {ListFooterComponent}
      {withKeyboardSpacer && <KeyboardSpacerIOS />}
    </>
  )

  return (
    <FlatList
      data={data}
      renderItem={(all) => all.item.render?.() || renderItem({ ...all, ...otherProps })}
      ItemSeparatorComponent={ItemSeparatorComponent}
      ListHeaderComponent={headerComponent}
      contentContainerStyle={contentContainerStyle}
      {...otherProps}
      style={[styles.container, style]}
      keyboardShouldPersistTaps={keyboardShouldPersistTaps}
      ListFooterComponent={footerComponent}
      ListFooterComponentStyle={styles.footer}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  searchContainer: {
    margin: 10,
  },
  footer: {
    flex: 1,
  },
  loadingContainer: {
    flex: 1,
  },
})

List.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequiredd,
    render: PropTypes.func,
  })).isRequired,
  emptyMessage: PropTypes.string,
  emptyComponent: PropTypes.element,
  withBorder: PropTypes.bool,
  ItemSeparatorComponent: PropTypes.element,
  renderItem: PropTypes.func,
  ListHeaderComponent: PropTypes.element,
  onSearch: PropTypes.func,
  searchParams: PropTypes.string,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  withKeyboardSpacer: PropTypes.bool,
  ListFooterComponent: PropTypes.element,
  keyboardShouldPersistTaps: PropTypes.string,
}

export default React.memo(List)
