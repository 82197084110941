import React, { useEffect, useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useAsync } from '@reveel/hooks/useRemoteState'
import { Alert } from '@reveel/vendors/alert'
import { useAuth } from '../../stores/useAuth'
import HeaderMore from '../../components/core/header/HeaderMore'
import { RUNNING } from '../../constants/Status'
import routes from '../../helpers/routes'

const SessionContextMenu = ({ creativeSession, setIntermediaryView }) => {
  if(creativeSession.ghost){
    return null
  }
  const { user } = useAuth()
  const navigation = useNavigation()
  const [actions, setActions] = useState([])

  const { run: deleteSession } = useAsync({
    url: routes.api.creativeSession.delete(creativeSession.id),
    method: 'delete',
    handleResponse: () => navigation.goBack(),
    alert: i18n.t('creativeSessions.ui.delete.alert'),
  })

  useEffect(() => {
    const canUpdate = creativeSession.can?.update
    const canLeave = creativeSession.user_id !== user.id && creativeSession.status === RUNNING
    const canDelete = creativeSession.can?.delete
    const availableOptions = []
    canUpdate && availableOptions.push({
      label: 'Edit Session Details',
      onPress: () => setIntermediaryView('details'),
    })
    canLeave && availableOptions.push({
      label: 'Leave Session',
      onPress: () => Alert.alert("Coming soon...", "This feature is not yet implemented 🤷🏼‍♂️"),
    })
    availableOptions.push({
      label: 'Show me how it works',
      onPress: () => navigation.navigate('HowSessionsWork'),
    })
    canDelete && availableOptions.push({
      label: i18n.t('creativeSessions.ui.delete.cta'),
      onPress: deleteSession,
      destructive: true,
    })
    setActions(availableOptions)
  }, [user, creativeSession])

  return <HeaderMore options={actions} />
}
export default SessionContextMenu
