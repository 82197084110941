const SESSION_TYPES = [
  "Demo",
  "PreProduction",
  "Production",
  "Tracking",
  "Overdub",
  "Mixing",
  "Mastering",

  "ArtistVocals",
  "DigitalEditing",
  "Editing",
  "LivePerformance",
  "Preservation",
  "Project",
  "Remixing",
  "Transfer",
  "TransfersAndSafeties",
  "Vocal",
]
export default SESSION_TYPES
