import React from 'react'
import { StyleSheet, Platform, Image } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import ScreenTitle from '@reveel/components/ScreenTitle'
import { View } from '@reveel/components/ui'
import Button from '@reveel/components/ui/Button'

import ExtraLinks from './ExtraLinks'
import ProjectList from '../../components/projects/ProjectList'

import Logo from '@reveel/../assets/images/logo/logo_beta_horizontal.svg'
import ModalSheet from '../../components/ui/modal/ModalSheet'
import { TrackNewForm } from '@reveel/screens/tracks/TrackNew';

const NewTrackButton = () => {
  const renderTrigger = ({ onOpen, onClose }) => (
    <Button
      testID="SidebarAddTrack"
      block
      title={i18n.t(`banners.track.new.button`)}
      style={[
        styles.button,
        Platform.OS === 'web' && {
          // the linear background is here as it is stripped by StyleSheet
          background: 'linear-gradient(352.86deg, #ED328B 10.99%, #F36A4B 77.65%, #FF9D00 116.69%)',
          alignSelf: 'stretch',
        },
      ].filter(Boolean)}
      onPress={onOpen}
    />
  )
  const renderContent = ({ onClose }) => <TrackNewForm onSuccess={onClose} />
  return <ModalSheet
    title={"Add New Project"}
    renderTrigger={renderTrigger}
    renderContent={renderContent}
  />
}

const Sidebar = () => (
  <View style={styles.container}>
    <View style={[tw.mX2, tw.mY4]}>
      <Logo width={117} height={40} />
      </View>
    <ProjectList
      withBorder={false}
      ListHeaderComponent={(
        <>
          <ScreenTitle title={["My", "Projects"]} row xs style={[tw.mL4, tw.mB2, tw.mT4]}/>
          <NewTrackButton />
        </>
        )}
    />
    <ExtraLinks />
  </View>
)

export default Sidebar

const styles = StyleSheet.create({
  container: {
    flex: 1,
    ...Platform.select({
      web: {
        maxHeight: '100vh',
      },
      ios: {
        maxHeight: '100%',
      },
      android: {
        maxHeight: '100%',
      },
    }),
  },
  button: {
    marginHorizontal: 10,
    marginTop: 10,
    marginBottom: 10,
  },
})
