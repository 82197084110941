import React from 'react'

import { useRoute } from '@react-navigation/native'
import { Text } from '@reveel/components/ui'
import { StatNumber } from '@reveel/components/ui/typography'
import View from '@reveel/components/ui/View'
import { useTrack } from '@reveel/hooks/queries/track'
import Page from '@reveel/layout/Page'
import useTrackBreadcrumb from '@reveel/navigation/useTrackBreadcrumb'
import { tw } from 'react-native-tailwindcss'

import Separator from '../../../components/ui/Separator'
import { useDesktop } from '../../../helpers/MediaQueries'
import RequestExportButton from '../credits/RequestExportButton'
import TaskItemComponent from './TaskItem/index'
import useTrackProgress from './useTrackProgress'
import Message from '../../../components/ui/Message';
import LinkWrapper from '../../../components/utils/LinkWrapper'
import TrackMainDetails from '@reveel/components/tracks/TrackMainDetails';

const SectionTitle = ({ children, style }) => <Text style={[tw.fontBold, tw.mB1, style]}>{children}</Text>
const SectionSubtitle = ({ children }) => <View style={[tw.mB2, tw.maxW128]}><Text style={[tw.flexWrap]}>{children}</Text></View>

const ProgressHeader = ({ trackID, score, time_saved, style }) => {
  const { track } = useTrack(trackID)
  const isDesktop = useDesktop()
  return (
    <View style={[tw.flexWrap, tw.flexRow, tw.justifyBetween, style]}>
      <TrackMainDetails track={track}/>
      <View style={[tw.flexGrow, !isDesktop ? tw.itemsCenter : tw.itemsEnd, tw.justifyBetween]}>
        <View style={[tw.flexRow, tw.justifyAround]}>
          <StatNumber
            style={[tw.pX2, tw.mY2]}
            number={Math.floor(score * 100)}
            label={i18n.t('dashboard.ui.score.label')}
            unit="%"
          />
          {/* hidden until we add  more info about this number
          <StatNumber
            style={[tw.pX2, tw.mY2]}
            number={time_saved}
            label={i18n.t('dashboard.ui.time_saved.label')}
            decimalPlaces={1}
          /> */}
        </View>
        <RequestExportButton
          trackID={trackID}
          buttonProps={{
            small: true,
          }}
          // this doesn't respond to parent's itemsEnd 🤷🏼‍♂️
          style={[isDesktop && tw.selfEnd, !isDesktop && tw.mT2]}
        />
      </View>
    </View>
  )
}

const DashboardScreen = () => {
  const trackID = useRoute().params?.trackID
  const {
    progress,
    changeStatus,
  } = useTrackProgress(trackID)
  useTrackBreadcrumb(trackID, ['Overview'])
  const isDesktop = useDesktop()
  return (
    <View style={tw.flexGrow}>
      <Page
        sheet
        fixNotScrolling
        renderAbove={() => (
          <>
            <ProgressHeader trackID={trackID} time_saved={progress?.time_saved} score={progress?.score} style={[tw.mY4, !isDesktop && tw.pX4]} />
            <Message style={[]} text={"Here is everything you need to be ready to release a track.\nMark each item as Done as you complete them"} />
          </>
        )}
      >
        <View style={[tw.pT4, tw.pX4, tw.pB4]}>
          <LinkWrapper to={`/tracks/${trackID}/edit`}>
            <SectionTitle style={tw.textPrimary}>{i18n.t('dashboard.tasks.details.title')} {' \u203A'}</SectionTitle>
            <SectionSubtitle>{i18n.t('dashboard.tasks.details.description')}</SectionSubtitle>
          </LinkWrapper>
          {['project_info', 'track_info', 'composition_info'].map((name) => (
            <TaskItemComponent
              name={name}
              status={progress?.[name]}
              changeStatus={changeStatus}
              key={name}
              style={[tw.mT4]}
            />
          ))}
          <Separator style={tw.mY8} />
          <LinkWrapper to={`/tracks/${trackID}/credits`}>
            <SectionTitle style={[tw.textPrimary]}>{i18n.t('dashboard.tasks.credits.title')}{' \u203A'}</SectionTitle>
            <SectionSubtitle>{i18n.t('dashboard.tasks.credits.description')}</SectionSubtitle>
          </LinkWrapper>
          {['songwriter', 'producers', 'musicians', 'engineers'].map((name) => (
            <TaskItemComponent
              name={name}
              status={progress?.[name]}
              changeStatus={changeStatus}
              key={name}
              style={[tw.mT4]}
              
              canDisable={['musicians', 'engineers'].includes(name)}
            />
          ))}
          <Separator style={tw.mY8} />
          <SectionTitle style={[]}>{i18n.t('dashboard.tasks.royalties.title')}</SectionTitle>
          <SectionSubtitle>{i18n.t('dashboard.tasks.royalties.description')}</SectionSubtitle>
          <TaskItemComponent
            name="songwriting_splits"
            status={progress?.songwriting_splits}
            changeStatus={changeStatus}
            style={[tw.mT4]}
            url={`/tracks/${trackID}/songwriting-splits`}
            canDisable
          />
          <TaskItemComponent
            name="master_points"
            status={progress?.master_points}
            changeStatus={changeStatus}
            style={[tw.mT4]}
            url={`/tracks/${trackID}/master-points`}
            canDisable
          />

          <Separator style={tw.mY8} />
          <SectionTitle style={[]}>{i18n.t('dashboard.tasks.export.title')}</SectionTitle>
          <SectionSubtitle>{i18n.t('dashboard.tasks.export.description')}</SectionSubtitle>
          <RequestExportButton
            style={[tw.mY6, tw.selfStart]}
            trackID={trackID}
          />
        </View>
      </Page>
    </View>
  )
}

export default DashboardScreen
