import React from 'react'

import { Platform, StyleSheet, Switch as NativeSwitch } from 'react-native'
import Colors, { transparentize } from '@reveel/constants/Colors'

const Switch = (props) => {
  const trueColor = Platform.OS === 'android' ? transparentize(Colors.tintColor) : Colors.tintColor
  const trackColor = {
    true: trueColor,
  }

  return <NativeSwitch {...props} trackColor={trackColor} style={[props.small && styles.small]} />
}
export default Switch

const styles = StyleSheet.create({
  small: {
    transform: [{ scaleX: 0.75 }, { scaleY: 0.75 }],
  },
})
