export default {
  profiles: {
    show: {
      songwriter_info: {
        title: 'Songwriter Info',
      },
      publisher_info: {
        title: 'Publisher Info',
      },
      artist_info: {
        title: 'Artist Identifiers',
      },
      relationships_info: {
        title: 'Label/Distributor Info',
      },
    },
    form: {
      empty: {
        cta: '+ Add users to the split sheet',
      },
      submit: {
        and_add_credits: 'Submit and Add Credits',
      },
      messages: {
        will_complete: 'Enter only name and email, and we’ll do the rest within 24h! 🚀',
      },
    },
    message: {
      completed: {
        title: "**%{name}**'s details have been completed",
        view: "View Profile",
      },
    },
  },
}
