import React from 'react'
import { StyleSheet } from 'react-native'

import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'
import Colors from '@reveel/constants/Colors'
import { useDesktop } from '@reveel/helpers/MediaQueries'
import { InputToolbar as GiftedInputToolbar, Send } from 'react-native-gifted-chat'

const InputToolbar = (props) => {
  const isDesktop = useDesktop()
  const containerStyle = isDesktop ? [
    tw.pB2,
    {
      borderTop: 'none',
      backgroundColor: 'transparent',
      marginBottom: 10,
      justifyContent: 'center',
      itemsCenter: 'center',
    },
  ] : {}
  const primaryStyle = isDesktop ? {
    paddingTop: 6,
    backgroundColor: Colors.white,
    borderRadius: 5,
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    alignItems: 'center',
  } : {}
  return (
    <>
      <GiftedInputToolbar
        {...props}
        containerStyle={containerStyle}
        primaryStyle={primaryStyle}
        renderSend={(sendProps) => (
          <Send
            {...sendProps}
            containerStyle={{ paddingBottom: 10, alignSelf: 'center' }}
          >
            <View style={[tw.pX4, tw.pY2, tw.selfCenter]}>
              <Text primary>Send</Text>
            </View>
          </Send>
        )}
      />
    </>
  )
}

InputToolbar.propTypes = {
}
export default InputToolbar

const styles = StyleSheet.create({})
