import React from 'react'

import i18n from 'i18n-js'
import { useNavigation } from '@react-navigation/native'
import ActionableItem from '../core/ActionableItem'

import ProgressBar from '../ui/ProgressBar'
import useInbox from '../../stores/useInbox'

const UpdateProfile = ({ navigation: _nav, notFirst }) => {
  const navigation = _nav || useNavigation()
  const {
    tasks: { user },
  } = useInbox()
  const { completion_rate: progress } = user
  return (
    <ActionableItem
      {...i18n.t('system.completeProfile')}
      navigation={navigation}
      bordered
      notFirst={notFirst}
      description={<ProgressBar progress={progress} />}
      onPress={() => navigation.navigate('ProfileEdit')}
      testID="complete-profile"
    />
  )
}
export default UpdateProfile
