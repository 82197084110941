import { Text } from '@reveel/components/ui'
import Button from '@reveel/components/ui/Button'
import View from '@reveel/components/ui/View'
import React, { useState } from 'react'
import { Pressable, StyleSheet } from 'react-native'
import { useAsync } from '@reveel/hooks/useRemoteState'
import routes from '@reveel/helpers/routes'
import TextInput from '../ui/TextInput'
import { useDesktop } from '@reveel/helpers/MediaQueries';
import { useEffect } from 'react';

const Form = ({ onSubmit, loading }) => {
  const [body, setBody] = useState()
  const [isABug, setIsABug] = useState(false)

  const onPress = () => onSubmit({ feedback: { body } })
  return (
    <>
      <TextInput
        style={[tw.mT4, tw.mB2]}
        appearance="square"
        parentState={[body, setBody]}
        multiline
        placeholder="Leave your comments or bugs reports here..."
        minHeight={60}
      />
      <View style={[tw.itemsCenter, tw.flexRow, tw.justifyBetween]}>
        <View style={[tw.itemsCenter, tw.flexRow]}>
          {/* <Switch small value={isABug} onValueChange={() => setIsABug(!isABug)} /> */}
          {/* <Text sm light> This is a bug</Text> */}
        </View>
        <Button xs style={[]} title="Submit" onPress={onPress} disabled={!body?.length || loading} />
      </View>
    </>
  )
}

let timer
const LeaveFeedbackDialog = ({ style, onSubmit }) => {
  const [success, setSuccess] = useState(false)
  const { run, refreshing: loading } = useAsync({
    url: routes.api.feedback,
    method: 'post',
    handleResponse: () => {
      setSuccess(true)
      onSubmit?.()
    },
  })

  useEffect(() => {
    if(success){
      timer = setTimeout(() => {
        setSuccess(false)
      }, 2600)
      return
    }
    clearTimeout(timer)
  }, [success])
  return (
    <View style={style}>
      <Text style={[tw.pB1]}>Suggestions?</Text>
      <Text light sm>What changes would you like to see? </Text>
      { success
        ? (
          <View style={[tw.pT12]}>
            <Text style={[tw.fontBold, tw.pB1]}>Thanks for making Reveel better.</Text>
            <Pressable style={tw.mT8} onPress={() => setSuccess(false)}>
              <Text sm primary underline style={[tw.pB1]}>Have another idea?</Text>
            </Pressable>
          </View>
        )
        : <Form onSubmit={run} loading={loading} />}
    </View>
  )
}

LeaveFeedbackDialog.propTypes = {
}
export default LeaveFeedbackDialog

const styles = StyleSheet.create({})
