import React from 'react'
import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'
import avatarField from './avatarField';
import withAuth from '@reveel/screens/AuthLoadingScreen';
import skillsField_ from './skillsField';
import RVPhoneInput from './PhoneField';
import RVCountrySelect from './RVCountrySelect.js';
import { Platform } from 'react-native';
export const skillsField = skillsField_
const validateIPI = (input) => /^(00\d{9})|\d{9}$/.test(input.replace(/\s/g, ''))
const urlRegExp = RegExp('(https?:\\/\\/)((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$', 'i')
const validateURL = (input) => (urlRegExp.test(input) ? true : 'must be a valid URL')

export const firstNameField = { name: 'first_name', required: true }
export const lastNameField = { name: 'last_name', required: true }

export const emailField = {
  name: 'email', label: 'Email', required: true, inputProps: { textContentType: 'emailAddress', keyboardType: 'email-address', autoCompleteType: 'email', autoCapitalize: 'none' },
}

export const passwordField = {
  name: 'password', label: 'Password', required: true, inputProps: { textContentType: 'newPassword', secureTextEntry: true, autoCompleteType: 'password', autoCapitalize: 'none' },
}

export const passwordConfirmationField = {
  name: 'password_confirmation', label: "Confirm your password", required: true, inputProps: { textContentType: 'newPassword', secureTextEntry: true, autoCompleteType: 'password', autoCapitalize: 'none' },
}

const UserEmail = withAuth(({ user }) => (
  <View>
    <Text light small center>
      <Text italics light small center>login:</Text>
      {' '}
      {user.email}
    </Text>
  </View>
))

export const stageNameField = {
  name: 'stage_name',
  label: 'Artist Name',
  helpText: true,
}

export const locationField = {
  name: 'location',
  label: 'Location',
  render: ((props) => <RVCountrySelect {...props} />),
}

export const phoneNumberField = {
  name: 'phone_number',
  label: 'Phone Number',
  placeholder: '+1 (121) 123 31-31',
  inputProps: {
    type: 'phone-pad',
  },
  render: (Platform.OS !== 'web' && ((props) => <RVPhoneInput {...props} />)),
}

export const ipiField = {
  name: 'songwriter_information.ipi',
  validate: validateIPI,
  inputProps: {
    type: 'number',
  },
}

export const industryIDFields = {
  'Songwriter Details': [
    {
      name: 'songwriter_information.pro',
    },
    ipiField,
  ],
  'Artist Details': [
    {
      name: 'artist_information.isni',
      helpText: true,
      inputProps: {
        type: 'number',
      },
      validate: (input) => (/^\d{16}$/.test(input.replace(/ /g, '')) ? true : "Must contain 16 alphanumeric characters"),
    },
  ],
  'Publisher Details': [
    {
      name: 'publisher_information.publisher_company_name',
    },
    {
      name: 'publisher_information.publisher_company_contact',
    },
    {
      name: 'publisher_information.publisher_ipi',
      inputProps: {
        type: 'number',
      },
      validate: validateIPI,
    },
  ],
  'Label Details': [
    {
      name: 'label_information.label_company_name',
    },
    {
      name: 'label_information.label_company_contact',
    },
  ],
}

export const socialFields = (displayName = 'you') => [
  { name: 'social.website', placeholder: `https://${displayName}.com/` },
  { name: 'social.spotify', placeholder: `https://open.spotify.com/artist/${displayName}` },
  { name: 'social.apple_music', placeholder: `https://applemusic.com/artist/${displayName}` },
  { name: 'social.bandcamp', placeholder: `https://bandcamp.com/${displayName}` },
  { name: 'social.soundcloud', placeholder: `https://soundcloud.com/${displayName}` },
  { name: 'social.youtube', placeholder: `https://youtube.com/channels/${displayName}` },
  { name: 'social.instagram', placeholder: `https://instagram.com/${displayName}` },
  { name: 'social.tiktok', placeholder: `https://tiktok.com/${displayName}` },
  { name: 'social.twitter', placeholder: `https://twitter.com/${displayName}` },
  { name: 'social.facebook', placeholder: `https://facebook.com/${displayName}` },
  { name: 'social.resident_advisor', placeholder: `https://residentadvisor.com/dj/${displayName}` },
  { name: 'social.mixcloud', placeholder: `https://mixcloud.com/${displayName}` },
  { name: 'social.discogs', placeholder: `https://discogs.com/${displayName}` },
].map((f) => ({
  ...f,
  validate: validateURL,
}))

export const requiredProfileFields = [
  avatarField,
  { name: 'first_name', required: true },
  { name: 'last_name', required: true },
  stageNameField,
]

export const profileFields = [
  ...requiredProfileFields,
  {
    name: 'biography',
    inputProps: {
      type: 'textarea',
    },
  },
  locationField,
  phoneNumberField,
]