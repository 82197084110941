import routes from '@reveel/helpers/routes';
import { useCallback, useEffect, useState } from "react";
import { useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import searcher from '@reveel/helpers/searcher';

const key = ['projects'];
export const useProjects = () => {
  const [query, setQuery] = useState('');
  const [projects, setProjects] = useState([])

  const route = routes.api.projects.index
  const { isLoading, isFetching, error, data } = useQuery(key, async () => {
    const res = await axios.get(route)
    return res.data
  })
  const queryClient = useQueryClient()
  const refreshProjects = useCallback(() => queryClient.invalidateQueries(key), [queryClient])

  useEffect(() => {
    if(!data?.projects){
      return
    }
    if(query.length){
      const filtered = searcher(data.projects, { query, keys: ["title", "tracks.title"] })
      setProjects(filtered)
      return
    }

    setProjects(data.projects)
  }, [data, query, setProjects])
  return {
    projects,
    isLoading,
    isFetching,
    error,
    query,
    setQuery,
    refreshProjects,
  };
};
