import _ from "lodash";

export const generateSessionMarkup = (text, query, id) => {
  const markup = `[${query}]({ "session": { "id": ${id} } })`;
  return _.replace(text, query, markup);
};

export const generateUserMarkup = (text, query, id) => {
  const markup = `[${query}]({ "user": { "id": ${id} } })`;

  return _.replace(text, query, markup);
};
