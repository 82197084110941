import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, Platform } from 'react-native';
import View from '@reveel/components/ui/View';
import { Text } from '@reveel/components/ui';
import Button from '@reveel/components/ui/Button';

const FeatureCTA = ({ 
  i18nPrefix,
  onPress,
  button
}) => {

  return (
    <View flex mt-4 alignCenter style={[tw.pX2, styles.container]}>
      <Text center bold larger>
        {i18n.t(`placeholders.${i18nPrefix}.empty.title`)}
      </Text>
      <View mt-4 mb-4 alignStretch px>
        {button || <Button title={i18n.t(`placeholders.${i18nPrefix}.empty.cta`)} onPress={onPress} />}
      </View>
      <Text light smaller style={tw.textCenter}>{i18n.t(`placeholders.${i18nPrefix}.empty.more`)}</Text>
    </View>
  )
}

FeatureCTA.propTypes = {
  i18nPrefix: PropTypes.string.isRequired,
  onPress: PropTypes.func,
  button: PropTypes.element,
}
export default FeatureCTA

const styles = StyleSheet.create({
  container: {
    maxWidth: 400,

    ...Platform.select({
      web: {
        margin: 'auto',
      },
    }),
  },
})
