import React from 'react'
import { StyleSheet } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import * as Sentry from 'sentry-expo'
import { Text } from '@reveel/components/ui'
import HeaderMore from '../../components/core/header/HeaderMore'

import shareProfileAction from './shareProfileAction'
import { useAuth } from '@reveel/stores/useAuth';
import useMyProfile from '../../stores/useMyProfile'

const MoreButton = ({ }) => {
  const { user } = useAuth()
  const { myProfile } = useMyProfile()
  const navigation = useNavigation()
  const profileUrl = `profile/${myProfile?.id}`

  const contextOptions = [
    {
      label: 'Edit Profile',
      onPress: () => navigation.navigate('ProfileEdit'),
    },
    shareProfileAction(profileUrl),
  ].filter(Boolean)
  return (
    <>
      <HeaderMore style={styles.contextMenu} options={contextOptions} />
    </>
  )
}

export default React.memo(MoreButton)

export const styles = StyleSheet.create({
  contextMenu: {
    position: 'absolute',
    top: 50,
    right: 10,
    zIndex: 10,
  },
  shareIcon: {
    right: 20,
    top: 50,
    opacity: 0.3,
    position: 'absolute',
  },
})
