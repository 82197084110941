import React from "react"
import { StyleSheet } from "react-native"
import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'

function ScreenTitle({
  xs, row, title, center, style,
}){
  // eslint-disable-next-line no-nested-ternary
  const marginTop = row
    ? 0
    : xs
      ? -3
      : -10

  return (
    <View
      row={row}
      style={[
        xs ? styles.xsContainer : [tw.mL4, tw.mY10],
        center ? styles.center : undefined,
        style,
      ]}
    >
      {title[0] && (
        <Text xl={!xs} bold>
          {title[0]}
          {' '}
        </Text>
      )}
      {title[1] && (
        <Text
          xl={!xs}
          bold
          primary
          style={
            {
              marginTop,
            }
        }
        >
          {title[1]}
        </Text>
      )}
    </View>
  )
}
export default ScreenTitle

const styles = StyleSheet.create({
  container: {
    marginLeft: 20,
    marginVertical: 40,
  },
  center: {
    alignItems: 'center',
  },
})
