import React, { useCallback } from 'react'
import { Text } from '@reveel/components/ui'
import SessionsList from '../../components/core/SessionsList'
import RowWithActions from '../../components/RowWithActions'
import { creativeSessionName } from '../creativeSessionsFormatter'

export const url = {
  name: 'url',
  label: 'Paste a link',
  required: true,
  inputProps: {
    autoCorrect: false,
    autoCapitalize: 'none',
    keyboardType: 'url',
  },
}

export const trackID = {
  name: 'track_id',
  label: 'Track ID',
  inputProps: {
    type: 'hidden',
  },
}

export const name = {
  name: 'name',
  label: 'Link name',
}

export const description = {
  name: 'description',
  label: 'Description',
  inputProps: {
    type: 'textarea',
  },
}

export const creativeSession = {
  name: 'creative_session',
  label: 'Session',
  renderCustom: function CreativeSessionSelector({
    light,
    object: { track_id, creative_sessions_count, creative_session, ...rest },
    onValueChange,
  }){
    const renderSessionList = () => (
      <SessionsList
        inverted
        trackID={track_id}
        onPress={onValueChange}
        selected={[creative_session].filter(Boolean)}
      />
    )// , [track_id, creative_session_id, onValueChange])
    if(creative_sessions_count === 0){
      return null
    }
    return (
      <RowWithActions actions={renderSessionList} rowStyle={{ paddingBottom: 5 }}>
        <Text pl light={creative_session === undefined} style={{ flex: 1 }}>
          {!creative_session ? 'Select a session...' : creativeSessionName(creative_session)}
        </Text>
      </RowWithActions>
    )
  },
}
