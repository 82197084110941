import React from 'react'

import View from '@reveel/components/ui/View'
import Page from '@reveel/layout/Page'

import Loading from '../../../components/Loading'
import useTrackBreadcrumb from '../../../navigation/useTrackBreadcrumb'
import CreditsActions, { ViewPendingCredits } from './CreditsActions'
import { CreditSection } from './CreditSection'
import EmptyCredits from './EmptyCredits'
import useTrackCredits from './useTrackCredits'


const CREDIT_SECTIONS = [
  "artists",
  "songwriters",
  "producers",
  "musicians",
  "engineers",
  "managers",
  "others",
]

const TrackCredits = ({
  route,
}) => {
  const trackID = +route.params?.trackID
  useTrackBreadcrumb(trackID, ['Credits'])
  const { isLoading, credits } = useTrackCredits(trackID)

  const renderCredits = () => (
    // TODO: use FlatList
    <View style={tw.pT4}>
      {CREDIT_SECTIONS.map((section) => (
        <CreditSection
          key={section}
          section={section}
          contributors={credits[section]}
          style={[tw.pB4]}
        />
      ))}
    </View>
  )

  if(isLoading){
    return <Loading />
  }
  const hasCredits = Boolean(Object.keys(credits).length)
  if(!hasCredits){
    return (
      <>
        <EmptyCredits trackID={trackID} />
        <ViewPendingCredits trackID={trackID} />
      </>
    )
  }
  return (
      <Page
        sheet
        fixNotScrolling
        renderAbove={() => <CreditsActions trackID={trackID} />}
      >
        {renderCredits()}
      </Page>
  )
}

export default TrackCredits
