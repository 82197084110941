import { FontAwesome5 } from '@expo/vector-icons';
import Colors from '@reveel/constants/Colors';
import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet } from 'react-native';
import Box from './box.svg';
import Dropbox from './dropbox.svg';
import GoogleDrive from './google_drive.svg';
import Icloud from './icloud.svg';
import Soundcloud from './soundcloud.svg';
import Wetransfer from './wetransfer.svg';



const ServiceIcon = ({ host, size = 40 }) => {

  if(/dropbox/.test(host)){
    return <Dropbox width={size} height={size} />
  }
  if(/drive\.google/.test(host)){
    return <GoogleDrive width={size} height={size} />
  }
  if(/wetransfer/.test(host)){
    return <Wetransfer width={size} height={size} />
  }
  if(/box/.test(host)){
    return <Box width={size} height={size} />
  }
  if(/icloud/.test(host)){
    return <Icloud width={size} height={size} />
  }
  if(/soundcloud/.test(host)){
    return <Soundcloud width={size} height={size} />
  }
  return <FontAwesome5 name="file" size={size} color={Colors.lightBlack} />
}

ServiceIcon.propTypes = {
  host: PropTypes.string.isRequired,
}
export default ServiceIcon

const styles = StyleSheet.create({})