import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { PropTypes } from 'prop-types';

import ModalSheet from '../../../components/ui/modal/ModalSheet';
import InviteUsersDialog from '../../../components/invitation/InviteUsersDialog';

const AddCollaboratorCTA = ({ creativeSession, renderTrigger }) => {
  const navigation = useNavigation();
  const renderContent = ({ onClose }) => (
    <InviteUsersDialog
      creativeSession={creativeSession}
      navigation={navigation}
      onSuccess={onClose}
      style={[tw.pT4, tw.pX2]}
    />
  )

  return (
    <ModalSheet {...{
      renderTrigger,
      renderContent,
    }}
    />
  )
}

AddCollaboratorCTA.propTypes = {
  creativeSession: PropTypes.shape({
    id: PropTypes.number.isRequired,

  }).isRequired,
  renderTrigger: PropTypes.func.isRequired,
}

export default AddCollaboratorCTA
