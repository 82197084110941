import React, { useEffect, useState } from 'react'
import { Platform, StyleSheet } from 'react-native'

import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'
import { dateOnly, timeOnly, combineDateAndTime } from '@reveel/utils/dateFormatter'
import DateTimePicker from 'react-native-modal-datetime-picker'

const DateTimeField = ({
  type, value, onChange, placeholder, disabled, debug = true,
}) => {
  const showTime = type === 'datetime' || type === 'time'
  const showDate = type === 'datetime' || type === 'date'

  const [isVisible, setVisible] = useState(false)
  const [mode, setMode] = useState('date')

  const setDate = (v) => {
    let firstSetTime = hasSetTime
    if(mode === 'time'){
      firstSetTime = true
      setHasSetTime(firstSetTime)
    }
    if(v && !firstSetTime){
      v.setHours(8)
      v.setMinutes(0)
    }
    onChange(v)
  }
  const [hasSetTime, setHasSetTime] = useState(value !== undefined)

  const onConfirm = (v) => {
    setVisible(false)
    setDate(v)
  }

  const select = (mode) => {
    setMode(mode)
    setVisible(true)
  }

  const renderDate = () => (
    <View onPress={() => select('date')}>
      <Text light={value === undefined}>{value ? dateOnly(new Date(value)) : 'DD.MM.YYYY'}</Text>
    </View>
  )
  const renderTime = () => (
    <View onPress={() => select('time')}>
      <Text light={value === undefined}>{value ? timeOnly(new Date(value)) : 'HH:MM'}</Text>
    </View>
  )

  if(!isVisible){
    return (
      <View
        row
        style={{
          paddingVertical: 10,
          alignSelf: 'stretch',
        }}
      >
        {showDate && renderDate()}
        {showTime && showDate && <Text> - </Text>}
        {showTime && renderTime()}
      </View>
    )
  }

  // TODO: widget should show current date
  // when a value is already set
  return (
    <>
      <DateTimePicker
        isVisible
        value={value}
        onCancel={() => setVisible(false)}
        onConfirm={onConfirm}
        modalTransparent={false}
        mode={mode}
        locale="en_GB"
        is24Hour
        display="default"
        headerTextIOS={mode === 'time' ? 'Pick a time' : 'Pick a date'}
        placeholderText={placeholder}
      />
    </>
  )
}

export default DateTimeField
