import React from 'react';
import { Animated, Pressable } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Text } from '@reveel/components/ui';
import View from '@reveel/components/ui/View';
import Colors from '@reveel/constants/Colors';
import i18n from 'i18n-js';
import QRCode from 'react-native-qrcode-svg';
import { tw } from 'react-native-tailwindcss';
import { useRef, useEffect } from 'react';
import { useDesktop } from '@reveel/helpers/MediaQueries';

const MAX_HEIGHT = 600
const useStyles = ({ offset, isVisible }) => {
  const isDesktop = useDesktop()
  const translateY = useRef(new Animated.Value(offset)).current
  const maxHeight = useRef(new Animated.Value(0)).current
  useEffect(() => {
    if(!isDesktop){
      return
    }
    // translate to bottom if
    // modal changes height
    Animated.timing(
      translateY,
      {
        toValue: offset,
        duration: 0,
        useNativeDriver: false,
      },
    ).start();
  }, [translateY, offset])
  useEffect(() => {
    if(!isDesktop) {
      const toValue = isVisible ? MAX_HEIGHT : 0;
      Animated.timing(
        maxHeight,
        {
          toValue,
          duration: 50,
          useNativeDriver: false,
        }
      ).start();
      return
    }
    const toValue = isVisible ? 0 : offset;
    Animated.timing(
      translateY,
      {
        toValue,
        duration: 150,
        useNativeDriver: false,
      }
    ).start();
  }, [offset, isVisible]);

  if(isDesktop){
    return {
      height: offset,
      opacity: translateY.interpolate({
        inputRange: [0, offset],
        outputRange: [1, .5],
      }),
      top: 0,
      transform: [{ translateY }],
      position: 'absolute',
      paddingBottom: 40,
    }
  }
  return {
    height: 'auto',
    maxHeight,
    paddingBottom: maxHeight.interpolate({
      inputRange: [0, MAX_HEIGHT],
      outputRange: [0, 40],
    }),
  }
}


const QRWrapper = ({ url, isVisible, dismiss, offset }) => {
  const animatedStyle = useStyles({ isVisible, offset })
  const isDesktop = useDesktop()
  return (
    <Animated.View
      useNativeDriver
      style={[
        tw.bgWhite,
        tw.wFull,
        tw.overflowHidden,
        animatedStyle,
      ]}>
      <Text style={[tw.mB4, tw.leadingRelaxed]}>{i18n.t('tracks.ui.invite.qrTitle')}</Text>
      <View style={[tw.selfCenter]}>
        <QRCode size={150} value={url} logoSize={50} />
      </View>
      <Pressable onPress={dismiss} style={[tw.pL4, tw.pT4, tw.pB4, tw.absolute, tw.bottom0, tw.right0]}>
        <Ionicons name={isDesktop ? 'chevron-down' : 'chevron-up'} color={Colors.lightBlack} size={24} />
      </Pressable>
    </Animated.View>
  );
};

export default QRWrapper