import React, { useCallback, useState } from 'react'
import { Platform, StyleSheet, TouchableOpacity } from 'react-native'

import { Text } from '@reveel/components/ui'
import Colors from '@reveel/constants/Colors'
import { TRACK_ONBOARDED } from '@reveel/constants/Status'
import PageWrapper from '@reveel/screens/onboarding/PageWrapper'
import { useUpdateUser } from '@reveel/stores/useAuth'
import { SceneMap, TabBar, TabView } from 'react-native-tab-view'

import { HowDidYouFindOnboarding } from './HowDidYouFindOut'
import { OnboardingAvatar } from './OnboardingAvatar'
import { OnboardingCreatorID } from './OnboardingCreatorID'
import { OnboardingLocation } from './OnboardingLocation'
import { OnboardingSummary, useNavigateAfterOnboarding } from './OnboardingSummary'

export const Skip = ({ onPress }) => (
  <TouchableOpacity onPress={onPress} testID="Skip">
    <Text bold center my-1 light>Skip</Text>
  </TouchableOpacity>
)

// TODO: make this a filling progress bar
const renderTabBar = (props) => (
  <TabBar
    {...props}
    style={tabStyles.container}
    indicatorStyle={tabStyles.indicator}
    contentContainerStyle={tabStyles.contentContainer}
  />
)

const tabStyles = StyleSheet.create({
  container: {
    marginLeft: '15%',
    maxWidth: '70%',
    width: '70%',
    flexGrow: 0,
    backgroundColor: Colors.lightGrey,
  },
  contentContainer: {
    height: 2,
  },
  indicator: {
    backgroundColor: Colors.primary,
  },
})

const UserOnboarding = () => {
  const navigateAfterOnboarding = useNavigateAfterOnboarding()
  const { run } = useUpdateUser(navigateAfterOnboarding)
  const finishOnboarding = useCallback(() => run({ onboard_status: TRACK_ONBOARDED }), [run])
  const routes = [
    { key: 'creatorID' },
    { key: 'location' },
    { key: 'avatar' },
    { key: 'howDidYouFindOut' },
    // { key: 'summary' },
  ]
  const [nav, setNav] = useState({
    index: 0,
    routes,
  })
  const onNext = useCallback(() => setNav((prev) => ({ ...prev, index: prev.index + 1 })), [setNav])

  const renderScene = SceneMap({
    creatorID: useCallback(() => <OnboardingCreatorID onNext={onNext} />, [onNext]),
    location: useCallback(() => <OnboardingLocation onNext={onNext} />, [onNext]),
    avatar: useCallback(() => <OnboardingAvatar onNext={onNext} />, [onNext]),
    howDidYouFindOut: useCallback(() => <HowDidYouFindOnboarding onNext={finishOnboarding} />, [finishOnboarding]),
    // summary: useCallback(() => <OnboardingSummary />, []),
  })
  return (
    <PageWrapper noScroll>
      <TabView
        renderTabBar={renderTabBar}
        onIndexChange={(i) => setNav({ ...nav, index: i })}
        navigationState={nav}
        renderScene={renderScene}
        style={styles.container}
      />
    </PageWrapper>
  )
}

UserOnboarding.propTypes = {

}
export default UserOnboarding

const styles = StyleSheet.create({
  container: {
    ...Platform.select({
      native: {
        marginTop: 15,
      },
      web: {
        marginTop: 30,
        maxHeight: 600 - 30,
        margin: 10,
        alignItems: 'flex-start',
      },
    }),
  },
})
