import { Link } from '@react-navigation/native'
import Colors from '@reveel/constants/Colors'
import React, { useState } from 'react'
import { Pressable, StyleSheet } from 'react-native'
import Collapsible from 'react-native-collapsible'
import LeaveFeedbackDialog from '../../components/Feedback/LeaveFeedbackDialog'
import View from '../../components/ui/View'
import { TouchableOpacity } from 'react-native';
import { Text } from '@reveel/components/ui';
import { Icon } from 'native-base';


const ExtraLinks = () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <View py px-2 border-t>
      <Collapsible collapsed={isOpen}>
        <Link style={styles.link} to="/invite-a-friend">
          Share Reveel
        </Link>
        {/* disabled as no one goes to discord ftm.. */}
        {/* <Link style={styles.link} to="https://discord.gg/4PbEzzGQ5C" target="_blank">
          Join us on Discord
        </Link> */}
        <Pressable onPress={() => setIsOpen(!isOpen)}>
          <Text style={styles.link}>Ask a Question / Give Feedback</Text>
        </Pressable>
        <Link style={styles.link} to="https://api.reveel.id/terms-and-conditions" target="_blank">
          Terms & Conditions
        </Link>
      </Collapsible>
      {/* <Link style={styles.link} to="https://reveel.id/faqs" target="_blank">
        FAQ
      </Link> */}
      <Collapsible collapsed={!isOpen}>
        <View style={tw.pY4}>
          <LeaveFeedbackDialog onSubmit={() => setTimeout(() => setIsOpen(false), 2500)} />
          <Pressable style={[tw.absolute, tw.right0, tw.top0]} onPress={() => setIsOpen(false)}>
            <Icon style={tw.opacity50} name="ios-close" />
          </Pressable>
        </View>
      </Collapsible>
    </View>
  )
}

export default ExtraLinks

const styles = StyleSheet.create({
  link: {
    paddingVertical: 5,
    color: Colors.lightBlack,
    fontWeight: 'bold',
  },
})
