import routes from '@reveel/helpers/routes';
import { useQuery } from 'react-query';
import axios from 'axios';



export const friendsKey = ['friends'];
export const useFriendShips = () => {
  const route = routes.api.user.friends.index;
  const { isLoading, error, data }: { data: FriendsData, isLoading: boolean, error: { message: string} } = useQuery(friendsKey, async () => {
    const res = await axios.get(route);
    return res.data;
  });

  return {
    isLoading,
    error,
    data,
  };
};
