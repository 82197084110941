import React from 'react'
import {
  Text, View, StyleSheet, TouchableOpacity,
} from 'react-native'
import { Tooltip } from 'react-native-elements'

const LabelHelper = ({
  body,
  light,
  color = light ? '#FFFFFF77' : '#00000077',
  onPress,
}) => {
  const question = <Text style={{ ...styles.questionMark, color, borderColor: color }}>?</Text>
  return !(body || onPress) ? null
    : (
      <View style={styles.tooltip}>
        {body ? (
          <Tooltip
            popover={<Text style={{ color: 'white' }}>{body}</Text>}
            withOverlay={false}
            backgroundColor="#000000DD"
          >
            {question}
          </Tooltip>
        )
          : <TouchableOpacity onPress={onPress}>{question}</TouchableOpacity>}
      </View>
    )
}

const styles = StyleSheet.create({
  tooltip: {
    position: 'relative',
    right: 0,
    paddingTop: 5,
    top: 5,
  },
  questionMark: {
    borderWidth: 1,
    width: 20,
    height: 20,
    borderRadius: 10,
    textAlign: 'center',
  },
})

export default LabelHelper
