import Constants from 'expo-constants'
import { Platform } from 'react-native'
import * as Notifications from 'expo-notifications'
import axios from 'axios'

import * as Permissions from 'expo-permissions'
import * as Sentry from 'sentry-expo'
import routes from '../helpers/routes'
import requestPermission from '../utils/requestPermission'

const PERMISSION_NAME = 'NOTIFICATIONS'

async function getExpoPushTokenAsync(){
  if(Constants.appOwnership !== 'standalone'){ return { data: `${Math.ceil(Math.random() * 1e9)}-fake-token` } }
  const experienceId = "@walidvb/reveel"
  // this now returns { data: token, type: ios|android|web }
  // see https://docs.expo.io/versions/latest/sdk/notifications/#getdevicepushtokenasync-devicepushtoken
  const expoPushToken = await Notifications.getExpoPushTokenAsync({
    experienceId,
  })
  return expoPushToken
}

export async function registerDevice(){
  const token = await hadPermission() ? await getExpoPushTokenAsync().data : undefined
  return axios.post(routes.api.user.device, {
    device: {
      token,
      model: Constants.deviceName,
      app_version: Constants.nativeAppVersion,
      os: Platform.OS,
    },
  })
}

export async function updateDeviceToken(){
  try{
    registerDevice()
  } catch(err){
    console.log('Could not register device')
  }
}

export async function unregisterDevice(){
  if(await hadPermission()){
    try{
      const token = await getExpoPushTokenAsync()
      axios.delete(`${routes.api.user.device}/${token}`)
    }catch(err){
      Sentry.captureException(err)
    }
  }
}

export async function registerForPushNotificationsAsync({ askEvery } = { askEvery: [1, 2, 6, 11, 21, 31, 51, 101] }){
  try{
    requestPermission(registerDevice, {
      permissionNames: [PERMISSION_NAME],
      alertTitle: '🎶Notifications🎶',
      alertBody: 'Get notified when your session has closed and never miss an opportunity to enter your contributions.',
      askEvery,
    })
  }catch(err){
    console.log('Could not request permission', PERMISSION_NAME)
  }
}

const hadPermission = async () => await Permissions.getAsync(Permissions[PERMISSION_NAME]) === 'granted'
