import React from 'react'
import { Text } from 'react-native'
import Loading from '../components/Loading'

export class ErrorBoundary extends React.Component{
  constructor(props){
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info){
    // Display fallback UI
    this.setState({ hasError: true })
    // You can also log the error to an error reporting service
    logErrorToMyService(error, info)
  }

  render(){
    if(this.state.hasError){
      // You can render any custom fallback UI
      <Loading error="Oops" />
    }
    return this.props.children
  }
}

export const withErrorhandler = (Component) => (props) => (
  <ErrorBoundary>
    <Component {...props} />
    <Text>Error rendering component</Text>
  </ErrorBoundary>
)
