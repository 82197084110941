import React from 'react'
import Constants from 'expo-constants'
import { Platform, StyleSheet } from 'react-native'
import { Text } from 'native-base'
import Colors from '@reveel/constants/Colors'
import View from '@reveel/components/ui/View'
import * as Updates from 'expo-updates'
import CURRENT_VERSION from '../../helpers/CURRENT_VERSION'

const VersionInfo = (props) => (
  <View {...props} style={styles.container}>
    <Text light xs>
      version
      {' '}
      {CURRENT_VERSION}
      {' '}
      -
      {' '}
      {Updates.releaseChannel || 'local'}
    </Text>
  </View>
)

export default VersionInfo

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.lightBackground,
    paddingVertical: 15,
    alignItems: Platform.OS === 'ios' ? 'flex-end' : 'flex-start',
    paddingRight: 15,
  },
})
