import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet } from 'react-native';
import View from '@reveel/components/ui/View';
import Label from './Label';
import { Text } from '@reveel/components/ui';

// TODO: update Form to use this!
const TextField = ({ label, name, value, style }) => {

  return (
    <View border-b style={[tw.mL2, tw.pB2, style]}>
      {label?.length && <Label label={label} />}
      <Text>{value}</Text>
    </View>
  )
}

export default TextField

const styles = StyleSheet.create({})