import React from 'react'

import 'react-multi-email/style.css';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import { Text } from '@reveel/components/ui';

const EmailInputter = ({
  onChange,
  emails
}) => {
  return <ReactMultiEmail
    placeholder="Enter email addresses, separated by commas"
    emails={emails}
    onChange={onChange}
    validateEmail={email => {
      return isEmail(email); // return boolean
    }}
    style={{ minHeight: 120, fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'}}
    getLabel={(
      email: string,
      index: number,
      removeEmail: (index: number) => void,
    ) => {
      return (
        <div data-tag key={index}>
          <Text>{email}</Text>
          <span data-tag-handle onClick={() => removeEmail(index)}>
            ×
          </span>
        </div>
      );
    }}
  />
}

export default EmailInputter