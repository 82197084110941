// @flow

import variable from './../variables/platform';
import Colors from '@reveel/constants/Colors';

export default (variables /*: * */ = variable) => {
  const inputTheme = {
    height: variables.inputHeightBase,
    '.multiline': {
      height: null,
    },
    '.small': {
      height: null,
      paddingTop: 5,
      paddingBottom: 5,
      alignContent: 'center',
    },
    color: variables.inputColor,
    paddingLeft: 5,
    paddingRight: 5,
    flex: 1,
    fontSize: variables.inputFontSize
  };

  return inputTheme;
};
