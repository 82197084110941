import React from 'react'
import { Platform, ScrollView, StyleSheet } from 'react-native'

import { observe } from '@nx-js/observer-util'
import KeyboardSpacerIOS from '@reveel/components/KeyboardSpacerIOS'
import View from '@reveel/components/ui/View'
import Colors from '@reveel/constants/Colors'
import { flattenObjectToArray } from '@reveel/utils/flatten'
import humanize from '@reveel/utils/humanize'
import i18n from 'i18n-js'
import _, { cloneDeep, pick } from 'lodash'
import { Form, Input, Item } from 'native-base'
import { PropTypes } from 'prop-types'
import { store, view } from 'react-easy-state'
import RNPickerSelect from 'react-native-picker-select'

import { Text } from '..'
import variables from '../../../config/variables'
import Button from '../Button'
import Label from '../Label'
import DateTimeField from './DateTimeField/index.native'

const pickFieldsFromList = (obj, fields) => {
  const allFieldNames = flattenObjectToArray(fields).reduce((prev, curr) => {
    if(curr.name){
      return [curr.name, ...prev]
    }
    const subArrays = Object.keys(curr).reduce((subPrev, currKey) => [
      ...(Array.isArray(curr[currKey]) ? curr[currKey].map((f) => f.name) : []),
      ...subPrev,
    ], [])
    return [...subArrays, ...prev]
  }, [])
  return {
    ...cloneDeep(
      pick(obj, allFieldNames) || {},
    ),
  }
}

class FormItems extends React.Component{
  // this might not work if two field names collide
  tmpObject = store(pickFieldsFromList(this.props.formObject, this.props.fields))

  state = {
    validations: {},
  }

  componentDidMount(){
    this.observeToValidate()
  }

  observeToValidate(){
    observe(async () => {
      const allRequiredFilled = this.isRequiredValidated()
      const allValidated = this.validateFormat({ skipSettingState: true })
      const valid = allRequiredFilled && allValidated
      this.setState({ disabled: !valid })
    })
    this.validateFormat = this.validateFormat.bind(this)
  }

  isRequiredValidated(){
    return flattenObjectToArray(this.props.fields).reduce((memo, curr) => {
      const value = _.get(this.tmpObject, curr.name, '')
      const thisField = !curr.required ? true : Boolean(value && value !== '')
      return memo && thisField
    }, true)
  }

  validateFormat({ skipSettingState = false } = { }){
    return flattenObjectToArray(this.props.fields).reduce((prev, f) => {
      const value = _.get(this.tmpObject, f.name)
      if(!f.validate || !value){ return prev }
      const validationStatus = f.validate(value, this.tmpObject)
      if(!skipSettingState){
        console.log('invalid', f.name, value, validationStatus)
        this.setState({
          validations: {
            [f.name]: validationStatus,
          },
        })
      }
      return prev && validationStatus === true
    }, true)
  }

  render(){
    const defaultButton = (onPress, disabled, loading) => (
      <Button
        onPress={onPress}
        disabled={disabled}
        loading={loading}
        {...(light ? { bordered: true, light: true } : {})}
        block
        title={buttonText}
      />
    )

    const {
      onSubmit,
      fields,
      errors,
      light,
      color = light ? Colors.white : Colors.black,
      errorColor = !light ? Colors.errorText : 'white',
      buttonText = 'Save',
      renderButton = defaultButton,
      renderHeader,
      renderFooter,
      style = {},
      buttonContainerStyle = styles.buttonContainerStyle,
      disableAll = false,
      loading,
      withKeyboardSpacer = false,
      i18nPrefix,
    } = this.props
    const placeholderColor = `${color}77`
    const { disabled } = this.state

    let { labelType = 'stackedLabel' } = this.props

    // custom floatingLabel with tooltip helpers disappear on android 🤷🏼‍♂️
    if(Platform.OS === 'ios' && labelType === "floatingLabel"){
      labelType = 'stackedLabel'
    }
    // renderCustom is used to render the AvatarPicker, which immediately saves the image to the server,
    // without going through the FormItems...
    const renderSingleField = (field) => {
      const { name, label, placeholder, tooltip, helpText, required, inputProps = {}, render, renderCustom } = field
      const { type, options } = inputProps
      if(type === 'hidden'){
        return null
      }
      if(!name && !renderCustom){
        return sectionedFields(field)
      }
      const value = _.get(this.tmpObject, name)
      const hasError = Boolean(errors && errors[name])
      if(typeof renderCustom === 'function'){
        return (
          <View px>
            {renderCustom({
              light,
              value,
              disabled: disableAll,
              object: this.tmpObject,
              onValueChange: (value) => _.set(this.tmpObject, name, value),
            })}
          </View>
        )
      }

      const invalid = typeof this.state.validations[name] === 'string'
      const label_ = label !== false ? label || i18n.t(`${i18nPrefix}.${name}.label`, { defaultValue: humanize(name) }) : undefined
      const tooltip_ = tooltip || i18n.t(`${i18nPrefix}.${name}.tooltip`, { defaultValue: '' })
      const placeholder_ = placeholder || i18n.t(`${i18nPrefix}.${name}.placeholder`, { defaultValue: '' })
      const star = () => required && <Text style={[styles.star, { color }]}>*</Text>
      const moreSettings = {}
      const renderInput = () => {
        if(typeof render === 'function'){
          return (
            <View alignStretch pt>
              {render({
                light,
                value,
                object: this.tmpObject,
                onValueChange: (value) => _.set(this.tmpObject, name, value),
              })}
            </View>
          )
        }
        if(type === 'hidden'){
          return null
        }
        if(['date', 'datetime', 'time'].includes(type)){
          return (
            <DateTimeField
              value={_.get(this.tmpObject, name)}
              onChange={(value) => _.set(this.tmpObject, name, value)}
              placeholderText={placeholder}
              disabled={disableAll}
              {...inputProps}
            />
          )
        }
        if(type === 'select'){
          return (
            <RNPickerSelect
              onValueChange={(v) => _.set(this.tmpObject, name, v)}
              value={value}
              placeholder={{ label: "Select the session type", value: '' }}
              items={options.map((s) => ({ label: humanize(s), value: s, key: s }))}
              style={pickerStyles}
            />
          )
        }
        if(type === 'textarea'){
          moreSettings.multiline = true
          moreSettings.numberOfLines = 4
        }
        const onValueChange = (v) => {
          _.set(this.tmpObject, name, v)
          if(this.state.currentInvalid === name){
            this.validateFormat()
          }
        }
        const setCurrentInvalidEditing = () => {
          if(invalid){
            this.setState({
              currentInvalid: name,
            })
          }
        }
        const onBlur = () => {
          this.validateFormat()
          this.setState({
            currentInvalid: undefined,
          })
        }
        return (
          <Input
            onChangeText={onValueChange}
            defaultValue={value}
            placeholder={placeholder_}
            placeholderTextColor={placeholderColor}
            {...moreSettings}
            {...inputProps}
            style={[{ color, marginTop: 10 }, { textAlignVertical: moreSettings.multiline ? 'top' : 'auto' }]}
            disabled={disableAll}
            onBlur={onBlur}
            onFocus={setCurrentInvalidEditing}
            testID={name}
          />
        )
      }

      const helpText_ = helpText && <Text small light style={{ flexWrap: 'wrap' }}>{i18n.t(`${i18nPrefix}.${name}.helpText`, { defaultValue: helpText })}</Text>
      const collapsedKey = `${name}-collapsed`
      return (
        <View mb-2 key={`${name}-wrapper`}>
          <Item
            key={name}
            {...{ [labelType]: true }}
            error={hasError || invalid}
          >
            {label_ && (
            <Label
              tooltip={tooltip_}
              onPress={helpText ? () => this.setState({
                [collapsedKey]: Boolean(!this.state[collapsedKey]),
              }) : null}
              style={{ color, textTransform: label_ ? undefined : 'capitalize' }}
              {...{ light }}
            >
              {label_}
              {star()}
              {' '}
              <Text small red>
                {hasError && errors[name]}
                {' '}
                {invalid && this.state.validations[name]}
                {' '}
              </Text>
            </Label>
            )}
            {renderInput()}
          </Item>
          {helpText_ && (
          // <Collapsible key={collapsedKey} collapsed={!this.state[collapsedKey]}>
            <View style={styles.helpText}>{helpText_}</View>
          // </Collapsible>
          )}
        </View>
      )
    }

    const sectionedFields = (fields_) => Object.keys(fields_).map((section) => {
      if(!Array.isArray(fields_[section])){
        return renderCurrent(fields_[section])
      }
      return (
        <View key={`section${section}`}>
          <Item noBorder>
            <Text bold white={light} style={styles.sectionTitle}>
              {i18n.t(`${i18nPrefix}.sections.${section}`, { defaultValue: humanize(section) })}
            </Text>
          </Item>
          {fields_[section].map(renderSingleField)}
        </View>
      )
    })

    const renderCurrent = (fields_) => (Array.isArray(fields_) ? fields_.map(renderSingleField) : sectionedFields(fields_))
    const mappedFields = renderCurrent(fields)

    return (
      <View style={[styles.container, style]}>
        <ScrollView contentContainerStyle={tw.pB4}>
          {typeof renderHeader === 'function' && renderHeader()}
          <Form>
            <Item style={styles.errors}>
              {(typeof errors === 'string') && <Text small center style={{ color: errorColor }}>{errors}</Text>}
            </Item>
            {mappedFields}
          </Form>
          {renderFooter?.()}
        </ScrollView>
        <View style={buttonContainerStyle}>
          { this.props.renderBeforeButton?.()}
          {(!disableAll && renderButton) && renderButton(() => onSubmit(this.tmpObject), disabled, loading)}
          { this.props.renderAfterButton?.()}
        </View>
        { withKeyboardSpacer && <KeyboardSpacerIOS />}
      </View>
    )
  }
}

FormItems.propTypes = {
  navigation: PropTypes.object,
  formObject: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  fields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  light: PropTypes.bool,
  color: PropTypes.string,
  errorColor: PropTypes.string,
  buttonText: PropTypes.string,
  renderButton: PropTypes.func,
  renderHeader: PropTypes.func,
  // style: PropTypes.style,
  // buttonContainerStyle: PropTypes.style,
  disableAll: PropTypes.bool,
  loading: PropTypes.bool,
  withKeyboardSpacer: PropTypes.bool,
  i18nPrefix: PropTypes.string,
}

export default view(FormItems)

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    flex: 1,
    position: 'relative',
  },
  sectionTitle: {
    marginTop: 20,
    marginBottom: 5,
  },
  buttonContainerStyle: {
    paddingHorizontal: 15,
    marginBottom: 15,
  },
  star: {
    lineHeight: 18,
  },
  errors: { borderBottomWidth: 0, marginLeft: 0, justifyContent: 'center' },
  helpText: {
    marginLeft: 15,
    marginBottom: 20,
    paddingRight: 5,
    marginTop: 10,
  },
  labelError: {

  },
})

// RNPicker expects one style per OS
const sharedPickerStyles = {
  color: 'black',
  fontSize: variables.fontSizeBase,
  marginTop: 15,
}
const pickerStyles = StyleSheet.create({
  inputIOS: sharedPickerStyles,
  inputAndroid: sharedPickerStyles,
})
