import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'

import { Feather, Ionicons } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'
import { dateAndTimeShort } from '@reveel/utils/dateFormatter'
import PropTypes from 'prop-types'

import ServiceIcon from '../../../../assets/images/services/ServiceIcon'
import Colors from '../../../constants/Colors'
import { creativeSessionName } from '../../../helpers/creativeSessionsFormatter'
import openURL from '../../../utils/openURL'
import { useAttachmentMeta } from '../../sessions/sessions/Attachment'

export const AttachmentDetailsLine = ({ children, attachment: { created_at, url, creative_session, user: { display_name } }, ...viewProps }) => {
  const detailsTextProps = {
    light: true,
    smaller: true,
    "mr-2": true,
  }
  const { host } = useAttachmentMeta({ url })
  return (
    <>
      <View row {...viewProps}>
        <Text {...detailsTextProps}>{creativeSessionName(creative_session) || host}</Text>
        <Text {...detailsTextProps}>{dateAndTimeShort(created_at)}</Text>
        <Text {...detailsTextProps}>{display_name}</Text>
        {children}
      </View>
    </>
  )
}

// eslint-disable-next-line camelcase
const AttachmentSummary = ({ style, attachment, title, noActions = false }) => {
  const { name, url, id, messages_count } = attachment
  const openLink = () => openURL({ href: url, target: '_blank' })
  const navigation = useNavigation()
  const { host } = useAttachmentMeta({ url })
  const goToAttachment = () => navigation.navigate('AttachmentShow', { trackID: attachment.attachable_id, attachmentID: id })

  return (
    <View row py alignTop style={style}>
      <View style={styles.icon}>
        <ServiceIcon host={host} size={40} />
      </View>
      <View>
        <TouchableOpacity testID={`attachment-${id}`} onPress={goToAttachment}>
          <View flex>
            <Text>{title || name || 'Untitled'}</Text>
            <AttachmentDetailsLine flex attachment={attachment} />
          </View>
        </TouchableOpacity>
        {!noActions && (
        <View row mt-1>
          <TouchableOpacity style={styles.row} onPress={openLink}>
            <Text smaller light mr-2>
              <Ionicons name="md-open" color={Colors.primary} size={16} />
              {'  '}
              <Text smaller light underline>Go to link</Text>
            </Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.row} onPress={goToAttachment}>
            <Text smaller light>
              <Feather name="message-circle" color={Colors.primary} size={16} />
              {' '}
              {messages_count ? 'Add a comment' : `Comments (${messages_count})`}
            </Text>
          </TouchableOpacity>
        </View>
        )}
      </View>
    </View>

  )
}

const styles = StyleSheet.create({
  icon: {
    width: 40,
    marginRight: 15,
    marginLeft: 5,
    alignContent: 'flex-start',
    alignSelf: 'stretch',
    alignItems: 'center',
  },
})

AttachmentSummary.propTypes = {
  attachment: PropTypes.shape({
    id: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    attachable_type: PropTypes.oneOf(["Track"]).isRequired,
    attachable_id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    user: PropTypes.shape({
      name: PropTypes.string,
      display_name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default AttachmentSummary
