import { useRef, useEffect } from 'react'
import { setScreen } from "../analytics"

const getActiveRouteName = (state) => {
  const route = state.routes[state.index]
  if(route.state){
    return getActiveRouteName(route.state)
  }
  return {
    name: route.name,
    params: route.params,
  }
}

const useOnNavigationStateChange = (navigationRef) => {
  const routeNameRef = useRef()

  useEffect(() => {
    if(!navigationRef.current){ return }
    const state = navigationRef.current.getRootState()
    routeNameRef.current = getActiveRouteName(state)
  }, [navigationRef])

  const onNavigationStateChange = (state) => {
    const previousRouteName = routeNameRef.current
    const { name: currentRouteName, params } = getActiveRouteName(state)

    if(previousRouteName !== currentRouteName){
      setScreen(currentRouteName, params)
    }
    routeNameRef.current = currentRouteName
  }
  return onNavigationStateChange
}
export default useOnNavigationStateChange
