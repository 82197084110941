import { useActionSheet } from '@expo/react-native-action-sheet'
import { Ionicons } from '@expo/vector-icons'
import Colors from '@reveel/constants/Colors'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import {
  StyleSheet,
  TouchableOpacity,
} from 'react-native'
import MenuItemWithDropdown from '@reveel/navigation/createScreenHeader/MenuWithDropdown';
import { DropdownItem } from '@reveel/navigation/createScreenHeader/HeaderAccount';
import FilePicker from '../../ui/Form/FilePicker/index.web';
import { Text } from '@reveel/components/ui';
import View from '@reveel/components/ui/View';


const CustomActions = ({ wrapperStyle, onSend, extraButtons }) => {
  const onImageSelected = useCallback((image) => onSend([{ image }]))

  return (
    <MenuItemWithDropdown
      testID="custom-actions"
      renderTrigger={() => (
        <View style={[styles.wrapper, wrapperStyle]}>
          <Ionicons name="ios-attach" size={20} />
        </View>
      )}
      size="sm"
      shifted
      position="top"
      top={20}
    >
      <FilePicker onSelect={onImageSelected}>
        <View px-1 py>
          <Text>Send Image</Text>
        </View>
      </FilePicker>
      {extraButtons && extraButtons.map(button => (
        <DropdownItem key={button.label} title={button.label} onPress={button.onPress} />
      ))}
    </MenuItemWithDropdown>
  )
}

export default CustomActions

const styles = StyleSheet.create({
  container: {
  },
  wrapper: {
    width: 26,
    height: 26,
    marginLeft: 10,
    marginBottom: 5,
    borderRadius: 13,
    backgroundColor: Colors.lightBackground,
    textAlign: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },

})

CustomActions.defaultProps = {
  onSend: () => { },
  options: {},
  renderIcon: null,
  containerStyle: {},
  wrapperStyle: {},
  iconTextStyle: {},
}

CustomActions.propTypes = {
  onSend: PropTypes.func,
  options: PropTypes.object,
  renderIcon: PropTypes.func,
}
