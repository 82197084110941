import React from 'react'
import PropTypes from 'prop-types'
import { ScrollView, StyleSheet } from 'react-native';
import View from '@reveel/components/ui/View';
import ScreenTitle from '../ScreenTitle';
import { Text } from '@reveel/components/ui';

const TitleWithSheet = ({ 
  title,
  subtitle,
  children
}) => {
  return (
    <View style={{ flex: 1 }}>
      <ScreenTitle
        title={title}
        style={[
        ]}
      />
      <View style={tw.pX4}>
        {subtitle && <Text>{subtitle}</Text>}
      </View>
      <View sheet style={[{ flex: 1 }, tw.mT4, tw.pb2]}>
        <ScrollView contentContainerStyle={[tw.flexGrow]} style={[]}>
          {children}
        </ScrollView>
      </View>
    </View>
  )
}

TitleWithSheet.propTypes = {
  subtitle: PropTypes.string,
  children: PropTypes.element
}
export default TitleWithSheet

const styles = StyleSheet.create({})