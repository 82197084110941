import React from 'react'

import { createStackNavigator } from '@react-navigation/stack'

import TrackOnboarding from './TrackOnboarding'
import UserOnboarding from './UserOnboarding/index'

const OnboardingStack = createStackNavigator()

function OnboardingNavigator(){
  const options = {
    noSidebar: true,
    headerShown: false,
  }
  return (
    <>
      <OnboardingStack.Screen options={options} name="UserOnboarding" component={UserOnboarding} />
      <OnboardingStack.Screen options={options} name="TrackOnboarding" component={TrackOnboarding} />
    </>
  )
}

export default OnboardingNavigator
