import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet } from 'react-native';
import View from '@reveel/components/ui/View';
import { Text } from '@reveel/components/ui';

const Label = ({ label, style }) => {
  return (
    <View style={style}>
      <Text light style={[tw.textXs, tw.mB1]}>{label}</Text>
    </View>
  )
}

Label.propTypes = {
  label: PropTypes.string.isRequired
}
export default Label

const styles = StyleSheet.create({})