import React from 'react'
import { Platform, StyleSheet, TouchableOpacity } from 'react-native'
import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'
import Colors from '@reveel/constants/Colors'
import { useDesktop } from '@reveel/helpers/MediaQueries'
import routes from '@reveel/helpers/routes'
import { useAsync } from '@reveel/hooks/useRemoteState'
import { useAuth } from '@reveel/stores/useAuth'
import MenuItemWithDropdown from '../../navigation/createScreenHeader/MenuWithDropdown'

const EMOJIS = {
  heart: '❤️',
  thumbs_down: '👎',
  thumbs_up: '👍',
  laugh: '😁',
}

const Action = ({ emoji, id, onPress }) => {
  const { run, loading } = useAsync({
    url: routes.api.messages.reactions(id),
    method: 'post',
  })

  const addReaction = () => {
    // close popup before getting reply from server
    onPress()
    run({ emoji })
  }
  return (
    <TouchableOpacity style={styles.emoji} onPress={addReaction} testID={`message-reaction-${emoji}`}>
      <Text small>{EMOJIS[emoji]}</Text>
    </TouchableOpacity>
  )
}

export const MessageActions = ({ message: { id } }) => {
  const trigger = () => <View px py><Text light>...</Text></View>
  const renderContent = (onClose) => (
    <View row px-05 my-1 justifyAround>
      {Object.keys(EMOJIS).map((kk) => <Action id={id} emoji={kk} key={kk} onPress={onClose} />)}
    </View>
  )
  return (
    <MenuItemWithDropdown
      size="sm"
      position="top"
      renderContent={renderContent}
      renderTrigger={trigger}
      testID={`message-actions-${id}`}
    />
  )
}

const Reaction = ({ emoji, id, reactions }) => {
  const { user: { id: myID } } = useAuth()
  const hasReacted = reactions.includes(myID)
  const reactionsCount = reactions.length

  const isDesktop = useDesktop()
  const { run } = useAsync({
    url: `${routes.api.messages.reactions(id)}?emoji=${emoji}`,
    method: 'delete',
  })

  const backgroundColor = hasReacted ? `${Colors.primary}BB`
    : isDesktop ? Colors.white
      : Colors.lightBackground

  const deleteReaction = () => run({ emoji })
  return (
    <TouchableOpacity
      style={[
        styles.emoji,
        styles.reaction,
        hasReacted && styles.hasReacted,
        {
          backgroundColor,
        },
      ]}
      disabled={!hasReacted}
      onPress={deleteReaction}
      testID="message-reaction-heart"
    >
      <Text xs white={hasReacted}>
        {EMOJIS[emoji]}
        {' '}
        {reactionsCount}
        {' '}
      </Text>
    </TouchableOpacity>
  )
}

export const MessageReactions = ({ message }) => {
  const { reactions } = message
  return (
    <View row mb>
      {Object.keys(reactions).map((kk) => <Reaction id={message.id} emoji={kk} reactions={reactions[kk]} key={kk} />)}
    </View>
  )
}

const styles = StyleSheet.create({
  emoji: {
    ...Platform.select({
      web: {
        paddingHorizontal: 5,
        itemsCenter: 'center',
      },
      native: {
        padding: 3,
      },
    }),
    marginRight: 5,
  },
  reaction: {
    paddingHorizontal: 3,
    borderRadius: 17,
  },
  hasReacted: {
    backgroundColor: `${Colors.primary}BB`,
  },
})
