import { useState, useEffect } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'

let listeners = []

export const CONTRIB_KEY = '@MR:tmp_CONTRIBS'

export const clearContribsFromStorage = () => {
  AsyncStorage.removeItem(CONTRIB_KEY)
}

const useContributions = (initialSkills = []) => {
  const [contributions, setContributions] = useState(initialSkills)

  const setStateForAll = (state) => {
    // AsyncStorage.setItem(CONTRIB_KEY, JSON.stringify(state))
    listeners.forEach((l) => l(state))
  }

  useEffect(() => {
    listeners.push(setContributions)
    return () => { listeners = listeners.filter((l) => l !== setContributions) }
  }, [])
  useEffect(() => {
    // AsyncStorage.getItem(CONTRIB_KEY)
    //   .then((c) => c && setStateForAll(JSON.parse(c)))
  }, [])

  const toggleContribution = (skill) => {
    let newContributions = [...contributions]
    if(contributions.find((s) => s.id === skill.id)){
      newContributions = contributions.filter((s) => s.id !== skill.id)
      setStateForAll(newContributions)
    }else{
      newContributions = [...contributions, skill]
      setStateForAll(newContributions)
    }
  }
  return [contributions, toggleContribution]
}
export default useContributions
