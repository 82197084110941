import React, { useEffect, useState } from 'react'
import i18n from 'i18n-js'
import { ScrollView, StyleSheet, View } from 'react-native'

import Colors from '@reveel/constants/Colors'
import useRemoteState from '@reveel/hooks/useRemoteState'
import ContributionsList from '../contributions/ContributionsList'
import { PENDING_VALIDATION } from '../../constants/Status'
import HeaderCloseButton from '../../components/HeaderCloseButton'
import BottomSheetTitle from '../../components/core/BottomSheetTitle'
import { ValidationProvider } from "./validationContext"

import routes from '../../helpers/routes'

// this screen will likely be deprecated in favour of
// TrackValidate.
// TODO: currently the creativeSession does not update status
// which is a bug.
// Maybe it will make sense to have a button leading to TrackValidate
// instead of replicating here.

const ValidateSession = {}
ValidateSession.screen = ({ navigation, creativeSession }) => {
  useEffect(() => {
    const { status } = creativeSession
    const backText = status === PENDING_VALIDATION ? 'Cancel' : 'Done'
    navigation.setOptions({
      title: `Validate ${creativeSession?.track_title}`,
      headerLeft: () => <HeaderCloseButton navigation={navigation} text={backText} />,
    })
  }, [creativeSession.track_title])

  // can.validate should come from the validationContext
  const sheetTitle = i18n.t(`creativeSessions.header.${creativeSession.status}.${creativeSession.can.validate ? 'validate' : 'wait'}`)
  // We use a Provider to give access to actions to deep nested children
  const [track, setTrack] = useState({ creative_sessions: [] })
  const { loading } = useRemoteState({
    url: `${routes.api.tracks.contributions.index(creativeSession.track_id)}?&`,
    handleResponse: ({ data: { track: trackIn } }) => {
      setTrack(trackIn)
    },
  }, [creativeSession.track_id])
  return (
    <View style={{ flex: 1 }}>
      <ValidationProvider track={track} loading={loading} setTrack={setTrack}>
        <ScrollView keyboardShouldPersistTaps="always" contentContainerStyle={{ flexGrow: 1 }}>
          <BottomSheetTitle creativeSession={creativeSession} title={sheetTitle} />
          <ContributionsList
            style={{ paddingLeft: 5 }}
            creativeSession={creativeSession}
          />
        </ScrollView>
      </ValidationProvider>
    </View>
  )
}

ValidateSession.navigationOptions = ({ route }) => {
  const creativeSession = route.params?.creativeSession
  const { status } = creativeSession

  const backText = status === PENDING_VALIDATION ? 'Cancel' : 'Done'
  return {
    title: `Validate ${route.params?.creativeSession ||  {}.track_title}`,
    headerLeft: <HeaderCloseButton navigation={navigation} text={backText} />,
  }
}
export default ValidateSession

const styles = StyleSheet.create({
  validateAll: {
    alignSelf: 'flex-end',
    marginRight: 15,
    marginTop: 15,
    textDecorationLine: 'underline',
    color: Colors.tintColor,
    fontSize: 16,
  },
})
