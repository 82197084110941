import React, { useState, useEffect } from "react";

import { View, TouchableOpacity } from "react-native";
import Popup from "../../components/ui/Popup";

import { useNavigation } from "@react-navigation/native";
import track from '../../analytics/index';

type IProps = {
  testID?: string,
  children?: React.ReactNode,
  renderTrigger: (isOpen: boolean) => React.ReactNode,
  renderContent?: (onClose: () => void) => React.ReactNode,
  analyticsProps?: {
    event: string,
    data: any,
  },
  size: 'xl'
}
const MenuItemWithDropdown = ({
  testID,
  children,
  renderTrigger,
  renderContent,
  analyticsProps,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onPress = () => {
    setIsOpen(!isOpen)
    analyticsProps && track(analyticsProps.event, analyticsProps.data);
  }
  const onClose = () => {
    setIsOpen(false);
  }

  const navigation = useNavigation();

  useEffect(() => {
    const closeOnBlur = navigation.addListener("blur", () => {
      setIsOpen(false);
    });

    return closeOnBlur;
  }, [navigation]);

  return (
    <View>
      <TouchableOpacity onPress={onPress} testID={testID}>
        {renderTrigger(isOpen)}
      </TouchableOpacity>

      <Popup isOpen={isOpen} onPressOut={onClose} {...props}>
        {renderContent?.(onClose) || children}
      </Popup>
    </View>
  );
};

export default MenuItemWithDropdown;
