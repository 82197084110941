/* eslint-disable no-var */
import KeyboardSpacerIOS from '@reveel/components/KeyboardSpacerIOS'
import { Input, Item, Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'
import { useAsync } from '@reveel/hooks/useRemoteState'
import { dateOnly } from '@reveel/utils/dateFormatter'
import React, { useEffect, useState } from 'react'
import { SafeAreaView, ScrollView, StyleSheet } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import ErrorsPresenter from '../../components/ErrorsPresenter'
import ScreenTitle from '../../components/ScreenTitle'
import Button from '../../components/ui/Button'
import routes from '../../helpers/routes'
import { useStartSessionForNewTrack } from './tracksHooks'
import trackEvent from '../../analytics/index'
import { useQueryClient } from 'react-query';
import { useDesktop } from '@reveel/helpers/MediaQueries';

const defaultTitle = () => `Track ${dateOnly(new Date())}`

const TrackNew = () => {
  const navigation = useNavigation()
  const route = useRoute()
  useEffect(() => {
    navigation.setOptions({
      headerStyle: { borderBottomColor: 'transparent' },
    })
  }, [navigation])
  
  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentContainerStyle={styles.container}>
        <ScreenTitle title={["Start", "New Project"]} />
        <TrackNewForm startSessionAfterCreate={route.params?.startSessionAfterCreate} />
      </ScrollView>
    </SafeAreaView>
  )
}

export const TrackNewForm = ({
  onSuccess,
  loading,
  errors,
  renderAfterButton,
  renderBeforeButton,
  startSessionAfterCreate
}) => {
  const navigation = useNavigation()
  const queryClient = useQueryClient()
  const onCreate = (track) => {
    queryClient.invalidateQueries('projects')
    onSuccess?.()
    navigation.reset({
      index: 1,
      routes: [
        { name: 'Home' },
        { name: 'TrackShow', params: { trackID: track.id } },
      ],
    })
    trackEvent('Created Track', {
      id: track.id,
      with_session: false,
    })
  }

  const [title, setTitle] = useState('')
  const isDesktop = useDesktop()
  if(startSessionAfterCreate){
    // eslint-disable-next-line block-scoped-var
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // eslint-disable-next-line no-var
    var { loading, errors, createAndStartSession: run } = useStartSessionForNewTrack()
  }else{
    // eslint-disable-next-line no-var
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // eslint-disable-next-line vars-on-top
    var { run, refreshing: loading, errors } = useAsync({
      url: routes.api.tracks.create,
      method: 'post',
      handleResponse: ({ data: track }) => {
        onCreate(track)
      },
    })
  }

  const createTrack = () => {
    const usedTitle = title.length ? title : defaultTitle()
    run({ track: { title: usedTitle } })
  }

  return (
    <>
      <ErrorsPresenter style={styles.errors} errors={errors} />
      <View style={[tw.flexGrow]}>
        <View flex={!isDesktop} style={styles.subContainer}>
          <Item>
            <Input
              placeholder="Project Name"
              onChangeText={setTitle}
              defaultValue={title}
              testID="TrackName"
            />
          </Item>
        </View>
        { renderBeforeButton?.()}
        <View style={[tw.flexRow, tw.justifyEnd]}>
          <Button
            style={[
              isDesktop && tw.selfStart,
              isDesktop && tw.mT8,
              styles.button
            ]}
            block
            testID="SubmitCreateTrack"
            title={`Start`}
            onPress={createTrack}
            loading={loading}
            />
        </View>
        { renderAfterButton?.()}
      </View>
      <KeyboardSpacerIOS />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  subContainer: {
    paddingLeft: 15,
  },
  button: {
    marginHorizontal: 10,
    marginBottom: 10,
    marginTop: 10,
  },
  errors: {
    marginLeft: 20,
  },
})

export default TrackNew
