import React from 'react'
import { StyleSheet, SafeAreaView } from 'react-native';
import View from '@reveel/components/ui/View';
import { Text } from '@reveel/components/ui';
import Button from '../components/ui/Button';

const ImpersonatingNotice = ({ masquerade: { impersonating, desimpersonate } }) => {
  if(!impersonating){
    return null
  }
  return (
    <SafeAreaView>
      <View justifyBetween row bg-red px style={tw.pY} >
        <Text white small style={{ paddingVertical: 5 }}>Masquerading as {impersonating.name}</Text>
        <Button light bordered xs title=" Remove mask" onPress={desimpersonate} />
      </View>
    </SafeAreaView>
  )
}

ImpersonatingNotice.propTypes = {
}
export default ImpersonatingNotice

const styles = StyleSheet.create({})