import { CommonActions, StackActions } from '@react-navigation/native'

import * as Sentry from 'sentry-expo'

let _navigator = null

function setTopLevelNavigator(navigatorRef){
  _navigator = navigatorRef.current
  return _navigator
}

function navigate(name, params){
  try{
    _navigator.dispatch(
      CommonActions.navigate({
        name,
        params,
      }),
    )
  }catch(err){
    __DEV__ && console.error(err)
    Sentry.captureException(err)
  }
}

function back(params = null){
  _navigator.dispatch(
    CommonActions.goBack({
      params,
    }),
  )
}

function reset(params){
  _navigator.dispatch(
    CommonActions.reset(params),
  )
}

function replace(routeName, params = null){
  _navigator.dispatch(
    StackActions.replace({ routeName, params }),
  )
}

function popToTop(){
  _navigator.dispatch(
    StackActions.popToTop(),
  )
}

export default {
  navigate,
  back,
  replace,
  popToTop,
  reset,
  setTopLevelNavigator,
}
