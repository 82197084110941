import routes from '@reveel/helpers/routes';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import axios from 'axios';


type Status = 'todo' | 'done' | 'disabled'

const useTrackProgress = (trackID) => {
  const queryClient = useQueryClient()

  const key = ['track', 'progress', trackID]
  const route = routes.api.tracks.progress(trackID)

  const { isLoading, error, data } = useQuery(key, async () => {
    const res = await axios.get(route)
    return res.data
  })

  const mutation = useMutation(
    ({ name, status }: { name: string, status: Status }) => axios.put(route, { progress: { [name]: status } }),
    {
      onMutate: async ({name, status}) => {
        await queryClient.cancelQueries(key)
        const previousValue = queryClient.getQueryData(key)
        queryClient.setQueryData(key, ({
          progress: {
            ...previousValue.progress,
            [name]: status,
          },
        }))
        return previousValue
      },
      onError: (err, variables, previousValue) => {
        queryClient.setQueryData(key, previousValue)
      },
      onSuccess: ({ data: newData }) => {
        queryClient.setQueryData(key, newData)
      },
    },
  )
  
  // TODO: this is fetching /tracks, and /tracks/X multiple time, 
  // find out why
  const changeStatus = (name: string, status: Status) => {
    mutation.mutate({name, status})
  }
  return {
    isLoading,
    error,
    changeStatus,
    ...data,
  }
}

export default useTrackProgress
