import React from 'react'
import ModalSheet from '@reveel/components/ui/modal/ModalSheet'
import View from '@reveel/components/ui/View'

import SubmitContributionsForm from './SubmitContributionsForm'


const AddCreditsButton = ({ trackID, creativeSessionID, renderTrigger }) => {
  const renderContent = ({ onClose }) => (
    <View style={[tw.p4]}>
      <SubmitContributionsForm onClose={onClose} trackID={trackID} creativeSessionID={creativeSessionID} />
    </View>
  )
  return (
    <ModalSheet {...{
      renderTrigger,
      renderContent,
      modalizeProps: {
        adjustToContentHeight: true,
      },
    }}
    />
  )
}

export default AddCreditsButton
