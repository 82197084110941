import { Text, View } from '@reveel/components/ui';
import Button from '@reveel/components/ui/Button';
import React, { ReactNode } from 'react';
import { tw } from 'react-native-tailwindcss';
import { useNavigation } from '@react-navigation/native';
import useMyProfile from '../../stores/useMyProfile';
import { Image } from 'react-native'
import { useDesktop } from '@reveel/helpers/MediaQueries';
import Page from '@reveel/layout/Page';
import Card from '@reveel/components/ui/Card';

import Logo from '@reveel/../assets/images/logo/logo_beta_horizontal.svg'
import { SplitsCard, TracksCard, LabelCopyCard } from './TracksCard';
import RoyaltyLocatorCard from './RoyaltyLocatorCard';
import track from '../../analytics/index';
import styled from 'styled-components';
import FriendsList from '../../features/friends/FriendsList';
import { useAuth } from '../../stores/useAuth/index';


const ProfileCard = ({ style, from }: { style?: any, from: string }) => {
  const { navigate } = useNavigation()
  const { myProfile } = useMyProfile()
  
  const header = myProfile && <Image source={{uri: myProfile.avatar_url}} style={{width: 75, height:75, borderRadius: 40 }}/>
  const onPress = () => {
    track('Card Clicked', {
      action: 'view_profile',
      from,
    })
    navigate('Profile')
  }
  return <Card
    title={"Keep all your details in one place"}
    actions={<Button onPress={onPress} title={"View Your Profile"}/>}
    header={header}
    style={style}
    align="center"
  />
}

const CardContainer = styled.div`
  grid-template-columns: var(--grid-columns);
  grid-gap: 1rem;
  display: grid;

  @media (min-width: 768px) {
    --grid-columns: repeat(2, 1fr);
  }
  @media (min-width: 991px) {
    --grid-columns: repeat(3, 1fr);
  }
`

export default function HomeScreen(){
  const isDesktop = useDesktop()
  const { myProfile } = useMyProfile()
  const { user: { admin } } = useAuth()

  return <Page >
    {!isDesktop && <View style={[tw.mT2]}>
      <Logo width={'auto'} height={30} />
    </View>}
    <View style={[tw.mB8, tw.mT12]}>
      <Text style={[tw.text4xl, tw.fontBold]}>
        Welcome back, {myProfile?.display_name}
      </Text>
    </View>
    {<View>
      <FriendsList />
    </View>}
    <CardContainer>
      <TracksCard from="home" />
      <SplitsCard from="home" />
      <LabelCopyCard from="home" />
      <RoyaltyLocatorCard from="home" />
      <ProfileCard from="home" />
    </CardContainer>
  </Page>
}