import React from 'react'
import { StyleSheet } from 'react-native'
import InvitationWithActions from '../../components/invitation/InvitationWithActions'
import { List } from '../../components/ui'
import { useDesktop } from '../../helpers/MediaQueries'
import UserWithActions from './UserWithActions'
import SectionTitle from '../../components/ui/SectionTitle'

export default function ParticipantsList({ header, footer, creativeSession: { participants, invitations } }){
  const isDesktop = useDesktop()
  const numColumns = isDesktop ? 5 : 1
  // TODO: display section title
  const participantsList = [
    ...participants.map((user) => {
      const subText = user.contributions.length ? user.contributions.map((s) => s.skill_name).join(', ') : undefined
      return {
        key: `${user.name}-${user.id}`,
        render: () => (
          <UserWithActions
            user={user}
            subText={subText}
          // wrap contributions
            style={{ maxWidth: '100%', flex: isDesktop ? undefined : 1 / 3 }}
          />
        ),
      }
    }),
    ...invitations.map((invitation) => ({
      key: `${invitation.id}`,
      render: () => <InvitationWithActions style={styles.invitation} invitation={invitation} />,
    })),
  ].filter(Boolean)

  return (
    <List
      withBorder={false}
      ListHeaderComponent={header}
      data={participantsList}
      ListFooterComponent={footer}
      numColumns={numColumns}
      key={numColumns}
      emptyMessage="No one has joined this session yet"
    />
  )
}

const styles = StyleSheet.create({
  invitation: {
    marginVertical: 10,
  },
})
