import React from 'react'
import { StyleSheet } from 'react-native'

import { Icon } from 'native-base'
import UserAvatar from 'react-native-user-avatar'

import Colors from '@reveel/constants/Colors'
import View from '@reveel/components/ui/View'

import { Text } from '@reveel/components/ui'
import useStoreContext from '../../stores/useStoreContext'
import { useDesktop } from '../../helpers/MediaQueries'
import LinkWrapper from '../../components/utils/LinkWrapper';

const TrackListItem = ({
  track,
}) => {

  const [currentTrack] = useStoreContext('currentTrack')
  const selected = track.id === currentTrack?.id
  const { project } = track
  const context = useStoreContext('lastReadAt').lastReadAt
  const lastReadAt = context[track.id]
  const unread = !lastReadAt || (new Date(track.last_activity_at) > new Date(lastReadAt))
  const isDesktop = useDesktop()
  const avatarSize = isDesktop ? 25 : 45

  const projectTitleIsTrackTitle = project.title === track.title
  return (
    <LinkWrapper
      style={[
        styles.wrapper,
        !isDesktop && styles.wrapperMobile,
        (isDesktop && selected) && styles.wrapperSelected,
      ]}
      to={`/tracks/${track.id}`}
      testID={`track-${track.id}`}
    >
      <View style={styles.avatar}>
        <UserAvatar size={avatarSize} name={track.title} src={track.project.artwork_url}/>
      </View>
      <View flex>
        <Text bold={unread} style={styles.title}>{track.title}</Text>
        {!projectTitleIsTrackTitle && <View style={styles.footer}>
          <Text mt-05 note small bold={unread}>
            {project.title}
            {track.lastReadAt?.toString()}
          </Text>
        </View>}
      </View>
      { !isDesktop && (
      <View>
        <Icon name="ios-arrow-forward" />
      </View>
      )}
    </LinkWrapper>
  )
}

export default TrackListItem

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: 10,
    paddingVertical: 10,
    alignItems: 'center',
  },
  wrapperMobile: {
    paddingVertical: 20,
    // border still comes from NativeBase
    // borderBottomWidth: 1,
    // borderBottomColor: Colors.borderGrey,
  },
  wrapperSelected: {
    backgroundColor: Colors.lightBackground,
  },
  avatar: {
    marginRight: 10,
  },
  footer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    marginLeft: 0,
  },
  statusDot: {
    marginRight: 5,
    width: 5,
    height: 5,
    borderRadius: 5,
  },
})
