// This class overrides native-base/Label to add a question mark hint to the label
// source: https://github.com/GeekyAnts/NativeBase/blob/master/src/basic/Label.js

// AND, ...somehow it stopped working.. 🤷🏾‍ switched back to native label in the meantime

import React, { Component } from "react"
import PropTypes from "prop-types"
import { View, StyleSheet } from "react-native"

import { connectStyle } from "native-base-shoutem-theme"
import mapPropsToStyleNames from "app/node_modules/native-base/src/utils/mapPropsToStyleNames"
import { Text } from '@reveel/components/ui'
import LabelHelper from "./LabelHelper"

class Label extends Component{
  render(){
    return (
      <View ref={(c) => (this._root = c)} style={styles.container}>
        <Text light ref={(c) => (this._root = c)} {...this.props} />
        {/* currently helper text is displayed under the field */}
        {/* <LabelHelper {...{ light: this.props.light }} body={this.props.tooltip} onPress={this.props.onPress} /> */}
      </View>
    )
  }
}

Label.propTypes = {
  ...Text.propTypes,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.array]),
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignContent: 'center',
    width: '100%',
  },
})

const StyledLabel = connectStyle("NativeBase.Label", {}, mapPropsToStyleNames)(Label)

export default StyledLabel
