import {
  PENDING_CONTRIBUTION_SUBMISSIONS, RUNNING, PENDING_VALIDATION, PENDING_CONFLICT_RESOLUTION, PENDING_MISSING_PARTICIPANTS, SIGNED_OFF,
  SUBMITTED_VALIDATION,
  SUBMITTED_CONTRIBUTIONS,
  PENDING_SIGN_OFF,
  VALIDATED,
  PAST,
} from '../constants/Status'

export default {
  creativeSessions: {
    name: 'Session',
    empty: {
      title: 'Keep track of your time spent',
      cta: '+ Start Session',
      more: 'Log all your sessions and invite your collaborators',
    },
    status: {
      [PAST]: "Invite Collaborators",
      [RUNNING]: "Running",
      [SUBMITTED_CONTRIBUTIONS]: "Running",
      [PENDING_CONTRIBUTION_SUBMISSIONS]: "Enter Contributions",
      [PENDING_VALIDATION]: "Pending Validations",
      [PENDING_CONFLICT_RESOLUTION]: "Mitigated",
      [PENDING_MISSING_PARTICIPANTS]: "Missing Participants",
      [PENDING_SIGN_OFF]: "Pending Admin Sign Off",
      [SIGNED_OFF]: "Signed Off",
      [VALIDATED]: "Signed Off",
    },
    header: {
      [PAST]: {
        [RUNNING]: "Adding %{count} credits",
        [SUBMITTED_CONTRIBUTIONS]: "Adding %{count} credits",
      },
      [RUNNING]: {
        [RUNNING]: "Adding %{count} credits",
        [PENDING_CONTRIBUTION_SUBMISSIONS]: "Adding %{count} credits",
        [SUBMITTED_CONTRIBUTIONS]: "Adding %{count} credits",
      },
      [PENDING_CONTRIBUTION_SUBMISSIONS]: {
        [PENDING_CONTRIBUTION_SUBMISSIONS]: "Sending %{count} credits for review",
        [PENDING_VALIDATION]: "We're still waiting on other people to submit their contributions",
        [PENDING_SIGN_OFF]: "An admin is reviewing this session",
        validate: "Please validate the following contributions",
        wait: "An admin is reviewing this session",
      },
      [PENDING_VALIDATION]: {
        validate: "Please validate the following contributions",
        [SUBMITTED_VALIDATION]: "Waiting for others to submit their contributions",
        wait: "An admin is reviewing this session",
      },
      [PENDING_CONFLICT_RESOLUTION]: {
        validate: "Please resolve the conflict",
        [PENDING_CONFLICT_RESOLUTION]: "Please resolve the conflict",
        [SUBMITTED_VALIDATION]: "Please resolve the conflict",
        wait: "An admin is reviewing this session",
      },
      [PENDING_MISSING_PARTICIPANTS]: {
        [PENDING_VALIDATION]: "Invite the remaining participants",
        [PENDING_CONTRIBUTION_SUBMISSIONS]: "Invite the remaining participants",
      },
      [SIGNED_OFF]: {
        [SUBMITTED_VALIDATION]: "Validated",
      },
      // TODO: get this status out!
      validated: 'Validated',
    },
    button: {
      [PAST]: 'Next',
      [RUNNING]: 'End Session',
    },
    email: {
      subject: 'Join the Session for %{track_title}',
      body: 'Hi,\n\nI’m using Reveel to keep track of our progress on %{track_title}. I’m logging our work in a Session: %{session_name}.\n\nFollow this link to join the session and log your credits: %{url}\n\nThanks,\n\n\nPS: If you have any questions, the team at Reveel can help. Email them to: service@reveel.id\n\nAbout Reveel\nReveel is made for remote collaboration and helps you stay on top of all your creative projects. On Reveel, you can\n\nTrack credits and log sessions\nStay in touch with your creative team \nOrganize & share files and attachments\n',
    },
    ui: {
      cta: {
        new: {
          title: 'Your Sessions',
          description: 'See all your Sessions and create new ones',
          button: 'Start a New Session',
        },
      },
      delete: {
        cta: 'Delete Session',
        alert: {
          title: 'Are you sure you want to delete this session?',
          description: 'This action is irreversible.',
          confirmText: 'I understand',
          destructive: true,
        },
      },
      ghost: {
        screenTitle: 'Add Credits',
        cta: '+ Add Credits',
      },
      past: {
        newTrackButton: '+ Add Track',
        title: ['Add', 'A Past Session'],
      },
      running: {
        newTrackButton: '+ New Track',
        title: ['Start', 'A New Session'],
      },
      invite: {
        cta: '+ Invite user to join',
        modal: {
          title: 'Invite a collaborator',
        },
      },
      join: {
        modal: {
          title: 'Join the Session',
        },
      },
      file: {
        inputPrompt: '   Attach link to a session?',
        placeholder: 'Paste a link for the session here...',
        view: 'View \u203A',
        label: 'File Link - Optional',
        noFileName: 'Unknown Filename',
      },
    },
    fields: {
      started_at: {
        label: "Start date and hour",
      },
      ended_at: {
        label: "End date and hour",
      },
      venue_name: {
        label: 'Venue',
        placeholder: 'Where did this session take place?',
      },
      track: {
        title: {
          label: 'Track Title',
        },
      },
    },
    not_found: {
      alert: {
        title: 'Oops, session not found',
        description: 'Maybe the owner deleted it?',
      },
    },
  },
}
