export default {
  splits: {
    total: 'Total',
    edit: {
      songwriting_splits: {
        cta: 'Edit Splits',
      },
      master_points: {
        cta: 'Edit Points',
      },
    },
    form: {
      empty: {
        cta: '+ Select users to add to the split sheet',
      },
      splitEqually: 'Split Equally',
      submit: 'Save Splits',
      toggleList: {
        show: 'Show all',
        hide: 'Hide all',
      },
    },
    message: {
      songwriting_splits: {
        create: '%{user_name} has established splits',
        update: '%{user_name} has updated splits',
        split: '%{user_name}: %{value}%',
      },
      master_points: {
        create: '%{user_name} has established points',
        update: '%{user_name} has updated points',
        split: '%{user_name}: %{value}%',
      },
    },
  },
}
