import React, { useEffect, useState, useCallback } from 'react'
import { useAsync } from '@reveel/hooks/useRemoteState'
import { StyleSheet, Image } from 'react-native'
import Colors from '@reveel/constants/Colors'
import { Text } from '@reveel/components/ui'
import Loading from '@reveel/components/Loading'
import View from '@reveel/components/ui/View'
import processErrors from '../../helpers/processErrors'
import routes from '../../helpers/routes'
import { withAuth } from '../../stores/useAuth'
import FilePicker from '../ui/Form/FilePicker'
import useWhyDidYouUpdate from '../../hooks/useWhyDidYouUpdate';
import BotFace from '@reveel-assets/images/BotFace'
import { useAuth } from '@reveel/stores/useAuth';
const helpText = 'Change Profile Picture'

const AvatarPicker = ({
  light,
  instructionProps = {},
  size = 100,
  disabled,
}) => {
  const { user: { id, avatar_url }, setUser } = useAuth()
  const [instructions, setInstructions] = useState(!avatar_url ? 'Add Profile Picture' : helpText)

  const setTmpInstruction = useCallback((label) => {
    setInstructions(label)
    setTimeout(() => setInstructions(helpText), 3000)
  }, [setInstructions])

  const { run, errors, refreshing } = useAsync({
    url: routes.api.user.update(id),
    method: 'put',
    handleResponse: ({ data }) => {
      setUser(data)
      setTmpInstruction('Done!')
    },
  }, [id, setUser, setTmpInstruction])

  useEffect(() => {
    if(errors){
      setInstructions(processErrors(errors))
    }
  }, [errors, setInstructions])

  const onImage = useCallback(async (image_) => {
    if(image_){
      run({
        user: {
          avatar: image_,
        },
      })
    }
  }, [run])

  const renderInstructions = () => {
    if(disabled) return null
    return refreshing
      ? <Loading size="small" />
      : <Text {...instructionProps} small>{instructions}</Text>
  }
  return (
    <View style={styles.container}>
      <FilePicker disabled={disabled} name="user.avatar_url" onSelect={onImage} loading={refreshing}>
        <View alignCenter>
          <Image style={[styles.placeholder, { width: size, height: size }]} source={{ uri: avatar_url }} />
          {renderInstructions()}
        </View>
      </FilePicker>
    </View>
  )
}

export default AvatarPicker

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginBottom: 20,
    marginTop: 20,
  },
  placeholder: {
    width: 75,
    height: 75,
    backgroundColor: Colors.lightBackground,
    marginBottom: 15,
    borderRadius: 8,
  },
})
