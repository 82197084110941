
import React from 'react';
import WelcomeScreen from './WelcomeScreen';
import { useInvitedBy, InvitedWelcomeScreen } from './InvitedWelcomeScreen';


const FirstScreen = ({  }) => {
  const { inviter, hasInviter } = useInvitedBy()

  if(hasInviter){
    return <InvitedWelcomeScreen inviter={inviter} />
  }
  return <WelcomeScreen />

}

export default FirstScreen