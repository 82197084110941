import React from 'react';
import AttachmentSummary from "@reveel/screens/tracks/attachments/AttachmentSummary"
import { creativeSessionName } from '@reveel/helpers/creativeSessionsFormatter';

const AttachmentNotification = ({ message }) => {
  const {
    type,
    user,
    meta: { creative_session, attachment },
  } = message
  return (
    <AttachmentSummary
      attachment={{
        ...attachment,
        creative_session,
        user,
      }}
      title={i18n.t(
        `tracks.feed.messages.attachments.${creative_session ? "creativeSession" : "track"
        }.title`,
        {
          name: user.display_name,
          attachment_name: attachment.name || "an attachment",
          creative_session_name: creativeSessionName(creative_session),
        },
      )}
    />
  )
}

export default AttachmentNotification