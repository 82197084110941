import React from 'react'
import { Platform, StyleSheet, View } from 'react-native'

import {
  createNavigatorFactory,
  StackRouter, useNavigationBuilder,
} from '@react-navigation/native'
import Colors from '@reveel/constants/Colors'
import MediaQuery from 'react-responsive'

import TrackMenu from '../screens/tracks/TrackMenu'
import StackViewNoHeader from './AuthenticatedNavigator/StackViewNoHeader'
import Sidebar from './Sidebar'
import { useCallback } from 'react';

const getCurrentScreenOptions = ({ descriptors, state }) => {
  const topRouteKey = state.routes[state.routes.length - 1].key
  return descriptors[topRouteKey].options
}

function CustomStackNavigator({
  initialRouteName,
  children,
  screenOptions,
  ...rest
}){
  const { state, descriptors, navigation } = useNavigationBuilder(StackRouter, {
    initialRouteName,
    children,
    screenOptions,
  })

  const { noSidebar } = getCurrentScreenOptions({ descriptors, state })

  const renderSidebar = useCallback(({ route }) => route.params?.trackID && (
    <MediaQuery minWidth={767}>
      <TrackMenu style={styles.secondSidebar} route={route} trackID={route.params?.trackID} />
    </MediaQuery>
  ), [])

  return (
    <View style={styles.container}>
      { !noSidebar && (
        <MediaQuery minWidth={767}>
          <View style={styles.sidebar}>
            <Sidebar {...{ navigation, state, ...rest }} />
          </View>
        </MediaQuery>
      )}
      <StackViewNoHeader
        testID="mainContent"
        {...rest}
        state={state}
        descriptors={descriptors}
        navigation={navigation}
        renderSidebar={renderSidebar}
      />
    </View>
  )
}

export default createNavigatorFactory(CustomStackNavigator)

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
  },
  secondSidebar: {
    width: 200,
    minWidth: 200,
    maxWidth: 180,
    ...Platform.select({
      // force the second side bar
      // not to collapse from the flex parent
      web: {
        'flex-basis': 200,
        'flex-shrink': 0,
      },
    }),
  },
  sidebar: {
    width: 250,
    maxWidth: '25vw',
    ...Platform.select({
      web: {
        boxShadow: `1px 0px 3px 0px ${Colors.borderGrey}`,
        zIndex: 1,
      },
      native: {
        shadowColor: Colors.borderGrey,
        shadowOffset: {
          width: 4,
          height: 0,
        },
        shadowOpacity: 0.5,
        shadowRadius: 8,
        elevation: 3,
      },
    }),
  },
})
