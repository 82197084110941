/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react"

import { useNavigation } from '@react-navigation/native'

import useSubmitCreditsForTrack from '../../components/credits/SubmitContributionsForm/useSubmitCreditsForTrack'
import preventBack from '../../helpers/preventBack'
import EnterContributions from '../contributions/EnterContributions'

// this screen is only used to submit your _own_ contributions
const AddCreditsToSession = {}
AddCreditsToSession.screen = ({
  user, creativeSession,
}) => {
  const navigation = useNavigation()
  const theProps = useSubmitCreditsForTrack({ creativeSessionID: creativeSession.id, trackID: creativeSession.track_id })

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => null,
      headerLeft: () => null,
    })
  }, [])
  preventBack()
  const { id: user_id } = user
  const myParticipation = creativeSession.participants.find((profile) => profile.user_id === user_id) || {}

  const contributions = myParticipation?.contributions.map(({ skill_id: id, skill_name: name }) => ({ id, name }))

  return (
    <EnterContributions
      i18nTitleKey={`creativeSessions.header.${creativeSession.status}.${creativeSession.status_for_user}`}
      initialContributions={contributions}
      {...theProps}
    />
  )
}

export default AddCreditsToSession
