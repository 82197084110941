import React, { useState } from "react";
import {
  Platform,
  StyleSheet,
  View as NativeView,
  Dimensions,
} from "react-native";
import { Portal } from "react-native-paper";

import View from "@reveel/components/ui/View";
import Colors from "@reveel/constants/Colors";

const SM_WIDTH = 150;
const LG_WIDTH = 300;
const XL_WIDTH = 500;

const getStyleFor = ({ x, y, position, size }) => {
  const width = size === "sm" ? SM_WIDTH : size === "lg" ? LG_WIDTH : XL_WIDTH;
  const left = Math.min(x, Dimensions.get("window").width - width / 2 - 5);
  let bottom;
  let top;
  if (position === "top") {
    bottom = Dimensions.get("window").height - y + 45;
  } else {
    top = y + 5;
  }

  return {
    left,
    bottom,
    top,
    minWidth: width,
    maxWidth: width,
    transform: [{ translateX: -width / 2 }],
  };
};

// TODO: make this a hook returning
// onLayout and ref
const getMeasureForNode = (event, nodeRef, cb) => {
  if (Platform.OS === "web") {
    const { x, y } = event.nativeEvent.target.getBoundingClientRect();
    cb({ x, y });
    return;
  }
  setTimeout(() => {
    nodeRef.current.measure((xRel, yRel, w, h, x, y) => {
      cb({ x, y });
    });
  }, 10);
};

// TODO: prevent from going out of viewport
const DesktopChatPopup = ({
  isOpen,
  position = "bottom",
  size = "lg",
  style,
  children,
}) => {
  const [nodePosition, setPosition] = useState();
  const hasLayouted = Boolean(nodePosition);
  const nodeRef = React.createRef();

  const onLayout = (e) =>
    getMeasureForNode(e, nodeRef, ({ x, y }) =>
      setPosition(
        getStyleFor({
          x,
          y,
          position,
          size,
        })
      )
    );

  if (isOpen)
    return (
      <>
        <View style={{ position: "relative" }}>
          <NativeView
            onLayout={onLayout}
            ref={nodeRef}
            style={{ width: 1, height: 1, position: "absolute" }}
          />
        </View>
        <Portal>
          {hasLayouted && (
            <View box-sm style={[styles.popup, style, nodePosition]}>
              {children}
            </View>
          )}
        </Portal>
      </>
    );

  return null;
};

export default DesktopChatPopup;

const styles = StyleSheet.create({
  background: {
    ...StyleSheet.absoluteFillObject,
    position: Platform.OS === "web" ? "fixed" : "absolute",
    zIndex: 99,
    backgroundColor: __DEV__ ? "rgba(0,255,0,.1)" : undefined,
  },
  popup: {
    zIndex: 100,
    elevation: 3,
    position: "absolute",
    maxHeight: 500,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.15,
    shadowRadius: 8,
    backgroundColor: "white",
  },
});
