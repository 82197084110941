/* eslint-disable no-underscore-dangle, no-use-before-define */

import PropTypes from "prop-types"
import React from "react"
import { View, ViewPropTypes, StyleSheet } from "react-native"

import { Day, utils } from "react-native-gifted-chat"
import i18n from "i18n-js"

import { parsedTextArray } from "@reveel/utils/parsedTextMarkdown"
import parsedMentionMarkdown from "@reveel/utils/parsedMentionMarkdown"
import UserAvatar from "react-native-user-avatar"
import * as Linking from 'expo-linking';
import withNavigation from "@reveel/helpers/withNavigation"
import SlackBubble from "./SlackBubble"
import SystemAvatar from "./avatars/SystemAvatar"
import { PENDING_CONFLICT_RESOLUTION } from "../../constants/Status"
import { creativeSessionName } from "../../helpers/creativeSessionsFormatter"
import AttachmentNotification from './messages/AttachmentNotification';
import SplitsNotification from "./messages/SplitsNotification"

import ProfileNotification from './messages/ProfileNotification';
import { useAttachmentMeta } from '../../screens/sessions/sessions/Attachment';

// TODO: handle MessageText with urls, parsed as markdown :/

const { isSameUser, isSameDay } = utils

const attachmentProps = (meta, message) => {
  const { attachment } = meta
  const { host, fileName } = useAttachmentMeta(attachment)
  // www. needs to be stripped, as onPressUrl cannot be overriden
  // in current versin of gifted-chat, and MessageText
  // automatically parses www.

  const hostWithoutWWW = host.replace(/^www\./, "")
  // const onPress = () => {
  //   if(window){
  //     return window.open(attachment.url)
  //   }
  //   return Linking.openURL(attachment.url)
  // }
  return {
    withCaret: true,
    onPress: () => Linking.openURL(attachment.url),
    currentMessage: {
      ...message,
      meta,
      text: `${hostWithoutWWW}/${fileName}`,
      user: {
        ...message.user,
        name: i18n.t(
          `tracks.feed.messages.attachments.${
            meta.creative_session ? "creativeSession" : "track"
          }.title`,
          {
            name: message.user.display_name,
            attachment_name: meta.attachment.name || "an attachment",
            creative_session_name: creativeSessionName(meta.creative_session),
          },
        ),
      },
    },
  }
}

const creativeSessionProps = (meta, message, navigation) => {
  const { creative_session: creativeSession, title } = meta
  return {
    // TODO / HACK: understand why creativeSession would not exist
    // security added to prevent crash during the Sony Demo
    withCaret: creativeSession?.status === PENDING_CONFLICT_RESOLUTION,
    onPress: () => navigation.navigate("Session", { id: creativeSession.id }),
    currentMessage: {
      ...message,
      meta,
      user: {
        name: title,
      },
    },
  }
}

const meta2Props = ({ meta, ...rest }, navigation) => {
  switch(meta.type){
    case "creative_session":
      return creativeSessionProps(meta, rest, navigation)
    case "attachment":
      return attachmentProps(meta, rest, navigation)
    default:
      return {}
  }
}

class SlackMessage extends React.Component{
  getInnerComponentProps(){
    const { containerStyle, ...props } = this.props
    return {
      ...props,
      position: "left",
      isSameUser,
      isSameDay,
    }
  }

  renderDay(){
    if(this.props.currentMessage.createdAt){
      const dayProps = this.getInnerComponentProps()
      if(this.props.renderDay){
        return this.props.renderDay(dayProps)
      }
      return <Day {...dayProps} />
    }
    return null
  }

  enhanceBubbleProps(bubbleProps){
    const { system, meta } = bubbleProps.currentMessage
    if(!system){
      return bubbleProps
    }
    return {
      ...bubbleProps,
      ...meta2Props(bubbleProps.currentMessage, this.props.navigation),
    }
  }

  renderBubble(){
    const bubbleProps = this.enhanceBubbleProps(this.getInnerComponentProps())
    if(this.props.renderBubble){
      return this.props.renderBubble(bubbleProps)
    }

    const addExtraParsers = () => [
      parsedMentionMarkdown(this.props.navigation),
      ...parsedTextArray,
    ]

    return (
      <View style={styles.bubbleContainer}>
        <SlackBubble {...bubbleProps} parsePatterns={addExtraParsers} />
      </View>
    )
  }

  messageTextProps(){
    return {
      parsePatterns: () => parsedTextArray,
    }
  }

  renderAvatar(){
    let extraStyle
    if(
      isSameUser(this.props.currentMessage, this.props.previousMessage)
      && isSameDay(this.props.currentMessage, this.props.previousMessage)
      && !this.props.previousMessage.system
    ){
      // Set the invisible avatar height to 0, but keep the width, padding, etc.
      extraStyle = { height: 0, overflow: 'hidden' }
    }

    const avatarProps = this.getInnerComponentProps()
    // session messages use the user prop to render the avatar
    if(avatarProps.currentMessage.system){
      return (
        <SystemAvatar
          message={avatarProps.currentMessage}
          size={40}
          style={[styles.slackAvatar]}
        />
      )
    }

    return (
      <UserAvatar
        name={avatarProps.currentMessage.user.display_name}
        src={avatarProps.currentMessage.user.avatar_url}
        size={40}
        imageStyle={{
          ...styles.slackAvatar,
          ...avatarProps.imageStyle,
          ...extraStyle,
        }}
      />
    )
  }

  render(){
    const marginBottom = isSameUser(
      this.props.currentMessage,
      this.props.nextMessage,
    )
      ? 2
      : 10

    const message = this.props.currentMessage
    const { id, meta: { type } } = message

    switch(type){
      case 'attachment':
        return <AttachmentNotification message={message} />
      case 'master_points':
      case 'songwriting_splits':
        return <SplitsNotification message={message} />
      case 'profile':
        return <ProfileNotification message={message} />
      default:
        return (
          <View testID={`message-${id}`}>
            {this.renderDay()}
            <View
              style={[
                styles.container,
                { marginBottom },
                this.props.containerStyle,
              ]}
            >
              {this.renderAvatar()}
              {this.renderBubble()}
            </View>
          </View>
        ) 
    }
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginLeft: 8,
    marginRight: 0,
  },
  slackAvatar: {
    // The bottom should roughly line up with the first line of message text.
    height: 40,
    width: 40,
    borderRadius: 4,
  },
  caret: {
    flexShrink: 1,
  },
  bubbleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
    marginRight: 15,
    marginLeft: 8,
  },
})

SlackMessage.defaultProps = {
  renderAvatar: undefined,
  renderBubble: null,
  renderDay: null,
  currentMessage: {},
  nextMessage: {},
  previousMessage: {},
  user: {},
  containerStyle: {},
}

SlackMessage.propTypes = {
  renderAvatar: PropTypes.func,
  renderBubble: PropTypes.func,
  renderDay: PropTypes.func,
  currentMessage: PropTypes.object,
  nextMessage: PropTypes.object,
  previousMessage: PropTypes.object,
  user: PropTypes.object,
  containerStyle: PropTypes.shape({
    left: ViewPropTypes.style,
    right: ViewPropTypes.style,
  }),
}

export default withNavigation(SlackMessage)
