import React, { useState, useEffect } from 'react'
import { Pressable, StyleSheet, ScrollView } from 'react-native'

import ScreenTitle from '@reveel/components/ScreenTitle'
import { Text } from '@reveel/components/ui'
import Button from '@reveel/components/ui/Button'
import TextInput from '@reveel/components/ui/TextInput'
import View from '@reveel/components/ui/View'
import { useDesktop } from '@reveel/helpers/MediaQueries'
import routes from '@reveel/helpers/routes'
import { useAsync } from '@reveel/hooks/useRemoteState'
import { tw } from 'react-native-tailwindcss'

import Checkboxes from '../../../components/ui/Form/Checkboxes'

export const HowDidYouFindOnboarding = ({ onNext }) => (
  <ScrollView contentContainerStyle={[tw.flex1]}>
    <ScreenTitle style={[tw.mT10, tw.mB2]} title={i18n.t('onboarding.howDidYouFindOut.title')} />
    <View style={[tw.pX4, tw.mB4]}>
      <Text light style={tw.textSm}>{i18n.t('onboarding.howDidYouFindOut.subtitle')}</Text>
    </View>
    <Form onSubmit={onNext} />
  </ScrollView>
)

const roles = ['Artist', 'Producer', 'Songwriter', 'Musician', 'Manager', 'Label'].map((label) => ({ label }))
const Form = ({ onSubmit }) => {
  const [body, setBody] = useState()
  // TODO: prevent non-numeric input
  const [collaboratorsCount, setCollaboratorsCount] = useState()

  const [professions, setProfessions] = useState([])
  const { run, refreshing: loading } = useAsync({
    url: routes.api.user.update(),
    method: 'put',
    handleResponse: () => {
      onSubmit?.()
    },
  })
  const onPress = () => run({ user: { 
    how_did_you_find_out: body, 
    professions: professions.map(({ label }) => label),
    number_of_collaborators: collaboratorsCount,
  } })
  const disabled = loading || !body?.length || professions.length < 1 || !collaboratorsCount
  return (
    <View style={[tw.pX4, tw.flexGrow, tw.justifyBetween, tw.pB4]}>
      <View>
        <View style={[tw.mB4]}>
          <Text style={[tw.pB1]}>How many people do you usually collaborate with?</Text>
          <TextInput
            style={[tw.mT2, tw.mB2, tw.bgWhite]}
            appearance="square"
            parentState={[collaboratorsCount, setCollaboratorsCount]}
            inputProps={{
              type: "number"
            }}
            keyboardType="number-pad"
            placeholder="Enter 0 if you work alone"
            testID="collaboratorsCount"
          />
        </View>
        <Text>What role best describes you?</Text>
        <Checkboxes
          style={[tw.mT2, tw.mB4]}
          onChange={setProfessions}
          options={roles}
          displayKey="label"
          idKey="label"
        />
        <Text style={[tw.pB1]}>How did you find out about Reveel?</Text>
        <TextInput
          style={[tw.mT2, tw.mB2, tw.bgWhite]}
          appearance="square"
          parentState={[body, setBody]}
          multiline
          placeholder="A friend told me about it"
          minHeight={80}
          testID="howDidYouFindOut"
        />
      </View>
      <Button
        block
        style={[tw.alignBottom]}
        title="Get Started"
        onPress={onPress}
        disabled={disabled}
      />
    </View>
  )
}

export default HowDidYouFindOnboarding
