import React from 'react'

import { uri } from '@reveel/utils/regexp'
import NumberPicker from "../../components/ui/NumberPicker"
import { SESSION_TYPES } from '../../constants/ddex/index'
import { SessionAttachmentField } from '../../screens/sessions/sessions/SessionAttachmentForm'

export const mapCreativeSessionForApi = (formObject) => ({
  creative_session: {
    ...formObject,
    track_attributes: {
      ...track,
      ...formObject.track,
    },
    attachments_attributes: [
      formObject.attachment,
    ],
  },
})

export const declaredContributorsCount = (min) => ({
  name: 'declared_contributors_count',
  label: 'How many people contributed in this session?',
  render: ({ onValueChange, value }) => <NumberPicker style={{ marginTop: 20, marginBottom: 15 }} value={value} onChange={onValueChange} min={min} />,
})

export const trackTitle = {
  name: 'track.title',
  placeholder: "My Track",
}

export const name = {
  name: 'name',
  placeholder: "eg. Vocals Test 1",
}

// TODO: validate start date before today
export const startDate = {
  inputProps: {
    type: 'datetime',
  },
  name: 'started_at',
}

// TODO: validate end date after start date
export const endDate = {
  inputProps: {
    type: 'datetime',
  },
  name: 'ended_at',
}

export const sessionType = {
  name: "session_type",
  inputProps: {
    type: 'select',
    options: SESSION_TYPES,
  },
}

export const venueName = {
  name: 'venue_name',
}

export const notes = {
  name: 'notes',
  placeholder: "Thoughts, instruments used, or more...",
  inputProps: {
    type: 'textarea',
  },
}

export const attachments = {
  name: 'attachments_attributes[0].url',
  label: false,
  validate: (v) => (uri.test(v) ? true : 'Attachment must be a valid URL'),
  render: ({ onValueChange, value }) => <SessionAttachmentField parentState={[value, onValueChange]} />,
}
