import React from 'react'
import { Text } from '@reveel/components/ui'
import {
  StyleSheet, SafeAreaView, ScrollView, Dimensions,
} from 'react-native'
import Colors from '@reveel/constants/Colors'
import Button from '@reveel/components/ui/Button'
import View from '@reveel/components/ui/View'
import * as Animatable from 'react-native-animatable'
import RecIcon from 'app/assets/images/record.svg'
import Page from '@reveel/layout/Page'
import { registerForPushNotificationsAsync } from '../../config/PushNotifications'
import {
  START, RUNNING, PENDING_MISSING_PARTICIPANTS, PENDING_CONTRIBUTION_SUBMISSIONS, PENDING_CONFLICT_RESOLUTION, SIGNED_OFF, PENDING_SIGN_OFF,
} from '../../constants/Status'
import Dot from '../../components/core/Dot'

const END = 'end'

const LATENCY = 200
const DELAY = 60

const dotColors = {
  [START]: '#F72A4F',
  [PENDING_MISSING_PARTICIPANTS]: '#FC792C',
  [END]: '#FA4E60',
  [PENDING_CONTRIBUTION_SUBMISSIONS]: '#F72A8C',
  [PENDING_SIGN_OFF]: '#8F00BE',
  [PENDING_CONFLICT_RESOLUTION]: '#5C00C3',
  [SIGNED_OFF]: '#5C00C3',
}

const Step = ({
  i, title, description, status, nextStatus, first, last, optional,
}) => (
  <View style={styles.stepWrapper}>
    <Animatable.View animation="fadeIn" style={{ flexDirection: 'row' }} delay={LATENCY + i * DELAY}>
      <View style={styles.dotWrapper}>
        <View style={[
          styles.line,
          {
            minHeight: 8,
            borderColor: dotColors[status],
          },
          first ? styles.firstLine : null,
        ]}
        />
        <View>
          {
            status === RUNNING ? <RecIcon style={{ marginTop: -6 }} height={30} width={30} />
              : <Dot color={dotColors[status]} size={18} style={[{ marginHorizontal: 6 }, optional ? styles.optional : null]} />
          }
        </View>
        <View style={[
          styles.line, styles.flex, last ? styles.lastLine : null,
          {
            borderColor: dotColors[nextStatus],
          }]}
        />
      </View>
      <View>
        <View style={[
          styles.textWrapper,
          optional ? styles.optionalText : null,
        ]}
        >
          <Text mb bold>
            {i + 1}
            .
            {' '}
            {title}
          </Text>
          <Text smaller>{description}</Text>
          { optional && <Text small style={styles.optionalTag}>optional</Text> }

        </View>
      </View>
    </Animatable.View>
  </View>
)

const steps = [
  {
    title: 'Start a Session',
    description: 'Each time you enter the studio or start working on a project',
    status: RUNNING,
    nextStatus: PENDING_MISSING_PARTICIPANTS,
  },
  {
    title: 'Invite other participants',
    description: 'Add all your collaborators to the session via email, text, or by scanning their Creator ID',
    status: PENDING_MISSING_PARTICIPANTS,
    nextStatus: END,
  },
  {
    title: 'End the Session',
    description: 'Once you’re done working on this track for today, fill in the details and end the session',
    status: END,
    nextStatus: PENDING_CONTRIBUTION_SUBMISSIONS,
  },
  {
    title: 'Enter or Confirm your contributions',
    description: 'Each participant is responsible for submitting how they contributed to this session',
    status: PENDING_CONTRIBUTION_SUBMISSIONS,
    nextStatus: PENDING_SIGN_OFF,
  },
  {
    title: 'Sign Off',
    description: 'Once every one submitted their contributions, an admin will validate to ensure there are no mistakes',
    status: PENDING_SIGN_OFF,
    nextStatus: PENDING_CONFLICT_RESOLUTION,
  },
  {
    title: 'Resolve Conflict',
    description: 'Address any disagrements via the built-in chat. \nNo conflicts? Great, you’re done!',
    status: PENDING_CONFLICT_RESOLUTION,
    nextStatus: SIGNED_OFF,
    optional: true,
  },
  {
    title: 'You’re Credited!',
    description: 'Credits are now added to the track for all contributors!',
    nextStatus: PENDING_CONFLICT_RESOLUTION,
    status: SIGNED_OFF,
  },
]

const HowSessionsWork = ({ navigation }) => {
  const onBack = () => {
    registerForPushNotificationsAsync()
    navigation.pop()
  }
  return (
    <SafeAreaView style={styles.container}>
      <Text xl center bold mb-2 mt-2>How it works</Text>
      {/* eslint-disable-next-line react-native/no-inline-styles */}
      <Page contentContainerStyle={{ flex: 0 }} style={{ paddingHorizontal: 0 }}>
        <View sheet>
          <ScrollView contentContainerStyle={styles.wrapper}>
            {steps.map((stepProps, i) => {
              const step = (
                <Step
                  {...stepProps}
                  key={stepProps.title}
                  i={i}
                  first={i === 0}
                  last={i === steps.length - 1}
                />
              )
              return step
            })}
            <Animatable.View animation="fadeIn" delay={(2 + steps.length) * DELAY + LATENCY} duration={300}>
              <Button title="Got it!" block onPress={onBack} />
            </Animatable.View>
          </ScrollView>
        </View>
      </Page>
    </SafeAreaView>
  )
}

HowSessionsWork.navigationOptions = ({}) => ({
  header: null,
})

export default HowSessionsWork

const styles = StyleSheet.create({
  container: {
    // paddingVertical: 25,
    flex: 1,
    backgroundColor: Colors.lightBackground,
  },
  wrapper: {
    paddingVertical: 15,
    paddingHorizontal: 15,
    flex: 1,
  },
  stepWrapper: {
    flexDirection: 'row',
    flex: 1,
  },
  dotWrapper: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: 8,
  },
  line: {
    borderRightWidth: 1,
    minHeight: 10,
  },
  flex: {
    flex: 1,
  },
  textWrapper: {
    paddingLeft: 7,
    // margin required for the text not to go under the parent's padding
    marginRight: 20,
    paddingRight: 5,
    paddingVertical: 5,
    flexGrow: 0,
    marginBottom: 20,
    paddingBottom: 5,
    maxWidth: Dimensions.get('screen').width - 54,
  },
  optional: {
    opacity: 0.6,
  },
  optionalText: {
    opacity: 0.6,
    borderColor: Colors.lightGrey,
    borderWidth: 1,
    borderRadius: 4,
  },
  optionalTag: {
    position: 'absolute',
    top: -9,
    right: 10,
    backgroundColor: 'white',
    paddingHorizontal: 4,
    color: Colors.lightBlack,
  },
  firstLine: {
    opacity: 0,
  },
  lastLine: {
    opacity: 0,
  },
})
