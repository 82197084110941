import React from 'react'
import Colors from '@reveel/constants/Colors'
import { useNavigation } from '@react-navigation/native'
import TabBarIcon from '../../components/TabBarIcon'
import InboxScreen, { InboxTabIcon } from '../../screens/inbox/InboxScreen'
import MenuItemWithDropdown from './MenuWithDropdown'
import Inbox from '../../screens/inbox/Inbox'

const HeaderInbox = (isOpen) => {
  const navigation = useNavigation()
  return (
    <MenuItemWithDropdown
      testID="inbox"
      renderTrigger={() => <InboxTabIcon focused={isOpen} />}
      size="xl"
      shifted
      analyticsProps={{
        event: 'Opened Inbox',
        data: {
          from: 'header',
        }
      }}
    >
      <Inbox navigation={navigation} withTitle={false} contentContainerStyle={{ backgroundColor: Colors.white }} />
    </MenuItemWithDropdown>
  )
}

export default HeaderInbox
