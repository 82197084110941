import { webAppHost } from '@reveel/helpers/urls';

const frontRoutes = {
  absolute: (path) => `${webAppHost}${path}`,
  users: {
    show: (id) => `/profile/${id}`,
  },
  tracks: {
    show: (trackID) => `/tracks/${trackID}`,
  },
  creativeSessions: {
    join: (trackID, id) => `/tracks/${trackID}/sessions/${id}/join`,
  },
}

export default frontRoutes
