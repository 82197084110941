import slugify from "slugify";

export const creativeSessionName = (cs, capitalize = false) => {
  if (!cs) {
    return;
  }
  if (cs.proxy) {
    return "Individual Credits";
  }
  return cs.name || `${capitalize ? "Session" : "session"} #${cs.position}`;
};

export const generateCreativeSessionSlug = (cs, lowercase = true) => {
 return slugify(creativeSessionName(cs), {
    remove: /[*+~.()'"!:@#]/g,
    lower: lowercase,
    strict: true,
  });
};
