// Server side status
// tracks
export const PLACEHOLDER = 'placeholder'

// contrib + creative sessions
export const PAST = 'past' // hack to allow past session to behave differently
export const RUNNING = 'running'
export const PENDING_MISSING_PARTICIPANTS = 'pending_missing_participants'
export const PENDING_CONTRIBUTION_SUBMISSIONS = 'pending_contribution_submissions'
export const PENDING_VALIDATION = 'pending_validation'
export const PENDING_CONFLICT_RESOLUTION = 'pending_conflict_resolution'
export const ACCEPTED = 'accepted'
export const REJECTED = 'rejected'

// creative sessions only
export const START = 'start'
export const PENDING_SIGN_OFF = 'pending_sign_off'
export const SIGNED_OFF = 'signed_off'

// participations only
export const SUBMITTED_CONTRIBUTIONS = 'submitted_contributions'
export const SUBMITTED_VALIDATION = 'validated'
export const VALIDATED = 'validated'

// in store, and what we send to the server!
export const CONFLICTED = 'conflicted'

export const PENDING_ONBOARDING = 'pending_onboarding'
export const PROFILE_ONBOARDED = 'profile_onboarded'
export const TRACK_ONBOARDED = 'track_onboarded'
