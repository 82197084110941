import React from "react"

import Button from '@reveel/components/ui/Button'

import LinkWrapper from '../../components/utils/LinkWrapper'

export const ReportErrorOnProfile = ({ profile, ...props }) => {
  const subject = `Here's more correct info about ${profile.display_name}`
  const body = [
    "Hi,",
    `I noticed the profile of ${profile.display_name}(${profile.id}) is missing some information.`,
    "Here are the fields you can change:",
  ].join('\n')
  return (
    <LinkWrapper to={`mailto:service@reveel.id?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`}>
      <Button {...props} bordered title="✏️ Request Change" />
    </LinkWrapper>
  )
}

export const ClaimProfile = ({ profile, ...props }) => {
  const subject = `Claiming profile ${profile.display_name}`
  const body = [
    `👋  Hi, I'm ${profile.display_name}`,
    `I'd like to claim my profile and sign up to Reveel!`,
    "",
    "Here are my first name, last name, birthday and IPI to verify I'm the rightful owner:",
    ""
  ].join('\n')
  return (
    <LinkWrapper to={`mailto:service@reveel.id?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`}>
      <Button {...props} title="👋  Claim Profile" />
    </LinkWrapper>
  )
}
