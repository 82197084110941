import React from 'react'
import { Text, StyleSheet } from 'react-native'
import { useMediaQuery } from 'react-responsive'
import View from '@reveel/components/ui/View'
import Colors from '@reveel/constants/Colors'
import { useAuth } from '@reveel/stores/useAuth'
import HeaderNavigationItems from './HeaderNavigationItems'
import ScreenTitle from '../../components/ScreenTitle'

const createScreenHeader = ({ isDesktop }) => {
  const { user } = useAuth()
  if(!isDesktop){
    // on mobile use default header
    return undefined
  }
  // eslint-disable-next-line react/display-name
  return (props) => {
    const { scene } = props
    const { options } = scene.descriptor
    const title = options.headerTitle?.() || (options.title ? options.title : scene.route.name)

    const { headerRight, headerLeft, displayTitle } = options
    return (
      <View align-center style={styles.container}>
        <View flex pl-2>
          { headerLeft?.() }
          {displayTitle
            ? <ScreenTitle row xs title={displayTitle} />
            : <Text>{title}</Text>}
        </View>
        { headerRight?.() }
        <HeaderNavigationItems {...props} />
      </View>
    )
  }
}

export default createScreenHeader

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    paddingVertical: 20,
    borderBottomColor: Colors.borderGrey,
    borderBottomWidth: 1,
  },
})
