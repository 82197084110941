import React from 'react'
import { View, Text } from '@reveel/components/ui';
import ModalSheet from '../../components/ui/modal/ModalSheet';
import EmailInputter from './EmailInputter';
import Button from '@reveel/components/ui/Button';
import { tw } from 'react-native-tailwindcss';
import { useInviteFriends } from './useInviteFriends';
import { useFriendShips } from './useFriendShips';
import ViewMoreInvites from './FriendInvitesContent';
import Loading from '../../components/Loading';
import UsersAvatarsStacked from '../../components/core/user/UsersAvatarsStacked';
import Share from '@reveel/components/invitation/Share';
import { useAuth } from '../../stores/useAuth';
import frontRoutes from '../../helpers/routes/front';
import { useDesktop } from '@reveel/helpers/MediaQueries';

const AddFriendContent = ({ onClose }) => {
  const { isLoading, setEmails, emails, onSubmit, status } = useInviteFriends({ onSubmit: onClose })
  const { user } = useAuth()
  const url = frontRoutes.absolute(`?ref=${user.id}`)

  if (status === 'success'){
    return <View flex flexGrow alignCenter px py justifyCenter>
      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
      <View style={tw.mB4}>
        <Text xl>
          🍾
        </Text>
      </View>
      <Text style={[tw.fontBold]}>Great!</Text>
      <Text>{emails.length} invitations sent!</Text>
      <Text style={[tw.textLightBlack]}>Your connections will appear on the home screen once they are accepted</Text>
    </View>
  }
  return <View style={[tw.pX2, tw.pY2]}>
    <EmailInputter onChange={setEmails} emails={emails} />
    <View style={[tw.mT4, tw.flexRow, tw.justifyBetween]}>
      <Share url={url} />
      <Button
        title="Send"
        onPress={onSubmit}
        loading={isLoading}
        disabled={!emails.length}
        small
      />
    </View>
  </View>
}

const AddFriendModal = () => {
  return <ModalSheet
    title="Invite people to Reveel"
    renderContent={({ onClose }) => <AddFriendContent onClose={onClose} />}
    renderTrigger={({ onOpen }) => <Button onPress={onOpen} title="Invite" />}
  />
}

const FriendsList = () => {
  const { isLoading, error, data } = useFriendShips()
  const isDesktop = useDesktop()
  return <View style={[tw.mB8]}>
    <Text style={[tw.textXl, tw.fontBold]}>Your Network</Text>
    <View style={[isDesktop && tw.flexRow, !isDesktop && tw.mB4, tw.itemsCenter, tw.justifyBetween, tw.mT4]}>
      <View style={[isDesktop && tw.flexRow, !isDesktop && tw.mB4, tw.itemsCenter, tw.justifyStart]}>
        {(() => {
          if (isLoading || error){
            return <Loading size='small' error={error ? 'There was an error loading your network' : undefined}/>
          }
          const { friends } = data
          return <>
            {
              friends.length ? 
                <UsersAvatarsStacked canNavigate users={friends} size={40}/> :
                <Text style={[tw.mR1]}>You haven't added anyone to your network yet.</Text>
            }
            <ViewMoreInvites />
          </>
        })()}
      </View>
      {/* View wrapper needed here because
          AddFriendModal renders 2 divs 🤔, so it breaks the flex layout
      */}
      <View>
        <AddFriendModal />
      </View>
    </View>
  </View>
}

export default FriendsList