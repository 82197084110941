import React, { useState, useEffect } from 'react'
import { StyleSheet } from 'react-native'
import { View } from '@reveel/components/ui'
import validateEmail from '@reveel/utils/validateEmail'
import TextInput from '../../../components/ui/TextInput'

const InviteByEmail = ({ style, onAdd }) => {
  const [value, setValue] = useState('')
  const [isValidEmail, setIsValidEmail] = useState(false)
  useEffect(() => {
    setIsValidEmail(validateEmail(value))
  }, [value])

  const onSubmit = () => {
    onAdd(value)
    setValue('')
  }
  return (
    <View style={style}>
      <TextInput
        autoCapitalize="none"
        textContentType="emailAddress"
        placeholder="Enter email..."
        onSubmit={onSubmit}
        iconRight="send"
        parentState={[value, setValue]}
        disabled={!isValidEmail}
      />
    </View>
  )
}
export default InviteByEmail

const styles = StyleSheet.create({
  note: {
    marginLeft: 5,
    marginTop: 5,
  },
})
