import List from "@reveel/components/ui/List"
import Colors from '@reveel/constants/Colors'
import React from "react"
import { StyleSheet } from "react-native"
import View from "../../components/ui/View"
import Loading from '../Loading';
import { tw } from 'react-native-tailwindcss';
import { ProjectListItem } from './ProjectListItem'
import { useProjects } from "./useProjects"


const ProjectList = (props) => {
  const { isLoading, isFetching, error, projects, refreshProjects, query, setQuery } = useProjects()

  if(isLoading){
    return <Loading error={error} />
  }
  const data = projects.map((p) => ({
    key: `project-${p.id}`,
    render: () => <ProjectListItem style={[tw.pX2]} project={p} />,
  }))
  return (
    <View style={styles.container} testID="projectsList">
      <List
        data={data}
        refreshing={isFetching}
        onRefresh={refreshProjects}
        onSearch={setQuery}
        searchParams={query}
        withKeyboardSpacer
        {...props}
      />
    </View>
  )
}

ProjectList.navigationOptions = {
  headerMode: "none",
  header: null,
}
export default ProjectList

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    flex: 1,
  },
})
