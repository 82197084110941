import React from "react";

import { endlineWordsAt, endlineWordsHash } from "@reveel/utils/regexp";
import MentionUser from "@reveel/components/ChatRoom/MentionUser";
import MentionSession from "@reveel/components/ChatRoom/MentionSession";

const useMentions = (props) => ({ input, ...chatProps }) =>{
  const mentionedUser = endlineWordsAt.test(input) 
  if(mentionedUser) {
    return (ref) => <MentionUser ref={ref} {...props} input={input} {...chatProps} />
  }

  const mentionedSession = endlineWordsHash.test(input) 
  if(mentionedSession) {
    return (ref) => <MentionSession ref={ref} {...props} input={input} {...chatProps} />
  }

  return null
}

export default useMentions;