import CURRENT_VERSION from '../CURRENT_VERSION'
import { url as initialURL } from "../urls"

const url = initialURL
const api = `${url}/api/v1`

const apiRoutes = {
  feedback: `${api}/feedbacks`,
  resetDemo: `${api}/reset-demo`,
  isDeprecated: `${api}/deprecated?version=${CURRENT_VERSION}`,
  user: {
    beta: `${api}/users/beta`,
    signIn: `${api}/users/sign_in`,
    signUp: `${api}/users`,
    signOut: `${api}/users/sign_out`,
    skills: `${api}/users/skills`,
    skill: (id) => `${api}/users/skills/${id}`, // toggle skills from profile(with POST or DELETE)
    device: `${api}/users/devices`,
    show: (id) => id ? `${api}/users/${id}` : `${api}/user`,
    update: () => `${api}/user`,
    search: `${api}/users`,
    resendConfirmation: `${api}/users/confirmation`,
    profile: {
      show: `${api}/users/profile`,
      update: `${api}/users/profile`,
    },
    friends: {
      index: `${api}/friendships`,
      inviteEmail: `${api}/friendships/by-email`,
      update: (id: number) => `${api}/friendships/${id}`,
    },
    auditSubmissionForm: `${api}/audit_submission_forms/my-results`,
  },
  profile: {
    show: (id) => `${api}/profiles/${id}`,
    create: `${api}/profiles`,
    update: (id) => `${api}/profiles/${id}`,
    search: `${api}/profiles`,
  },
  participations: {
    update: (id) => `${api}/participations/${id}`,
  },
  creativeSession: {
    create: `${api}/creative_sessions`,
    index: `${api}/creative_sessions`,
    update: (id) => `${api}/creative_sessions/${id}`,
    pendingContributions: `${api}/creative_sessions/pending-contributions`,
    show: (id) => `${api}/creative_sessions/${id}`,
    delete: (id) => `${api}/creative_sessions/${id}`,
    end: (id) => `${api}/creative_sessions/${id}/end`,
    finishAddingPast: (id) => `${api}/creative_sessions/${id}/finish-adding-past`,
    sendInvitations: (id) => `${api}/creative_sessions/${id}/send-email-invitations`,
    addParticipant: (id) => `${api}/creative_sessions/${id}/participations/add-participant`,
    acceptInvitation: (id) => `${api}/creative_sessions/${id}/participations/accept-invitation`,
    invitations: {
      resend: (id) => `${api}/invitations/${id}/resend`,
      show: (id) => `${api}/invitations/${id}`,
    },
    participations: {
      update: (id) => `${api}/participations/${id}`,
    },
    attachment: {
      create: (id) => `${api}/creative_sessions/${id}/attachments`,
    },
    submitValidations: (id) => `${api}/creative_sessions/${id}/validations`,
  },
  contributions: {
    messages: (id) => `${api}/contributions/${id}/messages`,
    resolve: (id) => `${api}/contributions/${id}/resolve`,
    submit: (id) => `${api}/creative_sessions/${id}/contributions`,
    validate: (id) => `${api}/contributions/${id}/validations`,
  },
  skills: {
    index: `${api}/skills`,
  },
  tracks: {
    index: `${api}/tracks`,
    permissions: (trackID) => `${api}/tracks/${trackID}/permissions`,
    createSession: (trackID) => `${api}/tracks/${trackID}/creative_sessions`,
    creativeSessions: (trackID) => `${api}/tracks/${trackID}/creative_sessions`,
    creativeSessionsPendingValidation: (trackID) => `${api}/tracks/${trackID}/pending_creative_sessions`,
    participants: (trackID) => `${api}/tracks/${trackID}/participants`,
    profiles: (trackID) => `${api}/tracks/${trackID}/profiles`,
    messages: (id) => `${api}/tracks/${id}/messages`,
    credits: (id) => `${api}/tracks/${id}/credits`,
    delete: (id) => `${api}/tracks/${id}`,
    update: (id) => `${api}/tracks/${id}`,
    show: (id) => `${api}/tracks/${id}`,
    exports: (id) => `${api}/tracks/${id}/exports`,
    progress: (id) => `${api}/tracks/${id}/progress`,
    collaborators: (id) => `${api}/tracks/${id}/collaborators`,
    requestCredit: (id) => `${api}/tracks/${id}/request_credits`,
    create: `${api}/tracks`,
    contributions: {
      index: (id) => `${api}/tracks/${id}/contributions`,
      submit: (id) => `${api}/tracks/${id}/credits`,
    },
    attachments: {
      index: (id) => `${api}/tracks/${id}/attachments`,
    },
    splitSheet: {
      songwriting_splits: {
        index: (id) => `${api}/tracks/${id}/songwriting_splits`,
        show: (id) => `${api}/tracks/${id}/songwriting_splits`,
      },
      master_points: {
        index: (id) => `${api}/tracks/${id}/master_points`,
        show: (id) => `${api}/tracks/${id}/master_points`,
      },
    },
  },
  projects: {
    index: `${api}/projects`,
  },
  attachments: {
    show: (id) => `${api}/attachments/${id}`,
    update: (id) => `${api}/attachments/${id}`,
    delete: (id) => `${api}/attachments/${id}`,
    messages: (id) => `${api}/attachments/${id}/messages`,
  },
  inbox: {
    index: `${api}/inbox`,
  },
  activities: {
    index: `${api}/notifications`,
    openAll: `${api}/notifications/open_all`,
  },
  messages: {
    reactions: (id) => `${api}/messages/${id}/reactions`,
  },
  websocket: (authToken) => `${url}/cable?auth_token=${authToken}`, // because ActionCable magically replaces https with ws
}

export default apiRoutes
