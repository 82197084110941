import React, { useEffect } from 'react'
import { View, ActivityIndicator, StyleSheet } from 'react-native'
import { Text } from '@reveel/components/ui'
import Colors from '../constants/Colors'

type IProps = {
  size?: 'large' | 'small',
  style?: any,
  lightBackground?: boolean,
  color?: string,
  error?: string,
  text?: string,
}

const Loading = ({
  size = 'large', style, lightBackground, color = Colors.tintColor, error, text,
}: IProps) => {
  return (
    <View style={[
      styles.wrapper,
      lightBackground && styles.lightBackground,
      style,
    ]}
    >
      {(text && !error) && <Text mb-2 primary smaller>{text}</Text>}
      {error ? <Text red>{error}</Text> : <ActivityIndicator size={size} color={color} />}
    </View>
  )
}

export default Loading

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  lightBackground: {
    backgroundColor: Colors.lightBackground,
  },
})
