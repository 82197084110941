import React from 'react'
import { Linking, StyleSheet } from 'react-native'
import View from '@reveel/components/ui/View'
import i18n from 'i18n-js'
import IconButton from 'app/assets/images/ui/IconButton'
import { Text } from '@reveel/components/ui'
import URL from '@reveel/vendors/url'
import openURL from '../../../utils/openURL'
import LinkWrapper from '../../../components/utils/LinkWrapper'

export const useAttachmentMeta = (attachment) => {
  try{
    const { host, pathname } = new URL(attachment.url)
    const fileName = pathname.split('/').splice(-1)[0]
    return {
      host: host.replace('www.', ''),
      fileName,
    }
  }catch(err){
    return {
      host: 'invalid url',
      filename: attachment.url,
    }
  }
}

const Attachment = ({ attachment, style }) => {
  const onPress = () => openURL({ href: attachment.url, target: '_blank' })
  const { host, fileName } = useAttachmentMeta(attachment)

  return (
    <LinkWrapper style={[styles.container, style]} to={attachment.url} target="_blank">
      <IconButton icon="link" size={25} />
      <View style={styles.title}>
        <Text small light>{host}</Text>
        <Text>{attachment.name || fileName || i18n.t('creativeSessions.ui.file.noFileName')}</Text>
      </View>
      <Text smaller bold primary style={[tw.justifySelfEnd, tw.selfCenter]}>{i18n.t('creativeSessions.ui.file.view')}</Text>
    </LinkWrapper>
  )
}
export default Attachment

const styles = StyleSheet.create({
  container: {
    paddingVertical: 10,
    // align icon center to user avatars in signed off session
    paddingLeft: 5,
    flex: 1,
    flexDirection: 'row',
  },
  title: {
    flexGrow: 1,
    // 20 to match UserContributions
    marginLeft: 20,
  },
})
