import React, { useState } from 'react'
import View from '@reveel/components/ui/View'
import i18n from 'i18n-js'
import { useAsync } from '@reveel/hooks/useRemoteState'
import { Text } from '@reveel/components/ui'
import { uri } from '@reveel/utils/regexp'
import routes from '../../../helpers/routes'
import { withCreativeSession } from '../useCreativeSessionContext'
import TextInput from '../../../components/ui/TextInput'

export const SessionAttachmentField = ({ style, parentState, ...props }) => {
  const [value, setValue] = parentState || useState('')
  return (
    <View style={style}>
      <Text smaller>{i18n.t('creativeSessions.ui.file.label')}</Text>
      <TextInput
        parentState={[value, setValue]}
        {...props}
        autoCapitalize="none"
        textContentType="URL"
        placeholder={i18n.t('creativeSessions.ui.file.placeholder')}
        appearance="line"
      />
    </View>
  )
}

const SessionAttachmentForm = withCreativeSession(({ style, creativeSession, setCreativeSession }) => {
  const [value, setValue] = useState('')
  const { run, loading } = useAsync({
    url: routes.api.creativeSession.attachment.create(creativeSession.id),
    method: 'post',
  })
  const disabled = loading || !uri.test(value)
  const onSubmit = async () => {
    const [success] = await run({ attachment: { url: value } })
    if(success){
      setValue('')
      setCreativeSession({
        ...creativeSession,
        attachments: [
          success.data.attachment,
          ...creativeSession.attachments,
        ],
      })
    }
  }
  return <SessionAttachmentField style={style} iconRight="link" onSubmit={onSubmit} disabled={disabled} parentState={[value, setValue]} />
})
export default SessionAttachmentForm
