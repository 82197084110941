import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { useQuery } from 'react-query'

import routes from '../helpers/routes'
import { useAuth } from './useAuth'

const initialState = {
  creative_sessions: [],
  tracks: [],
  user: {},
  test: true,
}
const intervalMs = 5000
const key = ['inbox']
const useInbox = () => {
  const [hasUnread, setHasUnread] = useState(false)
  const { isLoggedIn } = useAuth()
  const {
    isLoading: loading,
    error,
    data: tasks,
    refetch,
  } = useQuery(
    key,
    async () => {
      const { data } = await axios.get(routes.api.inbox.index)
      return data
    },
    {
      initialData: initialState,
      // don't refetch in dev
      enabled: isLoggedIn,
      ...(!__DEV__ && { refetchInterval: intervalMs }),
    }
  )

  useEffect(() => {
    const hasUnreadNow = (tasks && tasks.creative_sessions.length > 0) || Object.keys(tasks.user) > 0
    setHasUnread(hasUnreadNow)
  }, [tasks, loading])

  return {
    loading,
    error,
    tasks,
    hasUnread,
    refresh: refetch,
  }
}
export default useInbox
