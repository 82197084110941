import axios from 'axios'

import * as Sentry from 'sentry-expo'
import * as Segment from './segment'
import * as SentryConfig from "./SentryConfig"

export const config = () => {
  if(__DEV__) return
  //  don't let analytics crash the app
  try{
    SentryConfig.init()
  }catch(err){}

  try{
    Segment.init()
  }catch(err){
    Sentry.captureException(err)
  }
}

export const setScreen = (name, props) => runAnals([
  Segment.screen,
], [name, props])

const track = (name, props) => runAnals([
  Segment.track,
], [name, props])
export default track

export const identify = ({ auth_token, ...user }) => {
  // don't crash for analytics!
  if(!user){
    delete axios.defaults.headers.common["X-AUTH-TOKEN"]
  }
  if(__DEV__) return
  try{
    Sentry.setUser(user || {})
    Segment.identify(user)
  }catch(err){
    Sentry.captureException(err)
  }
}

function runAnals(anals, _args){
  if(__DEV__){
    console.log('[anal]', _args[0], _args[1])
    return
  }
  anals.forEach((fn) => {
    try{
      fn(..._args)
    }catch(err){
      if(__DEV__){
        console.log(fn, ..._args)
        console.error(err)
        return
      }
      Sentry.captureException(err)
    }
  })
}
