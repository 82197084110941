import { StyleSheet } from "react-native";

const parsedMentionMarkdown = (navigation) => {
  const navigationLink = /\[([^\[]+)\]\(([^\)]+)\)/gm;
  const markdownStyles = StyleSheet.create({
    bold: {
      fontWeight: "bold",
    },
  });

  const renderNavigationText = (matchingString, matches) => {
    const match = matchingString.match(navigationLink);
    return `${match[0].replace(/\[(.*)\]/, "$1").replace(/\(.*\)/, "")}`;
  };

  const handlePress = (match) => {
    const rawJson = match
      .replace(/\[.*\]/, "")
      .replace(/^\(/, "")
      .replace(/\)$/, "");
    const linkObject = JSON.parse(rawJson);

    if (linkObject.session) {
      navigation.navigate("Session", { id: linkObject.session.id });
      return;
    }

    if (linkObject.user) {
      navigation.navigate('UserProfile',  { id: linkObject.user.id })
      return;
    }
  };

  return {
    pattern: navigationLink,
    style: markdownStyles.bold,
    renderText: renderNavigationText,
    onPress: handlePress,
  };
};

export default parsedMentionMarkdown;
