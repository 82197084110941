import React, { useCallback, useState } from 'react'
import View from '@reveel/components/ui/View'
import { Platform, StyleSheet, TouchableHighlight, TouchableOpacity } from 'react-native'
import KeyboardSpacerIOS from '@reveel/components/KeyboardSpacerIOS'
import { Text } from '@reveel/components/ui'
import FormItems from '@reveel/components/ui/Form'
import ScreenTitle from '@reveel/components/ScreenTitle'
import { Link, useNavigation } from '@react-navigation/native'

import routes from '@reveel/helpers/routes'

import useSignupForm from './useSignupForm'
import variables from '../../../config/variables'

const AlreadyHaveAnAccount = ({ switchView }) => (
  <View style={styles.footer}>
    <Text small light>
      Already have an account?&nbsp;
    </Text>
    <TouchableHighlight onPress={switchView} testID="SignIn">
      <Text small underline>
        Sign in
      </Text>
    </TouchableHighlight>
  </View>
)

const SignupForm = ({ switchView }) => {
  const [userDetails, setUserDetails] = useState()
  const [currentStep, setStep] = useState(0)
  const { formProps, fieldsByStep, signUp } = useSignupForm()

  const onSubmit = useCallback((data) => {
    if(currentStep === 0){
      setStep(currentStep + 1)
      setUserDetails(data)
    }else{
      signUp(userDetails)
    }
  }, [userDetails, currentStep, setUserDetails])

  return (
    <View flex px pb style={styles.container}>
      <FormItems
        {...formProps}
        onSubmit={onSubmit}
        fields={fieldsByStep[currentStep]}
        buttonText={i18n.t('onboarding.signup.buttonText')[currentStep]}
        renderBeforeButton={() => (
          currentStep === 1 && (
          <View mb-1 justifyCenter alignCenter>
            <Text small center my px>
              {`${i18n.t('onboarding.signup.tAndCNotice')}`}
            </Text>
            <TandCLink />
          </View>
          )
        )}
        renderHeader={() => <ScreenTitle style={styles.title} title={i18n.t('onboarding.signup.title')} />}
        renderAfterButton={() => <AlreadyHaveAnAccount switchView={switchView} />}
        buttonContainerStyle={styles.buttonStyle}
      />
      <KeyboardSpacerIOS />
    </View>
  )
}

const TandCLink = () => {
  const navigation = useNavigation()
  if(Platform.OS === 'web'){
    return (
      <a href="https://api.reveel.id/terms-and-conditions" target="_blank">
        <Text style={styles.link}>Terms and Conditions</Text>
      </a>
    )
  }
  return (
    <TouchableOpacity onPress={() => navigation.navigate('WebView', {
      source: {
        uri: routes.web.terms,
      },
      title: 'Terms & Conditions',
    })}
    >
      <Text center my px small underline>Terms and Conditions</Text>
    </TouchableOpacity>
  )
}

export default SignupForm

const styles = StyleSheet.create({
  container: {
    padding: 15,
  },
  title: {
    marginTop: 0,
    marginLeft: 10,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 20,
  },
  link: {
    textDecorationLine: 'underline',
    fontSize: variables.smallFontSize,
  },
  buttonStyle: {
    marginTop: 50,
  },
})
