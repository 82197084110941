import { useCallback, useEffect } from 'react'

import routes from '@reveel/helpers/routes'
import axios from 'axios'

import usePersistedState from '../hooks/usePersistedState'
import { useAsync } from '../hooks/useRemoteState'

export const useUpdateMyProfile = ({ onSuccess }) => {
  const { myProfile, setMyProfile } = useMyProfile()
  const { run, ...rest } = useAsync({
    url: () => routes.api.profile.update(myProfile.id),
    method: 'put',
    handleResponse: ({ data }) => {
      setMyProfile(data)
      onSuccess()
    },
  })

  // skills is returned by the `fields` of the form.
  // it should probably handled at a higher level
  const onSubmit = useCallback(({ skills, ...profileDetails }) => run({
    profile: {
      ...profileDetails,
    },
  }), [run])

  return {
    ...rest,
    run: onSubmit,
  }
}

const emptyProfile = {
  social: {},
  songwriter_information: {},
  publisher_information: {},
  artist_information: {},
  label_information: {},
  stats: {}
}

const useMyProfile = () => {
  const [myProfile, setMyProfile] = usePersistedState('MR_MY_PROFILE')

  const refreshMyProfile = async () => {
    const { data } = await axios.get(routes.api.user.profile.show)
    setMyProfile(data)
  }

  useEffect(() => {
    refreshMyProfile()
  }, [])

  return {
    myProfile,
    setMyProfile,
    refreshMyProfile,
  }
}

export default useMyProfile
