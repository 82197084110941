import React from 'react'
import { StyleSheet } from 'react-native'

import withNavigation from '@reveel/helpers/withNavigation'
import UserWithText from '../core/UserWithText'
import { withCreativeSession } from '../../screens/sessions/useCreativeSessionContext'

import RowWithActions from '../RowWithActions'
import Loading from '../Loading'
import useInvitationActions from './useInvitationActions'

const InvitationWithActions = ({
  invitation,
  style,
}) => {
  const { deleteEmail, sendEmail, sendingEmail, deletingEmail, sendCTA, } = useInvitationActions(invitation)
  const actions = []

  actions.push({
    text: sendCTA,
    onPress: sendEmail,
    loading: sendingEmail,
  })

  actions.push({
    text: 'Cancel Invitation',
    onPress: deleteEmail,
    loading: deletingEmail,
  })

  const user = invitation.user || {
    name: invitation.email,
    avatar_url: 'https://reveel-prod.s3.us-east-2.amazonaws.com/static/default_icon.png',
  }
  return (
    <RowWithActions actions={actions} style={[styles.container, style]} smaller>
      <UserWithText
        user={user}
        subText={invitation.status}
      />
    </RowWithActions>
  )
}

const styles = StyleSheet.create({
  container: {
  },
})

export default withNavigation(withCreativeSession(InvitationWithActions))
