import React from 'react'
import { TouchableOpacity } from 'react-native'
import * as Icon from '@expo/vector-icons'
import Colors from '@reveel/constants/Colors'

const HeaderBackButton = ({ name = 'ios-arrow-back', onPress, color = Colors.tintColor }) => {
  const inside = <Icon.Ionicons color={color} name={name} size={30} style={{ paddingHorizontal: 10 }} />
  if(onPress){
    return <TouchableOpacity onPress={onPress}>{inside}</TouchableOpacity>
  }
  return inside
}

export default HeaderBackButton
