import React from 'react'
import { Pressable } from 'react-native'

import { Ionicons } from '@expo/vector-icons'
import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'
import { useDesktop } from '@reveel/helpers/MediaQueries'
import { tw } from 'react-native-tailwindcss'

import LinkWrapper from '../../../../components/utils/LinkWrapper'

const SingleStatus = ({ status, active, onPress, style }) => (
  <Pressable onPress={onPress} style={[tw.cursorPointer]}>
    <Text style={[active && tw.fontBold, tw.fontSmall, style]}>{i18n.t(`dashboard.ui.toggle.${status}`)}</Text>
  </Pressable>
)

const TaskItem = ({ name, status, changeStatus, canDisable, url, style }) => {
  const isDesktop = useDesktop()
  const disabled = status === 'disabled'
  const update = (newStatus) => changeStatus(name, newStatus)
  const renderTitle = () => {
    const Wrapper = url ? LinkWrapper : View
    return (
      <Wrapper to={url} style={[tw.mR4, tw.flexShrink, tw.w100, !isDesktop && tw.mB4]}>
        <Text style={[
          disabled ? tw.textLightGrey : (url ? tw.textPrimary : undefined),
          tw.fontBold,
          tw.mB1,
        ]}
        >
          {i18n.t(`dashboard.tasks.${name}.title`)}
          {url && " \u203A"}
        </Text>
        <Text
          style={[
            disabled ? tw.textLightGrey : tw.textLightBlack,
          ]}
        >
          {i18n.t(`dashboard.tasks.${name}.description`)}
        </Text>
      </Wrapper>
    )
  }
  return (
    <View style={[tw.flexRow, !isDesktop && tw.flexWrap, style]}>
      {renderTitle()}
      {canDisable && status && (
      <Pressable
        onPress={() => update(disabled ? 'todo' : 'disabled')}
        style={[tw.cursorPointer, tw.mR4]}
      >
        {() => (
          <Ionicons
            name={disabled ? 'eye-off-outline' : 'eye-outline'}
            size={22}
            style={[disabled ? tw.textIconGrey : tw.textLightBlack]}
          />
        )}
      </Pressable>
      )}
      { status && !disabled && (
        <View style={[tw.flexGrow, tw.justifyEnd, tw.flexRow]}>
          <SingleStatus
            style={[tw.mR4, status === 'todo' ? tw.textOrange : tw.textLightBlack]}
            status="todo"
            active={status === 'todo'}
            onPress={() => update('todo')}
          />
          <SingleStatus
            status="done"
            style={[status === 'done' ? tw.textPrimary : tw.textLightBlack]}
            active={status === 'done'}
            onPress={() => update('done')}
          />
        </View>
      )}
    </View>
  )
}

export default TaskItem
