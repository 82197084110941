import React from 'react'

import { Link } from '@react-navigation/native'
import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'
import { tw } from 'react-native-tailwindcss'

export const DisableAdBlock = ({ style }) => (
  <View style={style}>
    <Text style={[tw.selfCenter, tw.text5xl, tw.mY4]} role="img">😔</Text>
    <Text style={[tw.fontBold, tw.mB2, tw.textLg]}>
      Oops, it looks like we couldn&apos;t create an invite link...
    </Text>
    <Text>
      You might have an adblocker that disables the service we use to invite people to your tracks.
      Can you please disable it for now? We&apos;re working on removing this service as quickly as possible.
    </Text>
    <Text style={[tw.mT1]}>
      If the error persists,
      <Link to="mailto:info@reveel.id" style={[tw.underline, tw.textPrimary]}>leave us a message</Link>
      .
    </Text>
  </View>
)
