import React from 'react'
import { StyleSheet, Image, Platform } from 'react-native'
import View from '@reveel/components/ui/View'
import { Text } from '@reveel/components/ui'
import Colors from '@reveel/constants/Colors'
import UserStatsBar from './UserStatsBar'
import { fullName, isni } from '../../helpers/userFormatter'

import Separator from '../../components/ui/Separator'
import UserQrCode from './UserQrCode'

export const AVATAR_SIZE = 140
const QR_SIZE = AVATAR_SIZE / 4

const ProfileHeader = ({ style, user }) => {
  const { avatar_url } = user
  return (
    <View style={style}>
      <View row>
        <View sheet style={styles.sheet} />
        <View style={styles.avatar}>
          <Image style={styles.image} source={{ uri: avatar_url }} />
        </View>
        <View style={styles.rightHand}>
          {
          Platform.OS !== 'web'
          && <UserQrCode style={styles.qrCode} user={user} size={AVATAR_SIZE / 4} avatarSize={AVATAR_SIZE} />
        }
          <View pl-3 style={{ height: AVATAR_SIZE / 2, justifyContent: 'center' }}>
            <Text xs>ISNI</Text>
            <Text small light>{isni(user) || "N/A"}</Text>
          </View>
          <Text bold large>
            {user.name}
            {' '}
            {__DEV__ && user.id}
            {' '}
          </Text>
          <Text>
            {user.first_name}
            {' '}
            {user.last_name }
          </Text>
        </View>
      </View>
      <View sheetBottom style={styles.infoContainer}>
        {user.biography?.length ? <Text smaller>{user.biography}</Text> : null}
        <Separator my-lg />
        <UserStatsBar user={user} />
      </View>
    </View>
  )
}

export default ProfileHeader
const styles = StyleSheet.create({
  avatar: {
    marginLeft: 15,
    backgroundColor: Colors.white,
    borderRadius: 8,
  },
  image: {
    borderRadius: 8,
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
  },
  rightHand: {
    flex: 1,
    paddingLeft: 20,
    justifyContent: 'space-around',
  },
  qrCode: {
    position: 'absolute',
    left: -QR_SIZE / 2,
    top: (AVATAR_SIZE / 2 - QR_SIZE) / 2,
    borderWidth: 2,
    borderColor: Colors.white,
  },
  infoContainer: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  sheet: {
    position: 'absolute',
    width: "100%",
    top: AVATAR_SIZE / 2,
    bottom: 0,
  },
})
