import React from 'react'
import { View } from 'react-native'
import WebView from '../../components/WebView'

const WebViewScreen = ({
  route,
}) => (
  <View style={{ flex: 1 }}>
    <WebView
      style={{ flex: 1 }}
      useWebKit
      source={{ uri: `${route?.params?.remoteRoute}?&app=1` }}
      presentationStyle="pageSheet"
    />
  </View>
)

export default WebViewScreen
