import React from 'react'
import View from '@reveel/components/ui/View'
import routes from '@reveel/helpers/routes'
import { Text } from 'react-native'
import UserWithText from '@reveel/components/core/UserWithText'
import Button from '@reveel/components/ui/Button'
import RefreshableScrollView from '@reveel/components/ui/RefreshableScrollView'
import { useNavigation } from '@react-navigation/native'
import Page from '@reveel/layout/Page'
import SplitsEmpty from './SplitsEmpty'
import useTrackBreadcrumb from '../../../navigation/useTrackBreadcrumb'
import TrackPermissionsWrapper from '../TrackPermissions/index';
import { useAuth } from '@reveel/stores/useAuth';
import { useDesktop } from '@reveel/helpers/MediaQueries';

const SplitSheetShow = ({ splitSheet }) => {
  const { splits } = splitSheet

  return (
    <View testID="splitsDetails" style={[tw.pX4, tw.pY4]}>
      {splits.map(({ id, profile, value }) => (
        <UserWithText
          key={id}
          user={profile}
          icon={(
            <Text testID="share" style={[tw.borderB, tw.borderGray600, tw.pB1]}>
              {Number(value).toFixed(2)}
              %
            </Text>
          )}
        />
      ))}
    </View>
  )
}

const TrackSplits = ({ splitsType, route }) => {
  const trackID = route.params?.trackID
  const navigation = useNavigation()
  useTrackBreadcrumb(trackID, ['Splits'])
  const goToEdit = () => navigation.push('TrackSplitsNew', { trackID })
  const { user: { admin } } = useAuth()
  const isDesktop = useDesktop()
  const renderData = (data) => {
    if(!data){
      return <SplitsEmpty trackID={trackID} splitsType={splitsType} />
    }
    return (
      <>
        <View style={tw.flexGrow}>
          <Page 
            sheet
            renderAbove={() => (
              <View py bg-light-grey style={[
                isDesktop && tw.flexRow,
                isDesktop && tw.justifyEnd,
                { gap: 16 },

              ]}>
                {admin && <TrackPermissionsWrapper
                  trackID={trackID}
                  renderTrigger={({ onOpen }) => <Button small title={"+ Add Collaborator"} onPress={onOpen} testID="addCollab" />}
                />}
                <Button small title={i18n.t(`splits.edit.${splitsType}.cta`)} onPress={goToEdit} testID="editSplits" />
              </View>
            )}
          >
            <SplitSheetShow splitSheet={data.split_sheet} />
          </Page>
        </View>
      </>
    )
  }
  return (
    <RefreshableScrollView
      renderData={renderData}
      url={routes.api.tracks.splitSheet[splitsType].show(trackID)}
      initialState={undefined}
      dependencies={[trackID, splitsType]}
    />
  )
}

export const SongwritingSplits = (props) => <TrackSplits splitsType="songwriting_splits" {...props} />
export const MasterPoints = (props) => <TrackSplits splitsType="master_points" {...props} />

export default TrackSplits
