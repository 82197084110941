import React from "react"
import { View, StyleSheet } from "react-native"
import { Text } from '@reveel/components/ui'
import colors from "@reveel/constants/Colors"

const LabeledDetail = ({ text, number }) => (
  <View style={styles.detailContainer}>
    <Text light small center style={styles.text}>{text}</Text>
    <Text bold larger style={styles.number}>{number}</Text>
  </View>
)

function UserStatsBar({
  style, user: {
    stats: {
      weekly_contributions_count,
      collaborators_count,
      tracks_count,
    },
  },
}){
  const stats = {
    "Active Tracks": tracks_count,
    Collaborators: collaborators_count,
    "Weekly Contributions": weekly_contributions_count,
  }
  return (
    <View style={[styles.container, style]}>
      {Object.keys(stats).map((k) => <LabeledDetail key={`stats-${k}`} text={k} number={stats[k]} />)}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  detailContainer: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  number: {
    marginTop: 5,
  },
})

export default UserStatsBar
