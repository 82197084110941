import { useCallback, useEffect } from 'react'

import routes from '@reveel/helpers/routes'
import usePersistedState from '@reveel/hooks/usePersistedState'
import axios from 'axios'
import queryString from 'query-string'
import * as Sentry from 'sentry-expo'

const { src, rev, ref } = queryString.parse(window.location.search)

export const SIGNUP_SRC_KEY = '@REV:SIGNUP_SRC_KEY'

export const useOnLoad = () => {
  const [initialQueryParams, setInitialQueryParams] = usePersistedState(SIGNUP_SRC_KEY, {})
  const setSingleKeys = useCallback((modifs) => {
    setInitialQueryParams((s) => ({
      ...s,
      ...modifs,
    }))
  }, [setInitialQueryParams])
  useEffect(() => {
    // rev=el is for signups
    if(src || rev || ref){
      setSingleKeys({
        src,
        rev,
        ref,
      })
    }
  }, [setSingleKeys])

  return {
    initialQueryParams,
    setInitialQueryParams: setSingleKeys,
  }
}
