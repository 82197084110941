import React from 'react'
import FieldsRenderer from '../../components/ui/FieldsRenderer'
import trackMetadataFieldsList from './trackMetadataFieldsList'
import Page from '@reveel/layout/Page';
import useTrackBreadcrumb from '../../navigation/useTrackBreadcrumb';
import useStoreContext from '@reveel/stores/useStoreContext';
import TrackArtwork from '../../components/tracks/TrackArtwork';
import { useTrack } from '@reveel/hooks/queries/track';
import { View } from 'react-native';
import { useDesktop } from '@reveel/helpers/MediaQueries';

const TrackInfo = ({ route }) => {
  const trackID = route.params.trackID
  useTrackBreadcrumb(trackID, ['Details'])
  const { track } = useTrack(trackID)
  const isDesktop = useDesktop()
  return (
    <Page sheet>
      <View style={[isDesktop && tw.flexRow]}>
        <TrackArtwork trackID={trackID} size={200} style={[tw.mX4, tw.mT4]} />
        <FieldsRenderer
          sectionTitleProps={{ withBorder: true }}
          fields={trackMetadataFieldsList}
          subject={track}
          style={tw.p4}
          />
      </View>
    </Page>
  )
}

export default TrackInfo
