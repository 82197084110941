import routes from '@reveel/helpers/routes';
import axios from 'axios';
import { useQuery } from 'react-query';

export const permissionsKey = (trackID) => ['track', 'permissions', trackID];
export const useTrackPermissions = (trackID) => {
  const route = routes.api.tracks.permissions(trackID);
  const { isLoading, error, data } = useQuery(permissionsKey(trackID), async () => {
    const res = await axios.get(route)
    return res.data
  })
  return {
    isLoading,
    error,
    permissions: data,
  };
};
