import React from 'react'

import { ScrollView, RefreshControl } from 'react-native'
import useRemoteState from '@reveel/hooks/useRemoteState'
import Loading from '../Loading'
import useRefreshScreen from '../../helpers/useRefreshScreen';

const RefreshableScrollView = ({
  renderData, url, initialState, dependencies = [],
}) => {
  const {
    data, loading, refresh, refreshing,
  } = useRemoteState({
    url,
    initialState,
  }, dependencies)
  useRefreshScreen(() => refresh({ hideRefreshing: true }), dependencies)
  return (
    <ScrollView
      refreshControl={(
        <RefreshControl
          refreshing={refreshing}
          onRefresh={refresh}
        />
    )}
      contentContainerStyle={{ flexGrow: 1, paddingBottom: 15 }}
    >
      {loading ? <Loading /> : renderData(data)}
    </ScrollView>
  )
}
export default RefreshableScrollView
