import React, { useState } from 'react'

import Colors from '@reveel/constants/Colors'
import withNavigation from '@reveel/helpers/withNavigation'
import { useNavigation } from '@react-navigation/native'
import UserWithText from '../../components/core/UserWithText'
import { withCreativeSession } from './useCreativeSessionContext'
import ParticipationSwitch from './ParticipationSwitch'

import RowWithActions from '../../components/RowWithActions'
import { useAuth } from '../../stores/useAuth'

export const useUserAction = () => {

}

const UserWithActions = ({
  user,
  subText,
  creativeSession: { can, user_id: owner_id },
  actions = [
    'admin', 'workForHire',
  ],
  style,
}) => {
  const navigation = useNavigation()
  const isSelf = useAuth().user.id === user.id

  const displayedActions = [
    (!isSelf && actions.includes('viewProfile')) && {
      text: 'View Profile',
      onPress: () => navigation.push('UserProfile', { id: user.id }),
    },
    (actions.includes('admin') && can.set_roles) && {
      text: 'Admin',
      children: <ParticipationSwitch user={user} attribute="admin" disabled={owner_id === user.id} />,
    },
    (actions.includes('workForHire') && can.set_roles) && {
      text: 'Work for Hire',
      children: <ParticipationSwitch user={user} attribute="work_for_hire" />,
    },
    // allow custom actions
    ...actions.filter((a) => typeof a !== 'string'),
  ].filter(Boolean)

  // using user.user_id as this actually renders a profile, not a user.
  // at least for the moment
  return (
    <RowWithActions style={style} actions={displayedActions} testID={`user-row-${user.user_id}`}>
      <UserWithText user={user} subText={subText} />
    </RowWithActions>
  )
}

export default withNavigation(withCreativeSession(UserWithActions))
