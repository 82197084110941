import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'
import React, { useState } from 'react'
import { Platform, StyleSheet, TouchableOpacity } from 'react-native'
import { SceneMap, TabView } from 'react-native-tab-view'
import Colors from '../constants/Colors'
import Button from './ui/Button'
import TouchableIcon from './ui/TouchableIcon'

const GuideSlides = ({
  tabs,
  onFinish,
  finishText,
}) => {
  const [nav, setNav] = useState({
    index: 0,
  })
  const renderDots = (props) => {
    const { index } = props.navigationState
    if(index === tabs.length - 1){
      return (
        <View style={styles.dotsWrapper}>
          <View style={{ flex: 1 }}>
            <Button block title={finishText} onPress={onFinish} />
          </View>
        </View>
      )
    }
    return (
      <View style={styles.dotsWrapper}>
        <View style={styles.dotsContainer}>
          {tabs.map((t, i) => <View key={`${i}`} onPress={() => setNav({ index: i})} style={[styles.dot, index === i && styles.active]} />)}
        </View>
        <TouchableIcon 
          onPress={() => setNav({ index: index + 1 })} 
          name="arrow-forward" 
          fill
          size={35}
        />
      </View>
    )
  }

  const routes = tabs.map((e) => ({ key: e.title, title: e.title }))
  const map = {}
  tabs.forEach((t) => map[t.title] = t.elem)
  return (
    <>
      <TabView
        onIndexChange={(i) => setNav({ ...nav, index: i })}
        navigationState={{
          index: nav.index,
          routes,
        }}
        renderScene={SceneMap(map)}
        renderTabBar={renderDots}
        style={styles.tab}
      />
      {nav.index !== tabs.length - 1 && (
      <TouchableOpacity style={styles.skip} onPress={onFinish}>
        <Text
          testID="skipIntro"
          light
          small
        >
          Skip
        </Text>
      </TouchableOpacity>
      )}
    </>
  )
}

export default GuideSlides

const styles = StyleSheet.create({
  dotsWrapper: {
    position: 'absolute',
    zIndex: 100,
    flexDirection: 'row',
    bottom: 0,
    paddingVertical: 25,
    paddingHorizontal: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    alignSelf: 'stretch',
  },
  dotsContainer: {
    flexDirection: 'row',
  },
  dot: {
    borderRadius: 5,
    width: 8,
    height: 8,
    backgroundColor: Colors.lightBackground,
    marginRight: 15,
  },
  active: {
    backgroundColor: Colors.tintColor,
  },
  skip: {
    position: 'absolute',
    right: 5,
    top: 0,
    zIndex: 101,
    padding: 10
  },
  tab: {
    flex: 1,
    backgroundColor: Colors.white,
    ...Platform.select({
      web: {
        paddingBottom: 60,
      }
    })
  },
})
