import React from "react";
import { Platform } from "react-native";
import KeyboardSpacer from "react-native-keyboard-spacer";

function KeyboardSpacerIOS() {
  if (Platform.OS === "ios") return <KeyboardSpacer />;

  return null;
}

export default KeyboardSpacerIOS;
