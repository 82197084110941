import React from "react"
import { StyleSheet } from "react-native"
import { ListItem as BaseListItem, Left, Body } from "native-base"
import UserAvatar from 'react-native-user-avatar'

import { Text } from '@reveel/components/ui'
import { fullName } from '@reveel/helpers/userFormatter'

const ListItem = ({ item, titleStyle }) => {
  if(__DEV__ && !item.key){
    console.warn("key missing for ", item)
  }

  const { user } = item

  return (
    <BaseListItem
      noIndent
      noBorder={true}
      onPress={item.onPress}
      style={item.style}
    >
      <Left style={styles.left}>
        <UserAvatar containerStyle={styles.avatar} name={fullName(user)} size={20} src={user.avatar_url} />
      </Left>

      <Body>
        <Text style={styles.name}>{fullName(user)}</Text>
      </Body>
    </BaseListItem>
  )
}

const styles = StyleSheet.create({
  left: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 0,
    paddingRight: 8,
    marginLeft: 10,
  },
  name: {
    marginLeft: 20,
  },
})

export default ListItem
