import React from 'react'
import { TextInput } from 'react-native'
import View from '@reveel/components/ui/View'
import { Text } from '@reveel/components/ui'
import UserWithText from '@reveel/components/core/UserWithText'
import Separator from '@reveel/components/ui/Separator'
import * as Animatable from 'react-native-animatable'
import { PropTypes } from 'prop-types'
import Switch from '@reveel/components/ui/Switch';
import { tw } from 'react-native-tailwindcss';
import i18n from 'i18n-js';

const SplitEqually = ({ dispatch, splitEqually }) => (
  <View style={[tw.flexRow, tw.itemsCenter, tw.justifyEnd, tw.pY2, tw.pX2]}>
    <Text>{i18n.t('splits.form.splitEqually')}</Text>
    <Switch testID="toggleEquality" small onValueChange={() => dispatch({ type: 'TOGGLE_EQUAL_SPLITS' })} value={splitEqually} />
  </View>
)

const SplitsForm = ({ profiles, splits, total, splitEqually, dispatch }) => (
  !profiles.length
    ? (
      <View style={[tw.pY2]}>
        <Text center primary small>{i18n.t('splits.form.empty.cta')}</Text>
      </View>
    )
    : (
      <>
        <SplitEqually dispatch={dispatch} splitEqually={splitEqually} />
        <View testID="splitsForm" style={tw.pX2}>
          {profiles.map((u, i) => {
            const value = splits.find((s) => s.profile_id === u.id)?.value
            const onChange = (val) => {
              if(splitEqually){
                dispatch({ type: 'TOGGLE_EQUAL_SPLITS' })
              }
              dispatch({ type: 'EDITED_SHARE', payload: { profileID: u.id, value: parseFloat(val) } })
            }
            const input = (
              <View style={[tw.flexRow, tw.borderB, tw.borderGray400, tw.pX1, tw.pB1]}>
                <TextInput
                  testID="share"
                  appearance="none"
                  value={splitEqually ? value : undefined}
                  defaultValue={`${value}`}
                  onChangeText={onChange}
                  keyboardType="number-pad"
                  style={[tw.w8]}
                />
                <Text>%</Text>
              </View>
            )
            return (
              <Animatable.View animation="slideInLeft" duration={200}>
                {i !== 0 && <Separator />}
                <UserWithText
                  key={u.id}
                  user={u}
                  icon={input}
                />
              </Animatable.View>
            )
          })}
        </View>
        <View style={[tw.flexRow, tw.justifyEnd, tw.pY3, tw.pX2]} border-t border-b>
          <Text smaller>
            {i18n.t('splits.total')} &nbsp;
          </Text>
          {!isNaN(total) && (
          <Text smaller red={total !== 100} testID="splitsTotal">
            {total}
            %
          </Text>
          )}
        </View>
      </>
    )
)

SplitsForm.propTypes = {
  profiles: PropTypes.array,
  splits: PropTypes.arrayOf(PropTypes.shape({
    profile_id: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  })).isRequired,
  total: PropTypes.number,
  splitEqually: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default SplitsForm
