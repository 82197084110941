import React, { useContext } from 'react'
import { TouchableOpacity } from 'react-native'
import * as Icon from '@expo/vector-icons'
import withNavigation from '@reveel/helpers/withNavigation'

import Colors from '@reveel/constants/Colors'
import { PENDING_VALIDATION, ACCEPTED } from '../../constants/Status'
import { Context as ValidationContext } from '../../screens/validation/validationContext'

import actionStyles from './contributionActionStyles'

const ContributionAccept = ({
  contribution,
  onPress,
}) => {
  const { status } = contribution
  let color
  if(status){
    color = status === ACCEPTED ? Colors.tintColor : Colors.disabledText
  }
  return (
    <TouchableOpacity onPress={onPress} style={actionStyles.button} testID="contributionAccept">
      <Icon.Ionicons color={color} name="ios-checkmark" size={35} />
    </TouchableOpacity>
  )
}

export default withNavigation(ContributionAccept)
