import Button from '@reveel/components/ui/Button';
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
import Colors from '@reveel/constants/Colors';
import { useAuth } from '@reveel/stores/useAuth';
import Card from '@reveel/components/ui/Card';
import ModalSheet from '@reveel/components/ui/modal/ModalSheet';
import { Platform } from 'react-native';
import { TrackNewForm } from '@reveel/screens/tracks/TrackNew';
import track from '../../analytics/index';

const copy = {
  noTracks: {
    title: "You haven't added projects yet",
    cta: "+ Add your first project",
  },
  hasTracks: {
    title: "Working on something spicy?",
    cta: "+ Add your new project",
  },
}

const NewTrackButton = ({ title, buttonProps = {}, onPress }: { title: string, buttonProps?: any, onPress?: () => void }) => {
  const renderTrigger = ({ onOpen }) => (
    <Button
      testID="SidebarAddTrack"
      block
      title={title}
      onPress={() => {
        onPress?.()
        onOpen()
      }}
      {...buttonProps}
    />
  )
  const renderContent = ({ onClose }) => <TrackNewForm onSuccess={onClose} />
  return <ModalSheet
    title={"Add New Project"}
    renderTrigger={renderTrigger}
    renderContent={renderContent}
  />
}

export const TracksCard = ({ style, from }: { style?: any; from: string }) => {
  const { user } = useAuth();
  const header = <Ionicons name="musical-notes" color={Colors.tintColor} size={48} />;
  const hasTracks = Boolean(user.owned_tracks_count);
  const props = hasTracks ? copy.hasTracks : copy.noTracks;
  const buttonProps = {
    style: [
      Platform.OS === 'web' && {
        // the linear background is here as it is stripped by StyleSheet
        background: 'linear-gradient(352.86deg, #ED328B 10.99%, #F36A4B 77.65%, #FF9D00 116.69%)',
        alignSelf: 'stretch',
      },
    ]
  }
  return <Card
    title={props.title}
    actions={<NewTrackButton
      title={i18n.t(`banners.track.new.button`)}
      buttonProps={buttonProps}
      onPress={() => {
        track('Card Clicked', {
          action: 'create_track',
          from,
        })
      }}
    />}

    header={header}
    style={style}
    align="center" />;
};

export const SplitsCard = ({ style, from }: { style?: any; from: string }) => {
  const header = <Ionicons name="book-outline" color={Colors.tintColor} size={48} />;
  return <Card
    title={"Set up Splits with your co-writers"}
    actions={<NewTrackButton
      title={"Create Splits Sheet"}
      onPress={() => {
        track('Card Clicked', {
          action: 'create_split_sheet',
          from,
        })
      }}
    />}
    header={header}
    style={style}
    align="center" />;
};

export const LabelCopyCard = ({ style, from }: { style?: any; from: string }) => {
  const header = <Ionicons name="document" color={Colors.tintColor} size={48} />;
  return <Card
    title={"Collect the data to distribute your song"}
    actions={<NewTrackButton 
      title={"Create Label Copy"}
      onPress={() => {
        track('Card Clicked', {
          action: 'create_label_copy',
          from,
        })
      }}
    />}
    header={header}
    style={style}
    align="center" />;
};
