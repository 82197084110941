import Fuse from 'fuse.js'

export default (items, { 
  query = '', 
  keys, 
  shouldSort = true, 
  threshold = 0.2 
}) => {
  if(!query.length){
    return items
  }

  const fuse = new Fuse(items, {
    threshold,
    shouldSort,
    keys,
  })
  return fuse.search(query).map(({ item }) => item)
}
