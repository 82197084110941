import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Text } from '@reveel/components/ui'

import processErrors from '../helpers/processErrors'

const ErrorsPresenter = ({ errors, style }) => (!errors ? null : (
  <View>
    {Object.keys(processErrors(errors)).map((k) => (
      <View style={[styles.container, style]} key={k}>
        <Text red small>
          {k}
          {' '}
          {errors[k].join(', ')}
        </Text>
      </View>
    ))}
  </View>
))
export default ErrorsPresenter

const styles = StyleSheet.create({
  container: {
  },
})
