import React from 'react'
import { StyleSheet } from 'react-native'
import View from '@reveel/components/ui/View'
import { Text } from '@reveel/components/ui'
import { useDesktop } from '../../helpers/MediaQueries'

const SectionTitle = ({ title, withBorder, style }) => {
  const isDesktop = useDesktop()
  return (
    <View 
      border-b={isDesktop || withBorder} 
      mb-4={isDesktop} 
      style={[styles.container, tw.pL2, style]}
    >
      <Text bold style={styles.text}>{title}</Text>
    </View>
  )
}

export default SectionTitle

const styles = StyleSheet.create({
  container: {
    paddingBottom: 10,
    marginBottom: 5,
  },
  text: {
    textTransform: 'capitalize',
  },
})
