import React, { useState, useEffect } from 'react'
import { StyleSheet, TextInput as NativeTextInput } from 'react-native'
import Colors from '@reveel/constants/Colors'
import IconButton from 'app/assets/images/ui/IconButton'
import View from '@reveel/components/ui/View'

const TextInput = ({
  onChangeText,
  parentState,
  style,
  rounded = true,
  prepend, 
  iconRight,
  initialState = '',
  appearance = 'rounded',
  onSubmit,
  disabled,
  minHeight = 30,
  ...restProps
}) => {
  const [value, setValue] = parentState || useState(initialState)

  const renderButtonRight = () => {
    if(!iconRight){ return null }
    const disabled_ = !onSubmit || disabled
    return <IconButton style={styles.buttonRight} size={30} icon={iconRight} onPress={() => onSubmit(value)} disabled={disabled_} />
  }

  // some cases require the value to be handled by a parent component
  // (to reset the value, for example in InviteUserMain). In this case, no need to bubble
  // up the new value
  useEffect(() => {
    if(parentState){ return }
    onChangeText(value)
  }, [value])

  return (
    <View style={[styles.container, style]}>
      <View flex row style={[styles[appearance]]}>
        {prepend && <View style={styles.prepend}>{prepend()}</View>}
        <NativeTextInput
          small
          onChangeText={setValue}
          defaultValue={value}
          style={[styles.minStyle, { minHeight }]}
          textAlignVertical="top"
          {...restProps}
        />
      </View>
      {renderButtonRight()}
    </View>
  )
}

export default TextInput

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  minStyle: {
    flex: 1,
    minHeight: 30,
    paddingTop: 5,
  },
  // eslint-disable-next-line react-native/no-unused-styles
  square: {
    borderColor: Colors.borderDarkGrey,
    borderWidth: 1,
    borderRadius: 2,
    paddingLeft: 10,
    paddingRight: 10,
  },
  // eslint-disable-next-line react-native/no-unused-styles
  leftLine: {
    borderColor: Colors.borderDarkGrey,
    borderLeftWidth: 1,
    borderRadius: 2,
    paddingLeft: 5,  
    paddingRight: 5,  
  },
  // eslint-disable-next-line react-native/no-unused-styles
  rounded: {
    borderColor: Colors.borderDarkGrey,
    borderWidth: 1,
    backgroundColor: Colors.lightBackground,
    borderRadius: 50,
    paddingLeft: 10,
  },
  prepend: {
    marginRight: 5,
    marginLeft: 5,
  },
  buttonRight: {
    marginLeft: 5,
  },
})
