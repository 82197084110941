import React from 'react'
import List from '../../components/ui/List'
import mapSkillsToListable from './mapSkillsToListable'
import useContributions from '../contributions/useContributions'
import useSkills from './useSkills'
import View from '../../components/ui/View'

const useStates = {
  useContributions,
  useSkills,
}

const SkillSelectScreen = ({ route }) => {
  const skills = route.params?.skills
  // sometimes we use the skills store for the user settings,
  // sometimes we use the validation store
  const useStateFrom = useStates[route.params?.useState]
  const data = mapSkillsToListable(skills, useStateFrom())
  return (
    <View style={[tw.flexGrow]}>
      <List withBorder={false} data={data} />
    </View>
  )
}

SkillSelectScreen.navigationOptions = ({ navigation }) => ({
  title: route.params?.title,
  headerTitleStyle: {
    textTransform: 'capitalize',
  },

})
export default SkillSelectScreen
