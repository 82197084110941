import React, { useEffect } from 'react'
import {
  TouchableOpacity, StyleSheet, TextInput, View,
} from 'react-native'
import * as Icon from '@expo/vector-icons'
import Colors from '@reveel/constants/Colors'

const NumberPicker = ({
  style, onChange, min = 0, max, value = min,
}) => {
  const bound = (v) => Math.max(Math.min(v, max || v), min)
  const increment = () => onChange(bound(parseInt(value) + 1))
  const decrement = () => onChange(bound(parseInt(value) - 1))

  const removeIconColor = (value <= min) ? 'lightgrey' : Colors.tintColor

  useEffect(() => {
    onChange(bound(value))
  }, [])

  const onBlur = () => (isNaN(parseInt(value)) ? onChange(min) : null)
  return (
    <View style={[styles.container, style]}>
      <TouchableOpacity style={styles.icon} onPress={decrement}>
        <Icon.Ionicons name="ios-remove" size={26} style={{ color: removeIconColor }} />
      </TouchableOpacity>

      <TextInput
        style={styles.input}
        keyboardType="number-pad"
        type="number"
        onChangeText={(value) => {
          onChange((isNaN(parseInt(value)) ? '' : bound(value)))
        }}
        onBlur={onBlur}
        value={value.toString()}
      />

      <TouchableOpacity style={styles.icon} onPress={increment}>
        <Icon.Ionicons name="ios-add" size={26} style={{ color: Colors.tintColor }} />
      </TouchableOpacity>
    </View>
  )
}

export default NumberPicker

const height = 40
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    margin: 10,
    flex: 1,
    backgroundColor: Colors.lightBackground,
    borderRadius: 30,
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 'bold',
    height,
  },
  icon: {
    backgroundColor: Colors.lightBackground,
    borderRadius: height / 2,
    height,
    width: height,
    justifyContent: 'center',
    alignItems: 'center',
    color: Colors.lightBackground,
  },
})
