const ipiHelpText = 'The Interested Parties Information (IPI) or Composer, Author and Publisher (CAE) is a unique identifying number for copyright holders. It is given to you by your PRO.'
export default {
  user: {
    fields: {
      first_name: {
        label: 'First Name',
      },
      last_name: {
        label: 'Last Name',
      },
      biography: {
        label: 'Biography',
      },
      artist_profile: {
        stage_name: {
          label: 'Artist Name',
          placeholder: 'Jayoncé',
          helpText: 'Your name as it should appear in the credits',
        },
      },
      songwriter_information: {
        ipi: {
          label: 'IPI / CAE',
          helpText: ipiHelpText,
          placeholder: '(00) 123456789',
        },
        pro: {
          label: 'PRO (Performance Rights Organisation)',
          placeholder: 'ASCAP, BMI, or SESAC',
        },
      },
      artist_information: {
        isni: {
          label: 'Name Identifier (ISNI)',
          placeholder: "1111 2222 3333 4444",
          helpText: 'The International Standard Name Identifier (ISNI) is an identifier for individuals and public identities who contribute to media content. Unsure if you have one? Check out the database on ISNI.org',
        },
      },
      publisher_information: {
        publisher_company_name: {
          label: 'Company Name',
          placeholder: 'ASCAP',
          helpText: 'A Performance Rights Organisation (PRO) collects royalties on behalf of copyright holders. In the US, these are ASCAP, BMI or SESAC.',
        },
        publisher_company_contact: {
          label: 'Contact',
          placeholder: 'johndoe@yourpublisher.com',
        },
        publisher_ipi: {
          label: 'IPI / CAE',
          placeholder: '(00) 123456789',
          helpText: ipiHelpText,
        },
      },
      label_information: {
        label_company_name: {
          label: 'Name',
          placeholder: "Your Label (leave blank if not signed)",
        },
        label_company_contact: {
          label: 'Contact',
          placeholder: 'johndoe@yourlabel.com',
        },
      },
    },
  },
  flow: {
    scan: {
      help: 'Use the Reveel app on another phone to be scanned into a session',
    },
  },
  profile: {
    share: {
      cta: 'Use this link to share your professional details with other people',
      mail: {
        subject: "My Artist Profile",
        body: 'Hi,\nYou can access my artist profile that includes all of my professional details by following this link:\n%{url}',
      },
    },
  },
}
