import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Text } from 'native-base'

import { useValidationContext } from '../validation/validationContext'
import UserContributions from '../../components/core/user/UserContributions'
import Loading from '../../components/Loading'

const ContributionsList = ({ style, creativeSession }) => {
  const { contributionsForSession, loading } = useValidationContext()
  // handle error
  if(loading){
    return <Loading />
  }
  const { profiles } = contributionsForSession(creativeSession.id)
  if(!profiles.length){
    return <Text small primary center style={styles.empty}>No one has entered contributions</Text>
  }
  return (
    <View style={[styles.container, style]}>
      {profiles.map(({ contributions, ...profile }, i) => (
        <UserContributions
          key={`val-${profile.id}`}
          author={profile}
          contributions={contributions}
          withBorder={i < profiles.length - 1}
        />
      ))}
    </View>
  )
}

export default ContributionsList

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    paddingBottom: 10,
  },
  empty: {
    marginTop: 25,
  },
})
