import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { PropTypes } from "prop-types";

import useRemoteState from "@reveel/hooks/useRemoteState";
import searcher from "@reveel/helpers/searcher";
import { List, Text } from "@reveel/components/ui";
import routes from "@reveel/helpers/routes";
import ListItem from './ListItem'

const defaultEmptyComponent = () => (
  <View style={{ marginVertical: 15, transform: [{ scaleY: -1 }] }}>
    <Text smaller primary>
      No collaborators for this track
    </Text>
  </View>
);

const MentionUsersList = ({
  trackID,
  navigation,
  emptyComponent = defaultEmptyComponent,
  onPress,
  selectedIds = [],
  query,
  style,
  ...listProps
}) => {
  const [users, setUsers] = useState([]);
  const url = routes.api.tracks.participants(trackID);

  const { data: allUsers, setData: setAllUsers, loading } = useRemoteState(
    {
      url,
      initialState: [],
    },
    [trackID]
  );

  useEffect(() => {
    if (query.length) {
      setUsers(
        searcher(allUsers, {
          query,
          keys: ["username"],
          threshold: 0.2,
        })
      );
      return;
    }

    setUsers(allUsers);
  }, [query, setUsers, allUsers]);

  const mapToRenderObject = (user) => ({
    user: user,
    key: `${user.id}`,
    title: user.username,
    selected: Boolean(selectedIds.find((selectedId) => selectedId === user.id)),
    onPress: () => onPress(user),
    style: { paddingLeft: 5, marginLeft: 0, backgroundColor: "transparent" },
  });

  return (
    <List
      {...listProps}
      ListEmptyComponent={emptyComponent}
      data={users.map(mapToRenderObject)}
      renderItem={ListItem}
      style={[styles.list, style]}
      loading={loading}
    />
  );
};

const styles = StyleSheet.create({
  list: {
    marginLeft: -10,
    marginTop: 5,
  },
});

MentionUsersList.propTypes = {
  trackID: PropTypes.number.isRequired,
};

export default MentionUsersList;
