import React from 'react'
import { StyleSheet, Text } from 'react-native'
import Colors from '@reveel/constants/Colors'
import UserWithText from '../UserWithText'
import ContributionItem from '../ContributionItem'
import { useAuth } from '../../../stores/useAuth'
import View from '@reveel/components/ui/View';
const UserContributions = ({
  author, contributions, withBorder,
}) => {
  return (
    <View style={withBorder && styles.container}>
      <UserWithText
        user={author}
        text="has submitted the following contributions"
      >
        <View pl-1>
          {contributions.map((contribution, i) => (
            <ContributionItem
              key={`contrib-${contribution.id}`}
              contribution={contribution}
              user={author}
              noBorder={i === contributions.length - 1}
            />
          ))}
        </View>
      </UserWithText>
    </View>
  )
}

export default UserContributions

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 0.5,
    borderBottomColor: Colors.borderGrey,
  },
})
