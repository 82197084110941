import React from 'react'
import { StyleSheet } from 'react-native'

import Colors from '@reveel/constants/Colors'
import Page from '@reveel/layout/Page'
import { tw } from 'react-native-tailwindcss'

import changelog from '../../CHANGELOG.js'
import Markdown from '../components/ui/Markdown'
import View from '../components/ui/View'

const ChangelogScreen = () => {
  return (
    // adding overflow auto else it doesn't scroll
    // even though it sorta seems like it should -.-
    <View style={tw.flexGrow}>
      <Page sheet contentContainerStyle={[{ overflow: 'auto', flex: 0 }, tw.pX4, tw.pB4]}>
        <Markdown>{changelog}</Markdown>
      </Page>
    </View>
  )
}

export default ChangelogScreen
