import React, { useEffect, useState } from "react"
import { View, StyleSheet } from "react-native"
import { PropTypes } from "prop-types"

import useRemoteState from "@reveel/hooks/useRemoteState"
import searcher from "@reveel/helpers/searcher"
import { List, Text } from "../ui"
import routes from "../../helpers/routes"
import { creativeSessionName } from "../../helpers/creativeSessionsFormatter"

const defaultEmptyComponent = () => (
  <View style={{ marginVertical: 15, transform: [{ scaleY: -1 }] }}>
    <Text smaller primary>
      No Session for this track
    </Text>
  </View>
)

const MentionSessionsList = ({
  trackID,
  navigation,
  emptyComponent = defaultEmptyComponent,
  onPress,
  selectedIds = [],
  query,
  style,
  ...listProps
}) => {
  const [sessions, setSessions] = useState([])
  const url = routes.api.tracks.creativeSessions(trackID)

  const {
    data: allSessions,
    setData: setAllSessions,
    loading,
  } = useRemoteState(
    {
      url,
      initialState: [],
    },
    [trackID],
  )

  useEffect(() => {
    if(allSessions.length === 0) return

    const sessionsWithTitles = allSessions.map((session) => ({ ...session, title: creativeSessionName(session) }))

    setAllSessions(sessionsWithTitles)
  }, [loading, setAllSessions])

  useEffect(() => {
    if(query.length){
      setSessions(searcher(allSessions, { query, keys: ["title"], threshold: 0.2}))
      return
    }

    setSessions(allSessions)
  }, [query, setSessions, allSessions])

  const mapToRenderObject = (session) => ({
    key: `${session.id}`,
    title: session.title,
    selected: Boolean(
      selectedIds.find((selectedId) => selectedId === session.id),
    ),
    onPress: () => onPress(session),
    style: { paddingLeft: 5, marginLeft: 0, backgroundColor: "transparent" },
  })

  return (
    <List
      {...listProps}
      ListEmptyComponent={emptyComponent}
      data={sessions.map(mapToRenderObject)}
      style={[styles.list, style]}
      loading={loading}
    />
  )
}

const styles = StyleSheet.create({
  list: {
    marginLeft: -10,
    marginTop: 5,
  },
})

MentionSessionsList.propTypes = {
  trackID: PropTypes.number.isRequired,
}

export default MentionSessionsList
