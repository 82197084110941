import React from 'react'

import { StyleSheet } from 'react-native'
import { Text } from '@reveel/components/ui'
import { dateShort } from '@reveel/utils/dateFormatter'
import View from '@reveel/components/ui/View'
import pad from '@reveel/utils/padNumber'
import { useDesktop } from '../../helpers/MediaQueries'

const SessionHeader = ({ creativeSession }) => {
  if(!creativeSession){
    return null
  }
  const isDesktop = useDesktop()
  if(isDesktop){
    return (
      <View>
        <Text>{i18n.t('creativeSessions.name')}</Text>
        <Text primary bold>
          #
          {pad(creativeSession.position)}
        </Text>
      </View>
    )
  }
  const {
    track_title, position, venue_name, started_at, ended_at,
  } = creativeSession
  return (
    <View style={styles.container}>
      <Text bold center>
        {track_title}
        {' '}
        #
        {position}
      </Text>
      <View>
        {venue_name && <Text light small center>{venue_name}</Text>}
        {(started_at || ended_at) && <Text center small light>{[started_at, ended_at].filter((d) => d).map(dateShort).join(' - ')}</Text>}
      </View>
    </View>
  )
}
export default SessionHeader

const styles = StyleSheet.create({
  container: {

  },
})
