import React from 'react'
import { StyleSheet } from 'react-native'
import ContactSupport from '../../components/ContactSupport'
import ScreenTitle from '../../components/ScreenTitle'
import View from '../../components/ui/View'
import CreateTrackCTA from './CreateTrackCTA'
import { TapToStartSession } from './TracksIndex/TracksIndexEmpty'
import TracksList from './TracksList'
import Colors from '@reveel/constants/Colors';

const TracksIndexScreen = () => {
  const renderFooterComponent = (tracks) => (
    tracks.length
      ? <ContactSupport style={styles.footer} />
      : <TapToStartSession style={styles.footer} />
  )
  return (
    <View style={styles.container}>
      <TracksList
        ListHeaderComponent={(
          <>
            <ScreenTitle title={["My", "Tracks"]} />
            <CreateTrackCTA style={styles.cta} />
          </>
        )}
        renderListFooterComponent={renderFooterComponent}
      />
    </View>
  )
}

TracksIndexScreen.navigationOptions = {
  headerMode: 'none',
  header: null,
}
export default TracksIndexScreen

const styles = StyleSheet.create({
  cta: {
    marginBottom: 10,
  },
  container: {
    backgroundColor: Colors.white,
    flex: 1,
  },
  footer: {
    marginBottom: 35,
  },
})
