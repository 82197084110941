const mapOptionsToActionSheet = (options_) => {
  const options = options_.filter(Boolean)
  return [
    {
      options: options.map((o) => o.label),
      cancelButtonIndex: options.findIndex((o) => o.cancel),
      destructiveButtonIndex: options.findIndex((o) => o.destructive),
    },
    (index) => {
      options[index].onPress && options[index].onPress()
    },
  ]
}
export default mapOptionsToActionSheet
