import { AppState } from 'react-native'
import { useEffect } from 'react'

const useAppState = ({
  onForeground,
  onBackground,
}) => {
  useEffect(() => {
    const eventMapper = {
      active: onForeground,
      background: onBackground,
    }
    const handleChange = (nextAppState) => {
      const cb = eventMapper[nextAppState]
      if(cb && typeof cb === 'function'){
        cb()
      }
    }
    AppState.addEventListener('change', handleChange)
    return () => AppState.removeEventListener('change', handleChange)
  })
}
export default useAppState
