import React from 'react'

import { useTrack } from '@reveel/hooks/queries/track'

import ImagePicker from '../ui/Form/ImagePicker'

// TODO: use a project endpoint, not a track one!!!
const TrackArtwork = ({ trackID, ...props }) => {
  const { track, mutation } = useTrack(trackID)
  const onSelect = (artwork) => {
    const newTrack = {
      ...track,
      project: {
        artwork,
        id: track.project_id,
        artwork_url: artwork.uri,
      },
    }
    mutation.mutate(newTrack)
  }
  return (
    <ImagePicker
      url={track?.project.artwork_url}
      name="album_artwork"
      onSelect={onSelect}
      {...props}
    />
  )
}

export default TrackArtwork
