import { useQuery } from 'react-query';
import routes from '@reveel/helpers/routes';
import axios from 'axios';

export const trackCreditKey = id => ['track','credits', `${id}`]

const useTrackCredits = (trackID) => {
  const route = routes.api.tracks.credits(trackID)
  const { isLoading, error, data } = useQuery(trackCreditKey(trackID), async () => {
    const { data: _data } = await axios.get(route)
    return _data
  })

  return {
    isLoading,
    error,
    credits: data?.track.credits,
  }
}

export default useTrackCredits