import React, { useState } from 'react'
import { Alert } from '@reveel/vendors/alert'
import QRCodeWithMask from '../../components/utils/CameraMask'

import trackStore from './trackStore'
import ModalWithActions from '../../components/ui/ModalWithActions'
import AvatarWithName from '../../components/core/AvatarWithName'
import Button from '../../components/ui/Button'

const ScanQRCodeScreen = ({
  navigation,
  route,
}) => {
  const creativeSession = route.params?.creativeSession
  const setCreativeSession = route.params?.setCreativeSession
  const onAdded = route.params?.onAdded
  const [visible, setVisible] = useState(false)
  const [user, setUser] = useState()
  const addUserToSession = async () => {
    try{
      const { data: newCreativeSession } = await trackStore.addUser(creativeSession.id, user)
      // else the modal lingers on the screen too long
      setVisible(false)
      setCreativeSession(newCreativeSession, { overwrite: true })
      onAdded()
      navigation.goBack()
    }catch(err){
      console.log(err)
    }
  }
  const confirmUser = ({ data }) => {
    try{
      const userFromJson = JSON.parse(data)
      const { id, rev } = userFromJson
      if(id && rev){
        setVisible(true)
        setUser(userFromJson)
      }else{
        throw Error('Not a user QR Code')
      }
    }catch(err){
      console.log(err)
      Alert.alert('Error Scanning User', 'Are you sure this is a user QR Code?')
    }
  }

  const userComponent = user && <AvatarWithName user={user} />

  const actions = (close) => ([
    <Button key="modal-cancel" bordered transparent onPress={close} title="Cancel" />,
    <Button key="modal-add" primary onPress={addUserToSession} title="Add" />,
  ])
  const modal = (
    <ModalWithActions
      closeModal={() => setVisible(false)}
      title="Add to session?"
      isVisible={visible}
      actions={actions}
    >
      {userComponent}
    </ModalWithActions>
  )
  return (
    <>
      {modal}
      <QRCodeWithMask onScan={confirmUser} instructions="Add your contributor by scanning their Creative ID" />
    </>
  )
}

ScanQRCodeScreen.navigationOptions = ({ navigation }) => ({
  title: 'Scan a user in',
})

export default ScanQRCodeScreen
