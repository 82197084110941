import React, { useState } from 'react'
import { View, StyleSheet } from 'react-native'
import i18n from 'i18n-js'
import axios from 'axios'
import Colors from '@reveel/constants/Colors'

import Button from '@reveel/components/ui/Button'
import * as Sentry from 'sentry-expo'
import { ACCEPTED, REJECTED, PENDING_CONFLICT_RESOLUTION } from '../../constants/Status'
import routes from '../../helpers/routes'

import { useAuth } from '../../stores/useAuth'

const ResolutionOptions = ({
  contribution,
  user,
  creativeSession,
}) => {
  const [loading, setLoading] = useState(false)
  const { status, status_for_user } = contribution
  const { user: { id: myId } } = useAuth()

  const canResolve = (creativeSession.can.validate || myId === user.id) && status === PENDING_CONFLICT_RESOLUTION
  if(!canResolve){
    return null
  }

  const resolveConflict = async (status_) => {
    setLoading(true)
    await axios.put(routes.api.contributions.resolve(contribution.id), { status: status_ })
      .then(console.log)
      .catch(Sentry.captureException)
      .finally(() => setLoading(false))
  }

  const accept = () => (
    <Button
      style={[styles.buttonCommon, styles.button]}
      primary
      bordered
      small
      disabled={loading || status_for_user === ACCEPTED || user.id === myId}
      onPress={() => resolveConflict(ACCEPTED)}
      title="✓ Keep"
    />
  )

  const reject = () => (
    <Button
      style={styles.buttonCommon}
      danger
      bordered
      small
      disabled={loading || status_for_user === REJECTED}
      onPress={() => resolveConflict(REJECTED)}
      title={`⨯ ${i18n.t(`contributions.actions.reject.${user.id === myId ? 'self' : 'other'}`)}`}
    />
  )

  const resolutionOptions = () => (
    <View style={styles.actions}>
      {reject()}
      {accept()}
    </View>
  )
  return resolutionOptions()
}
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: Colors.lightBackground,
    padding: 10,
    alignItems: 'center',
  },
  userContainer: {
    flexDirection: 'row',
    flexGrow: 1,
  },
  text: {
  },
  avatar: {
    marginRight: 10,
  },
  actions: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexShrink: 1,
    justifyContent: 'flex-end',
  },
  buttonCommon: {
    marginTop: 2.5,
    marginBottom: 2.5,
  },
  button: {
    marginLeft: 10,
  },
})

export default ResolutionOptions
