import React from 'react'
import { View, StyleSheet, Dimensions } from 'react-native'
import { Text } from 'native-base'

import QRCodeScanner from "../QRCodeScanner"

const QRCodeWithMask = ({
  onScan,
  instructions,
}) => (
  <>
    <QRCodeScanner onScan={onScan} />
    <View style={styles.mask}>
      <View style={[styles.seeThrough, styles.full, styles.titleContainer]}>
        <Text large center style={styles.title}>{instructions}</Text>
      </View>
      <View style={styles.middle}>
        <View style={styles.seeThrough} />
        <View style={styles.pinhole} />
        <View style={styles.seeThrough} />
      </View>
      <View style={[styles.seeThrough, styles.full]} />
    </View>
  </>
)

export default QRCodeWithMask
const styles = StyleSheet.create({
  mask: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  titleContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 50,
    paddingRight: 50,
  },
  title: {
    color: 'white',
    fontWeight: 'bold',
  },
  seeThrough: {
    minHeight: 100,
    backgroundColor: 'rgba(0,0,0,.7)',
    flexGrow: 1,
  },
  middle: {
    flexDirection: 'row',
  },
  full: {
    width: "100%",
    flexGrow: 1,
  },
  pinhole: {
    width: Dimensions.get('window').width * 0.7,
    height: Dimensions.get('window').width * 0.7,
  },
})
