export const fullName = ({ first_name, last_name }) => [first_name, last_name].join(' ')
export const stageName = ({ artist_profile: { stage_name } }) => stage_name
export const industryInfo = (user, key) => user.industry_ids[key]

export const isni = ({ artist_information: { isni } }) => isni
export const spotifyID = ({ social: { spotify } }) => /\/(\w+)$/.exec(spotify)?.[1]
export const appleID = ({ social: { apple_music } }) => /\/(\w+)$/.exec(apple_music)?.[1]
export const pro = ({ songwriter_information: { pro } }) => pro
export const ipi = ({ songwriter_information: { ipi } }) => ipi

export const publisherCompanyName = ({ publisher_information: { publisher_company_name } }) => publisher_company_name
export const publisherCompanyContact = ({ publisher_information: { publisher_company_contact } }) => publisher_company_contact
export const publisherIPI = ({ publisher_information: { publisher_ipi } }) => publisher_ipi

export const labelCompanyName = ({ label_information: { label_company_name } }) => label_company_name
export const labelCompanyContact = ({ label_information: { label_company_contact } }) => label_company_contact
