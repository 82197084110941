import React from 'react';
import { Pressable } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Text } from '@reveel/components/ui';
import View from '@reveel/components/ui/View';
import Colors from '@reveel/constants/Colors';
import { tw } from 'react-native-tailwindcss';
import UserAvatar from 'react-native-user-avatar';


const InvitationCandidate = ({ invitee = {}, onRemove }) => {
  const { email, avatar_url, display_name } = invitee;
  return (
    <View style={[tw.flexRow, tw.itemsCenter, tw.justifyBetween, tw.pY1, tw.mY1]}>
      {avatar_url
        ? <UserAvatar size={20} src={avatar_url} name={display_name} />
        : <Ionicons name="mail-outline" size={20} color={Colors.lightBlack} />}
      <Text style={[tw.mL2, tw.flexGrow]}>{email || display_name}</Text>
      <Pressable onPress={() => onRemove(invitee)}>
        {({ pressed }) => <Ionicons name="close-circle-outline" size={24} color={Colors[!pressed ? 'lightBlack' : 'black']} />}
      </Pressable>
    </View>
  );
};

export default InvitationCandidate