import React from 'react'
import { View, StyleSheet } from 'react-native'
import UserAvatar from 'react-native-user-avatar'
import { Text } from '@reveel/components/ui'

const AvatarWithName = ({ user, size = 50 }) => (
  <View style={styles.container}>
    <UserAvatar size={size} name={user.artist_profile.stage_name || user.first_name} src={user.avatar_url} />
    <View>
      <Text style={styles.name} bold>{user.artist_profile.stage_name}</Text>
    </View>
  </View>
)

export default AvatarWithName

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  name: {
    marginLeft: 10,
  },
})
