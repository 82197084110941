import React from 'react'

import AddCreditsButton from '@reveel/components/credits/AddCreditsButton'
import Button from '@reveel/components/ui/Button'
import View from '@reveel/components/ui/View'
import { useTrack } from '@reveel/hooks/queries/track'
import { PropTypes } from 'prop-types'
import { Link } from '@react-navigation/native'
import useInbox from '../../../stores/useInbox'

const renderAddCreditsButton = ({ onOpen }) => (
  <Button
    small
    title={i18n.t('creativeSessions.ui.ghost.cta')}
    testID="addCredits"
    onPress={onOpen}
  />
)

export const ViewPendingCredits = ({ trackID }) => {
  const { tasks } = useInbox()
  const hasPending = tasks.tracks.some((t) => t.id === +trackID)
  if (!hasPending) {
    return null
  }

  return (
    <Link testID="validatePending" to={`/tracks/${trackID}/pending-contributions`}>
      <Button small danger title={i18n.t('tracks.ui.validate.cta')} />
    </Link>
  )
}
ViewPendingCredits.propTypes = {
  trackID: PropTypes.number.isRequired,
}

const CreditsActions = ({ trackID }) => {
  const { track } = useTrack(trackID)
  return (
    <View row justifyAround py>
      <AddCreditsButton
        trackID={trackID}
        creativeSessionID={track?.proxy_id}
        renderTrigger={renderAddCreditsButton}
      />
      <ViewPendingCredits trackID={trackID} />
    </View>
  )
}
export default CreditsActions
