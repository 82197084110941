import routes from '@reveel/helpers/routes';

const AuthenticatedScreens = {
  UserOnboarding: '/create-your-profile',
  TrackOnboarding: '/create-your-first-track',

  Home: '/',
  TrackNew: '/tracks/new',
  TrackShow: '/tracks/:trackID',
  TrackOverview: '/tracks/:trackID/overview',
  TrackEditMetadata: '/tracks/:trackID/edit',
  TrackValidate: '/tracks/:trackID/pending-contributions',
  TrackSubmitCreditsScreen: '/tracks/:trackID/submit-credits',
  TrackSplits: '/tracks/:trackID/songwriting-splits',
  // edit is handled in /new, as you actually create a new split sheet
  // instead of editing it. This is invisible to the user, and used to have a trail
  TrackSplitsNew: '/tracks/:trackID/songwriting-splits/new',
  TrackPoints: '/tracks/:trackID/master-points',
  TrackPointsNew: '/tracks/:trackID/master-points/new',
  AttachmentNew: 'tracks/:trackID/attachments/new',
  AttachmentShow: 'tracks/:trackID/attachments/:attachmentID',
  TrackActivity: '/tracks/:trackID/feed',
  TrackSessions: '/tracks/:trackID/sessions',
  TrackCredits: '/tracks/:trackID/credits',
  TrackInfo: '/tracks/:trackID/info',
  TrackAttachmentsIndex: '/tracks/:trackID/attachments',
  TrackRelease: '/tracks/:trackID/release',

  Profile: '/my-id',
  ProfileEdit: '/account/edit',
  SkillsOverview: '/account/my-skills',
  SkillsProfessions: '/account/skills/',
  SelectSkills: '/account/skills/:name',
  SessionNewSelectTrack: '/sessions/new',
  HowSessionsWork: '/sessions/how-it-works',
  Session: '/tracks/:trackID/sessions/:id',
  
  ContribSkillProfessions: 'select-skills',
  ContribSelectSkills: 'select-skills/pro',
  
  ValidationResolutionChat: 'tracks/:trackID/resolve/:contributionID',
  
  JoinByQRCode: 'join',
  ScanUser: 'scan-user',
  ShareAppScreen: '/invite-a-friend',
  Changelog: '/changelog',
  Playground: '/playground',
}

const Authentication = {
  AuthForms: '/sign-in',
  Welcome: '/welcome',
}

const shared = {
  UserProfile: '/profile/:id',
  AcceptInvitation: routes.front.creativeSessions.join(':trackID', ':id'),
}

const linkingConfig = {
  config: {
    screens: {
      ...shared,
      Authenticated: {
        screens: AuthenticatedScreens,
      },
      Authentication: {
        screens: Authentication,
      },
    },
  },
}

export default linkingConfig
