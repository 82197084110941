import { useCallback } from 'react'
import AsyncKeys from './AsyncKeys'
import usePersistedState from '../hooks/usePersistedState'

const useLastReadAt = () => {
  const [lastReadAt, setLastReadAt] = usePersistedState(AsyncKeys.LAST_READ_AT_KEY, {})

  const markAsRead = useCallback((trackID) => {
    setLastReadAt({
      ...lastReadAt,
      [trackID]: new Date(),
    })
  }, [lastReadAt, setLastReadAt])

  return {
    lastReadAt,
    markAsRead,
  }
}
export default useLastReadAt
