import React from 'react';
import { Pressable } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Text } from '@reveel/components/ui';
import View from '@reveel/components/ui/View';
import Colors from '@reveel/constants/Colors';
import { tw } from 'react-native-tailwindcss';
import UserAvatar from 'react-native-user-avatar';
import useInvitationActions from "../../../components/invitation/useInvitationActions";

export const PendingInvitation = ({ invitation }) => {
  // deleting doesn't invalidate the useQuery
  // see useInvitationActions
  const { deleteEmail, sendEmail, deletingEmail, sendCTA, deleted } = useInvitationActions(invitation);

  if (deleted) {
    return null;
  }
  const { email, user } = invitation;
  return (
    <View style={[tw.flexRow, tw.itemsCenter, tw.justifyBetween, tw.pY1, deletingEmail && tw.opacity25]}>
      {user
        ? <UserAvatar size={20} src={user.avatar_url} name={user.display_name} />
        : <Ionicons name="mail-outline" size={20} color={Colors.lightBlack} style={[tw.mL1]} />}
      <Text style={[tw.mL2, tw.flexGrow]}>{user?.display_name || email}</Text>
      <Pressable onPress={() => sendEmail(invitation)} style={tw.mR2}>
        <Text style={[tw.textLightBlack]}>{sendCTA}</Text>
        {({ pressed }) => <Ionicons name="mail-outline" size={24} color={Colors[!pressed ? 'lightBlack' : 'black']} />}
      </Pressable>
      <Pressable onPress={() => deleteEmail(invitation)}>
        {({ pressed }) => <Ionicons name="close-circle-outline" size={24} color={Colors[!pressed ? 'lightBlack' : 'black']} />}
      </Pressable>
    </View>
  );
};
