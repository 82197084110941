import {
  PENDING_CONTRIBUTION_SUBMISSIONS, RUNNING, PENDING_VALIDATION, PENDING_CONFLICT_RESOLUTION, PENDING_MISSING_PARTICIPANTS, PENDING_SIGN_OFF,
} from '../constants/Status'

export default {
  banners: {
    invitation: {
      creative_session: {
        title: "Join %{track_title}",
        description: "%{name} invited you",
        button: 'View Invite',
      },
      credit_request: {
        title: "Add Credits to %{track_title}",
        description: "%{name} requests your contribution",
        button: 'Add',
      },
    },
    [PENDING_CONTRIBUTION_SUBMISSIONS]: {
      title: "Contribution missing",
      details: "Tap here to submit your contributions",
      button: "Contribute",
    },
    [RUNNING]: {
      title: "Session in progress",
      details: "Tap here to go to the session",
      button: "Go to session",
    },
    [PENDING_VALIDATION]: {
      title: "Pending validations",
      details: "Tap here to validate contributions",
      button: "Validate",
    },
    [PENDING_CONFLICT_RESOLUTION]: {
      title: "Conflicted contribution",
      details: "Tap here to resolve a conflict",
      button: "Resolve",
    },
    [PENDING_MISSING_PARTICIPANTS]: {
      title: "Participants missing",
      details: "Tap here to send a reminder",
      button: "Invite",
    },
    pending_credits: {
      title: 'Credits pending',
      button: 'View',
    },
    track: {
      new: {
        title: 'Create a roject',
        details: 'Start collaborating',
        button: '+ Add Project',
      },
    },
  },
}
