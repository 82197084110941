import '../i18n'
import './AxiosConfig'
import i18n from 'i18n-js'
import { tw } from 'react-native-tailwindcss'
global.i18n = i18n
global.tw = tw

if(__DEV__){
  import('./ReactotronConfig').then(() => console.log('Reactotron Configured'))
}
