import React from 'react'
import { Pressable } from 'react-native'

import AddCreditsButton from '@reveel/components/credits/AddCreditsButton'
import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'
import { useDesktop } from '@reveel/helpers/MediaQueries'
import useStoreContext from '@reveel/stores/useStoreContext'
import { tw } from 'react-native-tailwindcss'

import RowWithActions from '../../../components/RowWithActions'
import SectionTitle from '../../../components/ui/SectionTitle'
import ParticipationSwitch from '../../sessions/ParticipationSwitch'
import { UserContributions } from './UserContributions'
import { useLg } from '../../../helpers/MediaQueries';

const Contributor = ({ profile, participation, contributions, style }) => {
  const [{ can }] = useStoreContext('currentTrack')
  const actions = [
    can?.admin && {
      text: 'Work for Hire',
      children: <ParticipationSwitch participation={participation} attribute="work_for_hire" />,
    },
  ].filter(Boolean)
  return (
    <RowWithActions
      actions={actions}
      style={style}
      rowStyle={[tw.pY4]}
    >
      <UserContributions
        key={profile.id}
        profile={profile}
        contributions={contributions}
      />
    </RowWithActions>
  )
}

export const CreditSection = ({ section, contributors = [], style }) => {
  const [track] = useStoreContext('currentTrack')
  const isDesktop = useDesktop()
  const isLg = useLg()
  const contributorStyle = (() => {
    if(isLg){
      return [tw.w1_4, tw.mB4]
    }
    if(isDesktop){
      return [tw.w1_3, tw.mB4]
    }
    return [tw.wFull]
  })()
  if(!contributors.length){
    if(section === 'others'){
      return null
    }
    return (
      <View style={tw.mB4}>
        <SectionTitle title={section} />
        <AddCreditsButton
          trackID={track.id}
          creativeSessionID={track?.proxy_id}
          renderTrigger={({ onOpen }) => <AddCreditPlaceholder onOpen={onOpen} section={section} />}
        />
      </View>
    )
  }
  return (
    <View style={style}>
      <SectionTitle title={section} />
      <View style={[tw.flexRow, tw.itemsStart, tw.justifyBetween, tw.flexWrap, tw.pX2, tw.pY4]}>
        {contributors.map(({ profile, contributions, participation }) => (
          <Contributor
            key={profile.id}
            {...{ profile, contributions, participation }}
            style={contributorStyle}
          />
        ))}
        <AddCreditsButton
          trackID={track.id}
          creativeSessionID={track?.proxy_id}
          renderTrigger={({ onOpen }) => <AddCreditPlaceholder section={section} onOpen={onOpen} />}
        />
      </View>
    </View>
  )
}

const AddCreditPlaceholder = ({ onOpen, section }) => {
  const title = `+ Add ${section.replace(/s$/, '')}`
  return (
    <Pressable style={[tw.mL2, tw.mT2, tw.flexGrow]} onPress={onOpen}>
      <Text style={[tw.textPrimary, tw.italic]}>{title}</Text>
    </Pressable>
  )
}
