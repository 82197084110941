import React from 'react'
import { Text, View } from '@reveel/components/ui'
import { StyleSheet } from 'react-native'
import * as Updates from 'expo-updates'
import Button from '../components/ui/Button'
import Colors from '../constants/Colors'
import { Platform, Linking } from 'react-native';
import CURRENT_VERSION from '../helpers/CURRENT_VERSION';

const PLAY_STORE_URL = "https://play.google.com/store/apps/details?id=io.reveel.reveel"
const APP_STORE_URL = "https://apps.apple.com/us/app/reveel/id1473312283"
const STORE_URL = Platform.OS === 'ios' ? APP_STORE_URL : PLAY_STORE_URL

const PleaseUpdate = ({ appStoreUpdate }) => {
  const update = async () => {
    if(appStoreUpdate){
      Linking.openURL(STORE_URL)
    }else{
      await Updates.fetchUpdateAsync()
      await Updates.reloadAsync()
    }
  }
  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.title}>Time to update!</Text>
        <Text>
          We&apos;re working hard to bring you the best experience at all times. 
          This requires updating the app from time to time!
        </Text>
        <Button style={styles.button} block onPress={update} title="OK!" />
      </View>
      <Text style={styles.currentVersion}>version: {CURRENT_VERSION}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 15,
  },
  title: {
    color: Colors.tintColor,
    fontSize: 40,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  button: {
    marginTop: 60,
  },
  currentVersion: {
    position: 'absolute',
    bottom: 5,
    fontSize: 10,
    color: Colors.lightBlack,
  }
})
export default PleaseUpdate
