import React, { useMemo } from "react";
import { StyleSheet } from "react-native";
import { endlineWordsHash } from "@reveel/utils/regexp";
import MentionSessionsList from "../../components/core/MentionSessionsList";
import { generateCreativeSessionSlug } from "../../helpers/creativeSessionsFormatter";

const MentionSession = ({ track, input, setInput, setSelectedSessions }) => {
  const query = useMemo(() => input.match(endlineWordsHash)[0].slice(1), [
    input,
  ]);

  const selectSession = (targetSession) => {
    const slug = generateCreativeSessionSlug(targetSession);

    setSelectedSessions((previousSessions) => [
      ...previousSessions,
      { id: targetSession.id, slug },
    ]);

    setInput((previousInput) =>
      previousInput.replace(endlineWordsHash, `#${slug} `)
    );
  };

  return (
    <MentionSessionsList
      inverted
      trackID={track.id}
      onPress={selectSession}
      selectedIds={[]}
      query={query}
      style={styles.list}
    />
  );
};

const styles = StyleSheet.create({
  list: {
    maxHeight: 160,
  },
});

export default MentionSession
