import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Text, Thumbnail } from '@reveel/components/ui'
import IconButton from 'app/assets/images/ui/IconButton'
import padNumber from '@reveel/utils/padNumber'
import { Ionicons } from '@expo/vector-icons'
import Colors from '@reveel/constants/Colors'

const botImage = require('app/assets/images/bot.png')

function sumChars(str){
  let sum = 0
  for (let i = 0; i < str.length; i++){
    sum += str.charCodeAt(i)
  }

  return sum
}

const COLORS = [
  '#5C00C3',
  '#F9C80E',
  '#F86624',
  '#EA3546',
  '#43BCCD',
  '#FFBD00',
  '#41D3BD',
]

const SytemAvatar = ({ message: { meta: { type, creative_session: creativeSession } }, size = 40, style }) => {
  if(!creativeSession){
    return <Thumbnail small style={[styles.wrapper, { width: size, height: size, borderRadius: size / 2 }]} source={botImage} />
  }
  const initials = `#${padNumber(creativeSession.position)}`
  const backgroundColor = COLORS[sumChars(initials) % COLORS.length]
  if(type === 'attachment'){
    return <IconButton size={size} icon="link" fill={backgroundColor} />
  }
  const borderRadius = 3
  const fontDecrease = 2.8
  const fontSize = size / fontDecrease
  const renderContent = () => {
    if(creativeSession.ghost){
      return <Ionicons size={size} color={Colors.white} name="ios-checkmark" />
    }
    return <Text white bold style={{ fontSize }}>{initials}</Text>
  }
  return (
    <View style={[styles.wrapper, style, {
      borderRadius,
      backgroundColor,
      width: size,
      height: size,
    }]}
    >
      {renderContent()}
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
    justifyContent: 'center',
  },
})
export default SytemAvatar
