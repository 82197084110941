import React, { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native'
import TrackScreenTitle from '@reveel/screens/tracks/TrackScreenTitle'
import Breadcrumb from './createScreenHeader/Breadcrumb'
import { useDesktop } from '@reveel/helpers/MediaQueries';
import { Text } from '@reveel/components/ui';
import View from '@reveel/components/ui/View';
import useStoreContext from '../stores/useStoreContext';
import { useMemo } from 'react';
import HeaderMore from '../components/core/header/HeaderMore/index';
import trackStore from '../screens/sessions/trackStore';
import { useTrack } from '@reveel/hooks/queries/track';
import { useQueryClient } from 'react-query';

const Subtitle = ({ title }) => (
  <View>
    <Text primary>{title[0]}</Text>
    { title[1] ? <Text primary bold>{title[1]}</Text> : null }
  </View>
)

export function trackHeaderTitle({ subtitle, navigatable, track }){
  return function TrackHeader(){
    return (
      <Breadcrumb>
        <TrackScreenTitle
          noColor={subtitle}
          navigatable={navigatable}
          track={{
            title: track?.title,
            id: track?.id,
          }}
        />
        {subtitle && <Subtitle title={subtitle} />}
      </Breadcrumb>
    )
  }
}

const useTrackBreadcrumb = (trackID, subtitle, { navigatable, options, dependencies = [] } = { navigatable: true }) => {
  // TODO: this should be refactored to remove the current track from context
  // and better benefit from useQuery
  // keep in mind that the left column currently requires
  // it to highlight current nav state
  const navigation = useNavigation()
  const isDesktop = useDesktop()
  const [trackInContext, setCurrentTrack] = useStoreContext('currentTrack')
  const trackWasIsInContext = useMemo(() => `${trackInContext?.id}` === `${trackID}`, [trackID, trackInContext])
  const { track } = useTrack(trackID)

  useEffect(() => {
    if(track && !trackWasIsInContext){
      setCurrentTrack(track)
    }
  }, [setCurrentTrack, track, trackWasIsInContext])
  const queryClient = useQueryClient()
  
  useEffect(() => {
    setCurrentTrack({
      id: trackID,
      ...track,
    })
    const selectedOptions = options || [
      track?.can?.edit && {
        label: 'Edit Details',
        onPress: () => navigation.navigate('TrackEditMetadata', { trackID: track.id }),
      },
      track?.can?.delete && {
        label: 'Delete',
        destructive: true,
        onPress: async () => {
          await trackStore.deleteTrack(track, () => {
            queryClient.invalidateQueries(['projects'])
            queryClient.invalidateQueries(['tracks'])
          })
        },
      },
    ].filter(Boolean)

    navigation.setOptions({
      // TODO: we should probably have more generic solution for this
      // maybe one that recognizes the route names and renders titles
      // accordingly.
      title: [track?.title, typeof subtitle === 'string' ? subtitle : subtitle.join(' ')].filter(Boolean).join(' - '),
      headerTitle: trackHeaderTitle({ subtitle, navigatable, track, trackInContext }),
      headerLeft: isDesktop ? null : undefined,
      headerRight: () => <HeaderMore options={selectedOptions} />,
    })
  }, [options, trackInContext, isDesktop, navigatable, subtitle, navigation, track, setCurrentTrack, trackID, queryClient])
}

export default useTrackBreadcrumb
