import React from 'react'
import { Linking, Pressable } from 'react-native'

import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'
import routes from '@reveel/helpers/routes'
import axios from 'axios'

import { useAuth } from '../../stores/useAuth'

const ResendConfirmationEmail = () => {
  const { user: { email } } = useAuth()
  const resend = () => {
    axios.post(routes.api.user.resendConfirmation, {
      user: { email },
    })
  }
  return (
    <Pressable onPress={resend}>
      <Text style={[tw.textLg, tw.underline]}>Resend confirmation link.</Text>
    </Pressable>
  )
}

const ConfirmEmailAddress = ({ }) => {
  const { user } = useAuth()
  return (
    <View style={[tw.flexGrow, tw.justifyCenter, tw.itemsCenter]}>
      <View>
        <Text style={tw.text6xl}>☝️</Text>
        <Text style={tw.textXl}>One more thing... Could you check your emails and confirm your account?</Text>
        <View style={[tw.flexRow, tw.mT4, tw.mB2]}>
          <Text style={tw.textLg}>Didn&apos;t get the email?&nbsp;</Text>
          <ResendConfirmationEmail />
        </View>
        <Text style={tw.textLg} light>
          We require users to confirm their email
          to prevent anyone stealing your account!
        </Text>
      </View>
      <View style={[tw.absolute, tw.bottom0, tw.right0, tw.p4, tw.flexRow]}>
        <Text>Problems? </Text>
        <Pressable onPress={() => Linking.openURL("mailto:info@reveel.id")} style={tw.underline}>
          Email us
        </Pressable>
      </View>
    </View>
  )
}

ConfirmEmailAddress.propTypes = {
}
export default ConfirmEmailAddress
