import React, { useEffect } from 'react'
import { StyleSheet } from 'react-native'
import { View } from '@reveel/components/ui'
import { useAsync } from '@reveel/hooks/useRemoteState'
import { useNavigation } from '@react-navigation/native'
import FormItems from '../../../components/ui/Form'
import routes from '../../../helpers/routes'
import {
  startDate, endDate, trackTitle, venueName, sessionType, attachments, notes,
} from '../../../helpers/fields/creativeSessionFieldsList'
import ScreenTitle from '../../../components/ScreenTitle'
import { replaceNavigationStateWithSession } from '../../tracks/tracksHooks'
import { useAuth } from '../../../stores/useAuth'
import useStoreContext from '@reveel/stores/useStoreContext';

export const markAsRequired = (f) => ({
  ...f,
  required: true,
})

const SessionNewForm = ({ isPast, track }) => {
  const initialCreativeSession = {
    track_id: track?.id,
    track,
  }
  const { can } = useAuth()
  const navigation = useNavigation()
  const url = track ? routes.api.tracks.creativeSessions(track.id) : routes.api.creativeSession.create
  const { run, loading, errors } = useAsync({
    url,
    method: 'post',
    handleResponse: ({ data }) => {
      const dontLoadOnInit = true
      replaceNavigationStateWithSession(data, { dontLoadOnInit, navigation })
    },
  })
  const onSubmit = (data) => run({
    creative_session: {
      ...data,
      track_attributes: {
        ...track,
        ...data.track,
      },
    },
  })

  const fields = [
    trackTitle,
    {
      ...startDate,
      required: true,
    },
    endDate,
    attachments,
    can('use_notes') ? notes : null,
    venueName,
    sessionType,
  ].filter((v) => v)

  return (
    <View style={{ flex: 1 }}>
      <FormItems
        renderHeader={() => <ScreenTitle title={['Add', 'A Past Session']} />}
        formObject={initialCreativeSession}
        fields={fields}
        labelType="stackedLabel"
        onSubmit={onSubmit}
        errors={errors}
        loading={loading}
        i18nPrefix="creativeSessions.fields"
        withKeyboardSpacer
      />
    </View>
  )
}
export default SessionNewForm
const styles = StyleSheet.create({})
