import moment from 'moment'
import padNumber from './padNumber'

export const dateOnly = (date) => moment(date).format('LL')
export const timeOnly = (date) => moment(date).format('kk:mm')
export const dateAndTime = (date) => moment(date).format('LL kk:mm')
export const dateAndTimeShort = (date) => moment(date).format('MM/DD/YY kk:mm')
export const dateShort = (date) => moment(date).format('L')
export const timestamp = (date) => moment(date).format("MMMM Do YYYY, kk:mm:ss")

export const combineDateAndTime = (date, _time = date) => {
  if(!date){ return }
  const timeString = `${padNumber(_time.getHours(), 2)}:${padNumber(_time.getMinutes(), 2)}:00`
  const year = date.getFullYear()
  const month = padNumber(date.getMonth() + 1, 2) // Jan is 0, dec is 11
  const day = padNumber(date.getDate(), 2)
  const dateString = `${year}-${month}-${day}`
  const datec = `${dateString}T${timeString}`

  const combined = new Date(datec)

  return combined
}
