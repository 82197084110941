import React from 'react'
import { PropTypes } from 'prop-types'
import { TouchableOpacity, StyleSheet } from 'react-native'

import { Text } from '@reveel/components/ui'

import View from '@reveel/components/ui/View'
import { Ionicons } from '@expo/vector-icons'
import Colors from '@reveel/constants/Colors'

export const SingleOption = ({
  icon, label, destructive, onPress, render,
}) => render?.() || (
  <TouchableOpacity style={styles.headerItem} onPress={onPress} testID={label}>
    {icon && <Ionicons name={icon} color={Colors.lightGrey} size={20} style={styles.icon} />}
    <Text primary red={destructive}>{label}</Text>
  </TouchableOpacity>
)

// TODO: make dropdown if there are too many actions 
const InlineScreenActions = ({ options }) => (
  <View row alignCenter>
    {options.map((action) => <SingleOption key={action.label} {...action} />)}
   </View>
)

InlineScreenActions.props = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default InlineScreenActions

const styles = StyleSheet.create({
  headerItem: {
    marginHorizontal: 10,
    paddingHorizontal: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: 10,
  },
})
