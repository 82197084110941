import React from 'react'
import { StyleSheet, View, Image, Platform } from 'react-native'
import { Text } from '@reveel/components/ui'
import Colors from '@reveel/constants/Colors'
import { useMediaQuery } from 'react-responsive'
import FullWidthImage from '../../components/ui/FullWidthImage'

const WelcomePane = ({
  image, style, title, description,
}) => {
  return (
    <View style={[styles.container]}>
      <View style={styles.imageContainer}>
        <FullWidthImage source={image} style={styles.image} resizeMode="contain" />
      </View>
      <View style={styles.textContainer}>
        <Text xl bold mb-2>{title}</Text>
        <Text larger mb-4>{description}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingBottom: 40,
  },
  imageContainer: {
    backgroundColor: Colors.lightBackground,
    ...Platform.select({
      web: {
        maxHeight: '100%',
        width: 'auto',
      },
      native: {
        // flex: 1,
      }
    })
  },
  image: {
    maxWidth: "100%",
  },
  textContainer: {
    ...Platform.select({
      native: {
      }
    }),
    paddingTop: 40,
    paddingHorizontal: 25,

    justifyContent: 'center',
  },
})

export default WelcomePane
