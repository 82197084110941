import React from 'react'
import View from '@reveel/components/ui/View'
import Colors from '@reveel/constants/Colors'
import { StyleSheet } from 'react-native'
import props2Style from '@reveel/utils/props2Style'

const Separator = (props) => <View style={[styles.base, ...props2Style({ ...props }, styles), props.style]} />
export default Separator

const styles = StyleSheet.create({
  base: {
    flex: 1,
    borderBottomColor: Colors.borderDarkGrey,
    borderBottomWidth: 1,
  },
  ml: {
    marginLeft: 15,
  },
  "my-lg": {
    marginVertical: 20,
  },
  my: {
    marginVertical: 15,
  },
})
