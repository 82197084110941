import React, { useEffect, useState } from 'react'
import { StyleSheet, Platform, Pressable } from 'react-native'

import KeyboardSpacerIOS from '@reveel/components/KeyboardSpacerIOS'
import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'
import Colors from '@reveel/constants/Colors'
import Modal from "react-native-modal"

import { Icon } from "."
import { ReactNode } from 'react';

type IProps = {
  closeModal: () => void,
  children: ReactNode,
  title?: string,
  actions?: (closeModal: () => void) => ReactNode,
  isVisible: boolean,
  contentStyle?: any,
  withClose?: boolean,
  variant?: 'sidepanel' | 'modal',
  size?: 'md' | 'lg',
}

export const ModalTitle = ({ title, closeIcon, style }: { title: string, closeIcon?: React.ReactNode, style?: any }) => (
  <>
    {title && <Text center style={[styles.titleContainer, styles.modalTitle, style]}>{title}</Text>}
    { closeIcon }
  </>
)

const ModalWithActions = ({
  closeModal,
  children,
  title,
  actions,
  isVisible,
  contentStyle,
  withClose = true,
  variant = 'modal',
  size = 'md',
}: IProps) => {
  const closeIcon = (
    <View style={styles.closeIcon}>
      {/* eslint-disable-next-line react-native/no-inline-styles */}
      <Pressable style={{ cursor: 'pointer' }} onPress={closeModal}>
        <Icon style={styles.close} name="ios-close" />
      </Pressable>
    </View>
  )
  useEffect(() => {
    if(Platform.OS !== 'web'){
      return
    }
    const func = ({ code }) => code === 'Escape' && closeModal()
    window.addEventListener('keydown', func)
    return () => window.removeEventListener('keydown', func)
  }, [])
  const [isHiddenCompleted, setIsHiddenCompleted] = useState(false)
  const unMountChildrenOnHide = () => {
    setIsHiddenCompleted(true)
    setTimeout(() => setIsHiddenCompleted(false))
  }
  const props = (() => {
    if (variant === 'sidepanel'){
      return {
        animationIn: "slideInRight",
        swipeDirection: "right",
        animationOut: "slideOutRight",
        backdropOpacity: .2,
        style: styles.panel,
        hasBackdrop: false,
      }
    }
    return {
      style: styles.modal
    }
  })()
  return (
    <Modal
      isVisible={isVisible}
      onSwipeComplete={closeModal}
      onBackdropPress={closeModal}
      {...props}
      onModalHide={unMountChildrenOnHide}
    >
      {!isHiddenCompleted && (
        <View style={[
          variant === 'modal' ? styles.container : styles.containerPanel,
          size === 'lg' ? styles.containerLg : styles.containerMd,
          tw.flexShrink
        ]}>
          <ModalTitle title={title} closeIcon={withClose && closeIcon}/>
          <View style={[styles.content, contentStyle]}>
            {children}
          </View>
          {actions && (
            <View style={styles.actions}>
              {actions(closeModal)}
            </View>
          )}
        </View>
      )}
      {/* android handles  */}
      <KeyboardSpacerIOS />
    </Modal>
  )
}

export default ModalWithActions

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    borderRadius: 25,
  },
  containerPanel: {
    backgroundColor: Colors.white,
    height: '100%',
    borderLeftColor: Colors.borderGrey,
    borderLeftWidth: 1,
  },
  modal: {
    ...Platform.select({
      web: {
        width: 400,
        maxWidth: "95vw",
        margin: 'auto',
        maxHeight: '95vh',
      },
    }),
  },
  panel: {
    ...Platform.select({
      web: {
        width: '30vw',
        maxWidth: "600px",
        minWidth: '400px',
        top: 0,
        right: 0,
        height: '100vh',
        marginLeft: 'auto',
        marginTop: 0,
        marginRight: 0,
        marginBottom: 0,
      },
    }),
  },
  content: {
    paddingVertical: 15,
    paddingHorizontal: 15,
    ...Platform.select({
      web: {
        maxHeight: '100%',
        overflow: 'auto',
      },
    }),
  },
  titleContainer: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderGrey,
    paddingTop: 15,
    paddingBottom: 15,
    paddingHorizontal: 15,
  },
  closeIcon: {
    position: 'absolute',
    right: 15,
    top: 5,
    zIndex: 100,
  },
  close: {
    color: Colors.lightBlack,
  },
  modalTitle: {
    color: Colors.tintColor,
  },
  actions: {
    justifyContent: 'space-around',
    paddingBottom: 10,
    paddingTop: 10,
    paddingHorizontal: 15,
  },
})
