import axios from 'axios'
import routes from '@reveel/helpers/routes'
import stagingAuthenticateWithAPI from './index.js'

// currently using token based auth on staging
// as cross-domain cookies are not possible
// and we don't want to pay to SSL dev.reveel.id
// const authenticateWithAPI = async () => {
//   const { data: user } = await axios.get(routes.api.user.signIn, {
//     withCredentials: true,
//   })
//   return user
// }

export default stagingAuthenticateWithAPI
