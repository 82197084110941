import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Text } from 'native-base'
import i18n from 'i18n-js'
import ScreenTitle from '@reveel/components/ScreenTitle'

import List from '@reveel/components/ui/List'
import SearchInput from '@reveel/components/ui/SearchInput'
import OrComponent from '@reveel/components/ui/OrComponent'

import Button from '@reveel/components/ui/Button'
import useStoreContext from '@reveel/stores/useStoreContext';

const SelectTrack = ({ state = 'running', onSelectTrack, onNewTrack }) => {
  const { tracks, query, setQuery } = useStoreContext('tracks')
  const searchInput = <SearchInput placeholder="Search" style={styles.search} parentState={[query, setQuery]} />

  const renderNewTrackButton = () => (
    <View style={styles.buttonRow}>
      <Button onPress={onNewTrack} bordered block title={i18n.t(`creativeSessions.ui.${state}.newTrackButton`)} />
    </View>
  )
  const ListHeaderComponent = <ScreenTitle title={i18n.t(`creativeSessions.ui.${state}.title`)} />

  const data = [
    {
      left: 'Start a New Track',
      rightIconName: 'ios-add-circle-outline',
      key: 'new',
      render: renderNewTrackButton,
    },
    {
      divider: 'Select previous track',
      key: 'dakuebom',
      style: styles.gap,
      render: () => <OrComponent />,
    },
    {
      key: 'orText',
      render: () => <Text light center bold style={styles.orTitle}> Choose an existing track </Text>,
    },
    {
      key: 'search',
      render: () => searchInput,
    },
    ...tracks.map((track) => ({
      key: `${track.id}`,
      title: track.title,
      onPress: () => onSelectTrack(track),
    })),
  ]
  return (
    <View style={{ backgroundColor: 'white', flex: 1 }}>
      <List
        withBorder={false}
        ListHeaderComponent={ListHeaderComponent}
        data={data}
      />
    </View>
  )
}
export default SelectTrack

const styles = StyleSheet.create({
  buttonRow: {
    alignContent: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 10,
  },
  search: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  gap: {
    marginBottom: 25,
  },
  orTitle: {
    marginBottom: 25,
  },
})
