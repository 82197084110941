/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef, useCallback } from "react"
import { StyleSheet } from "react-native"
import { GiftedChat, Send } from "react-native-gifted-chat"
import axios from "axios"

import KeyboardSpacerIOS from "@reveel/components/KeyboardSpacerIOS"
import View from "@reveel/components/ui/View"
import Colors from "@reveel/constants/Colors"
import {
  generateSessionMarkup,
  generateUserMarkup,
} from "@reveel/utils/mentionsMarkup"
import { Text } from '@reveel/components/ui'
import SlackMessage from "./SlackMessage"

import { openWebSocket } from "../../helpers/ActionCable"
import { withAuth } from "../../stores/useAuth"
import useWebScrollFix from "./useWebScrollFix"
import { useDesktop } from "../../helpers/MediaQueries"
import DesktopChatPopup from "../ui/DesktopChatPopup"
import CustomActions from "./CustomActions"
import InputToolbar from './InputToolbar'

const mapMessageToGiftedChat = ({
  user = {},
  meta = {},
  created_at: createdAt,
  ...rest
}) => ({
  ...rest,
  // currently using createdAt for ID, as messages are stored in jsonb, without ids
  _id: createdAt,
  createdAt,
  meta,
  user: user
    ? {
      _id: user.id,
      avatar: user.avatar_url,
      ...user,
    }
    : {
      _id: meta.type,
      name: meta.title,
    },
})

const renderMessage = (msgProps) => <SlackMessage {...msgProps} />

const ChatRoom = ({
  user,
  style = { flex: 1 },
  allMessagesUrl,
  submitMessageUrl,
  onSend = (messages_) => {
    try{
      axios.post(submitMessageUrl, { messages: messages_ })
    }catch(err){
      console.error(err)
    }
  },
  channelType,
  channelId,
  processMessage,
  customActionProperties = {},
  accessories = [],
  ...otherProps
}) => {
  const { id, first_name, avatar_url } = user

  const [input, setInput] = useState("")

  const [selectedSessions, setSelectedSessions] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])

  const [messages, setMessages] = useState([])
  const giftedChatRef = useWebScrollFix()
  const msgRef = useRef(messages)

  // TODO: apparently bad practice to do this,
  // but i'm not sure how to do it otherwise? 🤔
  // maybe in a state?
  // or maybe use GiftedChat.append as per the example
  // https://github.com/FaridSafi/react-native-gifted-chat#example
  useEffect(() => {
    msgRef.current = messages
  }, [messages])

  useEffect(() => {
    const received = (incoming) => {
      if(Array.isArray(incoming)){
        const filteredMessages = incoming.filter((m) => {
          if(m.type === "system"){
            processMessage(m)
            return false
          }
          return true
        })
        setMessages([
          ...filteredMessages.map(mapMessageToGiftedChat),
          ...msgRef.current,
        ])
      }
      const { type, message } = incoming
      if(type === "messageUpdate"){
        const newMessages = msgRef.current.map((msg) => {
          if(msg.id === message.id){
            return mapMessageToGiftedChat(message)
          }
          return msg
        })
        setMessages(newMessages)
      }
    }

    const closeConnection = openWebSocket(channelType, channelId, {
      received,
      user,
    })
    const fetchMessages = async () => {
      try{
        const { data } = await axios.get(allMessagesUrl)
        setMessages(data.messages.map(mapMessageToGiftedChat))
      }catch(err){
        console.log(err)
      }
    }
    fetchMessages()
    return closeConnection
  }, [channelType, channelId, user, processMessage, allMessagesUrl])

  const accessoryRef = useRef()
  // if (loading) {
  //   return <Loading />
  // }

  const renderedAccessories = accessories.map((a) => a({
    input,
    setInput,
    selectedSessions,
    setSelectedSessions,
    selectedUsers,
    setSelectedUsers,
  }))
  const firstAccessoryRendered = renderedAccessories.find((a) => a)
  const isDesktop = useDesktop()
  const renderAccessory = () => {
    if(!firstAccessoryRendered){
      return null
    }
    if(!isDesktop){
      return (
        <View style={styles.overlay}>
          <View sheet style={styles.accessories}>
            {firstAccessoryRendered((ref) => (accessoryRef.current = ref))}
          </View>
        </View>
      )
    }
    return (
      <DesktopChatPopup position="top" isOpen style={{ left: 15, padding: 10 }}>
        {firstAccessoryRendered((ref) => (accessoryRef.current = ref))}
      </DesktopChatPopup>
    )
  }

  const handleMentions = useCallback(
    (initialMessage) => {
      const message = initialMessage

      message.creative_session_ids = []
      message.user_ids = []

      message.plain_text = input
      message.text = input

      selectedSessions.forEach((session) => {
        const query = `#${session.slug}`
        const mentionedSession = message.text.indexOf(query) !== -1

        if(mentionedSession){
          message.creative_session_ids.push(session.id)

          message.text = generateSessionMarkup(message.text, query, session.id)
        }
      })

      selectedUsers.forEach((mention) => {
        const query = `@${mention.username}`
        const mentionedUser = message.text.indexOf(query) !== -1

        if(mentionedUser){
          message.user_ids.push(mention.id)
          message.text = generateUserMarkup(message.text, query, mention.id)
        }
      })

      return message
    },
    [input, selectedSessions, selectedUsers],
  )

  const formatMessage = useCallback(
    (initialMessage) => {
      const message = initialMessage
      const mentionedAnything = selectedSessions.length > 0 || selectedUsers.length > 0

      if(mentionedAnything) return handleMentions(message)

      return message
    },
    [selectedSessions, selectedUsers, handleMentions],
  )

  const onSubmit = useCallback(
    (messages_) => {
      const message = formatMessage(messages_[0])

      onSend([message])

      setSelectedSessions([])
      setSelectedUsers([])
    },
    [formatMessage, onSend, setSelectedSessions, setSelectedUsers],
  )

  // const onSelectionChange = ({ nativeEvent: { selection } }) => {
  //   console.log(selection);
  // };

  return (
    <View style={[style]}>
      <GiftedChat
        messages={messages}
        onSend={onSubmit}
        renderMessage={renderMessage}
        text={input}
        onInputTextChanged={useCallback((text) => setInput(text), [setInput])}
        // textInputProps={{ defaultValue: "", onSelectionChange }}
        textInputProps={{ defaultValue: "" }}
        renderChatFooter={renderAccessory}
        renderActions={(p) => (
          <CustomActions {...p} {...customActionProperties} onSend={onSubmit} />
        )}
        user={{
          _id: id,
          name: first_name,
          avatar: avatar_url,
        }}
        // Reversing because using inverted scrolls to top rather than to bottom 😤
        inverted
        isKeyboardInternallyHandled={false}
        textStyle={{
          color: Colors.tintColor,
        }}
        ref={giftedChatRef}
        renderInputToolbar={(props) => <InputToolbar {...props} />}
        // this is needed to shift the chat content up
        // with the larger custom sized InputToolbar
        renderFooter={() => <View style={{ height: 25 }} />}
        {...otherProps}
        alwaysShowSend
      />

      <KeyboardSpacerIOS />
    </View>
  )
}

export default React.memo(withAuth(ChatRoom))

const styles = StyleSheet.create({
  overlay: {
    backgroundColor: "rgba(0,0,0,.6)",
    position: "absolute",
    flexDirection: "column",
    justifyContent: "flex-end",
    ...StyleSheet.absoluteFillObject,
  },
  accessories: {
    width: "100%",
    flex: 0,
  },
})
