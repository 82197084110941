import React from 'react'
import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'
import { StyleSheet, TouchableOpacity } from 'react-native'
import Colors from '@reveel/constants/Colors'
import { Linking } from 'expo'
import SocialIcon from '../../components/ui/social/SocialIcon'
import humanize from '../../utils/humanize'
import WithSeparators from '../../components/utils/WithSeparators'
import openURL from '../../utils/openURL';

export const SocialMedias = ({ profile: { social } }) => (
  <WithSeparators>
    {Object.keys(social).filter((k) => social[k]?.length > 0).map((k) => <SocialMedia k={k} url={social[k]} key={k} />)}
  </WithSeparators>
)

const SocialMedia = ({ k, url }) => {
  const onPress = () => openURL({ href: url, target: '_blank' })
  const displayUrl = url.replace(/https?:\/\//, '').replace(/(.+)\//, '')
  
  if(!displayUrl || displayUrl === ''){
    return null
  }
  return (
    <TouchableOpacity style={styles.wrapper} onPress={onPress}>
      <SocialIcon xlight style={styles.icon} size={30} k={k} />
      <View>
        <Text mb_5>{humanize(k)}</Text>
        <Text 
          bold
          primary
        >
          {/* TODO: don't slice, but ellipsis! */}
          {displayUrl.slice(0, 15)}
        </Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    paddingVertical: 15,
    flexDirection: 'row',
    paddingLeft: 10,

  },
  icon: {
    marginRight: 10,
    color: Colors.borderGrey,
  },
})

export default SocialMedia
