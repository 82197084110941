export default function props2Style(props, styles){
  const appliedStyles = []
  Object.keys(props).forEach((n) => {
    if(styles[n] && props[n]){
      appliedStyles.push(styles[n])
      delete props[n]
    }
  })
  return appliedStyles
}
