// @flow

import variable from "./../variables/platform";

export default (variables /*: * */ = variable) => {
  const iconTheme = {
    fontSize: variables.iconFontSize,
    color: "#000",
    ".small": {
      fontSize: 16,
    }
  };

  return iconTheme;
};
