import routes from '@reveel/helpers/routes';
import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const useChangePermission = (trackID, participationID) => {
  const queryClient = useQueryClient();
  const key = ['track', 'permissions', trackID];
  const mutation = useMutation(
    async (participation) => axios.put(routes.api.participations.update(participationID), { participation }),
    {
      onMutate: async (newPart) => {
        await queryClient.cancelQueries(key)
        const previousValue = queryClient.getQueryData(key)

        const newValue = {
          ...previousValue,
          users: previousValue.users.map((p) => {
            if (p.id !== participationID) { return p; }
            return {
              ...p,
              ...newPart,
            };
          }),
        };
        queryClient.setQueryData(key, newValue);
        return previousValue;
      },
      onError: (err, variables, previousValue) => {
        queryClient.setQueryData(key, previousValue);
      },
    }
  );
  const update = (newPart) => {
    mutation.mutate(newPart);
  };
  return { update };
};
