import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { Text } from '@reveel/components/ui';
import Button from '@reveel/components/ui/Button';
import Colors from '@reveel/constants/Colors';
import routes from '@reveel/helpers/routes';
import PageWrapper from '@reveel/screens/onboarding/PageWrapper';
import { useAuth, User } from '@reveel/stores/useAuth';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { tw } from 'react-native-tailwindcss';
import UserAvatar from 'react-native-user-avatar';
import Logo from '@reveel/../assets/images/logo/logo_beta_horizontal.svg';
import { useDesktop } from '@reveel/helpers/MediaQueries';

export const InvitedWelcomeScreen = ({ inviter = {} }) => {
  const tags = ['Creator Credits', 'Splits', 'Metadata', 'Contracts', 'Payments'];
  const { name, avatar_url } = inviter;
  const navigation = useNavigation();
  const isDesktop = useDesktop()
  return <PageWrapper>
    <View style={[tw.pX4, isDesktop ? tw.pY8: tw.pY4, tw.flexGrow]}>
      <View style={tw.flexGrow}>
        <View style={[tw.flexRow, isDesktop && tw.justifyCenter, tw.mB8]}>
          <Logo />
        </View>
        <View style={[tw.mXAuto]}>
          {avatar_url ? <UserAvatar name={name} size={100} src={avatar_url} /> : <View style={{ height: 100 }} />}
        </View>
        <Text style={[tw.textPrimary, tw.fontBold, tw.mT8, tw.mB2, tw.textCenter]}>
          {name} has invited you to
          join his network on Reveel
        </Text>
        <Text style={[tw.textLightBlack, tw.textCenter]}>
          Save hours on your music paperwork.
        </Text>
        <Text style={[tw.fontBold, tw.mT6, tw.mB4, tw.textCenter]}>Here's why {name} and thousands{'\n'}of other creators are using Reveel:</Text>
        <View style={[tw.flexRow, tw.flexWrap, tw.justifyAround, tw.pB4]}>
          {tags.map(tag => <View style={[tw.flexRow, tw.itemsCenter, tw.justifyCenter, tw.mB2, isDesktop && { flexBasis: '33%' }]}>
            <Ionicons name="md-checkmark" size={24} color={Colors.tintColor} />
            <Text key={tag} style={[tw.mL1, tw.textCenter, tw.mR2]}>{tag}</Text>
          </View>)}
          <Text style={[tw.textCenter, tw.mT2]}>
            ...for all your songs, all in one place.
          </Text>
        </View>
      </View>
      <Button onPress={() => navigation.replace('AuthForms')} title="Get Started" />
    </View>
  </PageWrapper>;
};

export const useInvitedBy = () => {
  const { initialQueryParams: { ref } } = useAuth();
  const [inviter, setInviter] = useState<User | undefined>(undefined);

  useEffect(() => {
    (async () => {
      if (!ref) {
        return;
      }
      const { data } = await axios.get(routes.api.user.show(ref));
      setInviter(data);
    })();
  }, [ref]);
  return {
    inviter,
    hasInviter: Boolean(ref),
  };
};
