export const Alert = {
  alert: (title, desc, actions) => {
    if(!actions){
      window.alert(`${title}\n${desc}`)
    }else if(actions.length === 1){
      window.alert(`${title}\n${desc}`)
      actions[0].onPress?.()
    }else{
      confirmBox()
    }

    function confirmBox(){
      let cancelAction; let
        confirmAction
      actions.forEach((a) => {
        if(a.style === 'cancel'){
          cancelAction = a
        }else{
          confirmAction = a
        }
      })

      const res = window.confirm(`${title}\n${desc}`)
      if(res){
        confirmAction.onPress()
      }else{
        cancelAction?.onPress?.()
      }
    }
  },
}
