import React from 'react'
import { Text, View } from '@reveel/components/ui'
import { StyleSheet, TouchableOpacity } from 'react-native'
import UserAvatar from 'react-native-user-avatar'
import { fullName, stageName } from '../../../helpers/userFormatter'

const UserMini = ({ style, user, onPress }) => (
  <TouchableOpacity disabled={onPress} onPress={onPress} disabled={!onPress} style={[styles.container, style]}>
    <View style={styles.left}>
      <UserAvatar containerStyle={styles.avatar} name={fullName(user)} size={20} src={user.avatar_url} />
      <Text style={styles.name}>{user.display_name}</Text>
    </View>
  </TouchableOpacity>
)
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  left: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  name: {
    marginLeft: 5,
  },
})

export default UserMini
