import React, { useState } from 'react'
import FormItems from '../../components/ui/Form'
import { useAuth, useUpdateProfile } from '@reveel/stores/useAuth';
import useMyProfile, { useUpdateMyProfile } from '../../stores/useMyProfile';


function UserUpdateHOC(props){
  const {
    fields, onSuccess, ...otherProps
  } = props
  const { myProfile } = useMyProfile()
  const { run: onSubmit, loading, errors } = useUpdateMyProfile({ onSuccess })

  if (!myProfile){
    return null
  }
  const formProps = {
    onSubmit,
    formObject: myProfile,
    labelType: 'stackedLabel',
    errors,
    loading,
    fields,
  }
  return (
    <FormItems
      {...otherProps}
      {...formProps}
      i18nPrefix="user.fields"
    />
  )
}

export default UserUpdateHOC
