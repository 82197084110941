import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { Text } from '@reveel/components/ui'
import { useAuth } from '@reveel/stores/useAuth'
import UserAvatar from 'react-native-user-avatar'

import MenuItemWithDropdown from './MenuWithDropdown'
import Colors from '@reveel/constants/Colors';

export const DropdownItem = ({ title, onPress }) => (
  <TouchableOpacity onPress={onPress} style={styles.menuItem}>
    <Text>{title}</Text>
  </TouchableOpacity>
)

const styles = StyleSheet.create({
  menuItem: {
    paddingVertical: 15,
    paddingHorizontal: 10,
  },
})


export const NavigationAvatar = ({ focused }) => {
  const { user: { first_name, last_name, avatar_url } } = useAuth()
  return (
    <UserAvatar
      name={`${first_name} ${last_name}`}
      src={avatar_url}
      style={{
        borderWidth: 1,
        borderColor: focused ? Colors.primary : 'white'
      }}
    />
  )
}

const HeaderAccount = () => {
  const navigation = useNavigation()
  const { logout, user: { name, avatar_url } } = useAuth()

  return (
    <MenuItemWithDropdown
      testID="account"
      renderTrigger={() => <NavigationAvatar />}
      large
      shifted
    >
      <DropdownItem title="Creator ID" onPress={() => navigation.navigate('Profile')} />
      <DropdownItem title="Account Settings" onPress={() => navigation.navigate('ProfileEdit')} />
      <DropdownItem title="My Skills" onPress={() => navigation.navigate('SkillsOverview')} />
      <DropdownItem title="Logout" onPress={() => logout(() => navigation.navigate('Onboarding'))} />
    </MenuItemWithDropdown>
  )
}

export default HeaderAccount
