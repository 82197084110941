import { useEffect, useRef, useCallback } from "react"
import * as Notifications from "expo-notifications"
import Axios from "axios"

import routes from "./routes"
import useAppState from "./useAppState"
import { updateDeviceToken } from "../config/PushNotifications"
import NavigationService from "./NavigationService"
import setNotificationChannelsForAndroid from "./setNotificationChannelsForAndroid"
import { Alert } from "react-native"

export const useRefreshUser = (user, setUser) => {
  const onForeground = useCallback(async () => {
    if(user){
      try{
        const { data } = await Axios.get(routes.api.user.show(undefined))
        setUser(data)
      }catch(err){}
    }
  }, [user?.id, setUser])

  const onBackground = () => { }

  useAppState({
    onForeground,
    onBackground,
  })
}

export const useUpdateDevice = (user) => {
  useEffect(() => {
    if(user){
      updateDeviceToken()
    }
  }, [user?.id])
}

export const useHandleNotifications = () => {
  const notificationListener = useRef()
  useEffect(() => {
    setNotificationChannelsForAndroid()
    notificationListener.current = Notifications.addNotificationResponseReceivedListener(({ notification, actionIdentifier }) => {
      const { request: { content: { data } } } = notification
      if(actionIdentifier === Notifications.DEFAULT_ACTION_IDENTIFIER){
        const { route, routeParams } = data
        if(route){
          NavigationService.reset({
            index: 1,
            routes: [{ name: 'Home' }, { name: route, params: routeParams }],
          })
        }
      }
    })
    Notifications.setNotificationHandler({
      handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: false,
        shouldSetBadge: false,
      }),
    })
    return () => Notifications.removeNotificationSubscription(notificationListener)
  }, [])
}

export const loadAssets = () => {
  const assets = [
    require('app/assets/images/avatar_dark.svg'),
  ]
  assets.forEach((ass) => Asset.fromModule(ass).downloadAsync())
}
