import React from 'react'
import View from '@reveel/components/ui/View'
import i18n from 'i18n-js'
import { RUNNING } from '../../constants/Status'
import Dot from '../../components/core/Dot'
import Checkmark from '../../components/ui/Checkmark'
import ActionableItem from '../../components/core/ActionableItem'

const PendingCreativeSessions = ({ creativeSessions, navigation }) => {
  const renderSingle = (creativeSession, notFirst) => {
    const {
      status, track_title, position, id,
    } = creativeSession
    const size = 17
    const leftIcon = () => (status === RUNNING ? <Dot status={status} size={size} /> : <Checkmark size={size * 1.7} />)
    const props = {
      leftIcon,
      title: `${track_title} #${position}`,
      description: i18n.t(`banners.${status}.title`),
      buttonTitle: i18n.t(`banners.${status}.button`),
      bordered: status !== RUNNING,
      testID: `creativeSession-${id}`,
      notFirst,
      navigation,
      navigateTo: ['Session', { trackID: creativeSession.track_id, id: creativeSession.id }],
    }
    return <ActionableItem key={`cs-todo-${id}`} {...props} />
  }
  return (
    <View testID="pendingCreativeSessions">
      {creativeSessions.map(renderSingle)}
    </View>
  )
}

export default PendingCreativeSessions
