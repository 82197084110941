import React from 'react'
import View from '@reveel/components/ui/View'
import QRCode from 'react-native-qrcode-svg'

import { StyleSheet } from 'react-native'
import { Text } from '@reveel/components/ui'
import i18n from 'i18n-js'
import ModalButton from '../../components/ModalButton'

const UserQrCode = ({
  style, user, size, avatarSize,
}) => {
  const content = () => (
    <View style={styles.container}>
      <QRCode size={avatarSize} value={publicUserAsJson()} />
      <Text center light smaller mt-2>{i18n.t('flow.scan.help')}</Text>
    </View>
  )
  const publicUserAsJson = () => {
    const {
      id, first_name, last_name, avatar_url, artist_profile,
    } = user
    return JSON.stringify({
      id, first_name, last_name, avatar_url, artist_profile, rev: true,
    })
  }
  return (
    <ModalButton style={style} content={content}>
      <QRCode size={size} value={publicUserAsJson()} />
    </ModalButton>
  )
}
export default UserQrCode

const styles = StyleSheet.create({
  container: {
    paddingVertical: 20,
    paddingHorizontal: 30,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
})
