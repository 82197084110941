
export default {
  attachments: {
    name: 'Attachments',
    ui: {
      new: {
        cta: {
          title: 'Add an attachment',
          subtitle: 'from any provider',
          buttonText: '+ New',
        },
        save: 'Add Attachment',
      },
      edit: {
        save: 'Save Attachment',
      },
      index: {
        empty: {
          title: "Add attachments to your track and\ncomment with your collaborators",
          cta: "Add attachments",
          andMore: 'and more...',
        },
        searchEmpty: 'No results for %{query}'
      }
    },
  },
}
