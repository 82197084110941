
export default {
  onboarding: {
    signup: {
      title: ["Join", "Reveel" ],
      buttonText: ['Continue', "Agree & Join"],
      tAndCNotice: "By clicking Agree & Join, you are agreeing to our",
    },
    creatorID: {
      title: ['Let’s get started!', 'Setup your\nCreator ID'],
    },
    howDidYouFindOut: {
      title: ['One', 'last thing...'],
      subtitle: ["Help us know more about yourself"]
    },
    location: {
      title: ['Where are you', 'Located?'],
    },
    avatar: {
      title: ['Add a picture', 'to be easily found!'],
    },
    summary: {
      title: ['Welcome', 'to Reveel!'],
      subtitle: ["Here's a summary of your profile: \nDon't worry, you'll be able to edit it later"]
    },
    track: {
      create: {
        title: ['Add Your', 'First Track'],
        subtitle: `Hi %{first_name}! You are all set to create your first track.`,
      },
      invite: {
        title: ['Invite Your', 'Collaborators'],
        subtitle: `Awesome! Now you can invite some people to collaborate on %{track_title}`,
      },
      credits: {
        title: ['Add Your', 'Credits'],
        subtitle: 'Are you ready to get credited for %{track_title}?',
      },
      attachments: {
        title: ['Share', 'Attachments'],
        subtitle: 'Link to exisitng files or folders for %{track_title}?',
      },
      summary: {
        title: ['Congrats, your', 'first track is up!'],
        subtitle: 'You can see and edit it, as well as set splits and export its metadata',
      },
    },
  },
  invite: {
    mail: {
      subject: 'You’re gonna love Reveel!',
      body: 'Hi,\nI am using Reveel to manage my creative projects more efficiently. I think it will help you too. \n\nReveel is made for remote collaboration and helps me stay on top of all my creative projects. With Reveel, I can\n\nTrack credits and log sessions\nStay in touch with my creative team \nOrganize & share files and attachments\n\n\nFollow this unique link, to Join Reveel: %{url}\n\nEnjoy,\n\n\nPS: If you have any questions, the team at Reveel can help. Email them to: service@reveel.id\n',
    },
  },
}
