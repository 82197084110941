import React from 'react'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { Platform } from 'react-native'
import { InboxTabIcon } from '../../screens/inbox/InboxScreen'
import TracksIndexScreen from '../../screens/tracks/TracksIndexScreen'
import AccountSettingsScreen from '../../screens/user/AccountSettingsScreen'
import TabBarIcon from '../../components/TabBarIcon'
import NewSessionButton from './NewSessionButton'

import MyID from '../../screens/MyID'
import Inbox from '../../screens/inbox/Inbox'
import { NavigationAvatar } from '../createScreenHeader/HeaderAccount'
import HomeScreen from '../../screens/home/HomeScreen'
import { Ionicons } from '@expo/vector-icons';

const Tab = createBottomTabNavigator()
const FakeMainButtonTab = () => null

const Home = () => {
  const tracks = (
    <Tab.Screen
      key="TracksIndex"
      name="TracksIndex"
      component={TracksIndexScreen}
      options={{
        tabBarLabel: 'Tracks',
        tabBarIcon: ({ focused }) => (<TabBarIcon focused={focused} name="ios-musical-notes" />),
      }}
    />
  )
  const todos = (
    <Tab.Screen
      key="InboxScreen"
      name="Inbox"
      component={Inbox}
      options={{
        tabBarLabel: "Inbox",
        tabBarIcon: ({ focused }) => <InboxTabIcon focused={focused} />,
      }}
    />
  )
  const home = (
    <Tab.Screen
      key="NewSession"
      name="NewSession"
      component={HomeScreen}
      options={({ navigation }) => ({
        tabBarLabel: () => null,
        tabBarIcon: (props) => <TabBarIcon name="home" {...props}/>,
      })}
    />
  )

  const profile = (
    <Tab.Screen
      key="Profile"
      name="Profile"
      component={MyID}
      options={() => ({
        tabBarLabel: "My ID",
        tabBarIcon: ({ focused }) => <NavigationAvatar focused={focused} />,
      })}
    />
  )
  const settings = (
    <Tab.Screen
      key="AccountSettingsStack"
      name="AccountSettingsStack"
      component={AccountSettingsScreen}
      options={{
        tabBarLabel: 'Settings',
        tabBarIcon: ({ focused }) => (<TabBarIcon focused={focused} name="ios-settings" />),
      }}
    />
  )

  const tabs = Platform.select({
    ios: [home, tracks, todos, profile, settings],
    android: [home, tracks, todos, profile, settings],
    web: [home, tracks, todos, profile, settings],
  })
  return (
    <Tab.Navigator
      tabBarOptions={{
        showLabel: false,
        // the bar stopped being
        // absolutely pos'ed, and icons
        // would appear in the corner
        style: {
          position: 'absolute',
        },
      }}
    >
      {tabs}
    </Tab.Navigator>
  )
}
export default Home
