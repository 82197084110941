import React, { useCallback, useEffect, useState } from 'react'
import { SafeAreaView, StyleSheet } from 'react-native'

import { useLinkTo, useNavigation, useRoute } from '@react-navigation/native'
import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'
import Colors from '@reveel/constants/Colors'
import { useAsync } from '@reveel/hooks/useRemoteState'
import Page from '@reveel/layout/Page'
import { Alert } from '@reveel/vendors/alert'
import axios from 'axios'
import queryString from 'query-string'
import UserAvatar from 'react-native-user-avatar'
import * as Sentry from 'sentry-expo'

import Loading from '../components/Loading'
import ScreenTitle from '../components/ScreenTitle'
import Button from '../components/ui/Button'
import routes from '../helpers/routes'
import { useAuth } from '../stores/useAuth'

const isParticipant = (creativeSession, userId) => creativeSession.participants.map((p) => p.id).includes(userId)
const AcceptInvitationScreen = () => {
  const route = useRoute()
  const navigation = useNavigation()
  const { replace } = navigation
  const { name, params } = route
  const [session, setCreativeSession] = useState()
  const { user, isLoggedIn, setInitialQueryParams } = useAuth()
  const creativeSessionId = route.params?.id
  const invitationToken = route.params?.token

  const linkTo = useLinkTo()
  const navigateToCreativeSessionOrTrack = useCallback((creativeSession) => {
    if(creativeSession.proxy){
      linkTo(`/tracks/${creativeSession.track_id}`)
    }else{
      linkTo(`/tracks/${creativeSession.track_id}/sessions/${creativeSession.id}`)
    }
  }, [linkTo])

  useEffect(() => {
    if(!isLoggedIn){
      // this is duplicated from useOnLoad, as there seems to be
      // some sort of race condition which overwrites the ref,
      // hence not giving access or setting the referrer
      const { rev, ref } = queryString.parse(window.location.search)
      setInitialQueryParams({
        rev,
        ref,
        src: name,
        afterAuth: {
          name,
          params,
        },
      })
      replace('Authentication')
    }
  }, [creativeSessionId, setInitialQueryParams, replace, params, name, isLoggedIn])

  useEffect(() => {
    (async function getStuff(){
      try{
        const { data } = await axios.get(routes.api.creativeSession.show(creativeSessionId))
        if(isParticipant(data, user)){
          navigation.popToTop()
          navigateToCreativeSessionOrTrack(data)
        }else{
          setCreativeSession(data)
        }
      }catch(err){
        if(err.response && err.response.status === 404){
          navigation.goBack(null)
          Alert.alert('Session not found', `Oops, we couldn't find session with id ${creativeSessionId}. Please verify the invitation link.`)
        }
        Sentry.captureException(err)
      }
    }())
  }, [creativeSessionId, navigateToCreativeSessionOrTrack, navigation, route, setInitialQueryParams, user?.id])

  const { run, refreshing } = useAsync({
    url: routes.api.creativeSession.acceptInvitation(creativeSessionId),
    method: 'post',
    handleResponse: ({ data }) => navigateToCreativeSessionOrTrack(data),
  })
  const accept = () => run({
    token: invitationToken,
  })

  if(!session){
    return <Loading />
  }

  return (
    <SafeAreaView style={styles.container}>
      <ScreenTitle title={["Join and", "Collaborate"]} />
      <Page>
        <View px box py>
          <View pb-2 mt-2 alignCenter row style={styles.info}>
            <UserAvatar containerStyle={styles.avatar} size={60} name={session.track_title || 'REV'} />
            <View pl style={[styles.nameContainer]}>
              <Text>
                {session.track_title}
              </Text>
              {!session.proxy && (
              <Text light small>
                Session #
                {session.readable_id}
              </Text>
              )}
            </View>
          </View>
          <View mt-2>
            <Button block disabled={refreshing} onPress={accept} title="Join" />
            <Button block cancel onPress={navigation.goBack} title="Cancel" />
          </View>
        </View>
      </Page>
    </SafeAreaView>
  )
}

export default AcceptInvitationScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.lightBackground,
  },
  nameContainer: {

  },
})
