import { url as initialURL, isProd } from "../urls"
import api from './api'
import front from './front'

const url = initialURL

// eslint-disable-next-line no-console
console.log("API endpoint:", url)
const remoteDeepLink = (dl) => `${url}/deep-links?url=${encodeURIComponent(dl)}`

const deepLink = {
  acceptInvitation: ({ creative_session_id, inviter }) => remoteDeepLink(Linking.makeUrl(
    `creative_sessions/${creative_session_id}/accept-invitation`,
    { inviter },
  )),
}

export default {
  api,
  deepLink,
  front,
  web: {
    terms: `${url}/terms-and-conditions?app=true`,
    privacy: `${url}/privacy?app=true`,
    faqs: `${url}/faqs?app=true`,
    forgotPassword: `${url}/api/v1/users/password/new?app=true`,
    changelog: `https://www.notion.so/reveel/Changelog-8b7ed86554a3489788037d2f4c0e35b0`,
    tracks: {
      export: (id) => `${url}/tracks/${id}/export?app=true`,
    },
  },
  isProd,
  url,
}
