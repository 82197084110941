import React from 'react'
import { StatusBar, StyleSheet } from 'react-native'

import WelcomePane from './WelcomePane'
import GuideSlides from '../../components/GuideSlides'
import PageWrapper from '@reveel/screens/onboarding/PageWrapper';
import { useDesktop } from '@reveel/helpers/MediaQueries';
import { useNavigation } from '@react-navigation/native';

const images = {
  mobile: {
    creatorId: require('app/assets/images/welcome_slides/creator_id.png'),
    manage: require('app/assets/images/welcome_slides/manage.png'),
    getCredit: require('app/assets/images/welcome_slides/get_credit.png'),
    collaborate: require('../../../assets/images/welcome_slides/collaborate.png'),
  },
  desktop: {
    creatorId: require('app/assets/images/welcome_slides/creator_id.web.png'),
    manage: require('app/assets/images/welcome_slides/manage.web.png'),
    getCredit: require('app/assets/images/welcome_slides/get_credit.web.png'),
    collaborate: require('../../../assets/images/welcome_slides/collaborate.web.png'),
  }
}

const WelcomeScreen = () => {
  const navigation = useNavigation()
  const imageKey = useDesktop() ? 'desktop' : 'mobile'
  const tabs = [
    {
      title: "ID",
      elem: () => (
        <WelcomePane
          title="Creator ID"
          description="Keep all your details & professional information in one place"
          image={images[imageKey].creatorId}
        />
      ),
    },
    {
      title: "Collaborate",
      elem: () => (
        <WelcomePane
          title="Collaborate"
          description="Invite your collaborators, communicate and organize"
          image={images[imageKey].collaborate}
        />
      ),
    },
    {
      title: "Credit",
      elem: () => (
        <WelcomePane
          title="Get Credit"
          description="Sign in to creative sessions & declare your contributions"
          image={images[imageKey].getCredit}
        />
      ),
    },
    {
      title: "Manage",
      elem: () => (
        <WelcomePane
          title="Manage"
          description="Run your projects from one place & set roles within your team"
          image={images[imageKey].manage}
        />
      ),
    },
  ]

  const skip = () => navigation.replace('AuthForms')
  return (
    <>
      <StatusBar hidden />
      <PageWrapper>
        <GuideSlides tabs={tabs} finishText="Get Started" onFinish={skip} />
      </PageWrapper>
    </>
  )
}

WelcomeScreen.navigationOptions = ({
  header: null,
  mode: 'modal',
})
export default WelcomeScreen

const styles = StyleSheet.create({

})
