import { useEffect, useRef, useCallback } from 'react'
import { Platform } from 'react-native'

// fix the scaleY(-1) as per
// https://github.com/FaridSafi/react-native-gifted-chat/issues/1860#issuecomment-656115581

const useWebScrollFix = () => {
  if(Platform.OS !== 'web'){
    return
  }
  const giftedChatRef = useRef(null)

  const invertedWheelEvent = useCallback((e) => {
    const listViewRef = giftedChatRef.current?._messageContainerRef
    if(listViewRef && listViewRef.current){
      listViewRef.current.getScrollableNode().scrollTop -= e.deltaY
      e.preventDefault()
    }
  }, [giftedChatRef])
  useEffect(() => {
    const listViewRef = giftedChatRef.current?._messageContainerRef
    if(listViewRef && listViewRef.current){
      listViewRef.current.getScrollableNode().addEventListener('wheel', invertedWheelEvent)
      listViewRef.current.setNativeProps({
        style: {
          transform: "translate3d(0,0,0) scaleY(-1)",
        },
      })
    }
    return () => {
      const listViewRef = giftedChatRef.current?._messageContainerRef
      if(listViewRef && listViewRef.current){
        listViewRef.current.getScrollableNode().removeEventListener('wheel', invertedWheelEvent)
      }
    }
  }, [giftedChatRef, giftedChatRef.current, invertedWheelEvent])

  return giftedChatRef
}

export default useWebScrollFix
