import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import Colors, { transparentize } from '@reveel/constants/Colors'

const icons = {
  workForHire: 'logo-usd',
  admin: 'ios-people',
}

const Badge = ({ name, style }) => (
  <View style={[styles.badge, style]}>
    <Ionicons name={icons[name]} color="white" size={10} />
  </View>
)

const styles = StyleSheet.create({
  badge: {
    backgroundColor: transparentize(Colors.tintColor, 'DD'),
    width: 16,
    height: 16,
    paddingTop: 1,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 5,
  },
})
export default Badge
