import React from 'react'
import { StyleSheet } from 'react-native'
import { Text } from '@reveel/components/ui'

const SocialIcon = ({
  style, size = 20, k, ...props
}) => <Text {...props} style={[sStyles.base, { fontSize: size }, style]}>{`${map[k]}`}</Text>
export default SocialIcon

const sStyles = StyleSheet.create({
  base: {
    fontFamily: 'Socicon',
  },
})
const map = {
  airbnb: "\ue00e",
  amazon: "\ue05c",
  amplement: "\ue070",
  android: "\ue03e",
  angellist: "\ue062",
  "app-net": "\ue013",
  apple: "\ue02e",
  apple_music: "\ue02e",
  baidu: "\ue061",
  bandcamp: "\ue06a",
  bebo: "\ue035",
  behance: "\ue027",
  blogger: "\ue020",
  buffer: "\ue018",
  coderwall: "\ue011",
  dailymotion: "\ue053",
  deezer: "\ue06c",
  delicious: "\ue028",
  deviantart: "\ue029",
  digg: "\ue025",
  disqus: "\ue019",
  douban: "\ue03b",
  draugiem: "\ue074",
  dribbble: "\ue023",
  drupal: "\ue002",
  ebay: "\ue063",
  ello: "\ue006",
  eight_tracks: "\ue05b",
  endomondo: "\ue075",
  envato: "\ue026",
  facebook: "\ue041",
  feedburner: "\ue048",
  filmweb: "\ue076",
  five_hundred_px: "\ue056",
  flattr: "\ue02f",
  flickr: "\ue055",
  forrst: "\ue02a",
  foursquare: "\ue044",
  friendfeed: "\ue032",
  github: "\ue030",
  goodreads: "\ue014",
  google: "\ue067",
  google_play: "\ue02b",
  google_plus: "\ue042",
  grooveshark: "\ue04f",
  houzz: "\ue00a",
  icq: "\ue05d",
  identica: "\ue034",
  imdb: "\ue064",
  instagram: "\ue057",
  istock: "\ue004",
  itunes: "\ue06b",
  lanyrd: "\ue016",
  last_fm: "\ue050",
  linkedin: "\ue049",
  mail: "\ue01f",
  medium: "\ue06d",
  meetup: "\ue03c",
  mixcloud: "\ue001",
  model_mayhem: "\ue000",
  mozilla_persona: "\ue008",
  mumble: "\ue07e",
  myspace: "\ue04c",
  newsvine: "\ue033",
  odnoklassniki: "\ue00d",
  openid: "\ue06f",
  outlook: "\ue010",
  patreon: "\ue01c",
  paypal: "\ue00c",
  periscope: "\ue00f",
  pinterest: "\ue043",
  playstation: "\ue03d",
  play_store: "\ue02b",
  pocket: "\ue01e",
  qq: "\ue03a",
  quora: "\ue073",
  raidcall: "\ue07d",
  ravelry: "\ue05f",
  reddit: "\ue022",
  renren: "\ue031",
  resident_advisor: "\ue066",
  rss: "\ue00b",
  sharethis: "\ue069",
  skype: "\ue046",
  slideshare: "\ue017",
  smugmug: "\ue05e",
  snapchat: "\ue03f",
  soundcloud: "\ue04d",
  spotify: "\ue04e",
  stackexchange: "\ue077",
  stackoverflow: "\ue007",
  stayfriends: "\ue065 ",
  steam: "\ue037",
  storehouse: "\ue01d",
  stumbleupon: "\ue024",
  swarm: "\ue003",
  teamspeak: "\ue079",
  teamviewer: "\ue07a",
  technorati: "\ue021",
  telegram: "\ue06e",
  tripadvisor: "\ue012",
  tripit: "\ue015",
  triplej: "\ue009",
  tumblr: "\ue059",
  twitch: "\ue05a",
  twitter: "\ue040",
  ventrilo: "\ue07b",
  viadeo: "\ue04a",
  vimeo: "\ue052",
  vine: "\ue054",
  viber: "\ue071",
  vkontakte: "\ue01a",
  weibo: "\ue060",
  whatsapp: "\ue01b",
  wikipedia: "\ue02d",
  windows: "\ue039",
  wordpress: "\ue058",
  wykop: "\ue078",
  xbox: "\ue038",
  xing: "\ue04b",
  yahoo: "\ue045",
  yammer: "\ue005",
  yandex: "\ue068",
  yelp: "\ue047",
  younow: "\ue07c",
  youtube: "\ue051",
  zerply: "\ue02c",
  zomato: "\ue072",
  zynga: "\ue036",
}
