import React, { useState } from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { Text } from '@reveel/components/ui'
import Collapsible from 'react-native-collapsible'

import { Icon } from 'native-base'
import Colors from '@reveel/constants/Colors'
import Loading from '../Loading'
import { Ionicons } from '@expo/vector-icons';

const RowWithActions = ({
  actions,
  children,
  style,
  smaller,
  rowStyle,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const actionRows = typeof actions === 'function' ? actions() : actions.map(({
    text, onPress, children: _children, loading,
  }, i) => (
    <TouchableOpacity key={text} onPress={onPress || undefined}>
      <View style={[styles.actionContainer, i !== actions.length - 1 && styles.border]}>
        <Text smaller={smaller} style={{ flex: 1 }}>{text}</Text>
        {_children && <View>{_children}</View>}
        {loading && <View><Loading size="small" /></View>}
      </View>
    </TouchableOpacity>
  ))

  const hasActions = actionRows.length !== 0
  const icon = (
    <Ionicons
      name={`caret-${isCollapsed ? 'down' : 'up'}`}
      style={styles.user}
      onPress={() => setIsCollapsed(!isCollapsed)}
    />
  )
  return (
    <View style={style}>
      <TouchableOpacity style={[styles.mainRow, rowStyle]} disabled={!hasActions} onPress={() => setIsCollapsed(!isCollapsed)}>
        {children}
        {hasActions && icon}
      </TouchableOpacity>
      {hasActions && (
      <Collapsible collapsed={isCollapsed}>
        <View style={styles.actionsWrapper}>
          {actionRows}
        </View>
      </Collapsible>
      )}
      {isCollapsed && <View style={[styles.userSeparator, styles.border]} />}
    </View>
  )
}

const styles = StyleSheet.create({
  userSeparator: {
    marginLeft: 15,
  },
  mainRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 10,
  },
  user: {
    color: Colors.lightGrey,
    marginRight: 10,
  },
  actionsWrapper: {
    backgroundColor: Colors.lightBackground,
    paddingLeft: 15,
  },
  actionContainer: {
    paddingRight: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 10,
  },
  border: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderGrey,
  },
})

export default RowWithActions
