import React, { useMemo } from "react";
import { StyleSheet } from "react-native";
import { endlineWordsAt } from "@reveel/utils/regexp";
import MentionUsersList from "../../components/core/MentionUsersList";

const MentionUser = ({ track, input, setInput, setSelectedUsers }) => {
  const query = useMemo(() => input.match(endlineWordsAt)[0].slice(1), [input]);

  const selectUser = (targetUser) => {
    const { username } = targetUser

    setSelectedUsers((previousUsers) => [
      ...previousUsers,
      { id: targetUser.id, username },
    ]);

    setInput((previousInput) =>
      previousInput.replace(endlineWordsAt, `@${username} `)
    );
  };

  return (
    <MentionUsersList
      inverted
      trackID={track.id}
      onPress={selectUser}
      selectedIds={[]}
      query={query}
      style={styles.list}
    />
  );
};

const styles = StyleSheet.create({
  list: {
    maxHeight: 160,
  },
});

export default MentionUser
