import React from 'react'
import i18n from 'i18n-js'

import withNavigation from '@reveel/helpers/withNavigation'
import BannerCTA from '../../components/core/BannerCTA'

const Notes = require('app/assets/images/notes.svg').default

const CreateTrackCTA = withNavigation(({ navigation, ...props }) => {
  const onPress = () => navigation.navigate('TrackNew')
  return (
    <BannerCTA
      onPress={onPress}
      Icon={Notes}
      title={i18n.t(`banners.track.new.title`)}
      subtitle={i18n.t(`banners.track.new.details`)}
      buttonText={i18n.t(`banners.track.new.button`)}
      {...props}
    />
  )
})
export default CreateTrackCTA
