import React, { useState } from 'react'

import QRCodeWithMask from '@reveel/components/utils/CameraMask'
import { Alert } from '@reveel/vendors/alert'
import { webAppHost } from '@reveel/helpers/urls';
import { useLinkTo } from '@react-navigation/native';

const getPathFromUrl = (input) => {
  const url = new URL(input)
  return [url.pathname, url.search].join('')
}

const JoinSessionByQRCodeScreen = ({
  navigation,
}) => {
  const [hasScanned, setHasScanned] = useState(false)
  const linkTo = useLinkTo()
  const joinSession = ({ data }) => {
    if(hasScanned){
      return
    }
    setHasScanned(true)
    if(data.indexOf(webAppHost)){
      navigation.pop()
      linkTo(getPathFromUrl(data))
    }else{
      Alert.alert("Invalid QR Code", "Are you sure you are scanning a session code?", [
        { text: 'OK', onPress: () => setHasScanned(false) },
      ])
    }
  }
  return (
    <QRCodeWithMask onScan={joinSession} instructions="Join a session by scanning its QR Code" />
  )
}

JoinSessionByQRCodeScreen.navigationOptions = () => ({
  title: 'Scan in to a session',
})

export default JoinSessionByQRCodeScreen
