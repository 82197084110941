import React from "react"
import { TouchableOpacity, StyleSheet, View } from "react-native"

import Colors from "@reveel/constants/Colors"
import * as Animatable from 'react-native-animatable'
import { Text } from '@reveel/components/ui'

const Pill = ({
  _testID, content, selected, onPress, style,
}) => {
  const Wrapper = onPress ? TouchableOpacity : View
  const localStyle = selected ? [styles.container, styles.selectedContainer] : styles.container
  const localStyleText = selected ? styles.selected : styles.unselected
  return (
    <Animatable.View animation="zoomIn" duration={200}>
      <Wrapper style={[localStyle, style]} onPress={onPress} testID={_testID || `pill-${content}`}>
        <Text smaller style={[localStyleText]}>{content}</Text>
      </Wrapper>
    </Animatable.View>
  )
}

export default Pill
const styles = StyleSheet.create({
  // eslint-disable-next-line react-native/no-color-literals
  container: {
    borderWidth: 1,
    borderColor: Colors.lightGrey,
    backgroundColor: 'transparent',
    borderRadius: 15,
    paddingVertical: 2,
    paddingHorizontal: 8,
  },
  selectedContainer: {
    borderColor: Colors.tintColor,
  },
  selected: {
    color: Colors.tintColor,
  },
  unselected: {
    color: Colors.lightGrey,
  },
})
