import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import './phone-reset.css'

const RVPhoneInput = ({ onValueChange, value, object }) => (
  <PhoneInput
    country="us"
    value={value}
    onChange={(phone) => onValueChange(phone)}
  />
)

{ /* <PhoneInput
  defaultValue={value}
  defaultCode={object.location}
  onChangeText={(text) => {
    onValueChange(text)
  }}
  flagButtonStyle={styles.flagButtonStyle}
  containerStyle={styles.containerStyle}
  textInputStyle={styles.textInputStyle}
  textContainerStyle={styles.textContainerStyle}
/> */ }

export default RVPhoneInput

// const styles = StyleSheet.create({
//   containerStyle: {
//     width: '100%',
//     backgroundColor: 'transparent'
//   },
//   textContainerStyle: {
//     backgroundColor: 'transparent',
//   },
//   flagButtonStyle: {
//     backgroundColor: 'transparent',
//     width: 'auto',
//     paddingRight: 5,
//   }
// })
