import React, { useCallback, useState } from 'react'
import { Platform, StyleSheet, TouchableOpacity } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import TitleWithSheet from '@reveel/components/layouts/TitleWithSheet'
import { Text } from '@reveel/components/ui'
import Button from '@reveel/components/ui/Button'
import Label from '@reveel/components/ui/Form/Label'
import TextField from '@reveel/components/ui/Form/TextField'
import View from '@reveel/components/ui/View'
import { TRACK_ONBOARDED } from '@reveel/constants/Status'
import { description, name, trackID, url } from '@reveel/helpers/fields/attachmentFieldsList'
import routes from '@reveel/helpers/routes'
import { useAsync } from '@reveel/hooks/useRemoteState'
import EnterContributions from '@reveel/screens/contributions/EnterContributions'
import PageWrapper from '@reveel/screens/onboarding/PageWrapper'
import { AttachmentNewForm } from '@reveel/screens/tracks/attachments/AttachmentNew'
import AttachmentSummary from '@reveel/screens/tracks/attachments/AttachmentSummary'
import { TrackNewForm } from '@reveel/screens/tracks/TrackNew'
import { useAuth, useUpdateUser } from '@reveel/stores/useAuth'
import useStoreContext from '@reveel/stores/useStoreContext'
import { SceneMap, TabView } from 'react-native-tab-view'

import InviteUsersDialog from '../../../components/invitation/InviteUsersDialog'
import TrackPermissions from '../../tracks/TrackPermissions/index'

export const Skip = ({ onPress }) => (
  <TouchableOpacity onPress={onPress} testID="skip">
    <Text bold center my-1 light>Skip</Text>
  </TouchableOpacity>
)

const useCompleteOnboarding = () => {
  const navigation = useNavigation()
  const { run } = useUpdateUser(() => {})
  const onSubmit = useCallback(() => {
    navigation.replace('Home')
    run({ onboard_status: TRACK_ONBOARDED })
  }, [navigation, run])
  return onSubmit
}

const CreateTrack = ({ onNext }) => {
  const { user: { first_name } } = useAuth()
  const [, setCurrentTrack] = useStoreContext('currentTrack')

  const skip = useCompleteOnboarding()
  const onCreate = (track) => {
    const proxy = {
      proxy: true,
      id: track.proxy_id,
      track_id: track.id,
      participants: [],
      invitations: [],
    }
    setCurrentTrack({
      ...track,
      proxy,
    })
    onNext()
  }
  return (
    <TitleWithSheet
      title={i18n.t('onboarding.track.create.title')}
      subtitle={i18n.t('onboarding.track.create.subtitle', { first_name })}
    >
      <TrackNewForm
        onCreate={onCreate}
        renderAfterButton={() => <Skip onPress={skip} />}
      />
    </TitleWithSheet>
  )
}

const InviteCollabs = ({ onNext }) => {
  const [track, setCurrentTrack] = useStoreContext('currentTrack')
  if(!track){
    return null
  }
  const setCreativeSession = (cs) => setCurrentTrack((t) => ({
    ...t,
    proxy: cs,
  }))
  return (
    <TitleWithSheet
      title={i18n.t('onboarding.track.invite.title')}
      subtitle={i18n.t('onboarding.track.invite.subtitle', { track_title: track.title })}
    >
      <View style={[tw.flex, tw.flexGrow, tw.pB2, tw.pT2]}>
        <InviteUsersDialog style={[tw.pX2, tw.flexGrow]} noTitle creativeSession={track.proxy} />
        <Button onPress={onNext} style={tw.mX2} block title="Next" />
        <Skip onPress={onNext} />
      </View>
    </TitleWithSheet>
  )
}

const AddCredits = ({ onNext }) => {
  const [track] = useStoreContext('currentTrack')
  if(!track){
    return null
  }

  const { run, loading } = useAsync({
    url: routes.api.tracks.contributions.submit(track.id),
    method: 'post',
    handleResponse: () => {
      onNext()
    },
  })
  const onSubmit = (contributions) => {
    const skill_ids = contributions.map((c) => c.id)
    run({
      credit: { skill_ids },
    })
  }
  return (
    <TitleWithSheet
      title={i18n.t('onboarding.track.credits.title')}
      subtitle={i18n.t('onboarding.track.credits.subtitle', { track_title: track.title })}
    >
      <EnterContributions
        style={[tw.flexGrow, tw.justifyBetween]}
        noChrome
        onSubmit={onSubmit}
        buttonProps={{
          title: 'Continue',
          loading,
        }}
        renderAfterButton={() => <Skip onPress={onNext} />}
      />
    </TitleWithSheet>
  )
}

const AddAttachment = ({ setAttachment, onNext }) => {
  const [track] = useStoreContext('currentTrack')
  if(!track){
    return null
  }
  const { id } = track
  const onSucces = (attachment) => {
    setAttachment(attachment)
    onNext()
  }
  return (
    <TitleWithSheet
      title={i18n.t('onboarding.track.attachments.title')}
      subtitle={i18n.t('onboarding.track.attachments.subtitle', { track_title: track.title })}
    >
      <AttachmentNewForm
        style={tw.pT2}
        trackID={id}
        onSuccess={onSucces}
        fields={[url, name, description, trackID]}
        formProps={{
          renderAfterButton: () => <Skip onPress={onNext} />,
        }}
      />
    </TitleWithSheet>
  )
}

const TrackSummary = ({ attachment }) => {
  const [track] = useStoreContext('currentTrack')
  const complete = useCompleteOnboarding()
  if(!track){
    return null
  }
  return (
    <TitleWithSheet
      title={i18n.t('onboarding.track.summary.title')}
      subtitle={i18n.t('onboarding.track.summary.subtitle')}
    >
      <View style={[tw.flexGrow, tw.pT4, tw.pB2, tw.pL2]}>
        <View style={tw.flexGrow}>
          <TextField style={tw.mB6} label="Track Name" value={track.title} />
          <Label style={tw.mL2} label="Collaborators and Credits" />
          <TrackPermissions
            trackID={track.id}
            noModal
          />
          {attachment && (
            <>
              <Label style={[tw.mT4, tw.pX4]} label="Attachment" />
              <AttachmentSummary
                style={[tw.pL2]}
                attachment={attachment}
                noActions
              />
            </>
          )}
        </View>
        <Button style={tw.mX2} block title="Start using Reveel" onPress={complete} />
      </View>
    </TitleWithSheet>
  )
}
const wrap = (Comp) => Comp

const TrackOnboarding = () => {
  const tabs = [
    { key: 'trackNew' },
    { key: 'inviteCollabs' },
    { key: 'addCredits' },
    { key: 'addAttachment' },
    { key: 'trackSummary' },
  ]
  const [nav, setNav] = useState({
    index: 0,
    routes: tabs,
  })
  const [attachment, setAttachment] = useState()
  const onNext = useCallback(() => setNav((state) => ({ ...state, index: state.index + 1 })), [setNav])

  const renderScene = SceneMap({
    trackNew: useCallback(() => wrap(<CreateTrack onNext={onNext} />), [onNext]),
    inviteCollabs: useCallback(() => wrap(<InviteCollabs onNext={onNext} />), [onNext]),
    addCredits: useCallback(() => wrap(<AddCredits onNext={onNext} />), [onNext]),
    addAttachment: useCallback(() => wrap(<AddAttachment setAttachment={setAttachment} onNext={onNext} />), [onNext]),
    trackSummary: useCallback(() => wrap(<TrackSummary attachment={attachment} />), [attachment]),
  })
  return (
    <PageWrapper noScroll>
      <TabView
        renderTabBar={() => null}
        onIndexChange={(i) => setNav({ ...nav, index: i })}
        navigationState={nav}
        renderScene={renderScene}
        style={styles.container}
        swipeEnabled={false}
      />
    </PageWrapper>
  )
}

TrackOnboarding.propTypes = {

}
export default TrackOnboarding

const styles = StyleSheet.create({
  container: {
    ...Platform.select({
      native: {
        marginTop: 15,
        flex: 1,
      },
      web: {
        marginTop: 30,
        maxHeight: 600 - 30,
        margin: 10,
      },
    }),
  },
})
