import { useEffect, useCallback } from 'react'
import { useFocusEffect } from '@react-navigation/native'

const dependencyObject = []
const refreshOnInitObject = true

// TODO: replace these with useQuery which handles refocusing

const useRefreshScreen = (refreshAction, dependencies = dependencyObject, refreshOnInit = refreshOnInitObject) => {
  const calledBackRefreshAction = useCallback(() => {
    refreshAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)

  useFocusEffect(calledBackRefreshAction)

  useEffect(() => {
    if(refreshOnInit){
      calledBackRefreshAction()
    }
  }, [refreshOnInit, calledBackRefreshAction])
}
export default useRefreshScreen
