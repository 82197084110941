import React from 'react';
import { useLinkProps } from '@react-navigation/native'
import { Platform, Pressable, View } from 'react-native'

const LinkWrapper = ({ to, action, children, style, hoveredStyles, ...rest }) => {
  const { onPress, ...props } = useLinkProps({ to, action })
  const [isHovered, setIsHovered] = React.useState(false);

  if(Platform.OS === 'web'){
    // It's important to use a `View` or `Text` on web instead of `TouchableX`
    // Otherwise React Native for Web omits the `onClick` prop that's passed
    // You'll also need to pass `onPress` as `onClick` to the `View`
    // You can add hover effects using `onMouseEnter` and `onMouseLeave`
    return (
      <View
        onClick={onPress}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={[
          {
            transitionDuration: '150ms',
            opacity: isHovered ? 0.7 : 1
          },
          { flexShrink: 1, },
          isHovered && hoveredStyles,
          style,
        ]}
        {...props}
        {...rest}
      >
        {children}
      </View>
    )
  }

  return (
    <Pressable onPress={onPress} style={style} {...props} {...rest}>
      {children}
    </Pressable>
  )
}

export default LinkWrapper
