import React from 'react'
import { View, Text } from '@reveel/components/ui'
import { StyleSheet, ScrollView } from 'react-native'
import UserAvatar from 'react-native-user-avatar'
import Colors from '@reveel/constants/Colors';
import { MotiPressable, useMotiPressable } from '@motify/interactions'
import { MotiView } from '@motify/components';
import { tw } from 'react-native-tailwindcss';
import { Link } from '@react-navigation/native';
import frontRoutes from '../../../helpers/routes/front';


const AVATAR_SPACING_COEFF = 0.5

type IProps = {
  users: Array<{
    name: String,
    avatar_url: String,
    id: number,
  }>,
  size?: number,
  canNavigate?: boolean,
}

const SingleAvatar = ({ user, size }) => {
  const { name, src } = user
  const state = useMotiPressable(({ pressed, hovered }) => {
    'worklet'

    return {
      transform: [{translateY: pressed || hovered ? -size/3: 0}],
      zIndex: pressed || hovered ? 30 : 0,
    }
  }, [])
  const nameState = useMotiPressable(({ pressed, hovered }) => {
    'worklet'

    return {
      opacity: pressed || hovered ? 1 : 0,
    }
  }, [])
  return <MotiView 
    transition={{ type: 'timing' }}
    state={state}
    style={[styles(size).avatar, tw.relative]}
  >
    <Link to={frontRoutes.users.show(user.profile_id)}>
      <UserAvatar size={size} {...{ name, src }} />
      <MotiView
        state={nameState}
        style={[ tw.absolute, { left: size, pointerEvents: 'none', }, tw.bgWhite, tw.mL2, tw.pX2, tw.pY1, tw.roundedLg]}
      >
        <Text style={[{
          whiteSpace: 'nowrap',
        }]}>{user.name}</Text>
      </MotiView>
    </Link>
  </MotiView>
}

const UsersAvatarsStacked = React.memo(({ users, size = 25, canNavigate = false }: IProps) => (users.length > 0 && (
  <View style={[tw.flexRowReverse, tw.flexWrap, styles(size).contentContainerStyle]}>
    {users.map((user) => (
      canNavigate ? (
      <MotiPressable key={user.id}>
        <SingleAvatar user={user} size={size} />
      </MotiPressable>
      ) : (
        <UserAvatar size={size} name={user.name} src={user.avatar_url} style={styles(size).avatar}/>
      )
    ))}
  </View>
)))
const styles = (size) => StyleSheet.create({
  avatar: {
    marginLeft: -size * AVATAR_SPACING_COEFF,
    borderColor: Colors.white,
    borderWidth: 1,
    borderRadius: size / 2,
  },
  contentContainerStyle: {
    justifyContent: 'flex-end',
    paddingVertical: 5,
    paddingRight: size * AVATAR_SPACING_COEFF,
    paddingLeft:  size * AVATAR_SPACING_COEFF,
  },
})
export default UsersAvatarsStacked
