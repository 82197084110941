import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Text } from 'native-base'
import Colors from '@reveel/constants/Colors'

const OrComponent = () => (
  <View style={styles.container}>
    <Text style={styles.text}>OR</Text>
    <View style={styles.bar} />
  </View>
)

export default OrComponent

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  text: {
    backgroundColor: Colors.white,
    paddingLeft: 15,
    paddingRight: 15,
    color: Colors.lightGrey,
    fontWeight: 'bold',
    zIndex: 10,
  },
  bar: {
    position: 'absolute',
    width: '100%',
    height: 1,
    backgroundColor: Colors.lightGrey,
    top: 10,
  },
})
