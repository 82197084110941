import React from 'react'
import View from '@reveel/components/ui/View'
import i18n from 'i18n-js'
import Checkmark from '../../components/ui/Checkmark'
import ActionableItem from '../../components/core/ActionableItem'

const Invitations = ({ invitations, navigation }) => {
  const renderSingle = (invitation, notFirst) => {
    const {
      track_title, id, inviter, type,
    } = invitation
    const size = 17
    const leftIcon = () => <Checkmark size={size * 1.7} />

    const navigateTo = type === 'creative_session'
      ? ['AcceptInvitation', { ...invitation, id: invitation.invitable_id }]
      : ['TrackSubmitCreditsScreen', { id: invitation.invitable_id }]
    const props = {
      leftIcon,
      title: i18n.t(`banners.invitation.${type}.title`, { track_title }),
      description: i18n.t(`banners.invitation.${type}.description`, { name: inviter?.name }),
      buttonTitle: i18n.t(`banners.invitation.${type}.button`),
      testID: `todo-${id}`,
      notFirst,
      navigation,
      navigateTo,
    }
    return <ActionableItem key={`cs-todo-${id}`} {...props} />
  }
  return (
    <View testID="invitation">
      {invitations.map(renderSingle)}
    </View>
  )
}

export default Invitations
