import i18n from 'i18n-js'

import notifications from './notifications'
import tracks from './tracks'
import banners from './banners'
import creativeSessions from './creativeSessions'
import user from './user'
import system from './system'
import contributions from './contributions'
import attachments from './attachments'
import onboarding from './onboarding'
import splits from './splits'
import profiles from './profiles'
import placeholders from './placeholders'
import dashboard from './tracks.dashboard'

i18n.fallbacks = true
i18n.defaultLocale = "en"
i18n.translations = {
  en: {
    ...attachments,
    ...notifications,
    ...tracks,
    ...creativeSessions,
    ...banners,
    ...user,
    ...system,
    ...contributions,
    ...onboarding,
    ...splits,
    ...profiles,
    ...dashboard,
    ...placeholders,
  },
}
