import React from 'react'

import { Ionicons } from '@expo/vector-icons'
import Button from '@reveel/components/ui/Button'
import View from '@reveel/components/ui/View'
import { Text } from 'native-base'
import UserAvatar from 'react-native-user-avatar'

import { useChangePermission } from './useChangePermission'
import Colors from '@reveel/constants/Colors';
import { useAuth } from '@reveel/stores/useAuth';

export const PermissionUser = ({ trackID, user, participation: { id: participationID, admin }, style }) => {
  const { update } = useChangePermission(trackID, participationID)
  const { user: self } = useAuth()
  const isSelf = self.id === user.id
  const iconLeft = (
    <Ionicons
      name={admin ? 'person-circle' : 'person-circle-outline'}
      size={18}
      color={admin && !isSelf ? Colors.tintColor : Colors.iconGrey }
    />
  )
  return (
    <View style={[tw.flexRow, tw.itemsCenter, style]}>
      <UserAvatar name={user.real_name} src={user.avatar_url} />
      <Text style={[tw.mL2, tw.flexGrow]}>{user.name}{isSelf && ' (You)'}</Text>
      <Text style={[tw.textLightBlack, tw.mR1]}>roles: </Text>
      <Button
        title={admin ? ' Admin' : ' Collaborator'}
        onPress={() => !isSelf && update({ admin: !admin })}
        iconLeft={iconLeft}
        noChrome
        small
        disabled={isSelf}
        style={[tw.flexRow, tw.pX0]}
        textStyle={[admin && tw.fontBold, !admin && tw.textLightBlack]}
      />
    </View>
  )
}
