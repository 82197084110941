import routes from '@reveel/helpers/routes'
import { useAsync } from '@reveel/hooks/useRemoteState'
import { useQueryClient } from 'react-query'

import { PENDING_CONTRIBUTION_SUBMISSIONS, RUNNING } from '../../../constants/Status'
import { trackCreditKey } from '../../../screens/tracks/credits/useTrackCredits'

const useSubmitCreditsForTrack = ({ creativeSessionID, trackID, onSuccess }) => {
  const queryClient = useQueryClient()

  const { run, loading, errors } = useAsync({
    url: routes.api.contributions.submit(creativeSessionID),
    method: 'post',
    handleResponse: (data) => {
      onSuccess?.(data)
      queryClient.invalidateQueries(trackCreditKey(trackID))
      queryClient.invalidateQueries(`creativeSession-credits-${creativeSessionID}`)
    },
  })
  const onSubmit = ({ skill_ids, profile_id, ...profile_attributes }) => {
    run({
      credit: { skill_ids },
      profile_id,
      profile_attributes,
    })
  }
  const theProps = {
    onSubmit,
    buttonProps: {
      loading,
      title: 'Submit',
    },
    errors,
    i18nTitleKey: `creativeSessions.header.${RUNNING}.${PENDING_CONTRIBUTION_SUBMISSIONS}`,
  }
  return theProps
}

export default useSubmitCreditsForTrack
