import React from 'react';
import { Ionicons } from '@expo/vector-icons';
import Card from '@reveel/components/ui/Card';
import Colors from '@reveel/constants/Colors';
import Button from '@reveel/components/ui/Button';

import Image from '@reveel/../assets/images/home/royalty_locator.svg'
import { Linking } from 'react-native';
import track from '../../analytics/index';
import routes from '@reveel/helpers/routes';
import axios from 'axios';
import { useQuery } from 'react-query';

const useRoyaltyLocatorResults = () => {
  const key = ['royalty_locator_results'];
  const route = routes.api.user.auditSubmissionForm
  const { isLoading, error, data } = useQuery(key, async () => {
    try{
      const res = await axios.get(route)
      return res.data
    } catch(err){
      if(err.response.status === 404){
        return
      }
      throw err
    }
  })

  return {
    id: data?.audit_submission_form?.id,
    isLoading,
    error,
  }
}

const RoyaltyLocatorCard = ({ style, from }: { style?: any, from: string }) => {
  const { id } = useRoyaltyLocatorResults()
  const header = <Image height={100} />;
  const onPress = () => {
    track('Card Clicked', {
      action: 'visit_royalty_locator',
      from,
    })
    Linking.openURL(`https://royalties.reveel.id/locate-your-royalties${id ? `/${id}`: ''}?utm_source=track-product&utm_medium=product&utm_campaign=audit-acquisition&from=${from}`)
  }
  return <Card
    title={"Find missing income with the Royalty Locator"}
    actions={<Button
      title={"Find more Royalties"}
      onPress={onPress}
    />}
    header={header}
    style={style}
    align="center" />;
};

export default RoyaltyLocatorCard