import React from 'react'
import { View } from 'react-native'
import { useAsync } from '@reveel/hooks/useRemoteState'
import FormItems from '../../components/ui/Form'
import routes from '../../helpers/routes'
import {
  declaredContributorsCount, sessionType, trackTitle, venueName, startDate, endDate, notes, name,
} from '../../helpers/fields/creativeSessionFieldsList'
import { useAuth } from '../../stores/useAuth'

const SessionMetadata = ({
  creativeSession,
  setCreativeSession,
  setIntermediaryView,
}) => {
  const { run, loading, errors } = useAsync({
    url: routes.api.creativeSession.update(creativeSession.id),
    method: 'put',
    handleResponse: ({ data }) => {
      setCreativeSession(data)
      setIntermediaryView()
    },
  })
  const onSubmit = (data) => run({
    creative_session: {
      ...creativeSession,
      ...data,
      track_attributes: {
        ...creativeSession.track,
        ...data.track,
      },
    },
  })

  const fields = [
    trackTitle,
    name,
    declaredContributorsCount(creativeSession.participants.length),
    startDate,
    endDate,
    notes,
    venueName,
    sessionType,
  ].filter(Boolean)

  return (
    <View style={{ paddingTop: 30, paddingRight: 15, flex: 1 }}>
      <FormItems
        formObject={creativeSession}
        fields={fields}
        labelType="stackedLabel"
        i18nPrefix="creativeSessions.fields"
        onSubmit={onSubmit}
        errors={errors}
        loading={loading}
      />
    </View>
  )
}

export default SessionMetadata
