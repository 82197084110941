import React, { useState } from 'react'
import { TouchableOpacity } from 'react-native'
import ModalWithActions from './ui/ModalWithActions'

const ModalButton = ({
  children,
  style,
  trigger,
  content, // content is a function so that it only renders
  onWillOpen,
  ...otherProps
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const openModal = () => {
    onWillOpen?.()
    setModalOpen(true)
  }
  const closeModal = () => setModalOpen(false)
  const modal = (
    <ModalWithActions
      isVisible={modalOpen}
      closeModal={closeModal}
      {...otherProps}
    >
      {content(closeModal)}
    </ModalWithActions>
  )
  // deprecated API
  //
  return (
    <>
      {typeof trigger === 'function' && trigger(openModal)}
      <TouchableOpacity style={style} onPress={openModal}>
        {children}
      </TouchableOpacity>
      { modal }
    </>
  )
}

export default ModalButton
