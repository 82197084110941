/* eslint-disable react/display-name */
import React, { useEffect, useMemo } from 'react'

import Page from '@reveel/layout/Page'

import { useDesktop } from '../../helpers/MediaQueries'
import useRefreshScreen from '../../helpers/useRefreshScreen'
import { useAuth } from '../../stores/useAuth'
import useMyProfile from '../../stores/useMyProfile'
import ProfileScreen from '../ProfileScreen'
import useSkills from '../skills/useSkills'
import MoreButton from './MoreButton'
import Breadcrumb from '../../navigation/createScreenHeader/Breadcrumb';
import { ScreenTitle } from '@reveel/components/ScreenTitle';

const MyID = ({ navigation }) => {
  const { user, impersonating } = useAuth()
  const { myProfile, refreshMyProfile } = useMyProfile()
  const [skills] = useSkills()
  const displayedUser = useMemo(() => impersonating || user, [impersonating, user])

  const isDesktop = useDesktop()
  useEffect(() => {
    navigation.setOptions({
      headerRight: () => <MoreButton user={displayedUser} />,
      headerTitle: () => <Breadcrumb>
        <ScreenTitle title={["My", "Creator ID"]} />
      </Breadcrumb>
    })
  }, [displayedUser, navigation])

  useRefreshScreen(refreshMyProfile)

  if(!myProfile){
    return null
  }
  return (
    <Page fixNotScrolling>
      <ProfileScreen
        title={["My", "Creator ID"]}
        profile={{
          ...myProfile,
          skills,
        }}
      >
        {!isDesktop && <MoreButton user={user} />}
      </ProfileScreen>
    </Page>
  )
}
export default MyID
