import React from "react"

import SkillPillList from '@reveel/components/core/SkillPillList'
import { Text } from '@reveel/components/ui'
import Separator from '@reveel/components/ui/Separator'
import View from '@reveel/components/ui/View'
import UpdateProfile from '@reveel/components/updateProfile/UpdateProfile'
import Colors from "@reveel/constants/Colors"
import {
  ipi,
  labelCompanyContact,
  labelCompanyName,
  pro,
  publisherCompanyContact,
  publisherCompanyName,
  publisherIPI,
} from '@reveel/helpers/userFormatter'
import { SocialMedias } from "@reveel/screens/user/SocialMedia"
import { useAuth } from '@reveel/stores/useAuth'

import { isni, spotifyID, appleID } from '../../helpers/userFormatter'
import UserStatsBar from '../user/UserStatsBar'
import { Box } from './Box'
import { ClaimProfile, ReportErrorOnProfile } from './ProfileActions'
import { WithLabel } from './WithLabel'
import RoyaltyLocatorCard from "../home/RoyaltyLocatorCard"

const renderSkills = (skills) => <SkillPillList skills={skills} />

const ProfileScreen = ({ profile }) => {
  const { user: self } = useAuth()

  const isSelf = self?.id === profile.user_id

  if(!profile){
    return <Loading />
  }
  const hasContact = profile.social && Object.keys(profile.social).find((k) => profile.social[k]?.length > 0)
  const hasSkills = profile.skills?.length > 0
  
  return (
    <div style={styles.container}>
      <div style={styles.leftContainer}>
        {/* eslint-disable-next-line react-native/no-inline-styles */}
        <div style={{ ...styles.fullRow, position: 'relative' }}>
          <div style={styles.avatar}>
            <img alt="Avatar" style={styles.img} src={profile.avatar_url} />
          </div>
          <Box style={styles.profile}>
            <div style={styles.bio}>
              <View mb>
                <Text bold large>
                  {profile.name}
                  {__DEV__ && profile.id}
                </Text>
              </View>
              <View mb>
                <Text>
                  {profile.first_name}
                  {' '}
                  {profile.last_name}
                </Text>
              </View>
              {profile.biography?.length ? <Text smaller>{profile.biography}</Text> : null}
            </div>
            <Separator my-lg />
            <UserStatsBar style={styles.bio} user={profile} />
          </Box>
        </div>
        <Box title={i18n.t('profiles.show.songwriter_info.title')}>
          <View style={[tw.flexRow, tw.flexWrap]}>
            <WithLabel k="PRO" value={pro(profile)} style={{ flex: 1 }} />
            <WithLabel k="IPI" value={ipi(profile)} style={{ flex: 1 }} />
          </View>
        </Box>
        <Box title={i18n.t('profiles.show.publisher_info.title')}>
          <View style={[tw.flexRow, tw.flexWrap]}>
            <WithLabel
              k="Publisher"
              value={(
                <>
                  {publisherCompanyName(profile)}
                  {publisherCompanyContact(profile) && (
                  <Text style={tw.textSm}>
                    (
                    {publisherCompanyContact(profile)}
                    )
                  </Text>
                  )}
                </>
              )}
            />
            <WithLabel k="IPI" value={publisherIPI(profile)} style={{ flex: 'none' }} />
          </View>
        </Box>
        <Box title={i18n.t('profiles.show.relationships_info.title')}>
          <View style={[tw.flexRow, tw.flexWrap]}>
            <WithLabel k="Name" value={labelCompanyName(profile)} style={{ flex: 1 }} />
            <WithLabel k="Contact" value={labelCompanyContact(profile)} style={{ flex: 1 }} />
          </View>
        </Box>
        <Box title={i18n.t('profiles.show.artist_info.title')}>
          <View style={[tw.flexRow, tw.flexWrap]}>
            <WithLabel k="ISNI" value={isni(profile)} style={{ flex: 1 }} />
            <WithLabel k="Spotify ID" value={spotifyID(profile)} style={{ flex: 1 }} />
            <WithLabel k="Apple ID" value={appleID(profile)} style={{ flex: 1 }} />
          </View>
        </Box>
        {isSelf && <Box px style={styles["mb-2"]}><UpdateProfile /></Box>}
      </div>
      <div style={{ ...styles.rightContainer }}>
        {isSelf ?
          <RoyaltyLocatorCard from="creator-id" />
          : (profile.user_id && (
          <>
            <ReportErrorOnProfile profile={profile} />
            <ClaimProfile profile={profile} />
          </>
        ))}
        {hasSkills && (
          <Box title="Skills">
            <View>
              {renderSkills(profile.skills)}
            </View>
          </Box>
        )}
        {hasContact && (
          <Box title="Contact">
            <SocialMedias profile={profile} />
          </Box>
        )}
      </div>
    </div>
  )
}

export default ProfileScreen

const styles = ({
  container: {
    backgroundColor: Colors.lightBackground,
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    minHeight: '100%',
    gap: '20px',
    paddingTop: "40px",
  },
  leftContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '20px',
    gridAutoRows: 'min-content',
  },
  rightContainer: {
    display: 'grid',
    gridAutoRows: 'min-content',
    gridRowGap: '20px',
  },
  "mb-2": {
    gridGap: '20px',
  },
  fullRow: {
    gridColumn: '1 / 3',
  },
  profile: {
    marginLeft: "25%",
    position: 'relative',
    padding: "20px",
  },
  bio: {
    paddingLeft: '20px',

  },
  avatar: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    width: '25%',
  },
  img: {
    maxWidth: 'calc(100% + 20px)',
    height: 'auto',
    borderRadius: '8px',
  },
})
