import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import AuthenticatedNavigator from './AuthenticatedNavigator'
import AuthenticationNavigator from './AuthenticationNavigator'
import defaultScreenOptions from './defaultScreenOptions'
import { withAuth } from '../stores/useAuth'
import { useRefreshUser, useUpdateDevice } from '../helpers/onAppBoot'
import Loading from '../components/Loading';
import WebView from '../components/WebView';
import UserProfile from '@reveel/screens/user/UserProfile';
import createScreenHeader from './createScreenHeader'
import AcceptInvitationScreen from '../screens/AcceptInvitationScreen';
import { useDesktop } from '@reveel/helpers/MediaQueries';

const Stack = createStackNavigator()

const RootStack = ({ user, setUser, loading: userLoading }) => {
  // forcing contributions is on hold ftm
  // useForcePendingContribution(user)
  useRefreshUser(user, setUser)
  useUpdateDevice(user)
  const isDesktop = useDesktop()
  if(userLoading){
    return (<Loading />)
  }

  const isLoggedIn = user !== null
  return (
    <Stack.Navigator
      initialRouteName="Authentication"
      headerMode="screen"
      screenOptions={defaultScreenOptions({
        headerShown: false,
        header: createScreenHeader({ isDesktop }),
      })}
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {user
        ? renderAuthd()
        : renderAnon()}
      <Stack.Screen
        name="WebView"
        component={WebView}
        initialParams={{
        }}
        options={{
          headerShown: true,
        }}
      />
      <Stack.Screen
        name="UserProfile"o
        component={UserProfile}
        options={{
          noSidebar: !isLoggedIn,
          headerShown: true,
          displayTitle: ["Reveel", "Creator ID"],
        }}
      />
      <Stack.Screen name="AcceptInvitation" component={AcceptInvitationScreen}
        options={() => ({
          title: '',
          headerShown: false,
        })}
        mode="modal"
      />
    </Stack.Navigator>
  )

  function renderAuthd(){
    return (
      <Stack.Screen
        name="Authenticated"
        component={AuthenticatedNavigator}
        initialParams={{
          user,
        }}
      />
    )
  }
  function renderAnon(){
    return (
      <Stack.Screen
        name="Authentication"
        component={AuthenticationNavigator}
        initialParams={{
          user,
        }}
        options={{
          noSidebar: true,
        }}
      />
    )
  }
}

export default withAuth(RootStack)
