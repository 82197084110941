import React, { useMemo } from 'react'

import KeyboardSpacerIOS from '@reveel/components/KeyboardSpacerIOS'
import Loading from '@reveel/components/Loading'
import View from '@reveel/components/ui/View'
import Page from '@reveel/layout/Page'
import PropTypes from 'prop-types'

import Button from '../../../components/ui/Button'
import useTrackBreadcrumb from '../../../navigation/useTrackBreadcrumb'
import SplitsCandidates from './SplitsCandidates'
import SplitsForm from './SplitsForm'
import useSplitsForm from './useSplitsForm'
import { useAuth } from '@reveel/stores/useAuth';
import TrackPermissionsWrapper from '../TrackPermissions/index';

const TrackSplitsNew = ({ route, splitsType }) => {
  const { trackID } = route.params
  useTrackBreadcrumb(trackID, ['New', 'Split Sheet'])
  const {
    state,
    dispatch,
    loading,
    submit,
    submitting,
  } = useSplitsForm(trackID, { splitsType })

  const { allCollaborators, selectedIDs, splits, splitEqually } = state

  const selectedProfilesComplete = useMemo(() => (
    allCollaborators.filter(({ id }) => selectedIDs.includes(id))
  ), [allCollaborators, selectedIDs])
  const { user: { admin } } = useAuth()
  if(loading){
    return <Loading />
  }
  const total = splits.reduce((prev, { value }) => prev + value, 0)

  return (
    <Page
      sheet
      style={[tw.pT5]}
      renderAbove={() => <View style={[tw.flexRow, tw.justifyEnd]}>
        {admin && <TrackPermissionsWrapper
          trackID={trackID}
          renderTrigger={({ onOpen }) => <Button small title={"+ Add Collaborator"} onPress={onOpen} testID="addCollab" />}
        />}
      </View>}
    >
      <SplitsForm
        profiles={selectedProfilesComplete}
        splits={splits}
        dispatch={dispatch}
        total={total}
        splitEqually={splitEqually}
      />
      <SplitsCandidates
        testID="allCollaborators"
        profiles={allCollaborators}
        selected={selectedIDs}
        dispatch={dispatch}
      />
      <View style={[tw.flexGrow, tw.justifyEnd, tw.pX2, tw.pB2]}>
        <Button
          block
          title={i18n.t('splits.form.submit')}
          testID="SaveSplits"
          onPress={submit}
          disabled={Boolean(total === 0)}
          loading={submitting}
        />
      </View>
      <KeyboardSpacerIOS />
    </Page>
  )
}

TrackSplitsNew.propTypes = {
  trackID: PropTypes.number.isRequired,
}

export const SongwritingSplitsNew = (props) => <TrackSplitsNew splitsType="songwriting_splits" {...props} />
export const MasterPointsNew = (props) => <TrackSplitsNew splitsType="master_points" {...props} />

export default TrackSplitsNew
