import React from 'react'
import { View, StyleSheet, TouchableWithoutFeedback } from 'react-native'
import { Text } from '@reveel/components/ui'
import Colors from '@reveel/constants/Colors'
import Button from '../ui/Button'
import { useDesktop } from '../../helpers/MediaQueries'

const BannerCTA = ({
  onPress, renderIcon, Icon, title, subtitle, buttonText, style,
}) => (
  <TouchableWithoutFeedback
    onPress={onPress}
  >
    <View style={[
      styles.banner,
      { backgroundColor: useDesktop() ? Colors.white : Colors.lightBackground },
      style,
    ]}
    >
      <View style={styles.iconWrapper}>
        {
            typeof renderIcon === 'function' ? renderIcon({ width: 25, height: 25}) : 
            <Icon
              width={25}
              height={25}
            />
        }
      </View>
      <View style={styles.textContainer}>
        <Text style={styles.status}>
          {title}
        </Text>
        <Text note>{subtitle}</Text>
      </View>
      <Button onPress={onPress} small primary rounded style={styles.button} title={buttonText} />
    </View>
  </TouchableWithoutFeedback>
)

const styles = StyleSheet.create({
  banner: {

    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textContainer: {
    flexGrow: 1,
    flexShrink: 1,
  },
  iconWrapper: {
    width: 55,
    paddingHorizontal: 10,
    alignItems: 'center',
  },
  icon: {
    maxWidth: 40,
  },
  status: {
    marginBottom: 5,
  },
  button: {
  },
})

export default BannerCTA
