import React, { useEffect } from 'react'
import { SafeAreaView } from 'react-native'

import Loading from '../../components/Loading'
import useRemoteSkills from '../../stores/remoteSkills'
import groupSkillsByProfession from './groupSkillsByProfession'
import List from '../../components/ui/List'
import mapSkillsToListable from './mapSkillsToListable'
import { useAuth } from '../../stores/useAuth'
import useContributions from '../contributions/useContributions'
import useSkills from '../contributions/useContributions'

const useStates = {
  useContributions,
  useSkills,
}

const SkillsByProfessionsScreen = ({ navigation, route }) => {
  const useStore = useStates[route.params?.useState]
  const { user } = useAuth()
  const [{
    skills,
    setSearchQuery,
    isLoading,
    hasQuery,
  }] = useRemoteSkills([])

  const [selectedSkills, toggleSkill] = useStore()
  useEffect(() => {
    navigation.setOptions({
      headerLeft: undefined,
    })
  }, [])
  if(isLoading){
    return <Loading />
  }
  let data
  if(hasQuery){
    data = mapSkillsToListable(skills, [selectedSkills, toggleSkill])
  }else{
    data = groupSkillsByProfession({
      skills, navigation, route, user,
    })
  }
  return (
    <SafeAreaView style={tw.flexGrow}>
      <List
        data={data}
        onSearch={setSearchQuery}
        withBorder={false}
      />
    </SafeAreaView>
  )
}

export default SkillsByProfessionsScreen
