import React from 'react'
import { StyleSheet } from 'react-native'
import View from '@reveel/components/ui/View'
import { Ionicons } from '@expo/vector-icons'
import Colors from '@reveel/constants/Colors'
import { useDesktop } from '../../helpers/MediaQueries'
import { useNavigation } from '@react-navigation/native';
import { Pressable } from 'react-native';

const HomeIcon = () => {
  const navigation = useNavigation()
  return <Pressable onPress={() => navigation.navigate('Home')}>
    <Ionicons name="home" size={24} color={Colors.iconGrey} />
  </Pressable>
}
const Breadcrumb = ({ children, mobileShow }) => {
  const realChildren = children.filter(Boolean)
  if(!useDesktop()){
    if (!realChildren.length){
      return children
    }
    return realChildren[mobileShow === undefined ? realChildren.length - 1 : mobileShow]
  }
  const separator = <Ionicons style={styles.separator} name="chevron-forward" size={24} color={Colors.iconGrey} />
  const displayedEntries = [<HomeIcon />, ...children]
  return (
    <View row>
      {displayedEntries.map((curr, i) => {
        return (
          <React.Fragment key={i}>
            {curr}
            {(displayedEntries[i + 1]) && separator}
          </React.Fragment>
        )
      })}
    </View>
  )
}

export default Breadcrumb

const styles = StyleSheet.create({
  separator: {
    marginHorizontal: 20,
    minWidth: 20
  },
})
