import React from 'react'
import { TouchableOpacity, StyleSheet, View } from 'react-native'
import { Text, Icon } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import SearchableDropdown from '../SearchableDropdown'

import useRemoteSkills from '../../stores/remoteSkills'

const SkillsDropdown = ({
  onSelect,
  style,
  selectedSkills = [],
  showBrowseAll = true,
  placeholder = "Search Skills"
}) => {
  const navigation = useNavigation()
  const [{ skills, setSearchQuery }] = useRemoteSkills({ flattened: true })
  // deprecated?
  const browseAllSkills = showBrowseAll && (
  <TouchableOpacity
    style={styles.browse}
    onPress={() => navigation.push('ContribSkillProfessions')}
  >
    <Text small bold>Browse all skills &nbsp;</Text>
    <Icon style={{ fontSize: 14 }} name="ios-arrow-forward" />
  </TouchableOpacity>
  )

  return (
    <View style={style}>
      <SearchableDropdown
        items={skills}
        selected={selectedSkills}
        onSearch={setSearchQuery}
        onSelect={onSelect}
        placeholder={placeholder}
        testID="SearchSkills"
      />
      { browseAllSkills }
    </View>
  )
}

const styles = StyleSheet.create({
  browse: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
    alignItems: 'center',
    marginTop: 5,
  },
})

export default SkillsDropdown