import { useState } from 'react'

import { useAuth } from '@reveel/stores/useAuth'
import axios from 'axios'

import { emailField, firstNameField, lastNameField, passwordConfirmationField, passwordField } from '../../../helpers/fields/userFieldsList'
import processErrors from '../../../helpers/processErrors'
import routes from '../../../helpers/routes'

const emailAndPasswordFields = [
  emailField, passwordField, passwordConfirmationField,
]

const useSignupForm = () => {
  const { initialQueryParams: { src, rev, ref }, setUser } = useAuth()
  const [errors, setErrors] = useState()
  const [loading, setLoading] = useState(false)

  const signUp = async (userDetails) => {
    setErrors(undefined)
    setLoading(true)
    try{
      const { data } = await axios.post(routes.api.user.signUp, {
        user:
        {
          ...userDetails,
          signed_up_from: src,
        },
        rev,
        ref,
      })
      axios.defaults.headers.common["X-AUTH-TOKEN"] = data.auth_token
      setUser(data)
    }catch(err){
      setErrors(processErrors(err))
    }
    setLoading(false)
  }

  const formProps = {
    errors,
    loading,
    floatingLabels: true,
    buttonContainerStyle: { marginTop: 50 },

  }
  return {
    formProps,
    fieldsByStep: [
      [
        firstNameField,
        lastNameField,
      ],
      emailAndPasswordFields,
    ],
    signUp,
  }
}

export default useSignupForm
