import React from 'react'
import { Pressable, StyleSheet } from 'react-native'
import View from '@reveel/components/ui/View'
import { Text } from '@reveel/components/ui'
import Button from '@reveel/components/ui/Button'
import Colors from '@reveel/constants/Colors'
import { useNavigation } from '@react-navigation/native'
import PropTypes from 'prop-types'
import Checkmark from '../ui/Checkmark'

const ActionableItem = ({
  navigation: _nav, notFirst, bordered, testID, navigateTo, size = 17,
  leftIcon = () => <Checkmark size={size * 1.7} />,
  title, description, buttonTitle,
  onPress,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigation = _nav || useNavigation()
  const onPress_ = onPress || (navigateTo && (() => navigation.navigate(...navigateTo)))

  const secondLine = (typeof description === "function") ? description : () => <Text note>{description}</Text>
  return (
    <Pressable
      onPress={onPress_}
      testID={testID}
    >
      <View style={[styles.container, notFirst && styles.notFirst]}>
        {leftIcon(styles.leftIcon)}
        <View style={styles.body}>
          <Text>{title}</Text>
          {secondLine()}
        </View>
        <Button onPress={onPress_} title={buttonTitle} xs bordered={bordered} />
      </View>
    </Pressable>
  )
}

ActionableItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  buttonTitle: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  leftIcon: PropTypes.element,
  navigateTo: PropTypes.arrayOf(PropTypes.any),
}

export default ActionableItem

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 15,
    borderTopColor: undefined,
    borderTopWidth: 0,
  },
  leftIcon: {
    height: 30,
  },
  notFirst: {
    borderTopColor: Colors.borderGrey,
    borderTopWidth: 1,
  },
  body: {
    flex: 1,
    paddingHorizontal: 10,
    marginLeft: 5,
  },
})
