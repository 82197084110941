import { useEffect } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'

const runEveryXTime = (callback, name, askEvery) => {
  const runCallbackIfNeeded = async () => {
    if(askEvery === undefined){
      return callback()
    }
    const visitsCountKey = `@MR:visit-count----${name}`
    const oldCountKey = await AsyncStorage.getItem(visitsCountKey)
    const visitsCount = parseInt(oldCountKey || 0)

    if(askEvery.includes(visitsCount)){
      callback()
    }

    if(Array.isArray(askEvery)){
      const largest = Math.max(...askEvery)
      if(visitsCount <= largest){
        AsyncStorage.setItem(visitsCountKey, `${visitsCount + 1}`)
      }
    }
  }

  runCallbackIfNeeded()
}
export default runEveryXTime
