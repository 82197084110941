import React, { useCallback, useEffect } from 'react'

import routes from '../../helpers/routes'
import ResolutionOptions from './ResolutionOptions'
import ChatRoom from '../../components/ChatRoom'
import ContributionBanner from './ContributionBanner'
import useTrackBreadcrumb from '../../navigation/useTrackBreadcrumb'
import { PENDING_CONFLICT_RESOLUTION } from '../../constants/Status';

const ValidationResolutionChat = ({ navigation: { setParams, pop } , route }) => {
  // TODO: fetch these from server
  const contribution = route.params?.contribution
  const user = route.params?.user
  const creativeSession = route.params?.creativeSession

  useTrackBreadcrumb(route.params?.trackID, ['Resolve', 'Conflict'])

  const processMessage = useCallback((m) => {
    if(m.key === 'contribution.update'){
      setParams({ contribution: m.contribution })
    }
  }, [setParams])

  useEffect(() => {
    if(contribution.status !== PENDING_CONFLICT_RESOLUTION){
      pop()
    }
  }, [contribution, pop])
  return (
    <>
      <ContributionBanner contribution={contribution} user={user} creativeSession={creativeSession}>
        <ResolutionOptions contribution={contribution} user={user} creativeSession={creativeSession} />
      </ContributionBanner>
      <ChatRoom
        allMessagesUrl={routes.api.contributions.messages(contribution.id)}
        submitMessageUrl={routes.api.contributions.messages(contribution.id)}
        channelType="ContributionChannel"
        channelId={contribution.id}
        processMessage={processMessage}
      />
    </>
  )
}

ValidationResolutionChat.navigationOptions = ({ navigation }) => ({
  title: route.params?.title,
})
export default ValidationResolutionChat
