import React from 'react'

import ScreenTitle from '../../../components/ScreenTitle'
import { locationField, phoneNumberField } from '../../../helpers/fields/userFieldsList'
import UserUpdateHOC from '../../user/UserUpdateHOC'
import { Skip } from './index'

export const OnboardingLocation = ({ onNext }) => (
  <UserUpdateHOC
    renderHeader={() => <ScreenTitle title={i18n.t('onboarding.location.title')} />}
    fields={[locationField, phoneNumberField]}
    onSuccess={onNext}
    renderAfterButton={() => <Skip onPress={onNext} />}
    withKeyboardSpacer
    withScrollView={false}
    buttonText="Continue"
  />
)
