import React, { useState } from 'react'
import PageWrapper from '@reveel/screens/onboarding/PageWrapper'
import LoginForm from './LoginForm'
import SignupForm from './SignupForm'

const logo = require('app/assets/images/logo_white.png')

const AuthenticationScreen = () => {
  const [showLogin, setShowLogin] = useState(false)
  const switchView = (loginState) => setShowLogin(loginState)
  return (
    <PageWrapper>
      {showLogin
        ? <LoginForm switchView={() => switchView(false)} />
        : <SignupForm switchView={() => switchView(true)} />}
    </PageWrapper>
  )
}
export default AuthenticationScreen
