import ActionCable from '@reveel/vendors/actioncable'
import Reactotron from '@reveel/vendors/reactotron'
import routes from './routes'

let cable

export const openWebSocket = (channel, id, { received, user }) => {
  const { auth_token } = user
  cable = cable || ActionCable.createConsumer(routes.api.websocket(auth_token))
  console.log('opening channel for ', channel, id)
  const thisSubscription = cable.subscriptions.create({ channel, id }, {
    received: (messages) => {
      if(__DEV__ && Reactotron){
        Reactotron.log(`wsReceived from ${channel}, ${id}`, messages)
      }
      if(received){
        received(messages)
      }
    },
  })
  return () => {
    console.log('closing channel for ', channel, id)
    thisSubscription.unsubscribe()
    closeWebSocket()
  }
}

export const closeWebSocket = () => {
  if(!cable.subscriptions.subscriptions.length){
    cable.subscriptions.consumer.disconnect()
  }
}
