import { StyleSheet } from 'react-native'

export const markdownStyles = StyleSheet.create({
  bold: {
    fontWeight: "bold",
  },
  italic: {
    fontStyle: "italic",
  },
  strikethrough: {
    textDecorationLine: "line-through",
  },
})
const boldPattern = /(\s|^)\*([^*]|\\\*)*[^\\]\*/gm
const italicPattern = /_([^*|_])*[^\\]_/gm
const strikethroughPattern = /~([^*|~])*[^\\]~/gm

const renderBoldText = (matchingString, matches) => {
  const match = matchingString.match(boldPattern)
  return `${match[0].replace(/\*(.*)\*/, "$1")}`
}

const renderItalicText = (matchingString, matches) => {
  const match = matchingString.match(italicPattern)
  return `${match[0].replace(/_(.*)_/, "$1")}`
}

const renderStrikethroughText = (matchingString, matches) => {
  const match = matchingString.match(strikethroughPattern)
  return `${match[0].replace(/-(.*)-/, "$1")}`
}

export const parsedTextArray = [
  {
    // Bold (matching asterisks)
    pattern: boldPattern,
    style: markdownStyles.bold,
    renderText: renderBoldText,
  },
  {
    // Italic (matching underscores)
    pattern: italicPattern,
    style: markdownStyles.italic,
    renderText: renderItalicText,
  },
  {
    // strikethrough (matching dashes)
    pattern: strikethroughPattern,
    style: markdownStyles.strikethrough,
    renderText: renderStrikethroughText,
  },
]
