import { useState } from 'react'

import routes from '@reveel/helpers/routes'
import { useAsync } from '@reveel/hooks/useRemoteState'
import { useAuth } from '@reveel/stores/useAuth'

import track from '../../analytics/index'
import { useQueryClient } from 'react-query';
import { splitsParticipantsKey } from '../../screens/tracks/splits/useSplitsForm';

const useInvitees = (creativeSession, { onSuccess }) => {
  const [invitees, setInvitees] = useState([])
  const removeInvitee = (target) => setInvitees((curr) => curr.filter((i) => (i.id !== target.id) || (i.email !== target.email)))
  const addInvitee = (target) => setInvitees((curr) => [...curr, target])

  const { user } = useAuth()
  const joinUrl = [routes.front.absolute(routes.front.creativeSessions.join(creativeSession.track_id, creativeSession.id)), `?ref=${user.id}&rev=el&src=${creativeSession.id}`].join('')
  const queryClient = useQueryClient()

  const { run, refreshing } = useAsync({
    url: routes.api.creativeSession.sendInvitations(creativeSession.id),
    method: 'post',
    handleResponse: ({ data }) => {
      onSuccess()
      queryClient.invalidateQueries(splitsParticipantsKey(creativeSession.track_id))
      track('Sent Invitation', {
        channel: 'email',
        to_registered_user: data.to_registered_user,
        track_id: creativeSession.track_id,
        invited_to: creativeSession.proxy ? 'track' : 'session',
      })
    },
  })

  const canSend = invitees.length > 0
  const onSend = () => run({
    invitations: {
      invitees,
      url: joinUrl,
    },
  })

  return {
    invitees,
    addInvitee,
    removeInvitee,
    onSend,
    canSend,
    loading: refreshing,
    url: joinUrl,
  }
}

export default useInvitees
