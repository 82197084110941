import React from 'react'

import { useNavigation } from '@react-navigation/native'
import FormItems from '@reveel/components/ui/Form'
import View from '@reveel/components/ui/View'
import { url, name, description, creativeSession, trackID } from '@reveel/helpers/fields/attachmentFieldsList'
import routes from '@reveel/helpers/routes'
import { useAsync } from '@reveel/hooks/useRemoteState'
import PropTypes from 'prop-types'

import useTrackBreadcrumb from '../../../navigation/useTrackBreadcrumb'
import Page from '@reveel/layout/Page';

const defaultFields = [
  url,
  name,
  description,
  // creativeSession,
  trackID,
]
const AttachmentNewScreen = ({ route }) => (
  <AttachmentNewForm trackID={route.params.trackID} />
)

export const AttachmentNewForm = ({
  trackID,
  onSuccess,
  formProps,
  style,
  fields = defaultFields,
}) => {
  const navigation = useNavigation()
  useTrackBreadcrumb(trackID, ['Add', 'Attachment'])
  const { run, loading, errors } = useAsync({
    method: 'post',
    url: routes.api.tracks.attachments.index(trackID),
    handleResponse: ({ data: { attachment } }) => {
      if(onSuccess){
        onSuccess(attachment)
        return
      }
      navigation.replace('AttachmentShow', { trackID, attachmentID: attachment.id })
    },
  })
  const onSubmit = (attachment) => {
    const { creative_session, ...attachmentProps } = attachment
    run({
      attachment: {
        ...attachmentProps,
        creative_session_id: creative_session?.id,
      },
    })
  }
  const initialAttachment = {
    track_id: trackID,
  }
  return (
    <Page sheet style={[tw.pT8, style]}>
      <FormItems
        {...{
          onSubmit,
          loading,
          errors,
          fields,
        }}
        formObject={initialAttachment}
        buttonText={i18n.t('attachments.ui.new.save')}
        withKeyboardSpacer
        {...formProps}
        style={tw.pT4}
      />
    </Page>
  )
}

export default AttachmentNewScreen
