import React from 'react'
import * as Icon from '@expo/vector-icons'

import { StyleSheet, View } from 'react-native'
import Colors from '../constants/Colors'

type TabBarIconProps = {
  withBadge?: boolean, 
  name: typeof Icon.Ionicons['name'],
  focused?: boolean, 
  color?: string,
}

const TabBarIcon = ({
  withBadge, name, focused, color,
}: TabBarIconProps) => {
  const badge = withBadge && <View style={styles.badge} />
  return (
    <View>
      { badge }
      <Icon.Ionicons
        name={name}
        size={26}
        style={{ marginBottom: -3 }}
        color={color || (focused ? Colors.tabIconSelected : Colors.lightBlack)}
      />
    </View>
  )
}
export default TabBarIcon

const styles = StyleSheet.create({
  badge: {
    width: 10,
    height: 10,
    backgroundColor: 'red',
    borderRadius: 5,
    position: 'absolute',
    right: -2,
    top: 3,
  },
})
