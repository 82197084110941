import React from 'react'
import { StyleSheet } from 'react-native'

import AddCreditsButton from '@reveel/components/credits/AddCreditsButton'
import { Text } from '@reveel/components/ui'
import { useTrack } from '@reveel/hooks/queries/track'
import i18n from 'i18n-js'

import FeatureCTA from '../../../components/placeholders/empty/FeatureCTA'
import View from '../../../components/ui/View'
import CreditsActions from './CreditsActions'
import Button from '@reveel/components/ui/Button';

const EmptyCredits = ({ trackID }) => {
  const { track } = useTrack(trackID)

  return (
    <FeatureCTA
      i18nPrefix="credits"
      button={(
        <AddCreditsButton
          trackID={trackID}
          creativeSessionID={track?.proxy_id}
          renderTrigger={({ onOpen }) => (
            <Button
              title={i18n.t('placeholders.credits.empty.cta')}
              testID="addCredits"
              onPress={onOpen}
            />
          )}
        />
)}
    />
  )
}

export default EmptyCredits

const styles = StyleSheet.create({
  container: {
    marginVertical: 20,
  },
})
