const trackMetadataFieldsList = {
  track_information: [
    {
      name: 'main_artist',
      label: 'Track Main Artist',
    },
    {
      name: 'featured_artists',
      label: 'Track Featured Artists',
    },
    {
      name: 'title',
      label: 'Track Title',
    },
    {
      name: 'recorded_at',
      label: "Recorded At",
    },
    {
      name: 'country',
      label: "Country",
    },
    {
      name: 'isrc',
      label: "Track ISRC",
    },
    {
      name: 'upc',
      label: "UPC Code",
    },
    {
      name: 'recording_year',
      label: "Recording Year",
      placeholder: '2021',
    },
    {
      name: 'duration',
      label: "Duration",
      placeholder: '3:34',
    },
  ],
  project_information: [
    {
      name: 'project.id',
      inputProps: {
        type: 'hidden',
      },
    },
    {
      name: 'project.main_artist',
      label: 'Main Artist',
    },
    {
      name: 'project.title',
      label: 'Project Title',
    },
    {
      name: 'project.label',
      label: 'Project Label',
    },
    {
      name: 'project.internal_id',
      label: 'Project Internal ID',
    },
  ],
  composition_information: [
    {
      name: 'work_title',
      label: 'Work Title',
    },
    {
      name: 'iswc',
      label: 'Work ISWC',
    },
    {
      name: 'work_creation_date',
      label: 'Creation Date',
      inputProps: {
        type: 'date',
      },
    },
    {
      name: 'tunecode',
      label: "Tunecode",
    },
  ],
}
export default trackMetadataFieldsList
