import React from 'react';
import i18n from 'i18n-js';
import { StyleSheet } from 'react-native';
import Collapsible from 'react-native-collapsible';
import axios from 'axios';

import routes from '../../helpers/routes';
import { Text } from '@reveel/components/ui';
import View from '@reveel/components/ui/View';
import { uri } from '@reveel/utils/regexp';
import SessionsList from '../../components/core/SessionsList';
import Switch from '../../components/ui/Switch';

const sessionSelectAccessory = (props) => ({ input, ...chatProps }) =>
  uri.test(input)
    ? (ref) => <AttachToSessions ref={ref} {...props} input={input} {...chatProps} />
    : null;
export default sessionSelectAccessory;

export class AttachToSessions extends React.Component{
  state = {
    attachToSession: false,
    selectedSession: undefined,
  }
  constructor(){
    super()
    this.onSubmit = this.onSubmit.bind(this)
  }
  async onSubmit(messages, super_){
    const { selectedSession, attachToSession } = this.state
    if (!selectedSession || !attachToSession){
      super_()
      return
    }
    const [message] = messages
    const url = uri.exec(message.text)[0]
    try{
      const { data } = await axios.post(routes.api.creativeSession.attachment.create(selectedSession.id), {
        attachment: { url },
        message
      })
    } catch(err) {
      console.log(err)
    }
  }
  render(){
    const toggleList = () => {
      this.setState({
        attachToSession: !this.state.attachToSession,
      })
    }

    const selectSession = (selectedSession) => {
      if (this.state.selectedSession && selectedSession.id === this.state.selectedSession.id){
        this.setState({ selectedSession: undefined })
        return
      }
      this.setState({ selectedSession })

    }

    const selectedArray = this.state.selectedSession ? [this.state.selectedSession] : []
    return <View style={styles.container}>
      <View style={styles.prompt}>
        <Text>{i18n.t('creativeSessions.ui.file.inputPrompt')}</Text>
        <Switch onValueChange={toggleList} value={this.state.attachToSession} small/>
      </View>
      <Collapsible collapsed={!this.state.attachToSession} style={styles.list}>
        <SessionsList inverted={true} trackID={this.props.track.id} onPress={selectSession} selected={selectedArray}/>
      </Collapsible>
    </View>;
  }
};

const styles = StyleSheet.create({
  prompt: {
    paddingVertical: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  list: {
    maxHeight: 160,
  }
})