import React, { useEffect } from 'react'

import { useAsync } from '@reveel/hooks/useRemoteState'
import Running from './RunningSession'
import { RUNNING, PAST } from '../../../constants/Status'
import routes from '../../../helpers/routes'
import SessionContextMenu from '../SessionContextMenu'

const PastOrRunning = {}

// Adding a past session currently shares the flow with a Running session
// at the exception that the 'next' button goes to ConfirmDetails, while
// the other straight POSTs to the server

PastOrRunning.screen = ({
  setCreativeSession, creativeSession, setIntermediaryView, navigation, ...props
}) => {
  const { status } = creativeSession
  if(status === RUNNING){
    const { can: { end: canEnd } } = creativeSession
    var loading = false
    var onNext = canEnd && (() => setIntermediaryView('confirmEnd'))
  }
  // this works for both past and ghost sessions
  else if(status === PAST){
    var { loading, run } = useAsync({
      url: routes.api.creativeSession.finishAddingPast(creativeSession.id),
      method: 'put',
      handleResponse: ({ data: newCreativeSession }) => {
        setCreativeSession(newCreativeSession, { overwrite: true })
      },
    })
    const declared_contributors_count = creativeSession.participants.length + creativeSession.invitations.length
    var onNext = async () => run({
      creative_session: {
        declared_contributors_count,
      },
    })
  }

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => <SessionContextMenu setIntermediaryView={setIntermediaryView} creativeSession={creativeSession} />,
      headerLeft: undefined, // return back to default when navigating out of an intermediaryView
    })
  }, [])

  return (
    <Running
      creativeSession={creativeSession}
      setIntermediaryView={setIntermediaryView}
      onNext={onNext}
      loading={loading}
      {...props}
    />
  )
}

export default PastOrRunning
