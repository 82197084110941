import React from 'react'

import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'

const newProfileHeader = {
  renderCustom: ({ object }) => {
    const hasInfo = object.last_name || object.first_name
    const text = hasInfo ? `Creating profile for ${[object.first_name, object.last_name].filter(Boolean).join(' ')}` : "Enter details for a new profile:"
    return (
      <View style={[tw.mY4]}>
        <Text style={tw.fontBold}>{text}</Text>
      </View>
    )
  },
}

export default newProfileHeader
