import React from 'react'
import { PropTypes } from 'prop-types'
import { StyleSheet, ScrollView, Platform } from 'react-native'
import Colors from '@reveel/constants/Colors'
import { useDesktop } from '@reveel/helpers/MediaQueries'
import View from '@reveel/components/ui/View'

const Page = ({ style = {}, renderAbove, sheet, children, contentContainerStyle }) => {
  const isDesktop = useDesktop()
  return (
    <View style={[
      styles.container,
      style,
    ]}>
      <div style={{ ...(isDesktop ? styles.desktop : {}) }}>
        {renderAbove?.()}
      </div>
      <ScrollView
        style={[
          sheet && styles.sheet,
          contentContainerStyle,
          isDesktop ? styles.desktop : styles.mobile,
          {
            overflowY: 'auto',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
          },
        ]}
      >
        {children}
      </ScrollView>
    </View>
  )
}

Page.propTypes = {
  sheet: PropTypes.bool,
  renderAbove: PropTypes.func,
  style: PropTypes.oneOf([PropTypes.array, PropTypes.object]),
}
export default Page

const styles = ({
  container: {
    backgroundColor: Colors.lightBackground,
    flex: 1,
    paddingHorizontal: 10,
  },
  contentContainer: {
    paddingTop: 10,
    flex: 1,
  },
  sheet: {
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    backgroundColor: Colors.white,
    overflow: 'hidden',
    marginTop: 15,
  },
  mobile: {
    flex: 1,
    paddingBottom: 64
  },
  desktop: {
    paddingHorizontal: 40,
    width: '100%',
    maxWidth: 1020,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})
