import React from 'react'
import {
  Platform, Button, StyleSheet, TouchableOpacity,
} from 'react-native'
import { Text } from 'native-base'
import * as Icon from '@expo/vector-icons'
import Colors from '../constants/Colors'

const HeaderRightButton = ({ color = Colors.tintColor, ...props }) => (Platform.OS === 'android' ? (
  <TouchableOpacity
    onPress={props.onPress}
    {...props}
  >
    <Text style={[styles.androidText, { color: props.disabled ? Colors.disabledText : color }]}>{props.title}</Text>
  </TouchableOpacity>
)
  : <Button {...props} color={color} style={[styles.button]} />)

const styles = StyleSheet.create({
  androidText: {
    paddingRight: 10,
  },
  button: {
    backgroundColor: 'white',
  },
})

export default HeaderRightButton
