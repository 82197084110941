// inspired by https://usehooks.com/useLocalStorage/
import { useState, useEffect, useCallback } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'

const usePersistedState = (key, initialValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(initialValue)

  useEffect(() => {
    (async () => {
      try{
        const item = await AsyncStorage.getItem(key)
        if(item){
          setStoredValue(JSON.parse(item))
        }
      }catch(error){
        console.log(error)
      }
    })()
  }, [])

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback((value) => {
    try{
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      AsyncStorage.setItem(key, JSON.stringify(valueToStore))
    }catch(error){
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }, [])

  return [storedValue, setValue]
}
export default usePersistedState
