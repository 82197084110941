import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet } from 'react-native';
import View from '@reveel/components/ui/View';
import { useDesktop } from '@reveel/helpers/MediaQueries';
import TrackArtwork from './TrackArtwork';
import { tw } from 'react-native-tailwindcss';
import { Text } from '@reveel/components/ui';

const TrackMainDetails = ({ track, style }) => {
  const isDesktop = useDesktop();
  return (
    <View style={[tw.flexRow, !isDesktop && tw.mB2, style]}>
      <TrackArtwork trackID={track?.id} style={tw.mR4} size={isDesktop ? 120 : 80} />
      <View>
        <Text style={[tw.text2xl, tw.textPrimary, tw.fontBold, tw.mB1]}>{track?.title}</Text>
        <Text style={[tw.textLg, tw.fontBold, tw.mB1]}>{track?.main_artist}</Text>
        {track?.featured_artists && (
          <Text style={[tw.textLg]}>
            feat.&nbsp;
            {track?.featured_artists}
          </Text>
        )}
      </View>
    </View>
  )
}

export default TrackMainDetails
