import React, { useEffect, useState } from 'react'
import {
  TouchableOpacity, StyleSheet, View, ScrollView, Platform,
} from 'react-native'
import { Text, Button } from 'native-base'
import * as Animatable from 'react-native-animatable'
import axios from 'axios'
import i18n from 'i18n-js'
import withNavigation from '@reveel/helpers/withNavigation'

import Colors from '@reveel/constants/Colors'

import Warning from 'app/assets/images/warning.svg'
import WarningGreen from 'app/assets/images/warning-green.svg'
import MissingParticipant from 'app/assets/images/avatar_dark.svg'
import Record from 'app/assets/images/record.svg'
import { captureException } from 'sentry-expo'
import { viewStyles } from '@reveel/components/ui/View'
import BannerCTA from '../../components/core/BannerCTA'
import { useDesktop } from '../../helpers/MediaQueries'
import routes from '../../helpers/routes'
import {
  PENDING_CONFLICT_RESOLUTION, PENDING_VALIDATION, RUNNING, PENDING_MISSING_PARTICIPANTS,
} from '../../constants/Status'

const icon = (status) => {
  switch (status){
    case PENDING_CONFLICT_RESOLUTION:
      return Warning
    case PENDING_MISSING_PARTICIPANTS:
      return MissingParticipant
    case RUNNING:
      return Record
    case PENDING_VALIDATION:
    default:
      return WarningGreen
  }
}
function PendingValidationBanner({ navigation, trackID }){
  const [sessions, setSessions] = useState([])
  const isDesktop = useDesktop()
  useEffect(() => {
    axios.get(routes.api.tracks.creativeSessionsPendingValidation(trackID))
      .then(({ data }) => setSessions(data))
      .catch(captureException)
  }, [trackID])

  const renderSingleSessionBanner = (creativeSession) => {
    const { status } = creativeSession
    const MIcon = icon(status)
    const onPress = () => navigation.navigate('Session', { trackID: creativeSession.track_id, id: creativeSession.id })
    return (
      <Animatable.View animation="slideInDown" easing="ease-out-quint" key={`${creativeSession.id}`}>
        <BannerCTA
          onPress={onPress}
          Icon={MIcon}
          title={i18n.t(`banners.${status}.title`)}
          subtitle={i18n.t(`banners.${status}.details`)}
          buttonText={i18n.t(`banners.${status}.button`)}
        />
      </Animatable.View>
    )
  }
  if(!sessions.length){
    return null
  }
  return (
    <ScrollView
      contentContainerStyle={isDesktop ? [styles.desktopWrapper] : styles.mobileWrapper}
      style={[styles.wrapper, viewStyles['shadow-b']]}
    >
      {sessions.map(renderSingleSessionBanner)}
    </ScrollView>
  )
}

export default React.memo(withNavigation(PendingValidationBanner))

const styles = StyleSheet.create({
  wrapper: {
    flexGrow: 0,
    maxHeight: 200,
  },
  mobileWrapper: {
    backgroundColor: Colors.lightBackground,
  },
  desktopWrapper: {
    backgroundColor: Colors.white,
  },
})
