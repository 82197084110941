import Colors from '@reveel/constants/Colors'
import React from 'react'
import { StyleSheet, SafeAreaView, ScrollView } from 'react-native'

const PageWrapper = ({ children, noScroll }) => (
  <SafeAreaView style={styles.container}>
    { noScroll ? children : (
      <ScrollView
        keyboardShouldPersistTaps="always"
        contentContainerStyle={styles.container}
      >
        {children}
      </ScrollView>
    )}
  </SafeAreaView>
)

PageWrapper.propTypes = {
}
export default PageWrapper

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    flexGrow: 1,
    flex: 1,
    backgroundColor: Colors.lightBackground,
  },
})
