import React from 'react'
import { StyleSheet } from 'react-native'
import View from '@reveel/components/ui/View'
import Colors from '@reveel/constants/Colors'
import Impersonate from '@reveel/admin/Impersonate'

import { useAuth } from '@reveel/stores/useAuth'
import HeaderInbox from './HeaderInbox'
import HeaderAccount from './HeaderAccount'

const HeaderNavigationItems = () => {
  const { user } = useAuth()
  return (
    <View row my-8 pr-4>
      <View style={styles.separator} />
      <View style={styles.item}>
        <HeaderInbox />
      </View>
      <View style={styles.item}>
        <HeaderAccount />
      </View>
      {user.admin && (
        <View style={styles.item}>
          <Impersonate />
        </View>
      )}
    </View>
  )
}

export default HeaderNavigationItems

const styles = StyleSheet.create({
  separator: {
    height: '100%',
    backgroundColor: Colors.borderDarkGrey,
    width: 1,
    marginHorizontal: 15,
  },
  item: {
    marginHorizontal: 15,
  },
})
