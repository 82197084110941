import { Platform } from 'react-native'
import * as Linking from 'expo-linking'

const openURL = ({ href, target }) => {
  if(Platform.OS === 'web' && target === '_blank'){
    return window.open(href, target)
  }
  return Linking.openURL(href, target)
}

export default openURL
