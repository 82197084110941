import * as Sentry from 'sentry-expo'
import { Platform } from 'react-native'

const processErrors = (err) => {
  try{
    if(!err.response && err.request){
      return "Server unreachable. \nAre you connected to the internet?"
    }
    const {
      response: {
        data,
      },
    } = err
    return data.error || data.errors || 'Unknown Error'
  }catch(notARequestError){
    console.log(err)
    __DEV__
    && console.log("=================================================")
    && console.error(notARequestError)
    && console.log("=================================================")
    // TODO: restore error capture
    Platform.OS === 'web' && Sentry.captureEvent(notARequestError)
  }
}
export default processErrors
