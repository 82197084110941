import { useReducer, useCallback, useEffect } from 'react';
import { useAsync } from '@reveel/hooks/useRemoteState';
import routes from '@reveel/helpers/routes';
import { useNavigation } from '@react-navigation/native';
import { useQueryClient } from 'react-query';
import { useQuery } from 'react-query';
import axios from 'axios';

const reducer = (state, { type, payload }): SplitsState => {
  const { splitEqually, splits } = state
  const maybeEqualSplits = (splitsIn, forceEqual = splitEqually) => (forceEqual
    ? splitsIn.map((s) => ({ ...s, value: 100 / splitsIn.length }))
    : splitsIn
  )
  switch(type){
    case 'SPLIT_SHEET_LOADED':
      return {
        ...state,
        ...payload.splitSheet,
        // eslint-disable-next-line camelcase
        selectedIDs: payload.splitSheet.splits.map(({ profile_id }) => profile_id),
      }
    case 'PARTICIPANTS_LOADED':
      return {
        ...state,
        allCollaborators: payload.profiles,
      }
    case 'ADD_USER':
      return {
        ...state,
        splits: maybeEqualSplits([...splits, { profile_id: payload.profile.id, value: 0 }]),
        selectedIDs: [...state.selectedIDs, payload.profile.id],
      }
    case 'REMOVE_USER':
      return {
        ...state,
        splits: maybeEqualSplits(state.splits.filter((s) => s.profile_id !== payload.profile.id)),
        selectedIDs: state.selectedIDs.filter((id) => id !== payload.profile.id),
      }
    case 'TOGGLE_EQUAL_SPLITS':
      return {
        ...state,
        splitEqually: !state.splitEqually,
        splits: maybeEqualSplits(splits, !state.splitEqually),
      }
    case 'EDITED_SHARE':
      return {
        ...state,
        splits: state.splits.map((s) => (s.profile_id === payload.profileID ? { ...s, value: payload.value } : s)),
      }
    default:
      return state
  }
}

const initialState = {
  selectedIDs: [],
  allCollaborators: [],
  splits: [],
  total: 0,
  splitEqually: true,
}

export const splitsParticipantsKey = (trackID: number) => `track-profiles-${trackID}`

const useSplitsForm = (trackID, { splitsType }) => {
  const navigation = useNavigation()
  const [state, dispatch] = useReducer(reducer, initialState)
  const { isLoading, data: profiles } = useQuery(splitsParticipantsKey(trackID), async () => {
    const res = await axios.get(routes.api.tracks.profiles(trackID))
    return res.data
  })
  useEffect(() => {
    if(!profiles){
      return
    }
    // TODO: get this out of the reducer
    dispatch({ type: 'PARTICIPANTS_LOADED', payload: profiles })
  }, [profiles])
  // fetch split sheet
  useAsync({
    url: routes.api.tracks.splitSheet[splitsType].show(trackID),
    immediate: true,
    handleResponse: ({ data }) => {
      if(!data){
        return
      }
      dispatch({ type: 'SPLIT_SHEET_LOADED', payload: { splitSheet: data.split_sheet } })
    },
  })

  // save split sheet
  const queryClient = useQueryClient()
  const { refreshing: submitting, run } = useAsync({
    url: routes.api.tracks.splitSheet[splitsType].show(trackID),
    method: 'post',
    handleResponse: () => {
      const next = splitsType === 'master_points' ? 'TrackPoints' : 'TrackSplits'
      queryClient.invalidateQueries(['track', 'progress', trackID])
      navigation.navigate(next, { trackID })
    },
  }, [trackID, navigation])

  const submit = useCallback(() => run({
    split_sheet: {
      splits_attributes: state.splits,
    },
  }), [state.splits])
  return {
    state,
    dispatch,
    submitting,
    submit,
    loading: isLoading,
  }
}

export default useSplitsForm

type SplitsState = {
  splitEqually: boolean,
  splits: Array<{profile_id: number, value: number}>
}