import React from 'react'
import {
  Platform, StyleSheet, View as NativeView, TouchableOpacity, ScrollView,
} from 'react-native'
import Colors from '@reveel/constants/Colors'
import props2Style from '@reveel/utils/props2Style'
import WithSeparators from '@reveel/components/utils/WithSeparators'

const View = ({
  style, children, disabled, withScrollView, onPress, ...props
}) => {
  const appliedStyles = props2Style(props, styles)

  const wrapped = props.sheet || onPress
  const wrapWithScrollView = (element) => (
    (props.sheet)
      ? <ScrollView 
          style={{ flex: 1, backgroundColor: 'red' }} 
          contentContainerStyle={[{ flex: 1 }, style]}
        >
          {element}
        </ScrollView> 
      : element
    )
  const touchable = (elem) => (onPress ? <TouchableOpacity style={[appliedStyles, style]} onPress={onPress} disabled={disabled}>{elem}</TouchableOpacity> : elem)
  return touchable(
    <NativeView style={[appliedStyles, wrapped ? null : style]} {...props}>
      {wrapWithScrollView(children)}
    </NativeView>
  )
}

export default View

export const viewStyles = {
  container: {
    paddingHorizontal: 15,
  },
  sheet: {
    borderTopColor: Colors.borderGrey,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    backgroundColor: Colors.white,

  },
  sheetBottom: {
    borderBottomColor: Colors.borderGrey,
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
    backgroundColor: Colors.white,
    paddingHorizontal: 15,
  },
  box: {
    borderColor: Colors.borderGrey,
    borderRadius: 25,
    backgroundColor: Colors.white,
  },
  "box-sm": {
    borderColor: Colors.borderGrey,
    borderRadius: 9,
    backgroundColor: Colors.white,
  },
  flex: {
    flex: 1,
  },
  flexGrow: {
    flexGrow: 1,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: Platform.OS === 'web' ? 'wrap' : undefined,
  },
  column: {
  },
  justifyBetween: {
    justifyContent: 'space-between',
  },
  justifyAround: {
    justifyContent: 'space-around',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  justifyEnd: {
    justifyContent: 'flex-end',
  },
  alignCenter: {
    alignItems: 'center',
  },
  alignStretch: {
    alignSelf: 'stretch',
  },
  px: {
    paddingHorizontal: 15,
  },
  "px-05": {
    paddingHorizontal: 5,
  },
  "px-1": {
    paddingHorizontal: 10,
  },
  "px-2": {
    paddingHorizontal: 20,
  },
  "px-3": {
    paddingHorizontal: 30,
  },
  "px-4": {
    paddingHorizontal: 40,
  },
  py: {
    paddingVertical: 15,
  },
  "py-4": {
    paddingVertical: 40,
  },
  "py-05": {
    paddingVertical: 5,
  },
  "py-1": {
    paddingVertical: 10,
  },
  "pt-1": {
    paddingTop: 10,
  },
  "pt-3": {
    paddingTop: 30,
  },
  pl: {
    paddingLeft: 15,
  },
  "pl-1": {
    paddingLeft: 10,
  },
  "pl-2": {
    paddingLeft: 20,
  },
  "pl-3": {
    paddingLeft: 30,
  },
  "pr-1": {
    paddingRight: 10,
  },
  "pr-2": {
    paddingRight: 20,
  },
  "pr-3": {
    paddingRight: 30,
  },
  "pr-4": {
    paddingRight: 40,
  },
  ml: {
    marginLeft: 15,
  },
  "ml-1": {
    marginLeft: 10,
  },
  "ml-2": {
    marginLeft: 20,
  },
  "ml-3": {
    marginLeft: 30,
  },
  "mr-1": {
    marginRight: 10,
  },
  "mr-2": {
    marginRight: 20,
  },
  "mr-3": {
    marginRight: 30,
  },
  "mr-4": {
    marginRight: 40,
  },
  pb: {
    paddingBottom: 15,
  },
  "pb-1": {
    paddingBottom: 10,
  },
  "pb-2": {
    paddingBottom: 20,
  },
  mx: {
    marginHorizontal: 15,
  },
  "mx-1": {
    marginHorizontal: 10,
  },
  "mx-05": {
    marginHorizontal: 5,
  },
  my: {
    marginVertical: 4,
  },
  mt: {
    marginTop: 5,
  },
  "mt-1": {
    marginTop: 10,
  },
  "mt-2": {
    marginTop: 20,
  },
  "mt-3": {
    marginTop: 30,
  },
  "mt-4": {
    marginTop: 40,
  },
  mb: {
    marginBottom: 5,
  },
  "mb-1": {
    marginBottom: 10,
  },
  "mb-2": {
    marginBottom: 20,
  },
  "mb-3": {
    marginBottom: 30,
  },
  "mb-4": {
    marginBottom: 40,
  },
  "bg-white": {
    backgroundColor: Colors.white,
  },
  "bg-light-grey": {
    backgroundColor: Colors.lightBackground,
  },
  "bg-red": {
    backgroundColor: Colors.errorBackground,
  },
  'border-t': {
    borderTopWidth: 1,
    borderTopColor: Colors.borderGrey,
  },
  'border-b': {
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderGrey,
  },
  'border-b-dark': {
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderGrey,
  },
  "shadow-b": {

    ...Platform.select({
      web: {
        boxShadow: `0 1px 4px 1px ${Colors.borderGrey}`,
      },
      native: {
        shadowColor: Colors.borderGrey,
        shadowOffset: {
          width: 0,
          height: 4,
        },
        shadowOpacity: 1,
        shadowRadius: 8,
        elevation: 3,
      },
    }),
  },
}

const styles = StyleSheet.create(viewStyles)
