import React from 'react'
import { StyleSheet } from 'react-native'
import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'
import AddSession from 'app/assets/images/ui/addSession.svg'

export const TapToStartSession = () => (
  <View flex justifyCenter alignCenter>
    <Text center small light mb-1>
      {i18n.t('tracks.index.empty.cta')}
    </Text>
    <AddSession width={26} height={26} style={{ opacity: 0.7 }} />
  </View>
)

const TracksIndexEmpty = ({ }) => (
  <View px flex justifyBetween>
    <Text large bold mb-1>
      {i18n.t('tracks.index.empty.title')}
    </Text>
  </View>
)
export default TracksIndexEmpty

const styles = StyleSheet.create({})
