// this is an attempt at not adding redux just yet

import React, {
  createContext, useContext, useEffect, useState,
} from 'react'
import equal from 'fast-deep-equal'
import useInbox from './useInbox'
import useLastReadAt from './useLastReadAt'
import useRemoteTracks from '../screens/useRemoteTracks'
const Context = createContext({})
const Provider = React.memo(({ value, children }) => (
  <Context.Provider value={value}>
    {children}
  </Context.Provider>
))

const useCurrentTrack = () => {
  const currentTrack = useState()
  return currentTrack
}

export const StoreProvider = (props) => {
  const inbox = useInbox()
  const lastReadAt = useLastReadAt()
  const tracks = useRemoteTracks()
  const currentTrack = useCurrentTrack({})

  const [value, setValue] = useState({
    inbox,
    lastReadAt,
    currentTrack,
    tracks,
  })

  useEffect(() => {
    const newValue = {
      inbox,
      lastReadAt,
      currentTrack,
      tracks,
    }
    if(!equal(value, newValue)){
      setValue(newValue)
    }
  }, [inbox, lastReadAt, currentTrack, tracks])

  return <Provider {...props} value={value} />
}

const useStoreContext = (k) => {
  const context = useContext(Context)
  if(k){
    if(!Object.prototype.hasOwnProperty.call(context, k)){
      throw new Error(`Trying to read invalid context ${k}. Available contexts are ${Object.keys(context).join(', ')}`)
    }
    return context[k]
  }
  return context
}
export default useStoreContext

export const withGlobalState = (Component) => (props) => (
  <Context.Consumer>
    {(contextProps) => <Component {...props} {...contextProps} />}
  </Context.Consumer>
)
