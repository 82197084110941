import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Text, Thumbnail } from 'native-base'
import Colors from '@reveel/constants/Colors'

const ContributionBanner = ({
  contribution,
  user,
  children,
}) => (
  <View style={styles.container}>
    <View style={styles.userContainer}>
      <Thumbnail small source={{ uri: user.avatar_url }} style={styles.avatar} />
      <View>
        <Text style={styles.text} bold small>{user.name}</Text>
        <Text style={styles.text} bold red small>{contribution.skill.name}</Text>
      </View>
    </View>
    {children}
  </View>
)
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: Colors.lightBackground,
    padding: 10,
    alignItems: 'center',
  },
  userContainer: {
    flexDirection: 'row',
    flexGrow: 1,
  },
  text: {
  },
  avatar: {
    marginRight: 10,
  },
})

export default ContributionBanner
