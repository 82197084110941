import { useState, useEffect } from "react"
import searcher from "../helpers/searcher"
import routes from "../helpers/routes"
import useRemoteState from "../hooks/useRemoteState"
import { useAuth } from "../stores/useAuth"
import { useQuery } from 'react-query';
import axios from 'axios';

const byDate = (trackOne, trackTwo) => new Date(trackTwo.last_activity_at) - new Date(trackOne.last_activity_at)

export default () => {
  const [query, setQuery] = useState("")
  const { user, isLoggedIn } = useAuth()
  const id = user?.id

  const key = ['tracks']
  const route = routes.api.tracks.index
  const { isLoading, data: allTracks } = useQuery(key, async () => {
    const { data } = await axios.get(route)
    return data
  }, {
    enabled: isLoggedIn,
  })
  const [tracks, setTracks] = useState([])

  useEffect(() => {
    // no idea why this id could be null.
    // maybe when masquerading?
    if (!id || !allTracks){
      return
    }
    if(query.length){
      setTracks(searcher(allTracks, { query, keys: ["title"] }))
      return
    }

    setTracks(allTracks.sort(byDate))
  }, [id, query, allTracks, setTracks])

  return {
    tracks,
    refreshing: isLoading,
    loading: isLoading,
    // used to avoid remote fetching the tracks when searching.
    // will be removed once we change our mind on this.
    // remains to be seen if we need to restore query to empty when unmounting the search component
    query,
    setQuery,
  }
}
