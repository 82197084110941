import React from 'react'
import ScreenTitle from '../../../components/ScreenTitle'
import { stageNameField } from '../../../helpers/fields/userFieldsList'
import skillsField from '../../../helpers/fields/userFieldsList/skillsField'
import UserUpdateHOC from '../../user/UserUpdateHOC'

export const OnboardingCreatorID = ({ onNext }) => (
  <UserUpdateHOC
    renderHeader={() => <ScreenTitle title={i18n.t('onboarding.creatorID.title')} />}
    fields={[
      stageNameField,
      // disabling until skills dropdown is fixed 
      // skillsField,
    ]}
    onSuccess={onNext}
    buttonText="Continue"
    withKeyboardSpacer
    withScrollView={false}
  />
)
