import React, { useState } from "react"
import { ScrollView, View, StyleSheet } from "react-native"

import { useNavigation } from '@react-navigation/native'
import Button from '@reveel/components/ui/Button'
import Colors from '@reveel/constants/Colors'
import i18n from 'i18n-js'

import ContactSupport from "../../components/ContactSupport"
import BottomSheetTitle from "../../components/core/BottomSheetTitle"
import SkillsDropdown from "../../components/core/SkillsDropdown"
import { Text } from "../../components/ui"
import Pill from '../../components/ui/Pill'
import { useDesktop } from '../../helpers/MediaQueries'
import useSkills from "../skills/useSkills"
import useContributions from "./useContributions"
import useMyProfile from "../../stores/useMyProfile"

const EnterContributions = ({
  buttonProps,
  onSubmit,
  initialContributions,
  i18nTitleKey,
  renderBeforeButton,
  renderAfterButton,
  style,
  noChrome,
}) => {
  const [mySkills] = useSkills()
  const [contributions, toggleContributions] = useContributions(initialContributions)
  const { myProfile } = useMyProfile()
  const navigation = useNavigation()
  const [showAddInput, setShowAddInput] = useState(false)
  const isDesktop = useDesktop()

  // make a copy of contributionsWithinMySkills so that we can
  // remove those that are included in mySkills as we go 😎
  const contributionsWithinMySkills = [...contributions]

  const isSkillSelected = (skill) => contributionsWithinMySkills.find((c, i) => {
    if(c.id === skill.id){
      contributionsWithinMySkills.splice(i, 1)
      return true
    }
    return false
  })

  const otherSkills = contributionsWithinMySkills.filter((s) => !mySkills.find((ms) => ms.id === s.id))

  const submit = () => {
    const skill_ids = contributions.map((c) => c.id)
    onSubmit({ skill_ids, profile_id: myProfile.id })
  }
  const button = (
    <Button
      style={styles.buttonRow}
      bordered
      block
      {...buttonProps}
      onPress={submit}
    />
  )

  const currentSkills = [...mySkills, ...otherSkills]
  const onAddOther = (() => setShowAddInput(true))
  const addOther = <Pill content="+ Add Other" onPress={onAddOther} style={styles.addOther} />
  // TODO: style this to look like a pill
  const selectSkills = (
    <SkillsDropdown
      selectedSkills={currentSkills}
      showBrowseAll={false}
      onSelect={toggleContributions}
      style={tw.mT2}
    />
  )

  const content = (
    <View>
      <Text smaller style={[tw.pX2, styles.helpText]}>Select the credits you would like to add:</Text>
      <View style={tw.pX2}>
        <View style={styles.pillsContainer}>
          {currentSkills.map((skill) => (
            <Pill
              key={skill.id}
              _testID={`skill-pill-${skill.id}`}
              content={skill.name}
              selected={isSkillSelected(skill)}
              onPress={() => toggleContributions(skill)}
              style={tw.mR2}
            />
          ))}
        </View>
        {showAddInput ? selectSkills : addOther}
      </View>
    </View>
  )
  if(noChrome){
    return (
      <View style={style}>
        {content}
        <View style={styles.buttonContainer}>
          {renderBeforeButton?.()}
          {button}
        </View>
      </View>
    )
  }
  return (
    <View style={styles.container}>
      <ScrollView keyboardShouldPersistTaps="always" style={styles.scrollContainer} contentContainerStyle={{ flexGrow: 1 }}>
        <BottomSheetTitle title={i18n.t(i18nTitleKey, { count: contributions.length })} />
        {content}
        <View style={[styles.padding, styles.skillsWrapper]} />
        <ContactSupport horizontal />
      </ScrollView>
      <View style={styles.buttonContainer}>
        {renderBeforeButton?.()}
        {button}
        {renderAfterButton?.()}
      </View>
    </View>
  )
}

export default EnterContributions

export const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: 'space-around',
  },
  scrollContainer: {
    flexGrow: 1,
  },
  helpText: {
    paddingVertical: 20,
  },
  pillsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  addOther: {
    borderStyle: 'dashed',
    borderColor: Colors.lightBlack,
    color: Colors.lightBlack,
    textAlign: 'center',
    marginTop: 10,
  },
  padding: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  skillsWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 15,
  },
  buttonRow: {
    marginLeft: 15,
    marginRight: 15,
    marginTop: 10,
  },
  buttonContainer: {
    // to give space to ContactSupport
    // flexGrow: 1,
    justifyContent: "flex-end",

    marginBottom: 10,
  },
})
