import {
  PENDING_CONTRIBUTION_SUBMISSIONS, RUNNING, PENDING_VALIDATION, PENDING_CONFLICT_RESOLUTION, PENDING_MISSING_PARTICIPANTS, PENDING_SIGN_OFF, SIGNED_OFF, PLACEHOLDER, PAST,
} from '../constants/Status'

const messages = {
  attachments: {
    creativeSession: {
      title: "%{name} added a %{attachment_name} to %{creative_session_name}",
    },
    track: {
      title: "%{name} added %{attachment_name}",
    }
  },
}
export default {
  tracks: {
    name: 'Project',
    index: {
      empty: {
        title: 'All your projects will \nappear here',
        cta: 'Add a Project',
      },
    },
    status: {
      [PAST]: "Adding Contributions",
      [PENDING_CONTRIBUTION_SUBMISSIONS]: "Pending contribution submission",
      [RUNNING]: "Session running",
      [PENDING_VALIDATION]: "Pending validation",
      [PENDING_CONFLICT_RESOLUTION]: "Pending conflict resolution",
      [PENDING_MISSING_PARTICIPANTS]: "Participants missing",
      [PENDING_SIGN_OFF]: "Pending Sign off",
      [SIGNED_OFF]: 'Signed Off',
      [PLACEHOLDER]: 'Created',
    },
    feed: {
      empty: {
        title: 'No Activity',
        description: 'Find all activity here and \nchat with your collaborators',
      },
      messages,
    },
    fields: {
      sections: {
        project_information: 'Project Info',
        track_information: 'Recording Info',
        composition_information: 'Composition Info',
      },
    },
    email: {
      invite: {
        subject: "%{track_title} - Staying Organized",
        body: 'Hi,\n\nI’m using Reveel to keep track of our progress on %{track_title}. \n\nFollow the link to join the project, so we can track sessions, log credits, share attachments and keep all our information organized.\n\n%{url}\n\nCheers,\n\nPS: If you have any questions, the team at Reveel can help. Email them to: service@reveel.id\n\nAbout Reveel\nReveel is made for remote collaboration and helps you stay on top of all your creative projects. On Reveel, you can\n\nTrack credits and log sessions\nStay in touch with your creative team \nOrganize & share files and attachments\n',
      },
    },
    message: {
      invite: {
        subject: "%{track_title} - Staying Organized",
        body: "Hi, I’m using Reveel to keep track of our progress on %{track_title}.\nJoin the project!",
      },
    },
    ui: {
      invite: {
        title: 'Invite a collaborator',
        send: 'Send Invites',
        qrTitle: 'Ask your collaborator to scan \nthis QR code with a camera app',
      },
      chat: {
        mentionSession: "   Mention a creative session?",
      },
      validate: {
        cta: 'View Pending Credits  \u203A',
        admin: {
          title: 'Please Validate the following Credits',
        },
        nonAdmin: {
          title: 'Waiting for an admin to validate',
        },
        empty: {
          title: "No credits pending!",
        },
      },
      collaborators: {
        invite: {
          title: 'Invite Friends',
          subtitle: 'Give access',
          buttonText: '+ New Collaborator',
        },
      },
      credits: {
        header: 'Adding %{count} credits',
        cta: 'Submit your credits for this track',
      },
      export: {
        cta: 'Export Data',
      },
    },
  },
}
