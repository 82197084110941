import React, { useState, useCallback } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'

import Colors from '@reveel/constants/Colors'
import { useNavigation } from '@react-navigation/native'
import ContributionAccept from './ContributionAccept'
import ContributionReject from './ContributionReject'
import ContributionStatus from './ContributionStatus'
import {
  CONFLICTED, PENDING_CONFLICT_RESOLUTION, PENDING_VALIDATION, PENDING_CONTRIBUTION_SUBMISSIONS, PENDING_MISSING_PARTICIPANTS, SIGNED_OFF, VALIDATED, REJECTED, ACCEPTED,
} from '../../constants/Status'
import { withCreativeSession } from '../../screens/sessions/useCreativeSessionContext'
import { useValidationContext } from '../../screens/validation/validationContext'
import Button from '../ui/Button'
import View from '@reveel/components/ui/View';
import { Text } from '@reveel/components/ui';

const ContributionItem = ({
  contribution,
  user,
  noBorder = false,
  creativeSession,
}) => {
  const navigation = useNavigation()
  // TODO: use can from creativeSession, not Track
  // maybe via contributionsFor, but that would
  // loop several times.. perhaps we need another context
  // but is it not over-engineered? 🤔
  const { contributionsForSession, submitValidationFor } = useValidationContext()
  const [showConfirmActions, setShowConfirmActions] = useState(false)

  // HACK: PERFO: this loops over trackCredits on every
  // contribution, needs to be removed!!
  const { can } = contributionsForSession(contribution.creative_session_id)
  const {
    id, text, status,
    skill: { name },
    was_conflicted: wasConflicted,
  } = contribution

  const isConflictedOrRejected = status === CONFLICTED || status === REJECTED
  const updateStatus = async (validationStatus) => submitValidationFor({ id }, { status: validationStatus })

  const goToChat = () => navigation.navigate('ValidationResolutionChat', { contribution, user, creativeSession, trackID: creativeSession.track_id })

  const actions = () => {
    if(status === PENDING_VALIDATION && can.validate){
      if(showConfirmActions){
        return (
          <View alignCenter row>
            <TouchableOpacity
              bordered
              cancel
              xs
              style={styles.rejectButton}
              onPress={() => setShowConfirmActions(false)}
            >
              <Text sm light>
                Cancel
              </Text>
            </TouchableOpacity>

            <Button
              bordered
              xs
              style={styles.confirmButton}
              onPress={() => updateStatus(ACCEPTED)}
              title="Confirm"
            />
          </View>
        )
      }
      return (
        <>
          <ContributionReject onPress={() => updateStatus(REJECTED)} contribution={contribution} user={user} />
          <ContributionAccept onPress={() => setShowConfirmActions(true)} contribution={contribution} />
        </>
      )
    }
    return <ContributionStatus goToChat={goToChat} creativeSession={creativeSession} contribution={contribution} user={user} />
  }

  const note = () => {
    if(text){
      return <Text note>{text}</Text>
    }
    if(wasConflicted && status !== PENDING_CONFLICT_RESOLUTION){
      return (
        <TouchableOpacity onPress={goToChat}>
          <Text note> View Chat</Text>
        </TouchableOpacity>
      )
    }
  }

  return (
    <View row pr-1 style={styles.container} testID={`contribution-${id}`}>
      <View style={styles.name}>
        <Text bold style={isConflictedOrRejected && styles.rejectedText}>
          {name}
        </Text>
        {note()}
      </View>
      <View style={styles.actionsContainer}>{actions()}</View>
    </View>
  )
}

export default withCreativeSession(ContributionItem)

const styles = StyleSheet.create({
  container: {
    alignContent: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingBottom: 5,
    paddingTop: 5,
  },
  name: {
    flexWrap: 'nowrap', 
    marginLeft: -10 
  },
  rejectButton: {
    marginRight: 10,
  },
  rejectedText: {
    color: Colors.errorText,
  },
  actionsContainer: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
})
