import React, { useState } from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'

import Colors from "@reveel/constants/Colors"
import * as Icon from '@expo/vector-icons'
import UserAvatar from 'react-native-user-avatar'
import { Text, Item, Textarea } from '../../components/ui'
import ModalButton from "../../components/ModalButton"
import { CONFLICTED } from "../../constants/Status"
import Button from '../../components/ui/Button'

const ValidationRejectionDialog = ({
  onReject,
  contribution,
  user,
}) => {
  const { status } = contribution

  const [text, setText] = useState('No Reason')

  const contributionInfo = (
    <View style={styles.infoContainer}>
      <View style={styles.infoSingle}>
        <Text xs light>Contribution</Text>
        <Text>{contribution.skill.name}</Text>
      </View>
      <View style={styles.infoSingle}>
        <Text xs light>Contributor</Text>
        <View style={styles.userContainer}>
          <UserAvatar size={25} name={user.name} src={user.avatar_url} />
          <Text style={{ marginLeft: 5 }}>{user.name}</Text>
        </View>
      </View>
    </View>
  )

  const input = <View style={styles.inputContainer}>
    <Text small style={styles.inputLabel}> Type your reason here</Text>
    <Item regular>
      <Textarea rowSpan={3} defaultValue={text} onChangeText={setText} autoFocus />
    </Item>
  </View>
  let color = Colors.disabledText
  const trigger = (open) => <TouchableOpacity onPress={open} style={styles.trigger}>
    <Icon.Ionicons name="ios-close" color={color} size={35}></Icon.Ionicons>
  </TouchableOpacity>
  const actions = (close) => [
    <Button key="save" block onPress={() => { onReject(text); close() }} primary title="Reject" />,
    <Button cancel key="cancel" onPress={close} title="Cancel" />,
  ]
  return (
    <ModalButton
      withClose={false}
      title="Please, enter the reason for rejecting this contribution:"
      trigger={trigger}
      actions={actions}
      content={() => (
        <>
          {contributionInfo}
          {input}
        </>
      )}
      contentStyle={{ paddingVertical: 0, paddingHorizontal: 0 }}
    />
  )
}

export default ValidationRejectionDialog

const styles = StyleSheet.create({
  content: {
    backgroundColor: 'white',
    paddingTop: 10,
    paddingBottom: 10,
  },
  infoContainer: {
    flexDirection: 'row',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    paddingBottom: 15,
    borderBottomWidth: 1,
    borderColor: Colors.borderGrey,
    backgroundColor: Colors.lightBackground,
  },
  infoSingle: {
    flexGrow: 1,
  },
  userContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputLabel: {
    marginBottom: 5,
    marginTop: 5,
  },
  modalTitle: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  inputContainer: {
    padding: 5,
  },
  trigger: {
    paddingHorizontal: 5,
  },
})
