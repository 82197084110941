const AsyncKeys = {
  AUTH_KEY: '@MR:auth',
  SESSION_KEY: '@MR:session',
  CONTRIB_KEY: '@MR:contrib',
  ACTIVITIES_KEY: '@MR:activities',
  LAST_READ_AT_KEY: '@MR:last_read_at',
  LAST_UPDATE_KEY: '@MR:LAST_ACTIVITY_UPDATE',
  USER_KEY: '@MR:user',
  AFTER_AUTH_ROUTE: '@MR:AFTER_AUTH_ROUTE',
}
export default AsyncKeys
