import React from 'react';
import UserAvatar from 'react-native-user-avatar'
import { useAuth } from '@reveel/stores/useAuth';

const MyAvatar = ({ size = 40, ...props }) => {
  const { user } = useAuth()
  return (
    <UserAvatar
      name={user.display_name}
      src={user.avatar_url}
      size={size}
      {...props}
    />
  )
}

export default MyAvatar
