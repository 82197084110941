import React, { useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { StyleSheet } from 'react-native'
import i18n from 'i18n-js'

import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'
import ChatRoom from '../../components/ChatRoom'
import routes from '../../helpers/routes'
import PendingValidationBanner from './PendingValidationBanner'

import sessionSelectAccessory from '@reveel/components/ChatRoom/useAttachToSession'
import useMentions from '@reveel/components/ChatRoom/useMentions'
import Page from '@reveel/layout/Page';
import useTrackBreadcrumb from '../../navigation/useTrackBreadcrumb';
import useWhyDidYouUpdate from '../../hooks/useWhyDidYouUpdate';

const renderChatEmpty = () => (
  <View container my mt-4 style={styles.empty}>
    <Text bold large>{i18n.t('tracks.feed.empty.title')}</Text>
    <Text>{i18n.t('tracks.feed.empty.description')}</Text>
  </View>
)

const styles = StyleSheet.create({
  empty: { 
    transform: [{ scaleY: -1 }],
  }
})

const TrackActivity = ({
  route, ...props
}) => {
  const id = route.params?.trackID
  const accessories = useMemo(() => [
    sessionSelectAccessory({ track: { id } }),
    useMentions({ track: { id } }),
  ], [id])
  const navigation = useNavigation()
  useWhyDidYouUpdate({ route, navigation})
  const extraButtons = [{
    label: 'Add Attachment',
    onPress: () => navigation.navigate('AttachmentNew', { trackID: id })
  }]
  useTrackBreadcrumb(id, ['Feed'])
  return (
    <View style={tw.flexGrow}>
      <PendingValidationBanner trackID={id} />
      <Page>
        <ChatRoom
          allMessagesUrl={routes.api.tracks.messages(id)}
          submitMessageUrl={routes.api.tracks.messages(id)}
          channelType="TrackChannel"
          channelId={id}
          renderChatEmpty={renderChatEmpty}
          accessories={accessories}
          customActionProperties={{
            extraButtons
          }}
        />
      </Page>
    </View>
  )
}

export default React.memo(TrackActivity)
