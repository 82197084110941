import React from 'react'
import { Pressable } from "react-native"
import { Text, View } from "@reveel/components/ui"
import Button from "@reveel/components/ui/Button"
import ModalWithActions from "../../components/ui/ModalWithActions"
import { useState } from "react"
import { friendsKey, useFriendShips } from './useFriendShips'
import Loading from '../../components/Loading'
import { tw } from 'react-native-tailwindcss'
import { useMutation, useQueryClient } from 'react-query'
import routes from '../../helpers/routes'
import axios from 'axios'
import UserAvatar from 'react-native-user-avatar'
import SimpleTabs from '../../components/SimpleTabs'
import { Ionicons } from '@expo/vector-icons'

const useFriendRequest = ({ id }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const queryClient = useQueryClient();
  const route = routes.api.user.friends.update(id);
  const mutation = useMutation(
    async ({ rsvp }: { rsvp: 'accept' | 'decline' }) => {
      setIsLoading(true);
      axios.put(route, { friend_id: id, rsvp });
    },
    {
      onMutate: async (data) => {
        setIsLoading(true);
      },
      onError: (err, variables, previousValue) => {
        // setIsLoading(false);
      },
      onSuccess: () => {
        setTimeout(() => queryClient.invalidateQueries(friendsKey), 100)
        // setIsLoading(false);
      },
    }
  );
  return {
    onAccept: () => mutation.mutate({ rsvp: 'accept' }),
    onDecline: () => mutation.mutate({ rsvp: 'decline' }),
    isLoading,
  }
}

const Request = ({ friend }: { friend: Friend}) => {
  const { onAccept, onDecline, isLoading } = useFriendRequest({ id: friend.id })
  return (
    <UserRow friend={friend} key={friend.id}>
      {
        isLoading ? <Loading size='small' /> : 
          <>
            <Button xs title="Decline" cancel  onPress={onDecline} />
            <Button xs style={tw.mL2} title="Accept" onPress={onAccept} />
          </>
      }
    </UserRow>
  )
}

const UserRow = ({ friend, children }: { friend: Friend, children?: React.ReactElement}) => (
  <View style={[tw.pY2, tw.flexRow, tw.justifyBetween, tw.itemsCenter]} key={friend.id}>
    <View style={[tw.flexRow, tw.itemsCenter]}>
      <UserAvatar name={friend.display_name} src={friend.avatar_url} />
      <Text style={tw.pL2}>{friend.display_name}</Text>
    </View>
    { children && <View style={tw.flexRow}>
      {children}
    </View>}
  </View>
)

const Empty = () => (
  <View flex flexGrow alignCenter px py justifyCenter style={[tw.mT4]}>
    {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
    <View style={tw.mB4}>
      <Text xl>
        😶‍🌫️
      </Text>
    </View>
    <Text>Nothing to see here...</Text>
  </View>
)
const Content = ({ }) => {
  const { isLoading, error, data } = useFriendShips()
  if(isLoading){
    return <Loading />
  }
  const { requested, pending, invitations } = data
  const tabs = [
    {
      title: 'Requests',
      elem: () => <>{requested.length ? requested.map((friend) => <Request friend={friend} />) : <Empty />}</>,
    },
    {
      title: 'Pending',
      elem: () => <>
        {
          Boolean(pending.length + invitations.length) ? <>
          {pending.map((friend) => <UserRow friend={friend} />)}
          {invitations.map((inv) => (
            <View style={[tw.pY2, tw.flexRow, tw.justifyBetween, tw.itemsCenter]} key={inv.id}>
            <View style={[tw.flexRow, tw.itemsCenter]}>
            <Ionicons name="mail-outline" size={20} style={[tw.mL1, tw.mR4]}/>
            <Text>{inv.email}</Text>
            </View>
            <Text style={[tw.textLightBlack]}> last sent {new Date(inv.last_sent_at).toLocaleDateString()}</Text>
            </View>
            ))}
          </> :
        <Empty />
        }
    </>,
    },
  ]
  return <SimpleTabs tabs={tabs} style={tw.flex}/>
}

const ViewMoreInvites = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const { isLoading, data } = useFriendShips()
    if(isLoading){
      return null
    }
    const { requested, pending, invitations } = data
    const totalPending = pending.length + invitations.length + requested.length
    if (!totalPending) {
      return null
    }
    return (
      <>
        <Pressable onPress={() => setIsOpen(true)}>
          {({ hovered }) => <Text style={[tw.textPrimary, hovered && tw.underline]}>See {totalPending} pending...</Text>}
        </Pressable>
        <ModalWithActions
          isVisible={isOpen}
          closeModal={() => setIsOpen(false)}
          title="Your Network"
          size='lg'
          variant='sidepanel'
        >
          <Content />
        </ModalWithActions>
      </>
    )
  }

  export default ViewMoreInvites