import React from 'react';
import View from '@reveel/components/ui/View';
import Button from '@reveel/components/ui/Button';
import { useNavigation } from '@react-navigation/native';
import { Text } from '@reveel/components/ui';
import { StyleSheet, Platform } from 'react-native';
import FeatureCTA from '../../../components/placeholders/empty/FeatureCTA';

const SplitsEmpty = ({ trackID, splitsType }) => {
  const navigation = useNavigation()
  const onPress = () => {
    const goTo = splitsType !== 'master_points' ? 'TrackSplitsNew' : 'TrackPointsNew';
    navigation.push(goTo, { trackID })
  }
  return <FeatureCTA
    i18nPrefix={`splits.${splitsType}`}
    onPress={onPress}
  />
}

export default SplitsEmpty
