import { Ionicons } from '@expo/vector-icons';
import { Text } from '@reveel/components/ui';
import Button from '@reveel/components/ui/Button';
import Card from '@reveel/components/ui/Card';
import Colors from '@reveel/constants/Colors';
import { useDesktop } from '@reveel/helpers/MediaQueries';
import routes from '@reveel/helpers/routes';
import axios from 'axios';
import React, { ReactText, useState } from 'react';
import { View } from 'react-native';
import { tw } from 'react-native-tailwindcss';
import { useMutation } from 'react-query';
import track from '../../analytics/index';
import RequestExportButton from '../../screens/tracks/credits/RequestExportButton';
const SoundExchange = require('@reveel/../assets/images/services/sound_exchange.png');

export const SectionTitle = ({ children, style }: { children: ReactText, style?: any }) => <Text style={[tw.fontBold, tw.textXl, tw.pT8, style]}>{children}</Text>
export const SectionSubTitle = ({ children, style }: { children: ReactText, style?: any }) => <Text style={[tw.pT2, style]}>{children}</Text>

export const exportOptions = ({ trackID }) => [
  {
    title: 'Export Recording Data',
    description: 'Get the Label Copy for your Track so you can easily share all the Track data with your label or distributor.',
    header: <Ionicons name="book-outline" size={48} color={Colors.lightGrey} />,
    actions: <RequestExportButton trackID={trackID} destination='label_copy'/>,
  },
  {
    title: 'Export Publishing data',
    description: 'Get the Work Registration Form for your song so you can easily share all the song’s publishing data with your publisher or PRO (performing rights organization).',
    header: <Ionicons name="recording-outline" size={48} color={Colors.lightGrey} />,
    actions: <RequestExportButton trackID={trackID} destination='split_sheet'/>,
  },
  {
    title: 'Export Neighboring Rights data',
    description: 'Get the Neighboring Rights (Record Public Performance) data for your Track so you can easily share the recording data & credits with your Neighboring Rights organization.',
    header: <img style={{ height: 48, width: 'auto', }} src={SoundExchange} alt="partner-logo" />,
    actions: <RequestExportButton trackID={trackID} destination='sound_exchange'/>,
  },
];

const ExportCards = ({ trackID, style }: { trackID: number | string, style?: any }) => {
  const isDesktop = useDesktop();
  return (<View style={style}>
    <View style={[
      isDesktop && tw.flexRow,
      tw.flexWrap,
      tw.mT8,
      { gap: 16 }
    ]}>
      {exportOptions({ trackID }).map(option => <Card
        {...option}
        key={option.title}
        style={tw.flex1} />
      )}
    </View>
  </View>
  );
};

export default ExportCards;