import React from 'react';
import { Text } from '@reveel/components/ui';
import View from '@reveel/components/ui/View';
import UserAvatar from 'react-native-user-avatar';
import { tw } from 'react-native-tailwindcss';
import LinkWrapper from '../../../components/utils/LinkWrapper';



export const UserContributions = ({ profile, contributions, style }) => (
  <View style={[tw.flexRow, tw.flexShrink, tw.itemsStart, style]}>
    <View style={[tw.mR2]}>
      <UserAvatar size={45} name={profile.name} src={profile.avatar_url} />
    </View>
    <View style={[tw.flexShrink]}>
      <LinkWrapper style={[tw.flexShrink]} to={`/profile/${profile.id}`} target="_blank">
        <Text style={[tw.fontBold, tw.flexWrap]}>{profile.name}</Text>
      </LinkWrapper>
      <Text light style={[]}>
        {contributions.map(({ skill_name }) => skill_name).join(', ')}
      </Text>
    </View>
  </View>
);
