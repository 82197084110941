import { useEffect, useState, useMemo } from 'react'

import useRemoteState, { useAsync } from '@reveel/hooks/useRemoteState'

import { PENDING_VALIDATION, PENDING_CONFLICT_RESOLUTION, REJECTED, ACCEPTED } from '../../constants/Status'
import fabricateContext from '../../helpers/fabricateContext'
import routes from '../../helpers/routes'
import { useQueryClient } from 'react-query';
import { trackCreditKey } from '../tracks/credits/useTrackCredits';

export const VALIDATIONS_KEY = '@MR:tmp_VALIDATIONS'

export const pendingCountForCS = (cs) => cs.profiles.reduce((prevUserCount, currentUser) =>
  // cs.contributions is {profile: { contributions: [], ...profile}}
  // so we need to loop over every profile
  prevUserCount + currentUser.contributions.reduce((prevCount, currentContrib) => (
    prevCount + [PENDING_VALIDATION, PENDING_CONFLICT_RESOLUTION].includes(currentContrib.status) ? 1 : 0
  ), 0), 0)

const useCreateValidationValue = ({ track, setTrack, loading }) => {
  const queryClient = useQueryClient()
  const handleResponse = (contribIn) => {
    const newCreativeSessions = track.creative_sessions.map((creativeSession) => {
      const { profiles, ...rest } = creativeSession
      return {
        ...rest,
        profiles: profiles.map((profile) => {
          if(contribIn.profile_id !== profile.id){
            return profile
          }
          return {
            ...profile,
            contributions: profile.contributions.map((c) => (c.id === contribIn.id ? contribIn : c)),
          }
        }),
      }
    })
    setTrack({
      ...track,
      creative_sessions: newCreativeSessions,
    })
    queryClient.invalidateQueries(trackCreditKey(track.id))
  }
  const { run } = useAsync({
    url: (id) => routes.api.contributions.validate(id),
    method: 'post',
    handleResponse: ({ data: { contribution } }) => handleResponse(contribution),
  }, [])

  const submitValidationFor = (contribution, { status, text }) => {
    run({
      urlParams: contribution.id,
      validation: {
        status,
        text,
      },
    })
  }
  const contributionsForSession = (creativeSessionID) => track.creative_sessions.find((cs) => cs.id === creativeSessionID)

  const pendingCreditsCount = useMemo(() => (
    track.creative_sessions?.reduce((prev, cs) => (
      prev + cs.profiles.reduce((prevU, u) => (
        prevU + u.contributions.reduce((prevC, c) => (
          prevC + ((c.status === REJECTED || c.status === ACCEPTED) ? 0 : 1)
        ), 0)
      ), 0)
    ), 0)
  ), [track.creative_sessions])

  // handle validate all at once
  return {
    loading,
    track,
    pendingCreditsCount,
    contributionsForSession,
    submitValidationFor,
  }
}

export const {
  Provider: ValidationProvider,
  Consumer: ValidationConsumer,
  useContext: useValidationContext,
} = fabricateContext(useCreateValidationValue)
