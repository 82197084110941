import { useState, useEffect } from "react"
import axios from "axios"

import { flattenObjectToArray } from '@reveel/utils/flatten'
import routes from "../helpers/routes"
import searcher from "../helpers/searcher"
import useAuth from '@reveel/stores/useAuth';

// {instruments: [a], engineering: [b]} => [a,b]

const useRemoteSkills = (options = {}) => {
  const { flattened = false } = options
  const [fetchedSkills, setFetchedSkills] = useState([])
  const [displayedSkills, setDisplayedSkills] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [hasQuery, setHasQuery] = useState(false)

  useEffect(() => {
    axios.get(routes.api.skills.index)
      .then(({ data }) => {
        let formattedData = data
        if(flattened){
          formattedData = flattenObjectToArray(data)
        }
        setFetchedSkills(formattedData)
        setDisplayedSkills(formattedData)
        setIsLoading(false)
      })
  }, [flattened])

  const setSearchQuery = (query = '') => {
    const willHaveQuery = query.length > 0
    setHasQuery(willHaveQuery)
    if(willHaveQuery){
      let flattenedSkills = fetchedSkills
      if(!flattened){
        flattenedSkills = flattenObjectToArray(fetchedSkills)
      }
      setDisplayedSkills(searcher(flattenedSkills, {
        keys: ['name', 'profession', 'notes'],
        query,
      }))
    }else{
      setDisplayedSkills(fetchedSkills)
    }
  }
  return [{
    skills: displayedSkills,
    setSearchQuery,
    isLoading,
    hasQuery,
  }]
}
export default useRemoteSkills
