import React, { useEffect, useState } from 'react'
import { ScrollView } from 'react-native'

import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'
import useRemoteState from '@reveel/hooks/useRemoteState'
import routes from '@reveel/helpers/routes'
import SessionMainInfo from '../../components/core/SessionMainInfo'
import ContributionsList from '../contributions/ContributionsList'
import { ValidationProvider } from '../validation/validationContext'
import SessionAttachments from './sessions/SessionAttachments'
import { useAuth } from '../../stores/useAuth'
import Markdown from '../../components/ui/Markdown'
import HeaderBackButton from '../../components/core/HeaderBackButton'
import SessionContextMenu from './SessionContextMenu'

const SignedOff = {}

SignedOff.screen = ({ navigation, creativeSession, setIntermediaryView }) => {
  useEffect(() => {
    navigation.setOptions({
      headerRight: () => <SessionContextMenu setIntermediaryView={setIntermediaryView} creativeSession={creativeSession} />,
      headerLeft: () => <HeaderBackButton onPress={navigation.goBack} />,
    })
  }, [])
  // We use a Provider to give access to actions to deep nested children
  const [track, setTrack] = useState({ creative_sessions: [] })
  const { loading } = useRemoteState({
    url: `${routes.api.tracks.contributions.index(creativeSession.track_id)}?&`,
    handleResponse: ({ data: { track: trackIn } }) => {
      setTrack(trackIn)
    },
  }, [creativeSession.track_id])

  return (
    <ScrollView>
      <SessionMainInfo creativeSession={creativeSession} />
      <SessionAttachments />
      {creativeSession.notes && (
      <View px mt-2>
        <Text bold>Notes</Text>
        <Markdown>{creativeSession.notes || ""}</Markdown>
      </View>
      )}
      <View px>
        <ValidationProvider track={track} loading={loading}>
          <ContributionsList creativeSession={creativeSession} />
        </ValidationProvider>
      </View>
    </ScrollView>
  )
}

export default SignedOff
