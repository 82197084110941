import React from 'react'


import AttachmentEdit from '../../screens/tracks/attachments/AttachmentEdit'
import AttachmentNew from '../../screens/tracks/attachments/AttachmentNew'
import AttachmentShow from '../../screens/tracks/attachments/AttachmentShow'
import TrackAttachmentsIndex from '../../screens/tracks/attachments/TrackAttachmentsIndex'
import TrackCredits from '../../screens/tracks/credits/TrackCredits'
import DashboardScreen from '../../screens/tracks/dashboard/DashboardScreen'
import { MasterPoints, SongwritingSplits } from '../../screens/tracks/splits/TrackSplits'
import { MasterPointsNew, SongwritingSplitsNew } from '../../screens/tracks/splits/TrackSplitsNew'
import TrackActivity from '../../screens/tracks/TrackActivity'
import TrackMetadataScreen from '../../screens/tracks/TrackEditMetadataScreen'
import TrackInfo from '../../screens/tracks/TrackInfo'
import TrackNew from '../../screens/tracks/TrackNew'
import TrackSubmitCreditsScreen from '../../screens/tracks/TrackSubmitCredits'
import TrackValidate from '../../screens/tracks/TrackValidate'
import { trackHeaderTitle } from '../useTrackBreadcrumb'
import TrackHome from '../../screens/tracks/Home/TrackHome'
import TrackRelease from '../../screens/tracks/TrackRelease';

const trackScreens = function TrackNavigator(isDesktop, Stack){
  return (
    <>
      <Stack.Screen
        name="TrackShow"
        component={TrackHome}
        options={({ route }) => ({
          title: route.params?.track?.title,
          headerTitle: trackHeaderTitle({ track: { id: route.params.trackID } }),
        })}
      />
      <Stack.Screen
        name="TrackOverview"
        component={DashboardScreen}

        options={({ route }) => ({
          title: route.params?.track?.title,
          headerTitle: trackHeaderTitle({ track: { id: route.params.trackID } }),
        })}
      />
      <Stack.Screen
        name="TrackEditMetadata"
        component={TrackMetadataScreen}
        options={({ route }) => ({
          title: 'Edit Details',
          headerTitle: trackHeaderTitle({ track: { id: route.params.trackID } }),
        })}
      />
      <Stack.Screen
        name="TrackSubmitCreditsScreen"
        component={TrackSubmitCreditsScreen}
        options={({ route }) => ({
          title: 'Add Credits',
          headerTitle: trackHeaderTitle({ track: { id: route.params.trackID } }),
        })}
      />
      <Stack.Screen
        name="TrackValidate"
        component={TrackValidate}
        options={({ route }) => ({
          title: 'Sign off',
          headerTitle: trackHeaderTitle({ track: { id: route.params.trackID } }),
        })}
      />
      {/* <Stack.Screen
        component={TrackAttachmentsIndex}
        name="TrackAttachmentsIndex"
        options={({ route }) => ({
            title: 'title',
            headerTitle: trackHeaderTitle({ subtitle: ["Attachments"], track: { id: route.params.trackID } }),
          })}
          />
      <Stack.Screen
        name="AttachmentNew"
        component={AttachmentNew}
        options={({ route }) => ({
          title: 'Add Attachment',
          headerTitle: trackHeaderTitle({ track: { id: route.params.trackID } }),
        })}
        />
        <Stack.Screen
        name="AttachmentShow"
        component={AttachmentShow}
        options={({ route }) => ({
          title: 'Attachment',
          headerTitle: trackHeaderTitle({ track: { id: route.params.trackID } }),
        })}
        />
      <Stack.Screen
        name="AttachmentEdit"}
        {component={AttachmentEdit}
        options={({ route }) => ({
          title: 'Attachment',
          headerTitle: trackHeaderTitle({ track: { id: route.params.trackID } }),
        })}
      /> */}

      {/* <Stack.Screen
        component={TrackSessions}
        name="TrackSessions"
        options={({ route }) => ({
          title: 'Sessions',
          headerTitle: trackHeaderTitle({ subtitle: ["Sessions"], track: { id: route.params.trackID } }),
        })}
      /> */}
      <Stack.Screen
        name="TrackSplitsNew"
        component={SongwritingSplitsNew}
        options={({ route }) => ({
          title: 'New Splits',
          headerTitle: trackHeaderTitle({ track: { id: route.params.trackID } }),
        })}
      />
      <Stack.Screen
        component={SongwritingSplits}
        name="TrackSplits"
        options={({ route }) => ({
          title: 'Songwriting Splits',
          headerTitle: trackHeaderTitle({ subtitle: ["Songwriting Splits"], track: { id: route.params.trackID } }),
        })}
      />
      <Stack.Screen
        name="TrackPointsNew"
        component={MasterPointsNew}
        options={({ route }) => ({
          title: 'New Splits',
          headerTitle: trackHeaderTitle({ track: { id: route.params.trackID } }),
        })}
      />
      <Stack.Screen
        component={MasterPoints}
        name="TrackPoints"
        options={({ route }) => ({
          title: 'Splits',
          headerTitle: trackHeaderTitle({ subtitle: ["Master Points"], track: { id: route.params.trackID } }),
        })}
      />
      {/* /SPLITS */}
      <Stack.Screen
        name="TrackNew"
        component={TrackNew}
        options={{
          title: '',
        }}
      />
      <Stack.Screen
        component={TrackActivity}
        name="TrackActivity"
        options={({ route }) => ({
          title: 'Activity',
          headerTitle: trackHeaderTitle({ subtitle: ["Feed"], track: { id: route.params.trackID } }),
        })}
      />
      <Stack.Screen
        component={TrackCredits}
        name="TrackCredits"
        options={({ route }) => ({
          title: 'Credits',
          headerTitle: trackHeaderTitle({ subtitle: ["Credits"], track: { id: route.params.trackID } }),
        })}
      />

      <Stack.Screen
        component={TrackInfo}
        name="TrackInfo"
        options={({ route }) => ({
          title: 'Info',
          headerTitle: trackHeaderTitle({ subtitle: ["Info"], track: { id: route.params.trackID } }),
        })}
      />
      <Stack.Screen
        component={TrackRelease}
        name="TrackRelease"
        options={({ route }) => ({
          title: 'Release',
          // headerTitle: trackHeaderTitle({ subtitle: ["Release"], track: { id: route.params.trackID } }),
        })}
      />
    </>
  )
}

export default trackScreens
