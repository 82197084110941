import React, { useEffect } from "react"
import { StyleSheet, View, TouchableOpacity } from "react-native"

import { Text } from "@reveel/components/ui"
import BottomSheetTitle from '@reveel/components/core/BottomSheetTitle'
import ParticipantsList from "./ParticipantsList"
import SessionContextMenu from "./SessionContextMenu"

const PendingParticipantsSreen = {}

PendingParticipantsSreen.screen = ({ navigation, setIntermediaryView, creativeSession }) => {
  useEffect(() => {
    navigation.setOptions({
      headerRight: () => <SessionContextMenu setIntermediaryView={setIntermediaryView} creativeSession={creativeSession} />,
      headerLeft: undefined,
    })
  }, [creativeSession])

  const missingCount = creativeSession.declared_contributors_count - creativeSession.participants.length
  const title = (
    <>
      <Text center bold style={styles.h1}>
        We're missing
        {missingCount}
        {' '}
        {missingCount > 1 ? "people" : "person"}
        !
      </Text>
      <Text center small>Please make sure every contributor has joined this session before continuing</Text>
    </>
  )

  const addParticipant = (
    <TouchableOpacity onPress={() => setIntermediaryView('invite')}>
      <Text style={[styles.addContrib]} bold small primary center> + Add Missing Participant</Text>
    </TouchableOpacity>
  )
  return (
    <View style={styles.container}>
      <ParticipantsList
        header={<BottomSheetTitle title={title} />}
        footer={addParticipant}
        creativeSession={creativeSession}
      />
    </View>
  )
}

PendingParticipantsSreen.navigationOptions = () => ({
  gesturesEnabled: false,
})

export default PendingParticipantsSreen

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: 'space-around',
  },
  h1: {
    marginBottom: 10,
  },
  addContrib: {
    justifyContent: 'center',
    paddingVertical: 20,
  },
  scrollContainer: {
    flexGrow: 1,
  },
  buttonContainer: {
    flexGrow: 1,
    justifyContent: "flex-end",

    marginBottom: 10,
  },
})
