import React, { useCallback } from 'react'
import { StyleSheet, SafeAreaView } from 'react-native'

import Colors from '@reveel/constants/Colors'

import ContactSupport from '../../components/ContactSupport'
import Loading from '../../components/Loading'
import ScreenTitle from '../../components/ScreenTitle'
import TabBarIcon from '../../components/TabBarIcon'
import List from '../../components/ui/List'
import View from '../../components/ui/View'
import UpdateProfile from '../../components/updateProfile/UpdateProfile'
import useRefreshScreen from '../../helpers/useRefreshScreen'
import useInbox from '../../stores/useInbox'
import useStoreContext from '../../stores/useStoreContext'
import Invitations from './Invitations'
import PendingCreativeSessions from './PendingCreativeSessions'

const renderInBox = (c, key) => () => (
  <View px key={key} box style={{ marginBottom: 15 }}>
    {c}
  </View>
)

const InboxScreen = ({ style, contentContainerStyle, withTitle = true }) => {
  const { loading, error, tasks, refresh } = useInbox()

  const refreshInbox = useCallback(() => {
    refresh()
  }, [refresh])

  useRefreshScreen(refreshInbox)

  if(loading || error || !tasks){
    return <Loading error={error} text="Loading Pending Actions" />
  }
  const { creative_sessions: creativeSessions, invitations, user } = tasks

  const accountItems = [
    user?.warn && ((notFirst) => <UpdateProfile notFirst={notFirst} key="completeProfile" />),
  ].filter(Boolean)

  const sections = [
    invitations?.length && {
      render: renderInBox(<Invitations invitations={invitations} />),
      key: 'invitations',
    },
    creativeSessions?.length && {
      render: renderInBox(<PendingCreativeSessions creativeSessions={creativeSessions} />),
      key: 'creativeSessions',
    },
    accountItems.length && {
      render: renderInBox(<>{accountItems.map((renderI, i) => renderI(i))}</>),
      key: 'updatess',
    },
  ].filter(Boolean)

  const isEmpty = sections.length === 0

  const emptyComponent = () => (
    <View style={{ flex: 1, justifyContent: 'center' }}>
      <ScreenTitle title={['Congrats,', 'Nothing to do!']} />
    </View>
  )

  const list = () => (
    <List
      ListHeaderComponent={withTitle && <ScreenTitle title={['My', 'Inbox']} />}
      data={sections}
      refreshing={loading}
      onRefresh={refresh}
      emptyComponent={emptyComponent}
      ListFooterComponent={<ContactSupport mb8 />}
      withBorder={false}
      style={style}
    />
  )
  return (
    <View style={[styles.container, contentContainerStyle]} testID="inboxList">
      {isEmpty ? emptyComponent() : list()}
    </View>
  )
}

export const InboxTabIcon = ({ focused }) => {
  const { hasUpdateAvailable } = useStoreContext()
  const { hasUnread } = useInbox()
  const showBadge = hasUnread || hasUpdateAvailable
  const name = focused ? 'ios-mail-open' : 'ios-mail'
  return <TabBarIcon focused={focused} name={name} withBadge={showBadge} testID="todosTabIcon" />
}

export default InboxScreen

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.lightBackground,
    flex: 1,
  },
})
