import React, { useState, useEffect } from 'react'
import { StyleSheet } from 'react-native'
import View from '@reveel/components/ui/View'

import { Text } from '@reveel/components/ui'
import Loading from '@reveel/components/Loading'
import Colors from '@reveel/constants/Colors'
import BottomSheetTitle from '@reveel/components/core/BottomSheetTitle'
import { useNavigation } from '@react-navigation/native'

import Page from '@reveel/layout/Page'
import useRemoteState from '@reveel/hooks/useRemoteState'
import Breadcrumb from '../../navigation/createScreenHeader/Breadcrumb'
import { CreativeSessionProvider } from '../sessions/useCreativeSessionContext'
import { creativeSessionName } from '../../helpers/creativeSessionsFormatter'
import { ValidationConsumer, ValidationProvider, pendingCountForCS } from '../validation/validationContext'
import ContributionsList from '../contributions/ContributionsList'
import TrackScreenTitle from './TrackScreenTitle'
import routes from '../../helpers/routes'

const CreditTitle = ({ creativeSession }) => <Text light mb>{creativeSessionName(creativeSession)}</Text>

const TrackValidationsEmpty = () => {
  return (
    <View flex flexGrow alignCenter px py justifyCenter>
      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
      <View style={tw.mB4}>
        <Text xl>
          🤷🏼‍♂️
        </Text>
      </View>
      <Text>No pending contributions...</Text>
    </View>
  )
}

const TrackValidationsSuccess = () => {
  const navigation = useNavigation()
  useEffect(() => {
    // setTimeout(navigation.goBack, 5000)
  }, [navigation])

  return (
    <View flex flexGrow px py justifyCenter testID="validationSuccess">
      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
      <View style={tw.mB4}>
        <Text xl>
          🎉
        </Text>
      </View>
      <Text>
        Credits have been added to the track!
      </Text>
      <Text style={tw.mT2} light>
        You can now see them, along with all collaborators details
        when exporting the track
      </Text>
    </View>
  )
}

const initialTrack = {
  creative_sessions: [],
  can: {},
}

const TrackValidate = ({ route: { params } }) => {
  const trackID = params?.trackID
  const navigation = useNavigation()

  // track needs to be loaded here to set the title...
  // was previously in useValidationContext
  // but did not want to pass it an onLoad function
  const [track, setTrack] = useState(initialTrack)
  const { loading } = useRemoteState({
    url: `${routes.api.tracks.contributions.index(trackID)}?contributions_scope=pending&`,
    handleResponse: ({ data: { track: trackIn } }) => {
      setTrack(trackIn)
    },
  }, [trackID])
  // TODO: add navigation title
  useEffect(() => {
    navigation.setOptions({
      headerTitle: () => (
        <Breadcrumb mobileShow={0}>
          <TrackScreenTitle noColor navigatable track={{ title: track.title, id: track.id }} />
          <View>
            <Text>Pending</Text>
            <Text primary bold>Credits</Text>
          </View>
        </Breadcrumb>
      ),
      title: 'Validate Credits',
      headerStyle: {
        // height: 95,
        backgroundColor: Colors.lightBackground,
        // borderBottomWidth: 1,
        // borderBottomColor: Colors.borderGrey,
      },
    })
  }, [track.title, track.id, navigation])

  const i18nKey = track.can?.validate ? 'admin' : 'nonAdmin'
  const title = track.creative_sessions.length ? i18n.t(`tracks.ui.validate.${i18nKey}.title`) : i18n.t(`tracks.ui.validate.empty.title`)

  return (
    <Page style={{ flex: 1 }} testID="TrackValidate">
      <ValidationProvider track={track} setTrack={setTrack} loading={loading}>
        <View sheet mt-4 flexGrow>
          <BottomSheetTitle title={title} />
          <ValidationConsumer>
            {({ track: valTrack, loadingValidations, pendingCreditsCount }) => {
              const creativeSessions = valTrack.creative_sessions
              if(loadingValidations){
                return <Loading text="Loading Pending Credits" />
              }
              if(creativeSessions.length === 0){
                return <TrackValidationsEmpty />
              }
              if(pendingCreditsCount === 0){
                return <TrackValidationsSuccess />
              }
              return creativeSessions.map((creativeSession, i) => (
                <CreativeSessionProvider creativeSession={creativeSession}>
                  <View key={`pending-cs-${creativeSession.id}`} pl mt-2 border-b={i < creativeSessions.length - 1}>
                    <CreditTitle creativeSession={creativeSession} />
                    {/* <Text>
                      {pendingCreditsCount} {JSON.stringify(creativeSession)}
                    </Text> */}
                    <ContributionsList
                      creativeSession={creativeSession}
                    />
                  </View>
                </CreativeSessionProvider>
              ))
            }}
          </ValidationConsumer>
        </View>
      </ValidationProvider>
    </Page>
  )
}

export default TrackValidate

const styles = StyleSheet.create({})
