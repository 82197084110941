import * as Permissions from 'expo-permissions'
import { Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Alert } from '@reveel/vendors/alert'
import useRunEveryXTime from '../helpers/useRunEveryXTime'

const key = (name) => `@MR:${name}-request-status`

// TODO: upgrade to expo permissions
async function requestPermission(callback, {
  permissionNames,
  alertBody,
  alertTitle,
  askEvery,
}){
  const statii = await Promise.all(permissionNames.map(async (name) => await Permissions.getAsync(Permissions[name])))
  // only ask if permissions have not already been determined, because
  // iOS won't necessarily prompt the user a second time.
  if(statii.find((s) => s !== 'granted')){
    return Platform.OS === 'ios' ? askLocal() : askForReal()
  }
  callback()

  async function askSinglePermission(permissionName){
    const { status } = await Permissions.askAsync(Permissions[permissionName])
    return status
  }
  async function askForReal(){
    const statii = await Promise.all(permissionNames.map(async (name) => await askSinglePermission(name)))
    if(statii.find((s) => s !== 'granted')){
      return
    }
    return callback()
  }

  async function askLocal(){
    if(await hasAcceptedLocal()){
      return askForReal()
    }

    const showAlert = () => {
      Alert.alert(
        alertTitle, alertBody, [
          {
            text: 'Maybe later',
            onPress: () => {},
            style: 'cancel',
          },
          {
            text: 'Allow',
            onPress: acceptLocal,
          },
        ],
      )
    }
    // this only considers the first permission request count
    // as i only know of one case where both would be needed.
    useRunEveryXTime(showAlert, permissionNames[0], askEvery)
  }

  // as iOS returns 'undetermined' for denied permissions,
  // we have no way to know whether it was denied or not.
  // therefore, we assume that if Local was granted
  // native also was
  async function hasAcceptedLocal(){
    const all = await Promise.all(permissionNames.map(async (name) => (await AsyncStorage.getItem(key(name))) === '1'))
    return !all.includes(false)
  }
  async function acceptLocal(){
    permissionNames.map(async (name) => AsyncStorage.setItem(key(name), '1'))
    askForReal()
  }
}

export default requestPermission
