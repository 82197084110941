import React from 'react'
import { StyleSheet } from 'react-native'
import View from '@reveel/components/ui/View'
import { useDesktop } from '../../helpers/MediaQueries'
import RowWithActionsMobile from './mobile'
import RowWithActionsDesktop from './desktop'

const RowWithActions = (props) => {
  const isDesktop = useDesktop()
  if(isDesktop){
    return <RowWithActionsDesktop {...props} />
  }
  return <RowWithActionsMobile {...props} />
}

export default RowWithActions
