import React from 'react'
import { View, StyleSheet } from 'react-native'
import Svg, {
  Circle, Rect, Line, Path, Defs, LinearGradient, Stop,
} from 'react-native-svg'
import Colors from '@reveel/constants/Colors'

const grey = Colors.lightGrey

const ProgressBar = ({ progress = 0.1 }) => {
  const barCount = 6
  const missing = (progress * 100) % (100 / barCount)
  const progessFitToDashes = progress * 100 + missing
  return (
    <View
      style={[
        // StyleSheet.absoluteFill,
        { alignItems: 'center', justifyContent: 'center' },
      ]}
    >
      <Svg height="20" width="100%" viewBox="0 0 365 9">
        {/* <Line x1="4" y1="5" x2="361" y2="5" stroke="#F4F4F4" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="40 20" /> */}
        <Line x1="4" y1="4" x2="361" y2="4" stroke="url(#paint0_linear)" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="40 20" />
        <Defs>
          <LinearGradient id="paint0_linear" x1="0" y1="8" x2="365" y2="8" gradientUnits="userSpaceOnUse">
            <Stop stopColor="#FC792C" />
            <Stop offset={`${progessFitToDashes}%`} stopColor="#F72C8B" />
            <Stop offset={`${progessFitToDashes}%`} stopColor={grey} />
            <Stop offset="1" stopColor={grey} />
          </LinearGradient>
        </Defs>
      </Svg>
    </View>
  )
}

export default ProgressBar
