import { useNavigation } from '@react-navigation/native';
import { ToDoCard } from '@reveel/components/ToDoCard';
import { Text } from '@reveel/components/ui';
import View from '@reveel/components/ui/View';
import React from 'react';
import { tw } from 'react-native-tailwindcss';
import useTrackProgress from '../dashboard/useTrackProgress';
import Loading from '@reveel/components/Loading';
import { AnimatePresence } from 'moti';
import RequestExportButton from '../credits/RequestExportButton';



const ThingsToDo = ({ trackID }: { trackID: string | number }) => {
  const navigation = useNavigation()
  const { changeStatus, progress } = useTrackProgress(trackID)
  const filteredCards = cards.filter(({ name }) => progress?.[name] === 'todo')
  const content = (() => {
    if(!progress){
      return <Loading size="small" />
    }
    if(!filteredCards.length){
      return <View>
          <Text style={[tw.text4xl, tw.mB2]}> 🎉</Text>
          <Text style={[tw.fontBold]}>
            You're all done!
          </Text>
          <RequestExportButton style={[tw.mT8]} trackID={trackID} />
        </View>
    }
    return filteredCards.map(({ name, onPress }) => (<ToDoCard
        key={name}
        localeKey={name}
        onPress={() => onPress({ navigation, trackID })}
        onCancel={() => changeStatus(name, 'disabled')}
    />
    ))
  })()
  return (<>
    {Boolean(filteredCards.length) && <Text style={[tw.fontBold, tw.textXl, tw.pT8]}>Things to do</Text>}
    <View style={[tw.mY8, { gap: 16 }]}>
      <AnimatePresence>
        {content}
      </AnimatePresence>
    </View>
  </>)
}

export default ThingsToDo


const cards = [
  {
    name: 'credits',
    onPress: ({ navigation, trackID }) => navigation.navigate('TrackCredits', { trackID }),
  },
  {
    name: 'songwriting_splits',
    onPress: ({ navigation, trackID }) => navigation.navigate('TrackSplits', { trackID }),
  },
  {
    name: 'master_points',
    onPress: ({ navigation, trackID }) => navigation.navigate('TrackPoints', { trackID }),
  },
  {
    name: 'details',
    onPress: ({ navigation, trackID }) => navigation.navigate('TrackEditMetadata', { trackID }),
  },
]