import React, { useEffect, useMemo, useState } from "react"
import { Pressable } from "react-native"

import { Ionicons } from '@expo/vector-icons'
import { useLinkTo } from '@react-navigation/native'
import { Text } from '@reveel/components/ui'
import { useDesktop } from '@reveel/helpers/MediaQueries'
import routes from '@reveel/helpers/routes'
import { tw } from 'react-native-tailwindcss'
import UserAvatar from 'react-native-user-avatar'

import useStoreContext from "../../stores/useStoreContext"
import View from "../ui/View"
import LinkWrapper from '../utils/LinkWrapper'

export const ProjectListItem = ({ project, style }) => {
  const { id, artwork_url, main_artist, title, tracks } = project
  const [currentTrack] = useStoreContext('currentTrack')
  const selected = useMemo(() => id === currentTrack?.project_id, [currentTrack])
  const isDesktop = useDesktop()
  const avatarSize = isDesktop ? 30 : 45
  const hasMultipleTracks = tracks.length > 1
  const [isOpen, setIsOpen] = useState(false)
  const linkTo = useLinkTo()
  const onProjectClick = () => {
    if(!hasMultipleTracks){
      linkTo(routes.front.tracks.show(tracks[0].id))
      return
    }
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    if(selected){
      setIsOpen(true)
    }
  }, [selected])
  const wrapperSelectedStyles = (() => {
    if(!selected){
      return
    }
    if(hasMultipleTracks){
      return [tw.borderB, tw.borderT, tw.borderGray500, tw.bgLightBackground]
    }
    return [tw.bgPrimary]
  })()

  const textStyles = (() => {
    if(!selected || hasMultipleTracks){
      return {
        bottom: [tw.textLightBlack],
      }
    }
    return {
      top: [tw.textWhite, tw.fontBold],
      bottom: [tw.textWhite],
    }
  })()
  return (
    <View style={wrapperSelectedStyles}>
      <Pressable
        style={[
          tw.flexRow, tw.itemsCenter, tw.pY2,
          style,
        ]}
        testID={hasMultipleTracks ? `project-${id}` : `track-${project.tracks[0]?.id}`}
        onPress={onProjectClick}
      >
        {({ hovered }) => (
          <>
            <View style={[tw.mR2]}>
              <UserAvatar size={avatarSize} name={title || '?'} src={artwork_url || ''} />
            </View>
            <View flex>
              <Text style={[textStyles.top]}>{title}</Text>
              <Text style={[tw.textSm, textStyles.bottom]}>{main_artist}</Text>
            </View>
            {(hasMultipleTracks && hovered) && (
              <Ionicons
                name={`caret-${!isOpen ? 'down' : 'up'}`}
                style={[tw.textIconGrey]}
              />
            )}
          </>
        )}
      </Pressable>
      {hasMultipleTracks && isOpen && (
      <View style={[tw.mT1]}>
        {tracks.map((t) => (
          <LinkWrapper
            key={`track-${t.id}`}
            style={[tw.pL12, tw.pY2, currentTrack?.id === t.id && tw.bgPrimary]}
            to={routes.front.tracks.show(t.id)}
          >
            <Text style={currentTrack?.id === t.id && [tw.textWhite, tw.fontBold]}>{t.title}</Text>
          </LinkWrapper>
        ))}
      </View>
      )}
    </View>
  )
}
