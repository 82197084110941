import Colors from '@reveel/constants/Colors'
import React from 'react'
import './PageWrapper.css'
import ReveelLogo from '../../../../assets/images/brand/reveel_light.svg'

import PageWrapperMobile from './mobile'
import { useMobile } from '../../../helpers/MediaQueries'

const PageWrapper = ({ children, xl }) => {
  // TODO: add footer with app store links etc
  const isMobile = useMobile()
  if(isMobile){
    return (
      <PageWrapperMobile>
        {children}
      </PageWrapperMobile>
    )
  }
  return (
    <div
      style={{
        ...styles.page,
        ...(xl ? styles.pageXl : {}),
      }}
      className="bg-stripes"
    >
      <div style={styles.logoContainer}>
        <ReveelLogo style={styles.logo} />
      </div>
      <div style={{
        ...styles.container,
        ...(xl ? styles.containerXl : {}),
      }}
      >
        {children}
      </div>
    </div>
  )
}

PageWrapper.propTypes = {
}
export default PageWrapper

const styles = ({
  page: {
    backgroundColor: Colors.lightBackground,
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    gridTemplateColumns: '1fr 680px 1fr',
    gridTemplateRows: '1fr minmax(600px, 100vh) 1fr',
    flex: 1,
  },
  pageXl: {
    gridTemplateColumns: '1fr min(1280px, 100vw) 1fr',
    gridTemplateRows: 'auto minmax(80vh, 1fr) auto',
  },
  container: {
    gridColumn: '2/3',
    gridRow: '2/3',
    borderRadius: 20,
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: Colors.lightBackground,
    margin: 15,
    maxHeight: 600,
  },
  containerXl: {
    maxWidth: '1280px',
  },
  logoContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    opacity: 0.2,
    mixBlendMode: 'overlay',
  },
  logo: {
    width: '100%',
    height: 'auto',
    display: 'block',
  },
})
