// @flow

import variable from "./../variables/platform";

export default (variables /*: * */ = variable) => {
  const platformStyle = variables.platformStyle;
  const platform = variables.platform;
  const lightCommon = {
    "NativeBase.Text": {
      color: variables.brandLight
    },
    "NativeBase.Icon": {
      color: variables.brandLight
    },
    "NativeBase.IconNB": {
      color: variables.brandLight
    }
  };
  const primaryCommon = {
    "NativeBase.Text": {
      color: 'white'
    },
    "NativeBase.Icon": {
      color: variables.btnPrimaryBg
    },
    "NativeBase.IconNB": {
      color: variables.btnPrimaryBg
    }
  };
  const buttonTheme = {
    ".disabled": {
      ".transparent": {
        backgroundColor: 'rgba(0,0,0,0)',
        "NativeBase.Text": {
          color: 'blue'
        },
        "NativeBase.Icon": {
          color: variables.btnDisabledBg
        },
        "NativeBase.IconNB": {
          color: variables.btnDisabledBg
        }
      },
      "NativeBase.Icon": {
        color: variables.brandLight
      },
      "NativeBase.IconNB": {
        color: variables.brandLight
      },
      backgroundColor: variables.btnDisabledBg,
      ".primary": {
        "NativeBase.Text": {
          color: 'white'
        },
      }
    },
    ".bordered": {
      "NativeBase.Text": {
        color: variables.brandPrimary,
      },
      ".light": {
        ...lightCommon,
        backgroundColor: "transparent",
        borderColor: variables.brandLight,
        borderWidth: variables.borderWidth * 2
      },
      ".primary": {
        ...primaryCommon,
        backgroundColor: "transparent",
        borderColor: variables.btnPrimaryBg,
        borderWidth: variables.borderWidth * 2,
        "NativeBase.Text": {
          color: variables.btnPrimaryBg,
        }
      },
      ".disabled": {
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: variables.btnDisabledBg,
        borderWidth: variables.borderWidth * 2,
        "NativeBase.Text": {
          color: variables.btnDisabledBg
        }
      },

      borderWidth: variables.borderWidth * 2,
      elevation: null,
      shadowColor: null,
      shadowOffset: null,
      shadowOpacity: null,
      shadowRadius: null,
      backgroundColor: "transparent"
    },

    ".light": {
      ".transparent": {
        ...lightCommon,
        backgroundColor: 'rgba(0,0,0,0)'
      },
      ".bordered": {
        ...lightCommon
      },
      backgroundColor: variables.brandLight
    },

    ".primary": {
      // ".bordered": {
      //   "NativeBase.Text": {
      //     color: variables.brandPrimary,
      //   },
      // },
      "NativeBase.Text": {
        color: 'red',
      },
      backgroundColor: variables.btnPrimaryBg
    },

    ".block": {
      justifyContent: "center",
      alignSelf: "stretch"
    },

    ".full": {
      justifyContent: "center",
      alignSelf: "stretch",
      borderRadius: 0
    },

    ".transparent": {
      backgroundColor: "rgba(0,0,0,0)",
      elevation: 0,
      shadowColor: null,
      shadowOffset: null,
      shadowRadius: null,
      shadowOpacity: null,
      ...primaryCommon,
      ".primary": {
        ...primaryCommon,
        backgroundColor: 'rgba(0,0,0,0)'
      },
      ".light": {
        ...lightCommon,
        backgroundColor: 'rgba(0,0,0,0)'
      },
      ".disabled": {
        backgroundColor: "rgba(0,0,0,0)",
        borderColor: variables.btnDisabledBg,
        borderWidth: variables.borderWidth * 2,
        "NativeBase.Text": {
          color: variables.btnDisabledBg
        },
        "NativeBase.Icon": {
          color: variables.btnDisabledBg
        },
        "NativeBase.IconNB": {
          color: variables.btnDisabledBg
        }
      }
    },

    ".small": {
      height: 30,
      minWidth: null,
      "NativeBase.Text": {
        fontSize: 14
      },
      "NativeBase.Icon": {
        fontSize: 20,
        paddingTop: 0
      },
      "NativeBase.IconNB": {
        fontSize: 20,
        paddingTop: 0
      }
    },

    ".large": {
      height: 60,
      "NativeBase.Text": {
        fontSize: 22,
      }
    },

    ".vertical": {
      flexDirection: "column",
      height: null
    },

    "NativeBase.Text": {
      fontFamily: variables.btnFontFamily,
      marginLeft: 0,
      marginRight: 0,
      fontSize: variables.btnTextSize,
      paddingHorizontal: 16,
      backgroundColor: "rgba(0,0,0,0)",
      fontWeight: 'bold',
      // childPosition: 1
    },

    "NativeBase.Icon": {
      color: variables.inverseTextColor,
      fontSize: 24,
      marginHorizontal: 16,
      paddingTop: platform === "ios" ? 2 : undefined
    },
    "NativeBase.IconNB": {
      color: variables.inverseTextColor,
      fontSize: 24,
      marginHorizontal: 16,
      paddingTop: platform === "ios" ? 2 : undefined
    },

    ".iconLeft": {
      "NativeBase.Text": {
        marginLeft: 0
      },
      "NativeBase.IconNB": {
        marginRight: 0,
        marginLeft: 16
      },
      "NativeBase.Icon": {
        marginRight: 0,
        marginLeft: 16
      }
    },
    ".iconRight": {
      "NativeBase.Text": {
        marginRight: 0
      },
      "NativeBase.IconNB": {
        marginLeft: 0,
        marginRight: 16
      },
      "NativeBase.Icon": {
        marginLeft: 0,
        marginRight: 16
      }
    },
    ".picker": {
      "NativeBase.Text": {
        ".note": {
          fontSize: 16,
          lineHeight: null
        }
      }
    },

    paddingVertical: variables.buttonPadding,
    // paddingHorizontal: variables.buttonPadding + 10,
    backgroundColor: variables.btnPrimaryBg,
    borderRadius: variables.borderRadiusBase,
    borderColor: variables.btnPrimaryBg,
    borderWidth: null,
    height: 45,
    alignSelf: "flex-start",
    flexDirection: "row",
    elevation: 2,
    shadowColor: platformStyle === "material" ? variables.brandDark : undefined,
    shadowOffset: platformStyle === "material" ? { width: 0, height: 2 } : undefined,
    shadowOpacity: platformStyle === "material" ? 0.2 : undefined,
    shadowRadius: platformStyle === "material" ? 1.2 : undefined,
    alignItems: "center",
    justifyContent: "center",
    minWidth: 120,
    borderRadius: variables.borderRadiusLarge,
  };

  return buttonTheme;
};
