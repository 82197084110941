import React, { useState, useEffect } from 'react'
import { StyleSheet } from 'react-native'

import View from '@reveel/components/ui/View'
import PropTypes from 'prop-types'

import Pill from '../Pill'

const Checkboxes = ({ 
  style,
  onChange,
  options,
  selected = [],
  idKey = 'id',
  displayKey = 'label'
}) => {
  const [checked, setChecked] = useState(selected)
  const toggleSkill = (option) => {
    const uncheckOption = () => setChecked(checked.filter((o) => o[idKey] !== option[idKey]))
    const checkOption = () => setChecked([...checked, option])

    const optionWasChecked = checked.find((o) => o[idKey] === option[idKey])
    // eslint-disable-next-line no-unused-expressions
    optionWasChecked ? uncheckOption() : checkOption()
  }

  useEffect(() => {
    onChange(checked)
  }, [checked, onChange])

  const checkedIDs = checked.map((o) => o[idKey])
  return (
    <View style={style}>
      <View style={[tw.flexRow, tw.flexWrap]}>
        {
          options.map((o) => (
            <Pill
              key={`option-${o[idKey]}`}
              content={o[displayKey]}
              _testID={`option-${o[displayKey]}`}
              onPress={() => toggleSkill(o)}
              selected={checkedIDs.includes(o[idKey])}
              style={[tw.mR2, tw.mB2]}
            />
          ))
        }

      </View>
    </View>
  )
}

Checkboxes.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,

}
export default Checkboxes

const styles = StyleSheet.create({})
