import React, { useMemo, useState } from 'react'

import { useNavigation } from '@react-navigation/native'
import Page from '@reveel/layout/Page'
import useStoreContext from '@reveel/stores/useStoreContext'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'

import Loading from '@reveel/components/Loading'
import FormItems from '@reveel/components/ui/Form'
import processErrors from '@reveel/helpers/processErrors'
import routes from '@reveel/helpers/routes'
import useTrackBreadcrumb from '@reveel/navigation/useTrackBreadcrumb'
import trackMetadataFieldsList from './trackMetadataFieldsList'
import { useTrack } from '@reveel/hooks/queries/track';
import TrackArtwork from '../../components/tracks/TrackArtwork'
import { View } from 'react-native';
import { useDesktop } from '@reveel/helpers/MediaQueries';

const useTrackMutation = (trackID, { onSuccess }) => {
  const key = ['track', trackID]
  const route = routes.api.tracks.show(trackID)
  const queryClient = useQueryClient()
  const [errors, setErrors] = useState()

  const mutation = useMutation(
    async ({ project: project_attributes, ...track }) => axios.put(route, { track: { project_attributes, ...track } }),
    {
      onMutate: async (track) => {
        await queryClient.cancelQueries(key)
        const previousValue = queryClient.getQueryData(key)
        queryClient.setQueryData(key, () => track)
        setErrors()
        return previousValue
      },
      onError: (err, variables, previousValue) => {
        queryClient.setQueryData(key, previousValue)
        setErrors(processErrors(err))
      },
      onSuccess: (data, variables, context) => {
        // maybe no need to invalidate query
        // if queryClient.setQueryData was successful?
        queryClient.invalidateQueries(key)
        queryClient.invalidateQueries(['projects'])
        // replace this with invalidating the tracksIndex key(not yet implemented)
        onSuccess?.(data, variables, context)
      },
    },
  )
  return {
    ...mutation,
    errors,
  }
}

const TrackMetadataScreen = ({ navigation, route }) => {
  // const navigation = useNavigation()

  const trackID = route.params?.trackID
  useTrackBreadcrumb(trackID, ['Edit Details'])
  const { isLoading, track } = useTrack(trackID)
  const { mutate, isLoading: isUpdating, errors } = useTrackMutation(trackID, {
    onSuccess: () => {
      navigation.replace('TrackInfo', { trackID })
    },
  })
  const isDesktop = useDesktop()
  if(isLoading){
    return <Loading />
  }

  const formProps = {
    onSubmit: mutate,
    formObject: track,
    labelType: 'stackedLabel',
    errors,
    fields: trackMetadataFieldsList,
    disableAll: !track.can?.edit,
    loading: isUpdating,
    i18nPrefix: 'tracks.fields',
  }
  return (
    <Page sheet fixPage >
      <View style={[isDesktop && tw.flexRow]}>
        <TrackArtwork trackID={trackID} size={200} style={[tw.mX4, tw.mT4, tw.selfCenter]} />
        <FormItems {...formProps} withKeyboardSpacer />
      </View>
    </Page>
  )
}

export default TrackMetadataScreen
