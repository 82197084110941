import React from 'react'
import { View, StyleSheet } from 'react-native'
import Pill from '../ui/Pill'

const SkillPillList = ({
  skills, onPress, isSelected = () => true,
  ...props
}) => (
  <View testID="skillPills" style={styles.container} {...props}>
    {skills.map((s) => (
      <Pill
        key={`sk-pill-${s.id}`}
        content={s.name}
        _testID={`skill-pill-${s.id}`}
        onPress={(onPress && (() => onPress(s)))}
        selected={isSelected(s)}
        style={[tw.mR2, tw.mB2]}
      />
    ))}
  </View>
)
export default SkillPillList

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
})
