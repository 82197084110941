import React from "react"
import { SafeAreaView } from 'react-native'

import List from "../../components/ui/List"
import useSkills from "./useSkills"

const MySkillsOverviewScreen = ({ navigation }) => {
  const [mySkills, toggleSkill] = useSkills()

  const data = [
    {
      title: "Add Skill",
      key: "add-skill",
      rightIconName: "ios-add-circle-outline",
      onPress: () => navigation.navigate("SkillProfessions"),
    },
    {
      divider: "My Skills",
      key: "my-skills",
    },
    // mySkills is a Set
    ...[...mySkills]
      .sort((a, b) => a.name > b.name)
      .map((s) => ({
        key: `${s.id}`,
        title: s.name,
        rightIconName: 'ios-close',
        onPress: () => toggleSkill(s),
      })),
  ]
  return (
    <SafeAreaView>
      <List data={data} withBorder={false} />
    </SafeAreaView>
  )
}

MySkillsOverviewScreen.navigationOptions = () => ({
  title: "Skills",
  headerMode: "none",
})
export default MySkillsOverviewScreen
