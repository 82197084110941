import React, { useState } from 'react'
import { TouchableOpacity, StyleSheet, Linking } from 'react-native'
import withNavigation from '@reveel/helpers/withNavigation'
import axios from 'axios'

import KeyboardSpacerIOS from '@reveel/components/KeyboardSpacerIOS'
import { Text } from '../../components/ui'
import FormItems from '../../components/ui/Form'
import routes from '../../helpers/routes'
import { useAuth } from '../../stores/useAuth'
import processErrors from '../../helpers/processErrors'
import ScreenTitle from '../../components/ScreenTitle'
import View from '@reveel/components/ui/View';
import { Platform } from 'react-native';

const fields = [
  {
    name: 'email',
    required: true,
    inputProps: {
      textContentType: 'emailAddress',
      keyboardType: 'email-address',
      autoCompleteType: 'email',
      autoCapitalize: 'none',
    },
  },
  {
    name: 'password',
    required: true,
    inputProps: {
      secureTextEntry: true,
      autoCompleteType: 'password',
      autoCapitalize: 'none',
    },
  },
]

function LoginForm({ switchView, navigation }){
  const [errors, setErrors] = useState()
  const [loading, setLoading] = useState(false)
  const { setUser } = useAuth()

  const signIn = async (userDetails) => {
    setErrors(undefined)
    setLoading(true)

    try{
      const { data } = await axios.post(routes.api.user.signIn, { user: userDetails })
      axios.defaults.headers.common["X-AUTH-TOKEN"] = data.auth_token
      setUser(data)
    }catch(err){
      setErrors(processErrors(err))
    } finally {
      setLoading(false)
    }
  }
  const linkToSignup = (
    <View style={styles.center} row justifyCenter>
      <Text center light small>
        Don&apos;t have an account yet?&nbsp;
      </Text>
      <TouchableOpacity onPress={switchView}>
        <Text center small style={{ textDecorationLine: 'underline'}}>
          Join Now
        </Text>
      </TouchableOpacity>
    </View>
  )

  const onForgotPasswordClick = () => Platform.OS === 'native' 
    ? navigation.navigate('ForgotPassword')
    : Linking.openURL(routes.web.forgotPassword)
  const forgotPassword = (
    <View style={styles.center}>
      <TouchableOpacity onPress={onForgotPasswordClick}>
        <Text center light small underline mb>
          Forgot your password?
        </Text>
      </TouchableOpacity>
    </View>
  )
  return (
    <View flex pl-2 pr-2 pb style={styles.container}>
      <FormItems
        renderHeader={() => <ScreenTitle style={styles.title} title={['Welcome', 'back!']} />}
        onSubmit={signIn}
        buttonText="Sign in"
        errors={errors}
        floatingLabels
        loading={loading}
        fields={fields}
        buttonContainerStyle={styles.buttonContainer}
      />
      <View style={styles.linksContainer}>
        {forgotPassword}
        {linkToSignup}
      </View>
      <KeyboardSpacerIOS />
    </View>
  )
}

export default withNavigation(LoginForm)

const styles = StyleSheet.create({
  container: {
    padding: 15,
  },
  title: {
    marginTop: 0,
    marginLeft: 10,
  },
  linksContainer: {
    marginTop: 20,
  },
  center: {
    textAlign: 'center',
  },
  buttonContainer: {
    marginTop: 50,
  },
})
