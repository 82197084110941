import React from 'react'
import View from '@reveel/components/ui/View'
import { Text } from '@reveel/components/ui'
import Bot from '@reveel-assets/images/bot.svg'
import { tw } from 'react-native-tailwindcss'
import UserAvatar from 'react-native-user-avatar'
import { PropTypes } from 'prop-types';

const SplitsNotification = ({ message }) => {
  const {
    meta: {
      splits,
      event,
      type,
      user,
    },
  } = message
  const { name } = user
  const renderSplit = (split) => {
    const { id: splitId, profile: { display_name, avatar_url }, value } = split
    return (
      <View key={splitId} style={[tw.flexRow, tw.itemsCenter, tw.pT1]}>
        <View style={tw.mR1}>
          <UserAvatar
            name={display_name}
            src={avatar_url}
            size={18}
          />
        </View>
        <Text smaller>
          {i18n.t(`splits.message.${type}.split`, { value, user_name: display_name })}
        </Text>
      </View>
    )
  }
  return (
    <View style={[tw.flexRow, tw.itemStart, tw.pY2]}>
      <View style={[tw.pL2, tw.pR3]}>
        <Bot width={40} height={40} />
      </View>
      <View>
        <Text>{i18n.t(`splits.message.${type}.${event}`, { user_name: name })}</Text>
        {/* splits should only be accessible to who owns them,
          and you should only be able to see your own splits(except if admin)
        */}
        {/* {splits.map(renderSplit)} */}
      </View>
    </View>
  )
}

SplitsNotification.propTypes = {
  message: PropTypes.shape({
    meta: PropTypes.shape({
      type: PropTypes.string.isRequired,
      user: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      splits: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
        profile: PropTypes.shape({
          display_name: PropTypes.string,
          avatar_url: PropTypes.string,
        }),
      })).isRequired,
    }).isRequired,
  }).isRequired,
}
export default SplitsNotification
