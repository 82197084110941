import React, { useState, useEffect } from 'react'
import {
  View, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, Platform,
} from 'react-native'
import {
  ListItem, Body, Text, Icon,
} from 'native-base'
import PropTypes from 'prop-types'
import SearchInput from './ui/SearchInput'
import List from './ui/List'
import Colors from '../constants/Colors'

const Item = ({
  text, renderIcon, onPress, isSelected,
}) => (
  <TouchableOpacity
    disabled={!onPress}
    onPress={onPress}
    style={styles.item}
  >
    <Text>{text}</Text>
    {isSelected && checkmark()}
  </TouchableOpacity>
)

const checkmark = () => <Icon style={{ color: Colors.tintColor, fontSize: 25 }} name={Platform.OS === 'ios' ? 'ios-checkmark' : 'checkmark'} />

// To allow for the child Input to reset its
// value, it must pass it its controlled input.
// A context did not work as 2 of these could not
// mount simultaneously, without passing the context
// down. in the end it's that same as passing the
// prop down..
// Result is, InviteUserByUserName must implement
// the clearing of the field 🤔😑

export const useSearchableDropdown = (initialState, cb) => {
  const [v, setV] = useState(initialState)
  useEffect(() => { cb(v) }, [v])
  return [v, setV]
}
const SearchableDropdown = ({
  items,
  selected,
  onSearch,
  onSelect,
  placeholder = 'Search',
  nameExtractorKey = 'name',
  minQueryLength = 0,
  parentState,
  testID,
  renderItem = (item, { isSelected, ...ref }) => (
    <Item
      text={item[nameExtractorKey]}
      isSelected={isSelected}
      onPress={() => {
        onSelect(item, ref)
        ref.resetTerm()
      }}
      style={styles.item}
    />
  ),
  inputProps = {},
  style,
}) => {
  const [value, setValue] = parentState || useState('')

  const resetTerm = () => setValue('')
  const mapItems = (items__) => items__.map((item, index) => {
    const isSelected = selected && Boolean(selected.find((sel) => sel.id === item.id))
    return ({
      title: item[nameExtractorKey],
      key: `search-${item.id}`,
      testID: `search-${testID}-${item.id}`,
      render: () => renderItem(item, { isSelected, index, resetTerm }),
    })
  })

  const [showResults, setShowResults] = useState(false)
  const onChangeText = (query) => {
    setValue(query)
    const queryValid = query && query.length >= minQueryLength
    if(typeof onSearch === 'function' && queryValid){
      onSearch(query)
    }
    setShowResults(Boolean(queryValid))
  }

  useEffect(() => {
    onChangeText(value)
  }, [value])
  return (
    <>
      <View style={[styles.container, style]} >
        <List
          ListHeaderComponent={(
            <SearchInput
              placeholder={placeholder}
              onChangeText={onChangeText}
              rounded={false}
              parentState={[value, setValue]}
              appearance="noBorder"
              {...inputProps}
              testID={testID}
            />
          )}
          style={styles.searchResults}
          data={showResults && mapItems(items)}
          emptyComponent={<ListItem noBorder style={styles.item}><Body><Text>No results...</Text></Body></ListItem>}
          nestedScrollEnabled
          keyboardShouldPersistTaps="always"
        />
      </View>
    </>
  )
}

SearchableDropdown.propTypes = {
  items: PropTypes.array,
  selected: PropTypes.arrayOf(PropTypes.object),
  onSearch: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
}
export default SearchableDropdown

const styles = StyleSheet.create({
  container: {
    zIndex: 2,
    overflow: 'hidden',
    // to follow native-base's Item.rounded 30 borderRadius...
    borderRadius: 17,
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    backgroundColor: Colors.lightBackground,
  },
  item: {
    backgroundColor: Colors.lightBackground,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8,
    paddingLeft: 25,
    paddingRight: 8,
  },
  searchResults: {
    maxHeight: 140,
    width: '100%',
    // position: 'absolute',
  },
  background: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 1,
  },
})
