import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import AuthenticationScreen from '../screens/authentication/AuthenticationScreen'
import WelcomeScreen from '../screens/welcome_screens'
import routes from '../helpers/routes'
import WebView from '../components/WebView'

const Stack = createStackNavigator()

const AuthenticationNavigator = () => {
  const navigatorProps = {
    initialRouteName: 'Welcome',
  }

  return (
    <Stack.Navigator
      {...navigatorProps}
    >
      <Stack.Screen
        name="Welcome"
        component={WelcomeScreen}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="AuthForms"
        component={AuthenticationScreen}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="ForgotPassword"
        options={{
          title: 'Forgot My Password',
          headerBackTitleVisible: false,
        }}
        initialParams={{
          source: {
            uri: `${routes.web.forgotPassword}?&app=1`,
          },
        }}
        component={WebView}
      />
    </Stack.Navigator>
  )
}

export default React.memo(AuthenticationNavigator)
