import React from 'react'
import HeaderBackButton from "../components/core/HeaderBackButton"
import Colors from '../constants/Colors'

const headerBackTitleStyle = { color: Colors.tintColor }
const defaultScreenOptions = (options = {}) => ({
  headerBackImage: () => <HeaderBackButton />,
  headerBackTitle: null,
  headerBackTitleStyle,
  ...options,
})
export default defaultScreenOptions
