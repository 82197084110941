import React from "react";
import { View, StyleSheet, Platform } from "react-native";
import { PropTypes } from "prop-types";

import useRemoteState from "@reveel/hooks/useRemoteState";
import { List, Text } from "../ui";
import routes from "../../helpers/routes";
import { creativeSessionName } from "../../helpers/creativeSessionsFormatter";

const defaultEmptyComponent = () => (
  <View style={{ marginVertical: 15, transform: [{ scaleY: -1 }] }}>
    <Text smaller primary>
      No Session for this track
    </Text>
  </View>
);

const SessionsList = ({
  trackID,
  navigation,
  emptyComponent = defaultEmptyComponent,
  onPress,
  selected = [],
  ...listProps
}) => {
  const url = routes.api.tracks.creativeSessions(trackID);
  const { data: creativeSessions } = useRemoteState(
    {
      url,
      initialState: [],
    },
    [trackID]
  );

  const mapToRenderObject = (session) => ({
    key: `${session.id}`,
    title: creativeSessionName(session),
    selected: Boolean(
      selected.find((selectedSession) => selectedSession.id === session.id)
    ),
    onPress: () => onPress(session),
    style: { paddingLeft: 5, marginLeft: 0, backgroundColor: "transparent" },
  });

  return (
    <List
      {...listProps}
      ListEmptyComponent={emptyComponent}
      data={creativeSessions.map(mapToRenderObject)}
      style={Platform.OS === "web" ? styles.webList : styles.mobileList}
    />
  );
};

const styles = StyleSheet.create({
  mobileList: {
    marginLeft: -10,
  },
  webList: {
    marginLeft: -10,
    height: 100,
  },
});

SessionsList.propTypes = {
  trackID: PropTypes.number.isRequired,
};

export default SessionsList;
