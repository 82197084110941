import View from '@reveel/components/ui/View'
import React from 'react'
import { Text } from '@reveel/components/ui'
import Page from '@reveel/layout/Page'
import { industryIDFields, profileFields, socialFields } from '../helpers/fields/userFieldsList'

import ChangelogScreen from '../screens/ChangelogScreen'
import PlaygroundScreen from '../screens/PlaygroundScreen'
import ShareAppScreen from '../screens/ShareAppScreen'
import SkillsByProfessionsScreen from '../screens/skills/SkillsByProfessionsScreen'
import SkillSelectScreen from '../screens/skills/SkillSelectScreen'
import SkillsOverviewScreen from '../screens/skills/SkillsOverviewScreen'
import UserUpdateHOC from '../screens/user/UserUpdateHOC'
import WebViewScreen from '../screens/user/WebViewScreen'
import { withAuth } from '../stores/useAuth'

const accountScreens = (Stack) => (
  <>
    <Stack.Screen
      name="ProfileEdit"
      component={FormInScroll}
      options={{ title: 'Profile' }}
    />

    <Stack.Screen
      name="SkillsOverview"
      component={SkillsOverviewScreen}
      options={{
        title: 'My Skills',
      }}
    />
    <Stack.Screen
      name="SkillProfessions"
      component={SkillsByProfessionsScreen}
      options={{
        title: 'Select Skills',
      }}
      initialParams={{
        nextRoute: "SelectSkills",
        useState: "useSkills",
      }}
    />
    <Stack.Screen
      name="SelectSkills"
      component={SkillSelectScreen}
      options={{
        title: 'Select Skills',
      }}
      initialParams={{
        useState: "useSkills",
      }}
    />

    <Stack.Screen
      name="ShareAppScreen"
      component={ShareAppScreen}
      options={{
        title: 'Invite Your Friends',
      }}
    />
    <Stack.Screen
      name="WebPortal"
      component={WebViewScreen}
      options={({ route }) => ({
        title: route.params?.title,
      })}
    />
    <Stack.Screen
      name="Changelog"
      component={ChangelogScreen}
      options={() => ({
        title: 'Updates',
      })}
      mode="modal"
    />

    {/* This screen is used to develop components.
    * We could probably use it to hold all of them? 🤔
  */}
    {__DEV__ && <Stack.Screen name="Playground" component={PlaygroundScreen} />}

  </>
)

export default accountScreens
const FormInScroll = withAuth(({
  user, navigation, route, ...props
}) => {
  const fields = [
    ...profileFields,
    industryIDFields,
    {
      'Social Networks': socialFields(),
    },
  ]
  const renderFooter = () => (
    <View px mb-2>
      <Text light smaller>
        logged in as:
        {user.email}
      </Text>
    </View>
  )
  return (
    <Page>
      <UserUpdateHOC
        {...props}
        fields={fields}
        renderFooter={renderFooter}
        withKeyboardSpacer
        onSuccess={navigation.goBack}
      />
    </Page>
  )
})
