import React from "react";
import { Text } from "@reveel/components/ui";
import View from "@reveel/components/ui/View";
import { useDesktop } from "@reveel/helpers/MediaQueries";
import { StyleSheet } from 'react-native';
import Colors from '@reveel/constants/Colors';

const SectionHeader = ({ text, ...props }) => {
  const isDesktop = useDesktop();
  return (
    <View py-05 px bg-white={!isDesktop} border-b={props['border-b'] || isDesktop}>
      <Text
        style={isDesktop ? styles.desktopSeparator : styles.mobileSeparator}
        larger
      >
        {text}
      </Text>
    </View>
  );
};

export default SectionHeader

const styles = StyleSheet.create({
  mobileSeparator: {
    marginTop: 5,
    paddingVertical: 10,
  },
  desktopSeparator: {
    marginTop: 5,
    paddingVertical: 10,
  },
})