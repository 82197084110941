import React from 'react'

import Bot from '@reveel-assets/images/bot.svg'
import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'
import { PropTypes } from 'prop-types'
import { tw } from 'react-native-tailwindcss'
import UserAvatar from 'react-native-user-avatar'
import { Link } from '@react-navigation/native'
import Markdown from '../../ui/Markdown';

const ProfileNotification = ({ message }) => {
  const {
    meta: {
      splits,
      event,
      type,
      profile,
    },
  } = message
  const { name, avatar_url } = profile

  return (
    <View style={[tw.flexRow, tw.alignStart, tw.pY2]}>
      <View style={[tw.pL2, tw.pR3]}>
        {avatar_url ? <UserAvatar name={name} src={avatar_url} />
          : <Bot width={40} height={40} />}
      </View>
      <View>
        <Markdown>{i18n.t(`profiles.message.${event}.title`, { name })}</Markdown>
        <Link
          to={`/profile/${profile.id}`}
          target='blank'
          style={tw.textPrimary}
        >
          <Text style={[tw.textPrimary, tw.underline]}>
            {i18n.t(`profiles.message.${event}.view`)}
          </Text>
          {' \u203A'}
        </Link>
      </View>
    </View>
  )
}

ProfileNotification.propTypes = {
  message: PropTypes.shape({
    meta: PropTypes.shape({
      type: PropTypes.string.isRequired,
      user: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      splits: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
        profile: PropTypes.shape({
          display_name: PropTypes.string,
          avatar_url: PropTypes.string,
        }),
      })).isRequired,
    }).isRequired,
  }).isRequired,
}
export default ProfileNotification
