import React from 'react'
import View from '@reveel/components/ui/View'
import i18n from 'i18n-js'
import { RUNNING } from '../../constants/Status'
import Dot from '../../components/core/Dot'
import Checkmark from '../../components/ui/Checkmark'
import ActionableItem from '../../components/core/ActionableItem'

const TracksInbox = ({ tracks, navigation }) => {
  const renderSingle = (track, notFirst) => {
    const { title, id } = track
    const size = 17
    const leftIcon = () => <Checkmark size={size * 1.7} />
    const props = {
      leftIcon,
      title: `${title}`,
      description: i18n.t(`banners.pending_credits.title`),
      buttonTitle: i18n.t(`banners.pending_credits.button`),
      bordered: true,
      testID: `track-inbox-${id}`,
      notFirst,
      navigation,
      navigateTo: ['TrackValidate', { trackID: id }],
    }
    return <ActionableItem key={`track-inbox-${id}`} {...props} />
  }
  return (
    <View testID="tracksInbox">
      {tracks.map(renderSingle)}
    </View>
  )
}

export default TracksInbox
