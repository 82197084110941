import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react'

import AsyncStorage from '@react-native-async-storage/async-storage'
import { useAsync } from '@reveel/hooks/useRemoteState'
import axios from "axios"

import { identify } from '../../analytics'
import { unregisterDevice } from '../../config/PushNotifications'
import processErrors from '../../helpers/processErrors'
import routes from '../../helpers/routes'
import AsyncKeys from "../AsyncKeys"
import authenticateWithAPI from '../authenticateWithAPI/index'
import { useOnLoad } from './useOnLoad'

import { AuthContext, User } from './index.d'

const Context = createContext<AuthContext>({})

export const useAuth = () => useContext(Context)

// initialState is needed to wrap
// storybook stories
export const AuthProvider = ({ children, initialState }) => {
  const [user, setUser] = useState<User>(initialState)
  const { initialQueryParams, setInitialQueryParams } = useOnLoad()
  const [mustConfirm, setMustConfirm] = useState<boolean>()
  const [loading, setLoading] = useState(true)
  const masquerade = useMasquerade()
  const { impersonating } = masquerade
  const id = user?.id
  const isLoggedIn = useMemo(() => user !== null, [user])
  useEffect(() => {
    if(user){
      identify(user)
      axios.defaults.headers.common["X-AUTH-TOKEN"] = user.auth_token
      AsyncStorage.setItem(AsyncKeys.USER_KEY, JSON.stringify(user))
    }
  }, [user])

  const refresh = useCallback(async () => {
    setMustConfirm(false)
    if(id && !impersonating){
      try{
        const { data } = await axios.get(routes.api.user.show(undefined))
        setUser(data)
      }catch(err){
        const msg = processErrors(err)
        if(msg === 'You have to confirm your email address before continuing.'){
          setMustConfirm(true)
          return
        }
        if(err.response.status === 401){
          logout()
        }
      }
    }
  }, [setUser, setMustConfirm, id, impersonating])

  useEffect(() => {
    (async () => {
      try{
        // eslint-disable-next-line no-underscore-dangle
        const _user = await authenticateWithAPI()
        if(_user){
          axios.defaults.headers.common["X-AUTH-TOKEN"] = _user.auth_token
          identify(_user)
        }
        setUser(_user)
      }catch(err){
        console.error('Error identifying user')
        console.log(err)
      }
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    refresh()
  }, [refresh])

  const logout = useCallback(async (cb?: () => void) => {
    await axios.delete(routes.api.user.signOut)
    delete axios.defaults.headers.common["X-AUTH-TOKEN"]
    AsyncStorage.getAllKeys().then(AsyncStorage.multiRemove)
    unregisterDevice()
    setUser(undefined)
    if(typeof cb === 'function'){
      cb()
    }
  }, [setUser])

  const can = useCallback<(flag: keyof User["beta_flags"]) => boolean>((flag) => user.beta_flags[flag], [user?.beta_flags])

  const value = {
    user,
    isLoggedIn,
    can,
    mustConfirm,
    setUser,
    loading,
    refresh,
    logout,
    masquerade,
    initialQueryParams, 
    setInitialQueryParams,
  }

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}

// eslint-disable-next-line react/display-name
export const withAuth = (Component) => (props) => (
  <Context.Consumer>
    {(contextProps) => <Component {...props} {...contextProps} />}
  </Context.Consumer>
)

export const AuthConsumer = Context.Consumer

const useMasquerade = () => {
  // TODO: stop analytics when masquerading
  const [impersonating, setImpersonate] = useState()

  const impersonate = (user_) => {
    axios.defaults.headers.common['X-AUTH-TOKEN-MASQ'] = user_.auth_token
    setImpersonate(user_)
  }

  const desimpersonate = () => {
    delete axios.defaults.headers.common['X-AUTH-TOKEN-MASQ']
    setImpersonate(null)
  }
  return {
    impersonating,
    impersonate,
    desimpersonate,
  }
}

export const useUpdateUser = (onSuccess) => {
  const { setUser } = useAuth()
  const { run, ...rest } = useAsync({
    url: routes.api.user.update(),
    method: 'put',
    handleResponse: ({ data }) => {
      setUser(data)
      onSuccess?.()
    },
  })

  const runUpdate = useCallback((userDetails) => {
    run({ user: userDetails })
  }, [run])

  return {
    run: runUpdate,
    ...rest,
  }
}
