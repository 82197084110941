import {
  PENDING_CONTRIBUTION_SUBMISSIONS, RUNNING, SUBMITTED_CONTRIBUTIONS, PENDING_VALIDATION, PENDING_CONFLICT_RESOLUTION, PENDING_MISSING_PARTICIPANTS, PENDING_SIGN_OFF, SIGNED_OFF, PAST,
} from './Status'

const tintColor = '#5C00C3'

const Colors = {

  white: '#FFFFFF',
  black: '#000000',
  tintColor,
  primary: tintColor,
  tabIconDefault: '#CCC',
  tabIconSelected: tintColor,
  tabBar: '#FEFEFE',
  errorBackground: '#F72A4F',
  errorText: '#F72A4F',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',
  disabledText: '#8C8C8C',
  disabledBackground: '#b5b5b5',
  lightGrey: '#BBB',
  lightBackground: '#F4F4F4',
  iconGrey: '#C4C4C4',
  borderGrey: '#C4C4C4',
  borderDarkGrey: '#C4C4C4',
  lightBlack: '#757575',
  placeholder: '#9A9A9A',

  tracks: {
    [PAST]: "#65B624",
    [PENDING_CONTRIBUTION_SUBMISSIONS]: "#F72A4F",
    [SUBMITTED_CONTRIBUTIONS]: "#65B624",
    [RUNNING]: "#65B624",
    [PENDING_VALIDATION]: "#FC792C",
    [PENDING_CONFLICT_RESOLUTION]: "#F72A4F",
    [PENDING_MISSING_PARTICIPANTS]: "#FC792C",
    [PENDING_SIGN_OFF]: "#FC792C",
    [SIGNED_OFF]: "#5C00C3",
  },
}
export default Colors
export const transparentize = (color, opacity = '77') => `${color}${opacity}`
