import React, { useState } from 'react'

import { Ionicons } from '@expo/vector-icons'
import { Text } from '@reveel/components/ui'
import Button from '@reveel/components/ui/Button'
import View from '@reveel/components/ui/View'
import Colors from '@reveel/constants/Colors'
import routes from '@reveel/helpers/routes'
import { useAsync } from '@reveel/hooks/useRemoteState'

import ModalWithActions from '../../../components/ui/ModalWithActions'

type IRequestExportButtonProps = {
  trackID: string | Number,
  destination: 'sound_exchange' | 'label_copy' | 'split_sheet',
  style?: any,
  buttonProps?: any,
}

const RequestExportButton = ({ 
  trackID,
  destination,
  buttonProps = {},
  style,
}: IRequestExportButtonProps) => {
  const [isSuccess, setIsSuccess] = useState(false)

  const { run: requestCredits, refreshing: loading } = useAsync({
    method: 'post',
    url: routes.api.tracks.exports(trackID),
    handleResponse: () => {
      setIsSuccess(true)
    },
  }, [trackID])

  const theLink = (
    <Button
      testID="ExportData"
      onPress={() => requestCredits({ destination })}
      loading={loading}
      title={i18n.t('tracks.ui.export.cta')}
      style={style}
      iconLeft={(
        <Ionicons
          color={Colors.white}
          size={22}
          name="ios-download"
          style={tw.mR1}
        />
      )}
      {...buttonProps}
    />
  )

  return (
    <>
      {theLink}
      <ModalWithActions
        title="Check your inbox!"
        isVisible={isSuccess}
        closeModal={() => setIsSuccess(false)}
      >
        <View style={tw.pY4}>
          <Text>You&apos;ll receive an email within a few minutes containing your label sheet as a pdf</Text>
        </View>
      </ModalWithActions>
    </>
  )
}

export default RequestExportButton
