import React from 'react'
import { StyleSheet } from 'react-native'
import Colors from '@reveel/constants/Colors'
import Loading from '../../components/Loading'
import ScreenTitle from '../../components/ScreenTitle'
import List from '../../components/ui/List'
import View from '../../components/ui/View'
import UpdateProfile from '../../components/updateProfile/UpdateProfile'
import Invitations from './Invitations'
import PendingCreativeSessions from './PendingCreativeSessions'
import TracksInbox from './TracksInbox'
import LeaveFeedbackDialog from '../../components/Feedback/LeaveFeedbackDialog'
import KeyboardSpacerIOS from '@reveel/components/KeyboardSpacerIOS'
import useInbox from '../../stores/useInbox'

const renderInBox = (c, key) => () =>
  (
    <View px key={key} box style={{ marginBottom: 15 }}>
      {c}
    </View>
  )

const Inbox = ({ navigation, style, contentContainerStyle, withTitle = true }) => {


  const { loading, error, tasks, refresh } = useInbox()

  if (loading || error || !tasks) {
    return <Loading error={error} text="Loading Pending Actions" />
  }
  const { creative_sessions: creativeSessions, tracks, invitations, user } = tasks

  const accountItems = [
    user?.warn &&
      ((notFirst) => (
        <UpdateProfile navigation={navigation} notFirst={notFirst} key="completeProfile" />
      )),
  ].filter(Boolean)

  const sections = [
    invitations?.length && {
      render: renderInBox(<Invitations navigation={navigation} invitations={invitations} />),
      key: 'invitations',
    },
    creativeSessions?.length && {
      render: renderInBox(
        <PendingCreativeSessions navigation={navigation} creativeSessions={creativeSessions} />
      ),
      key: 'creativeSessions',
    },
    tracks?.length && {
      render: renderInBox(<TracksInbox tracks={tracks} navigation={navigation} />),
      key: 'tracks',
    },
    accountItems.length && {
      render: renderInBox(<>{accountItems.map((renderI, i) => renderI(i))}</>),
      key: 'updatess',
    },
    {
      render: renderInBox(<LeaveFeedbackDialog style={[tw.pY4]} />),
      key: 'feedback',
    },
  ].filter(Boolean)

  const isEmpty = sections.length === 0

  const emptyComponent = () => (
    <View style={{ flex: 1, justifyContent: 'center' }}>
      <ScreenTitle title={['Congrats,', 'Nothing to do!']} />
    </View>
  )

  const list = () => (
    <List
      ListHeaderComponent={withTitle && <ScreenTitle title={['My', 'Inbox']} />}
      data={sections}
      refreshing={loading}
      onRefresh={refresh}
      emptyComponent={emptyComponent}
      withBorder={false}
      style={style}
    />
  )
  return (
    <View style={[styles.container, contentContainerStyle]} testID="inboxList">
      {isEmpty ? emptyComponent() : list()}
      <KeyboardSpacerIOS />
    </View>
  )
}

export default Inbox

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.lightBackground,
    flex: 1,
  },
})
