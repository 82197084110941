import React, { createContext } from 'react'

const Context = createContext({})

export const CreativeSessionProvider = ({ creativeSession, setCreativeSession, children }) => {
  const value = {
    creativeSession,
    setCreativeSession,
  }

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}

export const withCreativeSession = (Component) => (props) => (
  <Context.Consumer>
    {(contextProps) => <Component {...props} {...contextProps} />}
  </Context.Consumer>
)
