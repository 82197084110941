import React from 'react';
import { StyleSheet, Image } from 'react-native';
import { Text, View } from '@reveel/components/ui';
import FilePicker from './FilePicker';
import Colors from '@reveel/constants/Colors';

const ImagePicker = ({ url, onSelect, name, size = 80, style }) => {
  return (
    <FilePicker name={name} onSelect={onSelect}>
      {(() => {
        if(url){
          return <Image source={{ uri: url }} style={[styles.image, style, {
            width: size,
            height: size,
          }]} />
        }
        return (
          <View style={[styles.image, styles.placeholder, style, {
            width: size,
            height: size,
          }]}>
            <Text style={tw.textCenter} light>+ Add Artwork</Text>
          </View>
        )
      })()}
    </FilePicker>
  )
}

export default ImagePicker
const styles = StyleSheet.create({
  image: {
    width: 80,
    height: 80,
    borderRadius: 4,
  },
  placeholder: {
    borderColor: Colors.borderGrey,
    borderStyle: 'dashed',
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
  }
})
