import React from 'react'
import { StyleSheet, ScrollView } from 'react-native'
import { Text } from '@reveel/components/ui'
import _ from 'lodash'
import humanize from '@reveel/utils/humanize'
import Colors from '@reveel/constants/Colors'
import View from '@reveel/components/ui/View'
import SectionTitle from './SectionTitle'
import { useDesktop } from '../../helpers/MediaQueries'

const FieldsRenderer = ({ fields, subject, sectionTitleProps = {}, style }) => {
  const isDesktop = useDesktop()
  const renderSingleField = ({ name, label, inputProps = {} }) => {
    const label_ = label || humanize(name)
    let value = _.get(subject, name)
    if(inputProps.type === 'hidden'){
      return null
    }
    if(inputProps.type === 'date'){
      value = new Date(value).toLocaleDateString()
    }
    return (
      <View style={[styles.field, isDesktop && styles.fieldDesktop]}>
        <Text style={{ textTransform: label ? undefined : 'capitalize' }} light>
          {label_}
        </Text>
        <Text bold={value?.length > 0} style={styles.value} light={!value || !value?.length}>{value || ' - '}</Text>
      </View>
    )
  }

  const sectionedFields = () => Object.keys(fields).map((section) => (
    <View style={tw.mB4} key={`section-${section}`}>
      <SectionTitle {...sectionTitleProps} title={humanize(section)} style={styles.title} />
      <View row={isDesktop}>
        {fields[section].map(renderSingleField)}
      </View>
    </View>
  ))

  const mappedFields = Array.isArray(fields) ? fields.map(renderSingleField) : sectionedFields()

  return (
    <ScrollView contentContainerStyle={[styles.container, style]}>
      {mappedFields}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  title: {
    marginBottom: 20,
  },
  field: {
    marginBottom: 15,
  },
  fieldDesktop: {
    flex: 1 / 3,
    flexBasis: `${100 / 3}%`,
    flexShrink: 0,
    flexGrow: 0,
  },
  value: {
    width: "100%",
    marginTop: 5,
  },
})
export default FieldsRenderer
