import React, { useRef, useEffect } from 'react'
import { Modalize } from 'react-native-modalize'
import { useDesktop } from '../../../helpers/MediaQueries'
import ModalButton from '../../ModalButton'
import { useNavigation } from '@react-navigation/native';
import { ModalTitle } from '../ModalWithActions';

const DesktopMobileSheet = ({ renderContent, renderTrigger, title, onWillOpen }) => (
  <ModalButton
    title={title}
    content={(onClose) => renderContent({ onClose })}
    trigger={(onOpen) => renderTrigger({ onOpen })}
    onWillOpen={onWillOpen}
  />
)

type IProps = { 
  renderContent: ({ onClose }) => React.ReactElement
  renderTrigger: ({ onOpen }) => React.ReactElement,
  title: string,
  modalizeProps?: any,
  onWillOpen?: () => void,
}

const ModalSheet = ({ 
  renderContent,
  renderTrigger,
  title,
  modalizeProps = {
    modalTopOffset: 15,
  },
  onWillOpen,
}: IProps) => {
  const navigation = useNavigation()
  const modalizeRef = useRef(null)
  const onOpen = () => modalizeRef.current?.open()
  const onClose = () => modalizeRef.current?.close()
  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', onClose)
    
    return unsubscribe;
  }, [navigation, modalizeRef]);
  const isDesktop = useDesktop()
  if(isDesktop){
    return <DesktopMobileSheet {...{ title, renderContent, renderTrigger, onWillOpen }} />
  }

  return (
    <>
      {renderTrigger({ onOpen, onClose })}
      <Modalize
        withReactModal
        ref={modalizeRef}
        modalTopOffset={205}
        scrollViewProps={{
          keyboardShouldPersistTaps: "handle",
        }}
        {...modalizeProps}
      >
        <ModalTitle title={title} style={[tw.mB4]}/>
        {renderContent({ onOpen, onClose }) }
      </Modalize>
    </>
  )
}

export default ModalSheet
