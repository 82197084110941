import React, { useCallback } from 'react'

import { View } from '@reveel/components/ui'
import useRemoteState from '@reveel/hooks/useRemoteState'
import PropTypes from 'prop-types'

import routes from '../../helpers/routes'
import SearchableDropdown, { useSearchableDropdown } from '../SearchableDropdown'
import UserMini from './user/UserMini'

const MIN_QUERY_LENGTH = 2
const queryValid = (q = '') => q.length >= MIN_QUERY_LENGTH

const searchRoutes = {
  users: routes.api.user.search,
  profiles: routes.api.profile.search,
}

const SearchUser = ({
  onSelect,
  selected = [],
  placeholder = "Search by name...",
  style,
  resourceType = 'profiles',
}) => {
  const { run, data: items, setData: setItems } = useRemoteState({
    initialState: [],
    url: searchRoutes[resourceType],
    handleResponse: ({ data }) => {
      const selectedIds = selected.map((u) => u.id)
      const filteredUsers = data[resourceType].filter((u) => !selectedIds.includes(u.id))
      setItems(filteredUsers)
    },
    immediate: false,
  }, [selected])

  // sei SearchableDropdown.js for note
  const search = useCallback((q) => queryValid(q) && run({ q }), [run])
  const state = useSearchableDropdown('', search)

  const renderItem = (user, { index, isSelected, ...ref }) => {
    const onSelected = () => {
      onSelect(user)
      ref.resetTerm()
    }
    return (
      <UserMini
        isSelected={isSelected}
        key={user.id}
        onSelected={onSelected}
        user={user}
        onPress={onSelected}
        style={[tw.pY2, tw.pL6]}
      />
    )
  }
  return (
    <View style={style}>
      <SearchableDropdown
        {...{
          items, onSelect, renderItem, selected,
        }}
        minQueryLength={MIN_QUERY_LENGTH}
        placeholder={placeholder}
        clearOnSelect
        parentState={state}
        inputProps={{
          autoCorrect: false,
          autoCapitalize: 'none',
        }}
      />
    </View>
  )
}

SearchUser.propTypes = {
  resourceType: PropTypes.oneOf(['users', 'profiles']),
  onSelect: PropTypes.func.isRequired,
}

export default SearchUser
