import React, { ReactNode, useState } from 'react'
import { Dimensions } from 'react-native'
import { TabView, SceneMap, TabBar } from 'react-native-tab-view'
import Colors from '../constants/Colors'
import { setScreen } from '../analytics'
import { tw } from 'react-native-tailwindcss'

type IProps = {
  tabs: Array<{
    title: string,
    elem: ReactNode
  }>,
  tabBarProps?: any,
  routeName?: string,
  style?: any,
}

function SimpleTabs({
  tabs, tabBarProps = {}, routeName, style,
}: IProps){
  const [nav, setNav] = useState({
    index: 0,
  })

  const color = Colors.tintColor
  const renderTabBar = (props) => (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: color }}
      style={{ backgroundColor: Colors.white }}
      labelStyle={{
        backgroundColor: 'rgba(0,0,0,0)',
        alignSelf: 'center',
        fontSize: 12,
      }}
      activeColor={color}
      inactiveColor="#111"
    />
  )
  const routes = tabs.map((e) => ({ key: e.title, title: e.title }))
  const map = {}
  tabs.forEach((t) => { map[t.title] = t.elem })
  const onIndexChange = (i) => {
    routeName && setScreen(`${routeName} ${routes[i].title}`)
    setNav({ ...nav, index: i })
  }
  return (
    <TabView
      onIndexChange={onIndexChange}
      navigationState={{
        index: nav.index,
        routes,
      }}
      renderScene={SceneMap(map)}
      renderTabBar={(props) => renderTabBar({ ...props, ...tabBarProps })}
      style={[tw.flex, style]}
    />
  )
}

export default SimpleTabs
