import { View } from 'react-native';
import { Text } from '@reveel/components/ui';
import React, { ReactNode } from 'react';
import { tw } from 'react-native-tailwindcss';

type CardProps = {
  title: string,
  header: ReactNode,
  description?: string,
  actions?: ReactNode,
  style?: any
  align: 'left' | 'center'
}

const Card = ({
  header,
  title,
  description,
  actions,
  style,
  align = 'left'
}: CardProps) => {

  const center = align === 'center'
  return <View style={[
    tw.pY6,
    tw.pX4,
    tw.bgWhite,
    tw.roundedLg,
    tw.justifyCenter,
    tw.alignCenter,
    style
  ]}>
    <View style={[tw.mB4, center && tw.textCenter, center && tw.itemsCenter, !center && tw.itemsStart, !description && [tw.flexGrow, tw.justifyCenter]]}>
      {header}
    </View>
    <View style={[tw.mB6, tw.maxW5_6, center && tw.mXAuto, description ? tw.flexGrow : tw.flexShrink]}>
      <Text style={[center && tw.textCenter, tw.fontBold]}>{title}</Text>
      {description && <View style={[tw.mT4, tw.flexShrink]}>
        <Text style={center && tw.textCenter}>{description}</Text>
      </View>}
    </View>
    {actions && <View style={[tw.justifyEnd]}>
      {actions}
    </View>}
  </View>
}

export default Card
