import React, { useState, useEffect } from 'react'
import {
  View, TouchableOpacity, StyleSheet, Linking,
} from 'react-native'
import { BarCodeScanner } from 'expo-barcode-scanner'
import { Text } from 'native-base'
import * as Permissions from 'expo-permissions'

import Colors from '../constants/Colors'

const QRCodeScanner = ({ onScan }) => {
  const [hasPermission, setPermission] = useState(false)

  const [frontCamera, setFrontCamera] = useState(false)
  const toggleCamera = () => setFrontCamera(!frontCamera)

  const askPermission = async () => {
    const { status } = await Permissions.askAsync(Permissions.CAMERA)
    setPermission(status === 'granted')
  }
  useEffect(() => {
    askPermission()
  }, [])

  const notActive = (
    <TouchableOpacity onPress={() => Linking.openURL('app-settings:')}>
      <Text>Please give access to your camera in your settings </Text>
    </TouchableOpacity>
  )

  const activeView = (
    <View style={{ flex: 1 }}>
      <BarCodeScanner
        barCodeTypes={[BarCodeScanner.Constants.BarCodeType.qr]}
        onBarCodeScanned={onScan}
        type={frontCamera ? BarCodeScanner.Constants.Type.front : BarCodeScanner.Constants.Type.back}
        style={StyleSheet.absoluteFillObject}
      />
      {/* <TouchableOpacity style={styles.switchCamera} onPress={toggleCamera}>
      <Icon name="ios-reverse-camera" style={{color: Colors.tintColor}} />
    </TouchableOpacity> */}
    </View>
  )

  return hasPermission ? activeView : notActive
}

const styles = StyleSheet.create({
  switchCamera: {
    color: Colors.tintColor,
    position: 'absolute',
    right: 20,
    bottom: 10,
  },
})

export default QRCodeScanner
