import devIP from '@reveel/config/devIP'

const urls = {
  local: `//${devIP()}:3000`,
  prod: '//api.reveel.id',
  staging: '//reveel-dev.herokuapp.com',
  test: '//localhost:7357',
}

function getApiUrl(){
  if(window.location.host.indexOf('dev') !== -1){
    return urls.staging
  }
  if(window.location.search.indexOf('test=true') !== -1){
    return urls.test
  }
  if(__DEV__){
    return urls.local
  }
  return urls.prod
}

export const url = getApiUrl()
export const webAppHost = window.location.origin
export const isProd = url === urls.prod
