import React from 'react'
import { StyleSheet } from 'react-native'
import View from '@reveel/components/ui/View'
import { Text } from '@reveel/components/ui'
import { useNavigation } from '@react-navigation/native'
import { useDesktop } from '../../helpers/MediaQueries'
import LinkWrapper from '../../components/utils/LinkWrapper';

export const setTrackTitle = ({ navigation, track }) => {
  navigation.setOptions({
    headerTitle: () => <TrackScreenTitle track={track} />,
  })
}

const TrackScreenTitle = ({ track, noColor, navigatable }) => {
  const isDesktop = useDesktop()
  const navigation = useNavigation()
  if(isDesktop){
    return (
      <LinkWrapper to={`/tracks/${track.id}`}>
        {/* <Text>{i18n.t('tracks.name')}</Text> */}
        <Text bold={!noColor} primary={!noColor}>
          {track.title}
          {' '}
        </Text>
      </LinkWrapper>
    )
  }
  return <Text>{track.title}</Text>
}

export default TrackScreenTitle
