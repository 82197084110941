import React from 'react'
import { View } from '@reveel/components/ui'
import CheckmarkIcon from 'app/assets/images/ui/checkmark.svg'
import { StyleSheet } from 'react-native'
import Colors from '@reveel/constants/Colors'

const Checkmark = ({ size = 25, borderRadius = size / 2 }) => (
  <View style={[styles.container, { borderRadius }]}>
    <CheckmarkIcon width={size} height={size} />
  </View>
)
export default Checkmark

const styles = StyleSheet.create({
  container: {
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.15,
    shadowRadius: 8,
    elevation: 3,
    backgroundColor: 'white',
  },
})
