import React, { useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import NativeWebView from 'react-native-webview'
import routes from '../helpers/routes'
import { useAuth } from '../stores/useAuth'
import Loading from './Loading'

const isReveelDestination = (url) => url.indexOf(routes.url) === 0

function WebView({ navigation, route }){
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const { user } = useAuth()
  const headers = {}
  const { source, title } = route.params
  if(!source?.uri){
    return null
  }
  useEffect(() => {
    if(title){
      navigation.setOptions({ title })
    }
  }, [title, navigation])
  if(user && isReveelDestination(source.uri)){
    headers['X-AUTH-TOKEN'] = user.auth_token
  }

  const webView = (
    <NativeWebView
      useWebKit
      {...route}
      source={{
        ...source,
        headers,
      }}
      onHttpError={() => setError('Error loading page')}
      onLoadEnd={() => setLoading(false)}
    />
  )

  return (
    <>
      {webView}
      {loading && <Loading error={error} style={StyleSheet.absoluteFill} />}
    </>
  )
}

export default WebView
