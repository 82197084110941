import React from 'react'
import { StyleSheet } from 'react-native'
import View from '@reveel/components/ui/View'
import routes from '@reveel/helpers/routes'
import { useAsync } from '@reveel/hooks/useRemoteState'
import { useNavigation } from '@react-navigation/native'
import { RUNNING, PENDING_CONTRIBUTION_SUBMISSIONS } from '../../constants/Status'
import EnterContributions from '../contributions/EnterContributions'
import useTrackBreadcrumb from '../../navigation/useTrackBreadcrumb'
import Page from '@reveel/layout/Page';

const TrackSubmitCreditsScreen = ({ route: { params } }) => {
  const id = params?.trackID
  const navigation = useNavigation()
  useTrackBreadcrumb(id, ['Submit', 'Credits'])
  const { run, loading } = useAsync({
    url: routes.api.tracks.contributions.submit(id),
    method: 'post',
    handleResponse: () => {
      navigation.replace('TrackValidate', { id })
    },
  })
  const onSubmit = (contributions) => {
    const skill_ids = contributions.map((c) => c.id)
    run({
      credit: { skill_ids },
    })
  }
  const theProps = {
    onSubmit,
    buttonProps: {
      loading,
      title: 'Next',
    },
    i18nTitleKey: `creativeSessions.header.${RUNNING}.${PENDING_CONTRIBUTION_SUBMISSIONS}`,
  }
  return <Page sheet>
    <EnterContributions {...theProps} />
  </Page>
}

export default TrackSubmitCreditsScreen

const styles = StyleSheet.create({})
