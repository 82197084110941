// @flow

import Colors from "@reveel/constants/Colors"
import variable from "../variables/platform"

export default (variables /*: * */ = variable) => {
  const textTheme = {
    fontSize: variables.DefaultFontSize,
    fontFamily: variables.fontFamily,
    color: variables.textColor,
    ".light": {
      color: Colors.lightBlack,
    },
    ".primary": {
      color: variables.brandPrimary,
    },
    ".red": {
      color: variables.brandDanger,
    },
    ".white": {
      color: 'white',
    },
    ".center": {
      textAlign: 'center',
    },
    ".note": {
      color: "#a7a7a7",
      fontSize: variables.noteFontSize,
    },
    ".bold": {
      fontWeight: 'bold',
    },
    ".xs": {
      fontSize: variables.xsFontSize,
    },
    ".small": {
      fontSize: variables.smallFontSize,
    },
    ".sm": {
      fontSize: variables.smallFontSize,
    },
    ".smaller": {
      fontSize: variables.smallerFontSize,
    },
    ".larger": {
      fontSize: variables.largerFontSize,
    },
    ".lg": {
      fontSize: variables.fontSizeH2,
    },
    ".large": {
      fontSize: variables.fontSizeH1,
    },
    ".xl": {
      fontSize: 40,
    },
    ".italics": {
      fontStyle: 'italic',
    },
    '.underline': {
      textDecorationLine: 'underline',
    },
    ".py": {
      paddingVertical: 5,
    },
    ".px": {
      paddingHorizontal: 5,
    },
    ".pl": {
      paddingLeft: 15,
    },
    ".ml-1": {
      marginLeft: 10,
    },
    ".mr-2": {
      marginRight: 20,
    },
    ".ml-05": {
      marginLeft: 5,
    },
    ".mr-1": {
      marginRight: 10,
    },
    ".mr-05": {
      marginRight: 5,
    },
    ".my-1": {
      marginVertical: 10,
    },
    ".my-2": {
      marginVertical: 20,
    },
    ".mb": {
      marginBottom: 2,
    },
    ".mb_5": {
      marginBottom: 5,
    },
    ".mt-05": {
      marginTop: 5,
    },
    ".mb-1": {
      marginBottom: 10,
    },
    ".mb-2": {
      marginBottom: 20,
    },
    ".mt-2": {
      marginTop: 20,
    },
    ".mb-8": {
      marginBottom: 80,
    },
  }

  return textTheme
}
