import React from 'react';
import SessionScreen from '../../screens/sessions/SessionScreen';
import HowSessionsWork from '../../screens/sessions/HowSessionsWork';
import SkillsByProfessionsScreen from '../../screens/skills/SkillsByProfessionsScreen';
import SkillSelectScreen from '../../screens/skills/SkillSelectScreen';
import ValidationResolutionChat from '../../screens/validation/ValidationResolutionChatScreen';
import AcceptInvitationScreen from '../../screens/AcceptInvitationScreen';
import JoinSessionByQRCodeScreen from '../../screens/sessions/JoinSessionByQRCodeScreen';
import ScanQRCodeScreen from '../../screens/sessions/ScanQRCodeScreen';
import SessionNewSelectTrack from '../../screens/sessions/SessionNew/SessionNewSelectTrack';

const sessionScreens = (Stack) => (
  <>
    <Stack.Screen name="Session" component={SessionScreen} />
    <Stack.Screen name="SessionNewSelectTrack" component={SessionNewSelectTrack}
      options={({ route }) => ({
        headerTitle: null,
      })} />
    <Stack.Screen name="HowSessionsWork" component={HowSessionsWork}
      options={{
        headerShown: false,
      }}
      mode="modal" />
    <Stack.Screen name="ContribSkillProfessions" component={SkillsByProfessionsScreen}
      initialParams={{
        nextRoute: "ContribSelectSkills",
        useState: "useContributions",
      }}
      options={{
        title: "Select Contributions",
      }} />
    <Stack.Screen name="ContribSelectSkills" component={SkillSelectScreen}
      initialParams={{
        useState: "useContributions",
      }}
      options={{
        title: "Select Contributions",
      }} />

    <Stack.Screen name="ValidationResolutionChat" component={ValidationResolutionChat}
      options={({ route }) => ({
        title: 'Resolve Conflict',
      })} />
    
    <Stack.Screen name="JoinByQRCode" component={JoinSessionByQRCodeScreen}
      options={({ route }) => ({
        title: 'Join Session',
      })} />
    <Stack.Screen name="ScanUser" component={ScanQRCodeScreen}
      options={({ route }) => ({
        title: 'Scan User In',
      })} />
  </>
);

export default sessionScreens
