import React, { useState } from 'react'
import { StyleSheet } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import Loading from '@reveel/components/Loading'
import { Text } from '@reveel/components/ui'
import ModalSheet from '@reveel/components/ui/modal/ModalSheet'
import View from '@reveel/components/ui/View'
import { useTrack } from '@reveel/hooks/queries/track'
import useCreativeSession from '@reveel/screens/sessions/useCreativeSession'
import { tw } from 'react-native-tailwindcss'

import InviteUsersDialog from '../../../components/invitation/InviteUsersDialog'
import Button from '../../../components/ui/Button'
import Separator from '../../../components/ui/Separator'
import { PendingInvitation } from './PendingInvitation'
import { PermissionUser } from './PermissionUser'
import { useTrackPermissions } from './useTrackPermissions'
import { ReactNode } from 'react';

const TrackPermissions = ({ trackID, style }) => {
  const { permissions } = useTrackPermissions(trackID)

  if(!permissions){
    return <Loading />
  }
  const { users, invitations } = permissions
  const renderInvitations = () => {
    if(!invitations.length){
      return null
    }
    return (
      <>
        <Separator style={tw.mY4} />
        <Text style={[tw.mB2, tw.textLightBlack, tw.fontBold]}>Invitations pending</Text>
        {invitations.map((invitation) => <PendingInvitation key={invitation.id} invitation={invitation} />)}
      </>
    )
  }
  return (
    <View style={style}>
      <Text style={[tw.fontBold, tw.mB2]}>Permissions</Text>
      {users.map(({ user, ...part }) => (
        <PermissionUser
          trackID={trackID}
          style={tw.mY2}
          participation={part}
          key={user.id}
          user={user}
        />
      ))}
      {renderInvitations()}
    </View>
  )
}

const TrackPermissionsWrapper = ({ trackID, noModal, renderTrigger }: { trackID: number, noModal?: boolean, renderTrigger: ({ onClose, onOpen }) => ReactNode }) => {
  const { track } = useTrack(trackID)
  const { creativeSession } = useCreativeSession({ id: track?.proxy_id })
  const navigation = useNavigation()
  const [shareIsOpen, setShareIsOpen] = useState(false)

  const button = (
    <Button
      title={!shareIsOpen ? "Manage Access \u203A" : "\u2039 Back"}
      small
      noChrome={shareIsOpen}
      onPress={() => setShareIsOpen(!shareIsOpen)}
      style={[tw.selfEnd, tw.mR2]}
    />
  )
  const body = (onClose) => (
    <>
      { (() => {
        if(shareIsOpen){
          return <TrackPermissions style={[tw.mY4, tw.pX2]} trackID={trackID} />
        }
        return (
          <>
            <InviteUsersDialog
              creativeSession={creativeSession}
              navigation={navigation}
              onSuccess={onClose}
              style={[tw.pT4, tw.pX2]}
            />
          </>
        )
      })()}
      {button}
    </>
  )
  if(noModal){
    return body()
  }
  return (
    <ModalSheet
      renderTrigger={renderTrigger}
      renderContent={({ onClose }) => body(onClose)}
      onWillOpen={() => setShareIsOpen(false)}
    />
  )
}

export default TrackPermissionsWrapper

const styles = StyleSheet.create({})
