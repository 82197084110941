import React, { useRef, useEffect } from 'react'
import { View } from 'react-native'

import { Text } from '@reveel/components/ui'
import { useCountUp } from 'use-count-up'

export const StatNumber = ({ number, unit, label, decimalPlaces = 0, style, ...props }) => {
  const lastValue = useRef(0)
  
  const { value } = useCountUp({
    isCounting: true,
    start: lastValue.current,
    end: (number && !isNaN(number)) ? number : 0,
    duration: 1,
    decimalPlaces,
    autoResetKey: number,
    onComplete: () => { 
      if(!number || isNaN(number)){ return }
      lastValue.current = number
    },
    ...props,
  })

  return (
    <View style={[tw.itemsCenter, style]}>
      <Text style={[tw.text3xl, tw.fontBold]}>
        {value}
        {unit}
      </Text>
      <Text style={[tw.textLg]}>{label}</Text>
    </View>
  )
}
