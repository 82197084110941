import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, TouchableOpacity } from 'react-native'
import View from '@reveel/components/ui/View'
import UserWithText from '@reveel/components/core/UserWithText'
import { Text } from '@reveel/components/ui'
import Switch from '@reveel/components/ui/Switch'
import Collapsible from 'react-native-collapsible'

import Separator from '@reveel/components/ui/Separator'

const SplitsCandidates = ({ profiles, selected, dispatch }) => {
  const [open, setOpen] = useState(true)
  const toggler = ({ profile }) => {
    const isSelected = selected.includes(profile.id)
    const toggle = () => {
      dispatch({ type: isSelected ? 'REMOVE_USER' : 'ADD_USER', payload: { profile } })
    }
    return <Switch testID="toggleUser" onValueChange={toggle} value={isSelected} />
  }
  return (
    <View testID="allCollaborators" style={[tw.pX2, tw.mT2]}>
      {Boolean(selected.length) && (
        <TouchableOpacity style={[tw.pY2]} onPress={() => setOpen(!open)}>
          <Text primary center small>{i18n.t(`splits.form.toggleList.${open ? 'hide' : 'show'}`)}</Text>
        </TouchableOpacity>
      )}
      <Collapsible collapsed={!open}>
        {
          profiles.map((p, i) => (
            <>
              { i !== 0 && <Separator />}
              <UserWithText
                key={p.id}
                user={p}
                noBadges
                icon={toggler({ profile: p, selected })}
              />
            </>
          ))
        }
      </Collapsible>
    </View>
  )
}

SplitsCandidates.propTypes = {
  profiles: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
}
export default SplitsCandidates

const styles = StyleSheet.create({})
