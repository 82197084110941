import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Text } from 'native-base'
import Colors from '@reveel/constants/Colors'
import i18n from 'i18n-js'
import { PENDING_MISSING_PARTICIPANTS, PENDING_CONTRIBUTION_SUBMISSIONS, PAST } from '../../constants/Status'
import Dot from '../../components/core/Dot'

// ugly hack: if creative session is missing participants,
// this status should take precedence on the participation's status (
const SessionStatus = ({ creativeSession }) => (
  <View style={styles.container}>
    <Dot status={creativeSession.status} />
    <Text bold style={[styles.text, { color: Colors.tracks[creativeSession.status] }]}>
      {i18n.t(`creativeSessions.status.${creativeSession.status}`)}
    </Text>
  </View>
)

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 15,
  },
  text: {
    marginLeft: 10,
    fontSize: 14,
  },
})
export default SessionStatus
