import { Text } from '@reveel/components/ui';
import View from '@reveel/components/ui/View';
import React from "react";

// TODO:
// missing: email,
// website icon(is currently warcraft -.-)
// publisher contact
// personal info
// username
export const WithLabel = ({ style, k, value }) => !value ? null : (
  <View style={[{ flex: 1 }, style]}>
    <Text smaller light>{k}</Text>
    <Text selectable bold={value}>{value}</Text>
  </View>
);
