import React from 'react'
import View from '@reveel/components/ui/View';
import useTrackBreadcrumb from '@reveel/navigation/useTrackBreadcrumb';
import { useTrack } from '@reveel/hooks/queries/track';
import { useDesktop } from '@reveel/helpers/MediaQueries';
import Page from '@reveel/layout/Page';
import ExportCards, { SectionTitle, SectionSubTitle } from '@reveel/components/tracks/ExportCards';
import TrackMainDetails from '@reveel/components/tracks/TrackMainDetails';

const TrackRelease = ({ route }) => {
  const trackID = route.params?.trackID
  useTrackBreadcrumb(trackID, ['Release'])
  const { track } = useTrack(trackID)
  const isDesktop = useDesktop()
  return (
    <Page>
      <View>
        {isDesktop && <TrackMainDetails track={track} size={200} style={[tw.mT8]} />}
        <SectionTitle>Ready to release your track?</SectionTitle>
        <SectionSubTitle>Each of these documents will help you pass on all the exact data the music world needs to pay you what you are due, and for new fans to find out about you!</SectionSubTitle>
        <ExportCards trackID={trackID} />
      </View>
    </Page>
  )
}


export default TrackRelease
