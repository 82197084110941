import { useRoute } from '@react-navigation/native';
import TrackMainDetails from '@reveel/components/tracks/TrackMainDetails';
import { useTrack } from '@reveel/hooks/queries/track';
import Page from '@reveel/layout/Page';
import React from 'react';
import { tw } from 'react-native-tailwindcss';
import ExportCards, { SectionSubTitle, SectionTitle } from '@reveel/components/tracks/ExportCards';
import ThingsToDo from './ThingsToDo';
import useTrackBreadcrumb from '@reveel/navigation/useTrackBreadcrumb';
import { useAuth } from '@reveel/stores/useAuth';


const TrackHome = ({  }) => {
  const route = useRoute()
  const trackID = (route.params as any)?.trackID
  useTrackBreadcrumb(trackID, ['Things To Do'])
  const { track } = useTrack(trackID)
  const { user: { admin } } = useAuth()
  return (
    <Page contentContainerStyle={[tw.pB8]}>
      <TrackMainDetails
        style={[tw.mT8]}
        track={track}
      />
      <ThingsToDo trackID={trackID} />
      <SectionTitle>Ready?</SectionTitle>
      <SectionSubTitle>Get all your track data in one document to fill in your registration form, distribute, and pass on to your label</SectionSubTitle>
      <ExportCards trackID={trackID}/>
    </Page>
  )
}

export default TrackHome
