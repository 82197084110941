// @flow

import variable from "./../variables/platform";

export default (variables /*: * */ = variable) => {
  const spinnerTheme = {
    height: 80
  };

  return spinnerTheme;
};
