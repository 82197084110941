import axios from 'axios'
import AsyncStorage from '@react-native-async-storage/async-storage'

import { dateOnly } from '@reveel/utils/dateFormatter'
import * as Sentry from 'sentry-expo'
import { Alert } from '@reveel/vendors/alert'
import routes from '../../helpers/routes'
import navigation from '../../helpers/NavigationService'

import { CONTRIB_KEY } from '../contributions/useContributions'

// all these are being slowly oved to trackHooks.js
const trackStore = {
  create: async (track) => axios.post(routes.api.tracks.create, {
    track,
  }),
  addUser: async (creativeSessionId, { id }) => axios.post(routes.api.creativeSession.addParticipant(creativeSessionId), {
    participant: { id },
  }),
  acceptInvitation: async (creativeSessionId, { emailInvitationToken = undefined }) => axios.post(routes.api.creativeSession.acceptInvitation(creativeSessionId), { token: emailInvitationToken }),
  saveSession: ({ id, track, ...creative_session }) => {
    console.error('trackStore.saveSession is deprecated!')
    return axios.put(routes.api.creativeSession.update(id), {
      creative_session: {
        track_attributes: track,
        ...creative_session,
      },
    })
  },
  endSession: async ({ id, track, ...creative_session }) => axios.put(routes.api.creativeSession.end(id), {
    creative_session: {
      track_attributes: track,
      ...creative_session,
    },
  }),
  submitContributions: async ({ id, }, skill_ids) => {
    return axios.put(routes.api.contributions.submit(id), {
      contributions: skill_ids,
    })
  },
  submitValidations: async ({ id }, contributions_attributes) => axios.put(routes.api.creativeSession.submitValidations(id), { creative_session: { contributions_attributes } }),
  saveTrack: async ({
    id, project_id, project, ...track
  }) => axios.put(routes.api.tracks.update(id), {
    track: {
      ...track,
      project_attributes: {
        ...project,
        id: project_id,
      },
    },
  }),
  deleteTrack: async ({ id, title }, cb) => {
    Alert.alert(
      `Are you sure you want to delete ${title}?`, "This action cannot be undone", [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        {
          text: 'Delete',
          onPress: actuallyDelete,
          style: 'destructive',
        },
      ],
    )
    async function actuallyDelete(){
      await axios.delete(routes.api.tracks.delete(id))
      navigation.navigate('Home')
      cb?.()
    }
  },
}

export default trackStore
