/* eslint-disable import/prefer-default-export */
import React, { useCallback } from 'react'
import { useMemo } from 'react'

import { useNavigation } from '@react-navigation/native'
import SkillPillList from '@reveel/components/core/SkillPillList'
import ScreenTitle from '@reveel/components/ScreenTitle'
import { Text } from '@reveel/components/ui'
import Button from '@reveel/components/ui/Button'
import FormItems from '@reveel/components/ui/Form'
import View from '@reveel/components/ui/View'
import { emailField, firstNameField, lastNameField, locationField, phoneNumberField, stageNameField } from '@reveel/helpers/fields/userFieldsList'
import useSkills from '@reveel/screens/skills/useSkills'
import { useAuth, useUpdateUser } from '@reveel/stores/useAuth'

import { TRACK_ONBOARDED } from '../../../constants/Status'
import avatarField from '../../../helpers/fields/userFieldsList/avatarField'
import useMyProfile from '../../../stores/useMyProfile'

export const useNavigateAfterOnboarding = () => {
  const navigation = useNavigation()
  const { initialQueryParams: { afterAuth } } = useAuth()
  return () => {
    if(afterAuth){
      const { name, params } = afterAuth
      navigation.replace(name, params)
      return
    }
    // TrackOnboarding is currently disabled
    // navigation.push('TrackOnboarding')
    navigation.replace('Home')
  }
}

const SkillsList = () => {
  const [skills] = useSkills()
  return <SkillPillList skills={skills} />
}

export const OnboardingSummary = () => {
  const summaryFields = [
    avatarField,
    firstNameField,
    lastNameField,
    emailField,
    stageNameField,
    {
      name: 'top_skills',
      render: () => <SkillsList />,
    },
    locationField,
    phoneNumberField,
  ]

  const { myProfile } = useMyProfile()

  const navigateAfterOnboarding = useNavigateAfterOnboarding()
  const { run, loading } = useUpdateUser(navigateAfterOnboarding)
  const onSubmit = useCallback(() => run({ onboard_status: TRACK_ONBOARDED }), [run])

  // the onboarding currently displays the profile
  // but the email address still belongs to the user
  // and the names should be of the user
  // therefore we're merging the 2 display complete
  // user information
  // This should disappear once there is a clearer distinction
  // btw user and profile

  const formObject = {
    ...myProfile,
  }


  const renderedForm = useMemo(() => (
    myProfile && (
    <FormItems
      fields={summaryFields}
      formObject={formObject}
      onSubmit={onSubmit}
      renderBeforeButton={() => <Button loading={loading} block title="Get Started" onPress={onSubmit} />}
      withKeyboardSpacer
      renderHeader={() => (
        <>
          <ScreenTitle style={[tw.mT10, tw.mB2]} title={i18n.t('onboarding.summary.title')} />
          <View style={[tw.pX4, tw.mB4]}>
            <Text light style={tw.textSm}>{i18n.t('onboarding.summary.subtitle')}</Text>
          </View>
        </>
      )}
      buttonText="Continue"
      disableAll
    />
    )
  ), [myProfile, formObject, loading, onSubmit, summaryFields])


  return renderedForm
}
