import React, { Component, useEffect } from 'react'

import trackStore from './sessions/trackStore'
import Loading from '../components/Loading'
import { withAuth } from '../stores/useAuth'

const AuthLoadingScreen = ({ user, loading, navigation }) => {
  const redirectOnUserLoaded = () => {
    if(loading){
      return
    }
    // passing navigation is required as the _navigator hasn't been set yet on the NavigationService object at this time
    if(user && !user.beta_flags.access){
      navigation.navigate('BWall')
      return
    }
    !user ? navigation.navigate('Authenticated') : navigation.navigate('Authentication')
  }
  useEffect(() => {
    redirectOnUserLoaded()
  }, [user, loading])

  return <Loading />
}

export default withAuth(AuthLoadingScreen)
