import { useState, useEffect, useCallback } from "react"
import axios from "axios"
import AsyncStorage from '@react-native-async-storage/async-storage'

import routes from "../../helpers/routes"

const SKILL_KEY = "@MR:SKILLS"
// Uncomment to reset storage 🤷🏾‍
// AsyncStorage.removeItem(SKILL_KEY)

let listeners = []

export const refreshSkills = async (cb) => {
  try{
    const { data: skills } = await axios.get(routes.api.user.skills)
    persistToDisk(skills)
    if(typeof cb === 'function'){
      cb(skills)
    }
  }catch(err){
    console.log("error fetching skills 🤔")
    console.log(err)
  }
}

const persistToDisk = (skills) => {
  AsyncStorage.setItem(SKILL_KEY, JSON.stringify(skills.filter((s, i) => skills.indexOf(s) === i)))
}

const useSkills = () => {
  const [skills, setSkills] = useState([])
  const setStateForAll = useCallback((state) => {
    listeners.forEach((l) => l(state))
  }, [])
  const applyChanges = useCallback((skills_) => {
    setStateForAll(skills_)
    persistToDisk(skills_)
  }, [setStateForAll])

  useEffect(() => {
    listeners.push(setSkills)
    return () => {
      listeners = listeners.filter((l) => l !== setSkills)
    }
  }, [])

  useEffect(() => {
    refreshSkills(applyChanges)
  }, [applyChanges])

  useEffect(() => {
    (async () => {
      const skillsJSON = await AsyncStorage.getItem(SKILL_KEY)
      const storedSkills = JSON.parse(skillsJSON)
      if(storedSkills){
        applyChanges(storedSkills)
      }
    })()
  }, [applyChanges])

  const toggleSkill = (skill) => {
    const removeSkillFromState = () => applyChanges(skills.filter((s) => s.id !== skill.id))
    const addSkillToState = () => applyChanges([...skills, skill])

    // optimistically toggle skill, revert if api call failed
    if(skills.find((s) => s.id === skill.id)){
      // TODO : handle server error when editing skills
      removeSkillFromState()
      axios.delete(routes.api.user.skill(skill.id)).catch(addSkillToState)
    }else{
      addSkillToState()
      axios.put(routes.api.user.skill(skill.id)).catch(removeSkillFromState)
    }
  }
  return [skills, toggleSkill]
}
export default useSkills
