import React from "react"
import {
  Image, Dimensions, Platform,
} from "react-native"

const SCREEN_WIDTH = Dimensions.get('window').width

function FullWidthImage(props){
  if(Platform.OS === 'web'){
    return (
      <div>
        <img alt="Welcome panel" style={{ maxWidth: '100%', height: 'auto' }} src={props.source} />
      </div>
    )
  }
  const image = props.source
  const { width, height } = Image.resolveAssetSource(image)
  const ratio = height / width
  const styles = {
    width: SCREEN_WIDTH, height: SCREEN_WIDTH * ratio,
  }
  return (
    <Image source={image} style={styles} resizeMode="contain" />
  )
}

export default FullWidthImage
