import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'
import React from "react"
import { ScrollView, StyleSheet } from "react-native"
import Colors from "@reveel/constants/Colors"
import MediaQuery from 'react-responsive'
import SkillPillList from '../../components/core/SkillPillList'
import ScreenTitle from "../../components/ScreenTitle"
import UpdateProfile from '../../components/updateProfile/UpdateProfile'
import {
  ipi, labelCompanyContact, labelCompanyName, pro, publisherCompanyContact, publisherCompanyName, publisherIPI,
} from '../../helpers/userFormatter'
import ProfileHeader from '../user/ProfileHeader'
import { SocialMedias } from "../user/SocialMedia"
import { useAuth } from '../../stores/useAuth'
import Separator from '../../components/ui/Separator'
import { useDesktop } from '../../helpers/MediaQueries'
import { Title } from './Box'
import { WithLabel } from './WithLabel';
import { ClaimProfile, ReportErrorOnProfile } from './ProfileActions';
import RoyaltyLocatorCard from '../home/RoyaltyLocatorCard';

// TODO:
// missing: email, website icon(is currently warcraft -.-)
// publisher contact
// personal info
// username

const Box = (props) => <View style={styles.box} box {...props} />

const renderSkills = (skills) => <SkillPillList skills={skills} />

const renderTitle = (title, my = true) => <Text mb-2 mt-2={my} pl larger bold>{title}</Text>

const renderPRO = (user) => (
  <Box style={[tw.pL4, tw.pY4]}>
    <Title title={i18n.t('profiles.show.songwriter_info.title')} />
    <View  style={[tw.itemsStart, tw.flexRow]}>
      <WithLabel k="PRO" value={pro(user)} />
      <WithLabel k="IPI" value={ipi(user)} />
    </View>
    <Separator my />
    <Title title={i18n.t('profiles.show.publisher_info.title')} />
    <View  style={[tw.itemsStart, tw.flexRow]}>
      <WithLabel k="Publisher" value={publisherCompanyName(user)} />
      <WithLabel k="IPI" value={publisherIPI(user)} />
    </View>
    <Text mt-05 selectable smaller light>{publisherCompanyContact(user)}</Text>
    <Separator my />
    <Title title={i18n.t('profiles.show.relationships_info.title')} />
    <View  style={[tw.itemsStart, tw.flexRow]}>
      <WithLabel k="Name" value={labelCompanyName(user)} />
      <WithLabel k="Contact" value={labelCompanyContact(user)} />
    </View>
  </Box>
)

const renderSocial = (profile) => <SocialMedias profile={profile} />

const ProfileScreen = ({ profile, title, children }) => {
  const { user: self } = useAuth()
  const isSelf = self?.id === profile.user_id
  const hasContact = Object.keys(profile.social).find((k) => profile.social[k]?.length > 0)

  return (
    <View>
      {children}
      {!isSelf
        && (
          <View style={[tw.flexRow, tw.mT4, tw.justifyAround]}>
            <ReportErrorOnProfile small profile={profile} />
            <ClaimProfile small profile={profile} />
          </View>
      )}
      {title && <ScreenTitle title={title} />}
      {!title && <View mt-4 />}
      <ProfileHeader user={profile} style={styles.box} />
      {isSelf && <Box px><UpdateProfile /></Box>}
      {isSelf && <RoyaltyLocatorCard from="creator-id" />}
      <Box>
        {renderPRO(profile)}
      </Box>
      {hasContact && (
        <Box>
          {renderTitle('Contact')}
          {renderSocial(profile)}
        </Box>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  box: {
    marginBottom: 20,
  },
  title: {
  },
  shareIcon: {
    right: 20,
    top: 50,
    opacity: 0.3,
    position: 'absolute',
  },
})

export default ProfileScreen
