import React, { useState, useEffect } from 'react'
import { StyleSheet } from 'react-native'

import SkillPillList from '@reveel/components/core/SkillPillList'
import SkillsDropdown from '@reveel/components/core/SkillsDropdown'
import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'
import { tw } from 'react-native-tailwindcss'

import Pill from '../../../components/ui/Pill'
import { PENDING_ONBOARDING } from '../../../constants/Status'
import useSkillsHook from '../../../screens/skills/useSkills'

export const SkillsSelector = ({ style, skillsState, placeholder }) => {
  const [selectedSkills, toggleSkill] = skillsState
  return (
    <View style={style}>
      <View>
        <SkillPillList
          skills={selectedSkills}
          onPress={toggleSkill}
          isSelected={() => true}
        />
      </View>
      <SkillsDropdown
        selectedSkills={selectedSkills}
        showBrowseAll={false}
        onSelect={toggleSkill}
        placeholder={placeholder}
      />
    </View>
  )
}

const SkillsField = ({ onValueChange }) => {
  const [skills, setSkills] = useState([])
  const [hideSearch, setHideSearch] = useState(true)

  const toggleSkill = (skill) => {
    const removeSkillFromState = () => setSkills(skills.filter((s) => s.id !== skill.id))
    const addSkillToState = () => setSkills([...skills, skill])

    skills.find((s) => s.id === skill.id)
      ? removeSkillFromState()
      : addSkillToState()
  }

  useEffect(() => {
    onValueChange(skills.map((s) => s.id))
  }, [skills])
  return (
    <View>
      {
        hideSearch
          ? (
            <Pill
              content="+ Add Credits"
              onPress={() => setHideSearch(false)}
              style={[tw.borderDashed, tw.borderLightBlack, tw.textLightBlack, tw.textCenter, tw.mT2, tw.mB4]}
              _testID="AddSkills"
            />
          )
          : (
            <SkillsSelector
              skillsState={[skills, toggleSkill]}
              placeholder="Select credit to add..."
              style={[tw.mT2, tw.mB4]}
            />
          )
      }
    </View>
  )
}
export const skillsField2 = {
  label: 'asd',
  name: 'skill_ids',
  renderCustom: (props) => <SkillsField {...props} />,
}

// TODO: the below is actually an EditMySkills component
// but was here pre-profile

const MySkillsField = (props) => {
  const skillsState = useSkillsHook()
  return (
    <View>
      <Text>Select your Top Skills:</Text>
      <Text style={[tw.textSm]} light>These are the credits you often add</Text>
      <SkillsSelector skillsState={skillsState} style={{ marginTop: 15 }} {...props} placeholder="Search skills" />
    </View>
  )
}
const skillsField = {
  label: 'Credits',
  name: 'skills',
  renderCustom: (props) => <MySkillsField {...props} />,
}
export default skillsField
