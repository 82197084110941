import Button from '@reveel/components/ui/Button'
import View from '@reveel/components/ui/View'
import AvatarPicker from '@reveel/components/utils/AvatarPicker'
import React from 'react'
import { ScrollView } from 'react-native'
import ScreenTitle from '../../../components/ScreenTitle'
import { Skip } from './index'

// eslint-disable-next-line import/prefer-default-export
export const OnboardingAvatar = ({ onNext }) => (
  <ScrollView contentContainerStyle={{ flex: 1 }}>
    <ScreenTitle title={i18n.t('onboarding.avatar.title')} />
    <View>
      <AvatarPicker
        instructionProps={{ primary: true, bold: true, larger: true, mt: true }}
        size={200}
      />
    </View>
    <View px flex justifyEnd style={{ marginBottom: 15 }}>
      <Button title="Continue" block onPress={onNext} />
      <Skip onPress={onNext} />
    </View>
  </ScrollView>
)
