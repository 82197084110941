import {
  useState, useEffect, useReducer, useCallback,
} from 'react'
import Axios from 'axios'
import { Alert } from '@reveel/vendors/alert'
import routes from '../../helpers/routes'
import { openWebSocket } from '../../helpers/ActionCable'
import { useAuth } from '../../stores/useAuth'
import { RUNNING, PAST, SIGNED_OFF } from '../../constants/Status'
import useAppState from '../../helpers/useAppState'

const useCreativeSession = ({
  id, onFetched, onFetchError, dontfetchOnInit, creativeSession: alreadyLoadedCS = {},
}) => {
  const { user, impersonating } = useAuth()
  // dontfetchOnInit to prevent double loading from accept invitation
  // could be avoided by adding required CS fields in the qr code
  const withMyParticipation = (cs) => {
    const myParticipation = cs.participants.find((p) => p.id === user.id) || {}
    return {
      ...cs,
      isAdmin: myParticipation.admin,
      myParticipation,
    }
  }

  const reducer = (state, { type, override, ...incoming }) => {
    const getReducedCS = () => {
      switch (type){
        case 'creativeSessionDetails':
          if(override){
            return incoming.creativeSession
          }
          return {
            ...state,
            ...incoming.creativeSession,
          }
        case 'participantJoined':
          let { invitations } = state
          if(incoming.invitation){
            invitations = invitations.filter(({ id }) => id !== incoming.invitation.id)
          }
          return {
            ...state,
            invitations,
            participants: [
              ...state.participants,
              incoming.participant,
            ],
          }
        case 'participationUpdated':
          return {
            ...state,
            participants: state.participants.map((p) => (p.id === incoming.participant.id ? { ...p, ...incoming.participant } : p)),
          }
        default:
          return state
      }
    }

    return withMyParticipation(getReducedCS())
  }

  const [creativeSession, dispatch] = useReducer(reducer, alreadyLoadedCS)
  const [loading, setLoading] = useState(!dontfetchOnInit)
  const [error, setError] = useState(false)

  const setCreativeSession = (incomingCreativeSession, { overwrite = false } = {}) => {
    // This allows pushing partial creative sessions from the server
    dispatch({ type: 'creativeSessionDetails', creativeSession: incomingCreativeSession, overwrite })
  }

  const fetchSession = useCallback(async () => {
    setLoading(true)
    try{
      setError(false)
      const { data } = await Axios.get(routes.api.creativeSession.show(id))
      dispatch({ type: 'creativeSessionDetails', creativeSession: data, overwrite: true })
      setCreativeSession(data, { overwrite: true })
      onFetched?.({ creativeSession: data })
    }catch(err){
      if(err.response?.status === 404){
        Alert.alert(i18n.t('creativeSessions.not_found.alert.title'), i18n.t('creativeSessions.not_found.alert.description'))
        return
      }
      console.log(err)
      onFetchError?.()
      setError(true)
    }
    setLoading(false)
  }, [id])

  useAppState({
    onForeground: fetchSession,
  })

  useEffect(() => {
    if(!dontfetchOnInit){
      // BUG: check if id exists as reloading from the same state
      // otherwise returns a 404
      if(id){
        fetchSession()
      }
    }else{
      onFetched?.()
    }
  }, [id, impersonating])

  useEffect(() => {
    const received = (props) => {
      // cannot currently simply update the session received,
      // as it contains permissions, which might have been updated(eg. can.validate :( )
      // BUG: there is somehow a race condition here in dev at least,
      // which returns the old fetched session (not a 304, simply fetches it too early )
      if(props.type === 'creativeSessionUpdate'){
        return setTimeout(fetchSession, 1000)
      }
      dispatch(props)
    }
    return openWebSocket('CreativeSessionChannel', id, { received, user })
  }, [id, fetchSession, user, dispatch])

  useEffect(() => {
    onFetched?.({ creativeSession })
  }, [creativeSession])

  return {
    error,
    loading,
    creativeSession,
    setCreativeSession,
  }
}

export default useCreativeSession
