import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Text } from 'native-base'

import { timestamp } from '@reveel/utils/dateFormatter'
import Colors from '@reveel/constants/Colors'

const SessionMainInfo = ({
  creativeSession: {
    created_at, ended_at, readable_id, venue_name, session_type,
  },
}) => (
  <View style={styles.container}>
    <Text style={styles.title}>
      Session #
      {readable_id}
    </Text>
    {session_type && <Text small style={styles.timestamps}>{session_type}</Text>}
    {venue_name !== null && venue_name !== "" && (
    <Text small style={styles.timestamps}>
      venue:
      {venue_name}
    </Text>
    )}
    <Text small style={styles.timestamps}>
      started:
      {timestamp(created_at)}
    </Text>
    <Text small style={styles.timestamps}>
      ended:
      {timestamp(ended_at)}
    </Text>
  </View>
)

export default SessionMainInfo

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    marginBottom: 20,
    alignItems: 'center',
  },
  title: {
    marginBottom: 10,
    fontWeight: 'bold',
  },
  timestamps: {
    color: Colors.lightGrey,
  },
})
