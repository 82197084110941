import { useState, useEffect } from "react"
import AsyncStorage from '@react-native-async-storage/async-storage'
import AsyncKeys from "../stores/AsyncKeys"
import { Linking, Platform } from 'react-native';

const PERSISTENCE_KEY = "PERSISTENCE_KEY"
// AsyncStorage.removeItem(PERSISTENCE_KEY)

// AsyncStorage.removeItem(AsyncKeys.USER_KEY)

!__DEV__ && AsyncStorage.removeItem(PERSISTENCE_KEY)

export const usePersistedNavigationState = () => {
  const [isReady, setIsReady] = useState(false)
  const [initialState, setInitialState] = useState()

  useEffect(() => {
    const restoreState = async () => {
      try{
        const initialUrl = await Linking.getInitialURL()

        if(Platform.OS !== 'web' && initialUrl == null){
          // Only restore state if there's no deep link and we're not on web
          const savedStateString = await AsyncStorage.getItem(PERSISTENCE_KEY)
          const state = savedStateString ? JSON.parse(savedStateString) : undefined

          if(state !== undefined){
            setInitialState(state)
          }
        }
      } finally {
        setIsReady(true)
      }
    }

    if(!isReady){
      restoreState()
    }
  }, [isReady])

  return {
    isReady,
    initialState,
  }
}

export const persistState = (state) => AsyncStorage.setItem(PERSISTENCE_KEY, JSON.stringify(state))
