import React from 'react'

import View from '@reveel/components/ui/View'
import useRemoteState from '@reveel/hooks/useRemoteState'
import Page from '@reveel/layout/Page'
import PageWrapper from '@reveel/screens/onboarding/PageWrapper'
import { useAuth } from '@reveel/stores/useAuth'

import Loading from '../../components/Loading'
import routes from '../../helpers/routes'
import ProfileScreen from '../ProfileScreen'
import { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';

const useProfile = (profileID) => {
  const { data: profile, ...rest } = useRemoteState({
    url: routes.api.profile.show(profileID),
  })

  return { profile, ...rest }
}

const Profile = ({ route }) => {
  const navigation = useNavigation()
  const id = route.params?.id

  const { isLoggedIn } = useAuth()
  const { profile, loading, errors } = useProfile(id)

  useEffect(() => {
    navigation.setOptions({
      title: `${profile?.display_name || 'Loading'} – Reveel`,
    })
  }, [profile, navigation])

  if(loading || errors){
    return <Loading error={errors} />
  }
  if(!isLoggedIn){
    return (
      <PageWrapper xl>
        <View px-4 py-4>
          <ProfileScreen profile={profile} />
        </View>
      </PageWrapper>
    )
  }
  return (
    <Page fixNotScrolling>
      <ProfileScreen profile={profile} />
    </Page>
  )
}

export default Profile
