import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { Icon as NativeBaseIcon, Text } from 'native-base'
import * as Icon from '@expo/vector-icons'

import Colors from '@reveel/constants/Colors'
import { useNavigation } from '@react-navigation/native'
import actionStyles from './contributionActionStyles'
import withNavigation from '../../helpers/withNavigation'
import {
  ACCEPTED, REJECTED, PENDING_VALIDATION, PENDING_CONFLICT_RESOLUTION,
} from '../../constants/Status'

const ContributionStatus = ({
  contribution,
  goToChat,
}) => {
  const { status } = contribution
  if(status === PENDING_CONFLICT_RESOLUTION){
    return (
      <TouchableOpacity style={[actionStyles.button, styles.chatButton]} onPress={goToChat}>
        <Text red>
          Resolve
        </Text>
        <NativeBaseIcon style={styles.chatIcon} name="ios-arrow-forward" />
      </TouchableOpacity>
    )
  }
  if(status === ACCEPTED){
    return <Icon.Ionicons testID={`contrib__${ACCEPTED}`} style={actionStyles.button} color={Colors.tintColor} name="ios-checkmark" size={35} />
  }
  if(status === REJECTED){
    return <Icon.Ionicons testID={`contrib__${REJECTED}`} style={actionStyles.button} color={Colors.errorText} name="ios-close" size={35} />
  }
  return null
}

const styles = StyleSheet.create({
  chatButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  chatIcon: {
    marginLeft: 10,
  },
})
export default ContributionStatus
