import { Ionicons } from '@expo/vector-icons'
import { Text } from '@reveel/components/ui'
import Colors from '@reveel/constants/Colors'
import React from 'react'
import { SafeAreaView, Platform, StyleSheet, Pressable } from 'react-native'
import List from '../../components/ui/List'
import LinkWrapper from '../../components/utils/LinkWrapper'
import View from '@reveel/components/ui/View';
import { tw } from 'react-native-tailwindcss';
import { useDesktop } from '@reveel/helpers/MediaQueries';
import TrackPermissions from './TrackPermissions/index';
import { useNavigation } from '@react-navigation/native';
import Button from '../../components/ui/Button'
import { useAuth } from '@reveel/stores/useAuth';
import RequestExportButton from './credits/RequestExportButton';

const isCurrentPath = (name) => {
  if(Platform.OS !== 'web'){ return false }

  const pathName = /tracks\/\d+\/([\w-_]*)/.exec(window.location.pathname)
  // return for root (/tracks/1538) => track's home
  if(!pathName && name === ''){
    return true
  }
  return pathName?.[1] === name
}

const ICON_SIZE = 24

const links_ = ({ isDesktop, trackID }) => ([
  {
    title: "Invite Others",
    key: "TrackCollaborators",
    path: `collaborators`,
    small: true,
    icon: (isActive) => <Ionicons name="people-outline" size={18} color={isActive ? Colors.primary : Colors.lightBlack} />,
    render: ({ renderTrigger }) => <TrackPermissions trackID={trackID} renderTrigger={renderTrigger} />,
  },
  {
    key: 'spacer',
  },
  {
    title: `Things To Do`,
    key: "TrackHome",
    path: isDesktop ? '' : 'overview',
    icon: (isActive) => <Ionicons name="checkmark-done-circle-outline" size={ICON_SIZE} color={isActive ? Colors.primary : Colors.lightBlack} />,
  },
  {
    title: "Credits",
    key: "TrackCredits",
    path: `credits`,
    icon: (isActive) => <Ionicons name="ribbon-outline" size={ICON_SIZE} color={isActive ? Colors.primary : Colors.lightBlack} />,
  },
  {
    title: "Songwriting Splits",
    key: "TrackSplits",
    path: `songwriting-splits`,
    icon: (isActive) => <Ionicons name="book-outline" size={ICON_SIZE} color={isActive ? Colors.primary : Colors.lightBlack} />,
  },
  {
    title: "Master Points",
    key: "TrackPoints",
    path: `master-points`,
    icon: (isActive) => <Ionicons name="recording-outline" size={ICON_SIZE} color={isActive ? Colors.primary : Colors.lightBlack} />,
  },
  // {
  //   title: `Feed`,
  //   key: "TrackActivity",
  //   path: `feed`,
  //   icon: (isActive) => <Ionicons name="chatbubble-ellipses-outline" size={ICON_SIZE} color={isActive ? Colors.primary : Colors.lightBlack} />,
  // },
  // {
  //   title: "Sessions",
  //   key: "TrackSessions",
  //   path: `sessions`,
  //   icon: (isActive) => <Ionicons name="timer-outline" size={ICON_SIZE} color={isActive ? Colors.primary : Colors.lightBlack} />,
  // },
  // {
  //   title: "Attachments",
  //   key: "TrackAttachmentsIndex",
  //   path: `attachments`,
  //   icon: (isActive) => <Ionicons name="attach-outline" size={ICON_SIZE} color={isActive ? Colors.primary : Colors.lightBlack} />,
  // },
  {
    title: "Metadata",
    key: "TrackInfo",
    path: `info`,
    icon: (isActive) => <Ionicons name="information-circle-outline" size={ICON_SIZE} color={isActive ? Colors.primary : Colors.lightBlack} />,
  },
])

const LinkButton = ({ screenName, title, params, style }) => {
  const navigation = useNavigation()
  const onPress = () => navigation.navigate(screenName, params)
  return <Button onPress={onPress} title={title} style={style} />
}

const TrackMenu = ({ trackID, style }) => {
  const isDesktop = useDesktop()
  const { can } = useAuth()

  return (
    // eslint-disable-next-line react-native/no-inline-styles
    <SafeAreaView style={[{ flex: 1 }, style]}>
      {links_({ isDesktop, trackID }).map((link, i) => renderMenuItem({ link, trackID }, i))}
      
      {can('new_track_home') && <>
        <View style={[tw.borderB, tw.borderBorderGrey, tw.mB4]} />
        <RequestExportButton trackID={trackID} />
        {/* <LinkButton screenName="TrackRelease" title="Release" params={{ trackID }} style={[ tw.mX2, { alignSelf: 'stretch'}]} /> */}
      </>}
    </SafeAreaView>
  )
}

export default TrackMenu

const styles = StyleSheet.create({
  active: {
    borderRightWidth: 3,
    borderColor: Colors.tintColor,
  },
})

function renderMenuItem({ link, trackID }, i) {
  if (link.key === 'spacer') {
    return <View key={`sep-${i}`} style={[tw.borderB, tw.borderBorderGrey]} />
  }
  const isActive = isCurrentPath(link.path)
  const wrapperStyles = [
    link.small ? tw.pY2 : tw.pY4,
    tw.pL4,
    tw.flexRow,
    tw.itemsCenter,
    isActive && styles.active,
  ]
  const body = ({ hovered } = {}) =>  (
    <>
      <View>
        {link.icon(isActive)}
      </View>
      <Text primary={isActive} style={[
        tw.pL2, 
        isActive && tw.fontBold,
        (!hovered && link.small) && tw.textLightBlack,
      ]}>
        {link.title}
      </Text>
    </>
  )
  if (link.render) {
    const renderTrigger = ({ onOpen }) => (
      <Pressable style={wrapperStyles} onPress={onOpen}>
        {({ hovered }) => body({ hovered })}
      </Pressable>
    )
    return link.render({ isActive, renderTrigger })
  }
  return (
    <LinkWrapper
      key={link.key}
      to={`/tracks/${trackID}/${link.path}`}
      style={wrapperStyles}
      hoveredStyle={wrapperStyles}
      accessibilityLabel={link.title}
    >
      {body()}
    </LinkWrapper>
  )
}
