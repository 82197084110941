import React, { useEffect } from 'react'
import { Platform, StatusBar, StyleSheet, View } from 'react-native'

import { ActionSheetProvider } from '@expo/react-native-action-sheet'
import { useReduxDevToolsExtension } from '@react-navigation/devtools'
import { DefaultTheme, NavigationContainer } from '@react-navigation/native'
import { AuthConsumer, AuthProvider } from '@reveel/stores/useAuth'
import { StyleProvider } from 'native-base'
import { Provider as PaperProvider } from 'react-native-paper'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { enableScreens } from 'react-native-screens'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import getTheme from '../native-base-theme/components'
import ImpersonatingNotice from './admin/ImpersonatingNotice'
import Loading from './components/Loading'
import './config'
import variables from './config/variables'
import Colors from './constants/Colors'
import useOnNavigationStateChange from './helpers/handleNavigationStateChange'
import NavigationService from './helpers/NavigationService'
import { persistState, usePersistedNavigationState } from './helpers/persistNavigationState'
import AppNavigator from './navigation/AppNavigator'
import linkingConfig from './navigation/linkingConfig'
import { StoreProvider } from './stores/useStoreContext'

enableScreens()
const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: Colors.tintColor,
    background: 'white',
    card: 'white',
    border: Colors.borderGrey,
  },
}
const MyAppNavigator = ({ user }) => {
  const navRef = React.useRef()
  const onNavigationChange = useOnNavigationStateChange(navRef)
  const { isReady, initialState } = usePersistedNavigationState()
  if(__DEV__){
    useReduxDevToolsExtension(navRef)
  }
  useEffect(() => {
    if(navRef){
      NavigationService.setTopLevelNavigator(navRef)
    }
    // the navigator _must_ be updated for non-component
    // modules to be able to navigate
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, navRef.current])

  if(!isReady){ return null }
  const onStateChange = (...args) => {
    persistState(...args)
    onNavigationChange(...args)
  }
  return (
    <StoreProvider user={user}>
      <PaperProvider>
        <SafeAreaProvider>
          <NavigationContainer
            ref={navRef}
            initialState={initialState}
            onStateChange={onStateChange}
            theme={theme}
            linking={linkingConfig}
          >
            <AppNavigator />

          </NavigationContainer>
        </SafeAreaProvider>
      </PaperProvider>
    </StoreProvider>
  )
}

const queryClient = new QueryClient()
const App = () => (
  <QueryClientProvider client={queryClient}>
    <StyleProvider style={{ ...getTheme(variables) }}>
      <ActionSheetProvider>
        <View style={styles.container}>
          <StatusBar barStyle="dark-content" />
          <AuthProvider>
            <AuthConsumer>
              {({ user, setUser, loading, masquerade }) => {
                if(loading){ return <Loading /> }
                return (
                  <>
                    <ImpersonatingNotice masquerade={masquerade} />
                    <MyAppNavigator user={user} setUser={setUser} />
                    {Platform.OS === 'web' && <ReactQueryDevtools initialIsOpen={false} />}
                  </>
                )
              }}
            </AuthConsumer>
          </AuthProvider>
        </View>
      </ActionSheetProvider>
    </StyleProvider>
  </QueryClientProvider>
)
export default App

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
})
