import React, { useContext } from 'react'
import {
  View, TouchableOpacity, Linking, StyleSheet,
} from 'react-native'
import { Text } from '@reveel/components/ui'
import { useActionSheet } from '@expo/react-native-action-sheet'
import { NavigationContext } from '@react-navigation/core'
import mapOptionsToActionSheet from '../helpers/mapOptionsToActionSheet'

import routes from '../helpers/routes'

const imessageURL = 'imessage://walidvb@gmail.com'
const whatsappURL = 'https://wa.me/41786025121'

const subject = `[SUPPORT] Got a question for the reveel team 🏖`

export const useGetHelp = () => {
  const byWhatsapp = () => Linking.openURL(whatsappURL)
  const byMessage = () => Linking.openURL(imessageURL)
  const byEmail = () => Linking.openURL(`mailto: help@reveel.id?subject=${encodeURIComponent(subject)}`)
  const viewFAQ = () => navigation.push('WebPortal', { title: 'FAQs', remoteRoute: routes.web.faqs })
  const navigation = useContext(NavigationContext)
  const { showActionSheetWithOptions } = useActionSheet()
  return async () => {
    const buttons = [
      {
        label: 'Cancel',
        cancel: true,
      },
      {
        label: 'By Email',
        onPress: byEmail,
      },
    ]
    if(await Linking.canOpenURL(whatsappURL)){
      buttons.push({
        label: 'By Whatsapp',
        onPress: byWhatsapp,
      })
    }
    if(await Linking.canOpenURL(imessageURL)){
      buttons.push({
        label: 'By Text',
        onPress: byMessage,
      })
    }
    buttons.push({
      label: 'View FAQ',
      onPress: viewFAQ,
    })
    showActionSheetWithOptions(...mapOptionsToActionSheet(buttons))
  }
}

const ContactSupport = ({ style, horizontal, mb8 }) => {
  const getHelp = useGetHelp()
  return (
    <View style={[styles.container, style]}>
      <View style={[styles.wrapper]}>
        <TouchableOpacity
          onPress={getHelp}
          style={[
            horizontal && styles.horizontal,
            mb8 && styles.mb8,
            { paddingVertical: 5 },
          ]}
        >
          <Text center xs light>Feedback? Bug? </Text>
          <Text underline center xs light>Get in touch</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  wrapper: {
    paddingHorizontal: 20,
  },
  horizontal: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flexGrow: 0,
  },
  mb8: {
    marginBottom: 35,
  },
})
export default ContactSupport
