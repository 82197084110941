import { Platform } from 'react-native'
import * as Notifications from 'expo-notifications'

const setNotificationChannelsForAndroid = () => {
  if(Platform.OS === 'android'){
    Notifications.setNotificationChannelAsync('default', {
      name: 'All Activity',
      sound: true,
      lockscreenVisibility: true,
    })
    Notifications.setNotificationChannelAsync('chat', {
      name: 'Chat Messages',
      sound: true,
      lockscreenVisibility: true,
    })
    Notifications.setNotificationChannelAsync('creative-sessions-channel', {
      name: 'Session Activity',
      sound: true,
      lockscreenVisibility: true,
    })
  }
}
export default setNotificationChannelsForAndroid
