import React, { useState } from 'react'

import { StyleSheet, TouchableOpacity, TouchableWithoutFeedback } from 'react-native'
import { Text } from '@reveel/components/ui'

import Colors from '@reveel/constants/Colors'
import { Entypo } from '@expo/vector-icons'
import View from '@reveel/components/ui/View'
import Loading from '../Loading'
import MenuItemWithDropdown from '../../navigation/createScreenHeader/MenuWithDropdown'

const RowWithActions = ({
  actions,
  children,
  style,
  smaller,
  testID,
}) => {
  const actionRows = (
    <View style={styles.actionsWrapper}>
      {typeof actions === 'function' ? actions() : actions.map(({
        text, onPress, children: _children, loading,
      }, i) => (
        <TouchableOpacity 
          testID={text?.replace(' ', '')} 
          key={text} 
          onPress={(e) => onPress?.()}
          style={[styles.actionContainer, (i !== actions.length - 1) && styles.border]}
        >
          <Text smaller={smaller} style={{ flex: 1 }}>{text}</Text>
          {_children && <View>{_children}</View>}
          {loading && <View><Loading size="small" /></View>}
        </TouchableOpacity>
      ))}
    </View>
  )

  const hasActions = actions.length !== 0
  const [hovered, setHovered] = useState(false)
  
  return (
    <View 
      row 
      style={[styles.container, style]} 
      testID={testID} 
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {children}
      {hasActions && (
        <MenuItemWithDropdown
          testID="openActions"
          size="sm"
          renderTrigger={() => <Entypo style={[styles.icon, !hovered && tw.opacity0]} name="cog" color={Colors.iconGrey} size={14} />}
        >
          {actionRows}
        </MenuItemWithDropdown>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexWrap: 'nowrap',
  },
  icon: {
    marginLeft: 15,
  },
  actionsWrapper: {
    paddingLeft: 15,
  },
  actionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 10,
  },
  border: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderGrey,
  },
})

export default RowWithActions
