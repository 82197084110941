/* eslint-disable react-native/no-unused-styles */
import React from 'react'
import { View, StyleSheet } from 'react-native'

import UserAvatar from 'react-native-user-avatar'
import { Text } from '@reveel/components/ui'
import Badge from '../ui/Badge'
import { useDesktop } from '../../helpers/MediaQueries'

const themes = {
  thin: {
    marginTop: 5,
    marginBottom: 5,
    avatarSize: 25,
  },
  large: {
    marginTop: 10,
    marginBottom: 10,
    avatarSize: 40,
  },
  xl: {
    avatarSize: 60,
  },
}

const UserWithText = ({
  user, text, userNameStyle,
  subText,
  children,
  style,
  icon,
  noBadges,
  theme = "large",
}) => {
  const theme_ = themes[theme]
  const isSmall = theme === 'thin'
  const isBold = theme !== 'thin'

  const avatar = () => (
    <View>
      <UserAvatar containerStyle={styles(theme_).avatar} size={theme_.avatarSize} name={user.name} src={user.avatar_url} />
    </View>
  )

  const phrase = () => (
    <Text smaller>
      <Text smaller={isSmall} bold={isBold} style={userNameStyle}>{user.name}</Text>
      {text && ` ${text}`}
    </Text>
  )

  const badges = () => (
    <>
      {user.work_for_hire ? <Badge name="workForHire" /> : null}
      {user.admin ? <Badge name="admin" /> : null}
    </>
  )

  const isDesktop = useDesktop()
  const tStyles = styles({ ...theme_, isDesktop })

  return (
    <View testID={`user-${user.id}`} style={[tStyles.container, style]}>
      {avatar()}
      <View style={tStyles.text}>
        <View style={tStyles.upper}>
          {phrase()}
          {!noBadges && badges()}
        </View>
        {Boolean(subText) && <Text note style={tStyles.subText}>{subText}</Text>}
        {children}
      </View>
      {icon}
    </View>
  )
}

export default UserWithText

const styles = ({ marginTop, marginBottom, isDesktop }) => StyleSheet.create({
  container: {
    marginTop,
    marginBottom,
    // flex: isDesktop ? undefined : 1,
    flexDirection: 'row',
    // wrap text
    maxWidth: '100%',
    alignItems: 'flex-start',
  },
  upper: {
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
  },
  children: {
    marginTop: 10,
  },
  avatar: {
    marginRight: 10,
  },
  text: {
    // flexWrap: 'wrap',
    marginLeft: 10,
    // probably is a much better way, but until then, as per hack:
    // https://github.com/facebook/react-native/issues/1438#issuecomment-278745825
    flexGrow: isDesktop ? 1 : 1,
    width: isDesktop ? undefined : 1,
    // wrap text
    maxWidth: '100%',
  },
  subText: {
    maxWidth: "100%",
  },
})
