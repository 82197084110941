const groupSkills = (skills) => skills

const groupInstruments = (skills) => {
  const grouped = {}
  skills.forEach((s) => {
    grouped[s.category] = grouped[s.category] || []
    grouped[s.category].push(s)
  })
  return grouped
}

const groupedSkillListData = ({
  navigation, route, skills, user,
}) => {
  const nextRoute = route.params?.nextRoute

  let _instruments; let
    rest
  if(!user.beta_flags.ddex_skills){
    const { instruments, ..._rest } = groupSkills(skills)
    _instruments = instruments
    rest = _rest
  }else{
    const { avs_InstrumentType, ..._rest } = groupSkills(skills)
    _instruments = avs_InstrumentType
    rest = _rest
  }

  const groupedInstruments = groupInstruments(_instruments)
  const instrumentCategories = Object.keys(groupedInstruments).map((cat) => ({
    key: cat,
    title: cat,
    canEnter: true,
    onPress: () => navigation.push(nextRoute, { title: cat, skills: groupedInstruments[cat] }),
  }))
  const ddexFormat = (prof) => prof.replace('avs_', '').split(/(?=[A-Z])/).join(' ')
  const other = Object.keys(rest).map((prof) => ({
    key: prof,
    title: ddexFormat(prof),
    canEnter: true,
    onPress: () => navigation.push(nextRoute, { title: ddexFormat(prof), skills: rest[prof] }),
  }))

  return [
    {
      divider: 'instruments',
      key: 'instrument',
    },
    ...instrumentCategories,
    {
      divider: 'other',
      key: 'other',
    },
    ...other,
  ]
}

export default groupedSkillListData
