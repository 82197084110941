import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'

import Clipboard from 'expo-clipboard';

import { SingleOption } from '@reveel/components/core/header/HeaderMore/desktop'
import ModalButton from '@reveel/components/ModalButton'
import ScreenTitle from '@reveel/components/ScreenTitle'
import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'
import PropTypes from 'prop-types'
import { useState } from 'react';

const ShareModalContent = ({ profileUrl }) => {
  const [copied, setCopied] = useState(false)
  
  const urlWithHost = `${window.location.origin}/${profileUrl}`
  const copyToClipboard = () => {
    setCopied(true)
    Clipboard.setString(urlWithHost)
    setTimeout(() => setCopied(false), 3000)
  }
  const subject = i18n.t('profile.share.mail.subject')
  const body = i18n.t('profile.share.mail.body', { url: urlWithHost })
  const mailTo = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
  return (
    <View py-2>
      <ScreenTitle title={['Share your', 'Creator ID']} xs row style={styles.title} />
      <Text mb-2>
        {i18n.t('profile.share.cta')}
      </Text>
      <View row>
        <input type="text" readOnly value={urlWithHost} onClick={({ target }) => target.select()} style={{ marginRight: '1rem' }} />
        <TouchableOpacity onPress={copyToClipboard} style={{ marginRight: '1rem' }}>
          <Text primary>{!copied ? "Copy Link": "Copied!"}</Text>
        </TouchableOpacity>
        <a href={mailTo}>
          <Text primary>
            Send by email
          </Text>
        </a>
      </View>
    </View>
  )
}

ShareModalContent.propTypes = {
  profileUrl: PropTypes.string.isRequired,
}

export default (profileUrl) => ({
  // eslint-disable-next-line react/display-name
  render: () => (
    <ModalButton
      trigger={(onPress) => (
        <SingleOption
          label="Share Profile"
          icon="share"
          onPress={onPress}
        />
      )}
      content={() => <ShareModalContent profileUrl={profileUrl} />}
    />
  ),
})

const styles = StyleSheet.create({
  title: {
    marginLeft: 0,
    marginVertical: 0,
    marginBottom: 20,
  },
})
