import { ImpersonateUI } from '@reveel/admin/Impersonate'
import KeyboardSpacerIOS from '@reveel/components/KeyboardSpacerIOS'
import { Text } from '@reveel/components/ui'
import { useAsync } from '@reveel/hooks/useRemoteState'
import { useAuth } from '@reveel/stores/useAuth'
import React from 'react'
import { useGetHelp } from '../../components/ContactSupport'
import VersionInfo from '../../components/core/VersionInfo'
import Loading from '../../components/Loading'
import ScreenTitle from '../../components/ScreenTitle'
import List from '../../components/ui/List'
import View from '../../components/ui/View'
import routes from '../../helpers/routes'
import { withAuth } from '../../stores/useAuth'
import openURL from '../../utils/openURL'


const AccountSettingsScreen = withAuth(({ navigation, user }) => {
  const getHelp = useGetHelp({ noFAQ: true })
  const { run: resetDemo, refreshing: isResetting } = useAsync({
    url: routes.api.resetDemo,
    method: 'put',
  })
  const { logout } = useAuth()

  let entries = [
    {
      key: 'profile',
      title: 'Profile',
      canEnter: true,
      onPress: () => navigation.push('ProfileEdit'),
    },
    {
      key: 'skills',
      title: 'My Skills',
      canEnter: true,
      onPress: () => navigation.navigate('SkillsOverview'),
    },
    {
      divider: true,
      key: 'div_1',
    },
    {
      key: 'share',
      title: 'Invite someone to Reveel',
      canEnter: true,
      onPress: () => navigation.navigate('ShareAppScreen'),
    },
    {
      key: 'whatsapp',
      title: 'Get Help',
      onPress: getHelp,
    },
  ]

  entries = ([
    ...entries,
    {
      key: 'discord',
      title: 'Join us on Discord',
      onPress: () => openURL({ href: 'https://discord.gg/4PbEzzGQ5C', target: '_blank' }),
    },
    {
      key: 'faq',
      title: 'Frequently Asked Questions',
      canEnter: true,
      onPress: () => navigation.push('WebPortal', { title: 'FAQs', remoteRoute: routes.web.faqs }),
    },
    {
      key: 'changelog',
      title: 'Changelog',
      canEnter: true,
      onPress: () => navigation.push('Changelog'),
    },
    {
      divider: true,
      key: 'div_2',
    },
    {
      key: 'tc',
      title: 'Terms & Conditions',
      canEnter: true,
      onPress: () => navigation.push('WebPortal', { title: 'Terms & Conditions', remoteRoute: routes.web.terms }),
    },
    {
      key: 'privacy',
      title: 'Privacy',
      canEnter: true,
      onPress: () => navigation.push('WebPortal', { title: 'Privacy', remoteRoute: routes.web.privacy }),
    },
    {
      divider: true,
      key: 'div3',
    },
    {
      title: 'Log out',
      key: 'log-out',
      onPress: () => logout(() => navigation.navigate('Onboarding')),
    },
  ])

  if(!routes.isProd || __DEV__){
    entries.push({
      divider: 'Admin Only 👀',
      key: 'divider',
      title: 'Archives',
    })
    entries.push({
      title: 'See User Onboarding',
      key: 'onboarding',
      href: '/create-your-profile',
    })
    entries.push({
      title: 'See Track Onboarding',
      key: 'trackonboarding',
      href: '/create-your-first-track',
    })
    entries.push({
      title: 'Playground',
      key: 'playgroud',
      onPress: () => navigation.navigate('Playground'),
    })
    entries.push({
      title: 'Impersonate',
      key: 'masq',
      render: () => <ImpersonateUI />,
    })
    entries.push({
      title: 'API endpoint',
      key: 'api-rouate',
      render: () => <Text style={{ paddingHorizontal: 25, paddingVertical: 15 }}>{routes.url}</Text>,
    })
  }
  entries.push({
    key: 'version',
    render: () => <VersionInfo />,
  })
  return (
    <View style={{ flex: 1, backgroundColor: 'white' }}>
      <List
        withBorder={false}
        ListHeaderComponent={<ScreenTitle title={["My", "Settings"]} />}
        data={entries}
      />
      <KeyboardSpacerIOS />
    </View>
  )
})
export default AccountSettingsScreen
