import React, { useRef, useEffect, useState } from 'react'
import { Animated, Pressable, StyleSheet } from 'react-native'

import { Ionicons } from '@expo/vector-icons'
import { Text } from '@reveel/components/ui'
import Button from '@reveel/components/ui/Button'
import OrComponent from '@reveel/components/ui/OrComponent'
import View from '@reveel/components/ui/View'
import i18n from 'i18n-js'
import PropTypes from 'prop-types'
import { tw } from 'react-native-tailwindcss'

import InviteByEmail from '../../screens/sessions/invitations/InviteByEmail'
import SearchUser from '../core/SearchUser'
import InvitationCandidate from './InvitationCandidate'
import QRWrapper from './QRWrapper'
import useInvitees from './useInvitees'
import Share from './Share'
import { useDesktop } from '../../helpers/MediaQueries';
import { DisableAdBlock } from '../DisableAdBlock'
const QrAction = ({ onPress, style }) => (
  <Pressable onPress={onPress} style={[tw.flexRow, tw.itemsCenter, style]}>
    {
      ({ pressed, hovered }) => (
        <>
          <Ionicons name="qr-code-outline" size={20} style={tw.mR2} />
          <Text style={[(hovered || pressed) && tw.underline]}>Show QR Code</Text>
        </>
      )
    }
  </Pressable>
)

const InviteUsersDialog = ({ creativeSession, onSuccess, style, noTitle }) => {
  const {
    onSend,
    canSend,
    invitees,
    addInvitee,
    removeInvitee,
    loading,
    showShareSheet,
    url,
  } = useInvitees(creativeSession, { onSuccess })

  const isDesktop = useDesktop()
  const [showQR, setShowQr] = useState(false)
  const [offset, setOffset] = useState(0)
  const scale = useRef(new Animated.Value(1)).current

  useEffect(() => {
    if(!isDesktop){
      return
    }
    const toValue = !showQR ? 1 : 0.95
    Animated.timing(
      scale,
      {
        toValue,
        duration: 150,
        useNativeDriver: false,
      },
    ).start()
  }, [scale, showQR, isDesktop])

  if(!url){
    return <DisableAdBlock />
  }

  return (
    <View style={style}>
      { !noTitle && <Text style={[tw.mB4, tw.fontBold]}>{i18n.t('tracks.ui.invite.title')}</Text> }
      <View 
        onLayout={({ nativeEvent: { layout } }) => setOffset(layout.height)} 
        style={[tw.relative, isDesktop && tw.overflowHidden]}
      >
        <Animated.View
          style={{
            transform: [{ scale }],
          }}
        >
          <InviteByInputs {...{ invitees, removeInvitee, addInvitee, loading, onSend, canSend }} />
          <OrComponent />
          <View style={[tw.flexRow, tw.justifyBetween, tw.mT2, tw.mB4]}>
            <Share style={tw.flexGrow} onPress={showShareSheet} url={url} />
            <QrAction style={tw.flexGrow} onPress={() => setShowQr(!showQR)} />
          </View>
        </Animated.View>
        {offset ? <QRWrapper dismiss={() => setShowQr(false)} isVisible={showQR} offset={offset} url={url} /> : null}
      </View>
    </View>
  )
}

export default InviteUsersDialog

function InviteByInputs({ invitees, removeInvitee, addInvitee, loading, onSend, canSend }){
  return (
    <View>
      {invitees.map((invitee) => (
        <InvitationCandidate
          invitee={invitee}
          onRemove={removeInvitee}
        />
      ))}
      <SearchUser
        selected={invitees.filter(({ id }) => id)}
        onSelect={addInvitee}
        style={[tw.mT2]}
        resourceType='users'
      />
      <InviteByEmail
        style={[tw.mT2]}
        onAdd={(email) => addInvitee({ email })}
      />

      <Button
        loading={loading}
        disabled={!canSend}
        style={[tw.selfEnd, tw.mT4]}
        xs
        title={i18n.t('tracks.ui.invite.send')}
        onPress={onSend}
      />
    </View>
  )
}
