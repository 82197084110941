import React from 'react'
import { TouchableOpacity, StyleSheet } from 'react-native'
import * as Icon from '@expo/vector-icons'
import Colors from '@reveel/constants/Colors'

const TouchableIcon = ({
  name = 'ios-arrow-back',
  onPress,
  size = 30,
  fill,
  color = fill ? 'white' : Colors.tintColor,
}) => {
  const inside = <Icon.Ionicons color={color} name={name} size={size} style={{ paddingHorizontal: 0 }} />
  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={!onPress}
      style={[styles.fill, {
        backgroundColor: fill ? Colors.tintColor : "transparent",
        borderRadius: size * 1.2 / 2,
        width: size * 1.2,
        height: size * 1.2,
        paddingLeft: fill ? 5 : 0,
        paddingTop: fill ? 2 : 0,
      }]}
    >
      {inside}
    </TouchableOpacity>
  )
}

export default TouchableIcon

const styles = StyleSheet.create({
  fill: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
})
