import React from 'react'
import { View } from 'react-native'
import { Icon } from 'native-base'
import Colors from '@reveel/constants/Colors'
import TextInput from './TextInput'

const SearchInput = ({ style, ...props }) => {
  // TODO: use native iOS searchbar
  const prepend = () => <Icon name="ios-search" small style={{ color: Colors.placeholder }} color={Colors.placeholder} />
  return (
    <View style={style}>
      <TextInput testID={props.placeholder} prepend={prepend} {...props} style={{ paddingTop: 0 }} />
    </View>
  )
}

export default SearchInput
