import React, { useEffect } from 'react'
import _ from 'lodash'
import { View, TouchableOpacity, StyleSheet } from 'react-native'

import { Text } from '@reveel/components/ui'
import Colors from '@reveel/constants/Colors'
import i18n from 'i18n-js'
import UserWithText from '../../../components/core/UserWithText'
import Button from '../../../components/ui/Button'
import BottomSheetTitle from '../../../components/core/BottomSheetTitle'
import ParticipantsList from '../ParticipantsList'
import ContactSupport from '../../../components/ContactSupport'
import HeaderRightButton from '../../../components/HeaderRightButton'
import AddCollaboratorCTA from '../../tracks/collaborators/AddCollaboratorCTA'
import { useDesktop } from '../../../helpers/MediaQueries'
import AddCreditsButton from '@reveel/components/credits/AddCreditsButton';

const Running = ({
  onNext,
  loading,
  user: { id: self_id }, creativeSession, buttonStyle, style, setIntermediaryView,
}) => {
  let user = {}
  let iAmParticipant = false
  const canEnd = typeof onNext === 'function'
  const isDesktop = useDesktop()
  const participants = creativeSession.participants.filter((p) => {
    const isCurrentUser = p.id === self_id
    if(isCurrentUser){ user = p; iAmParticipant = true; return false }
    return true
  })

  const touchableAction = (text, onPress, _style = {}) => (
    <TouchableOpacity onPress={onPress}>
      <Text style={[styles.addContrib, _style]} bold small primary>
        {' '}
        {text}
      </Text>
    </TouchableOpacity>
  )

  const hasEnteredContributions = user.contributions && user.contributions.length !== 0

  const sheetHeader = !iAmParticipant ? <BottomSheetTitle title="You are not part of this session" /> : (
    <UserWithText
      user={user}
      subText={hasEnteredContributions ? user.contributions.map((s) => s.skill_name).join(', ') : undefined}
      style={[isDesktop ? styles.sheetHeaderDesktop : styles.sheetHeader]}
      theme="xl"
    />
  )

  const renderTrigger = ({ onOpen }) => touchableAction(i18n.t('creativeSessions.ui.invite.cta'), onOpen, { marginTop: 15, alignSelf: 'center' })
  const addParticipant = <AddCollaboratorCTA {...{ renderTrigger, creativeSession }} />

  const footer = (
    <>
      {addParticipant}
      <AddCreditsButton creativeSessionID={creativeSession.id} renderTrigger={({ onOpen }) => touchableAction('+ Add Contribution For Yourself or Someone else', onOpen, { marginTop: 15, alignSelf: 'center' })} />
      <ContactSupport horizontal />
    </>
  )
  return (
    <View style={[styles.container, style]}>
      <ParticipantsList
        creativeSession={{
          ...creativeSession,
          participants,
        }}
        footer={footer}
        header={sheetHeader}
      />
      {canEnd && (
      <Button
        block
        disabled={loading}
        title={`${i18n.t(`creativeSessions.button.${creativeSession.status}`)}`}
        onPress={onNext}
        style={buttonStyle}
      />
      )}
    </View>
  )
}

export default Running
const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    flex: 1,
  },
  sheetHeaderDesktop: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderGrey,
    marginLeft: 0,
    paddingLeft: 20,
    paddingVertical: 20,
  },
  sheetHeader: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderGrey,
    marginLeft: 0,
    paddingLeft: 10,
    paddingBottom: 15,
  },
  addContrib: {
  },
})
