
import { useQuery, useQueryClient, useMutation } from 'react-query';
import routes from '@reveel/helpers/routes';
import axios from 'axios'


export const useTrack = (trackID) => {

  const queryClient = useQueryClient()
  const key = ['track', trackID]
  const route = routes.api.tracks.show(trackID)
  const { isLoading, error, data } = useQuery(key, async () => {
    if(!trackID){ return }
    const res = await axios.get(route)
    return res.data
  })
  const mutation = useMutation(
    async (track) => axios.put(route, { track: {
      ...track,
      // rails expects _attributes for whitelisted associations
      project_attributes: {
        ...track.project,
      },
    } }),
    {
      onMutate: async (track) => {
        await queryClient.cancelQueries(key)
        const previousValue = queryClient.getQueryData(key)
        queryClient.setQueryData(key, track)
        return previousValue
      },
      onError: (err, variables, previousValue) => {
        queryClient.setQueryData(key, previousValue)
      },
      onSuccess: ({ data: newData }) => {
        queryClient.setQueryData(key, newData)
        queryClient.invalidateQueries(['projects'])
      },
    },
  )
  return {
    track: data,
    isLoading,
    error,
    mutation,
  }
}