import React from 'react'
import { StyleSheet } from 'react-native'
import View from '@reveel/components/ui/View'
import Colors from '@reveel/constants/Colors'

import { withCreativeSession } from '../useCreativeSessionContext'
import SessionAttachmentForm from './SessionAttachmentForm'
import Attachment from './Attachment'

const SessionAttachments = withCreativeSession(({ creativeSession, setCreativeSession }) => (
  <View px style={styles.container}>
    <SessionAttachmentForm style={styles.formWrapper} />
    {creativeSession.attachments.map((a) => <Attachment key={a.id} attachment={a} />)}
  </View>
))
export default SessionAttachments
const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.lightBackground,
  },
  formWrapper: {
    paddingVertical: 10,
    paddingLeft: 45,
  },
})
