import React, { useState, useEffect } from 'react'
import SelectTrack from './SelectTrack'

import { useStartSessionFor } from '../../../tracks/tracksHooks'
import SessionNewForm from '../SessionNewForm'

const SessionNew = ({ route, navigation }) => {
  const isPast = route.params?.isPast

  const [showForm, setShowForm] = useState(false)
  const [selectedTrack, setSelectedTrack] = useState()

  useEffect(() => {
    navigation.setOptions({
      headerBackTitleVisible: true,
      headerBackTitle: 'Cancel',
      headerBackImage: () => null,
      headerBackTitleStyle: {
        marginLeft: 10,
      },
    })
  }, [])

  // new sessions initiate straight away,
  // while past need to display a form first
  if(isPast){
    var onSelectTrack = (track) => {
      setSelectedTrack(track)
      setShowForm(true)
    }
    var onNewTrack = () => setShowForm(true)
  }else{
    var { startSessionFor: onSelectTrack, loading } = useStartSessionFor()
    var onNewTrack = () => navigation.push('TrackNew', { startSessionAfterCreate: true })
  }

  if(showForm){
    return (
      <SessionNewForm
        track={selectedTrack}
        isPast={isPast}
      />
    )
  }
  return (
    <SelectTrack {...{
      onSelectTrack,
      onNewTrack,
      state: isPast ? 'past' : 'running',
    }}
    />
  )
}
export default SessionNew
