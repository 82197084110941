import React from 'react';
import View from '@reveel/components/ui/View';
import { View as MotiView } from 'moti'
import { Text } from '@reveel/components/ui';
import i18n from 'i18n-js';
import { tw } from 'react-native-tailwindcss';
import { TouchableOpacity, Pressable } from 'react-native';
import { Icon } from 'native-base';

type ToDoCardProps = {
  localeKey: string;
  onPress: () => void;
  onCancel: () => void;
};

const wrapperStyle = [tw.flexRow, tw.pT5, tw.pB6, tw.justifyBetween, { gap: 16 }, tw.pL5, tw.borderL2, tw.borderPrimary];

const Close = ({ onPress }) => (<Pressable style={[
  {cursor: 'pointer'},
  tw.absolute,
  tw.right0,
  tw.top0,
  tw._mR4,
  tw.mT1,
]} onPress={onPress}>
  <Icon style={[tw.textLightBlack]} name="ios-close" />
</Pressable>)

export const ToDoCard = ({ localeKey, onPress, onCancel }: ToDoCardProps) => {
  return (
    <MotiView
      from={{ opacity: 0 }}
      animate={{ opacity: 1, translateX: 0 }}
      transition={{ type: 'timing' }}
      exit={{
        translateX: 50,
        opacity: 0,
      }}
    >
        <TouchableOpacity
        onPress={onPress}
        style={[
          tw.roundedLg,
          tw.bgWhite,
          tw.pY1,
          tw.pL1,
          tw.pR6,
        ]}>
        <View style={wrapperStyle}>
          <View style={[tw.flexShrink]}>
            <Text style={[tw.mB2, tw.fontBold]}>{i18n.t(`dashboard.todos.${localeKey}.title`)}</Text>
            <Text>{i18n.t(`dashboard.todos.${localeKey}.description`)}</Text>
          </View>
          {/* <Button onPress={onPress} title={i18n.t(`dashboard.todos.${localeKey}.cta`)} /> */}
          <Close onPress={onCancel}/>
        </View>
      </TouchableOpacity>
    </MotiView>
  )
};
