import React from 'react'

import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'
import PropTypes from 'prop-types'
import { tw } from 'react-native-tailwindcss'
import { StylePropType } from 'react-native-gifted-chat/lib/utils'

const Message = ({ text, style }) => {
  const messageStyles = [tw.pY3, tw.pX2, tw.flexShrink] // shrink to wrap text
  const wrapperStyles = [tw.bgPrimaryBg, tw.rounded, tw.flexRow, tw.overflowHidden, style]
  return (
    <View style={wrapperStyles}>
      <View style={[tw.hFull, tw.bgPrimary, tw.w1]} />
      <View style={messageStyles}>
        <Text style={[tw.textPrimaryBgText]}>{text}</Text>
      </View>
    </View>
  )
}

Message.propTypes = {
  text: PropTypes.string.isRequired,
  style: StylePropType,
}
export default Message
