/* eslint-disable no-underscore-dangle, no-use-before-define */

import PropTypes from 'prop-types'
import React from 'react'
import {
  Clipboard,
  StyleSheet,
  TouchableOpacity,
  ViewPropTypes,
  Platform,
} from 'react-native'

import {
  MessageText, Time, utils,
} from 'react-native-gifted-chat'
import Colors from '@reveel/constants/Colors'
import { Icon } from 'native-base'
import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'
import { MessageActions, MessageReactions } from './MessageActions'
import MessageImage from './MessageImage';

const { isSameUser, isSameDay } = utils

const isNotAttachment = ({ meta: { type } }) => type !== 'attachment'

export default class SlackBubble extends React.Component{
  constructor(props){
    super(props)
    this.onLongPress = this.onLongPress.bind(this)
    this.onPress = this.onPress.bind(this)
  }

  onPress(){
    if(this.props.onPress){
      this.props.onPress()
    }
  }

  onLongPress(){
    if(this.props.onLongPress){
      this.props.onLongPress(this.context, this.props.currentMessage)
    }else if(this.props.currentMessage.text){
      const options = [
        'Copy Text',
        'Cancel',
      ]
      const cancelButtonIndex = options.length - 1
      this.context.actionSheet().showActionSheetWithOptions({
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        switch (buttonIndex){
          case 0:
            Clipboard.setString(this.props.currentMessage.text)
            break
        }
      })
    }
  }

  renderActions(){
    return <MessageActions message={this.props.currentMessage} />
  }

  renderReactions(){
    return <MessageReactions message={this.props.currentMessage} />
  }

  renderMessageText(){
    if(this.props.currentMessage.text){
      const {
        containerStyle, wrapperStyle, messageTextStyle, ...messageTextProps
      } = this.props
      if(this.props.renderMessageText){
        return this.props.renderMessageText(messageTextProps)
      }
      return (
        <MessageText
          {...messageTextProps}
          textProps={{
            selectable: true,
          }}
          // this is from the example code, but
          // textStyle.left expects an object
          textStyle={{
            left: { ...styles.slackMessageText },
          }}
        />
      )
    }

    return null
  }

  renderMessageImage(){
    if(this.props.currentMessage.image){
      const { containerStyle, wrapperStyle, ...messageImageProps } = this.props
      if(this.props.renderMessageImage){
        return this.props.renderMessageImage(messageImageProps)
      }

      return <MessageImage {...messageImageProps} imageStyle={[styles.slackImage, messageImageProps.imageStyle]} />
    }
    return null
  }

  renderTicks(){
    const { currentMessage } = this.props
    if(this.props.renderTicks){
      return this.props.renderTicks(currentMessage)
    }
    if(currentMessage.user._id !== this.props.user._id){
      return null
    }
    if(currentMessage.sent || currentMessage.received){
      return (
        <View style={[styles.headerItem, styles.tickView]}>
          {currentMessage.sent && <Text style={[styles.standardFont, styles.tick, this.props.tickStyle]}>✓</Text>}
          {currentMessage.received && <Text style={[styles.standardFont, styles.tick, this.props.tickStyle]}>✓</Text>}
        </View>
      )
    }
    return null
  }

  renderUsername(){
    const username = this.props.currentMessage.user.name
    if(username){
      const { containerStyle, wrapperStyle, ...usernameProps } = this.props
      if(this.props.renderUsername){
        return this.props.renderUsername(usernameProps)
      }
      return (
        <Text style={[styles.standardFont, styles.headerItem, styles.username, this.props.usernameStyle]}>
          {username}
        </Text>
      )
    }
    return null
  }

  renderTime(){
    if(this.props.currentMessage.createdAt){
      const { containerStyle, wrapperStyle, ...timeProps } = this.props
      if(this.props.renderTime){
        return this.props.renderTime(timeProps)
      }
      return (
        <Time
          {...timeProps}
          containerStyle={{ left: [styles.timeContainer] }}
          textStyle={{ left: [styles.standardFont, styles.headerItem, styles.time, timeProps.textStyle] }}
        />
      )
    }
    return null
  }

  renderCaret(){
    if(this.props.withCaret){
      const name = Platform.OS === 'ios' ? 'ios-arrow-forward' : 'arrow-forward'
      return (
        <TouchableOpacity onPress={this.onPress}>
          <Icon style={[styles.caret, { color: Colors.lightGrey }]} name={name} />
        </TouchableOpacity>
      )
    }
  }

  renderCustomView(){
    if(this.props.renderCustomView){
      return this.props.renderCustomView(this.props)
    }
    return null
  }

  render(){
    const isSameThread = isSameUser(this.props.currentMessage, this.props.previousMessage)
      && isSameDay(this.props.currentMessage, this.props.previousMessage)
      && isNotAttachment(this.props.currentMessage)

    const messageHeader = isSameThread ? null : (
      <View style={styles.headerView}>
        {this.renderUsername()}
        {this.renderTime()}
        {this.renderTicks()}
      </View>
    )
    return (
      <View style={[styles.container, this.props.containerStyle]}>
        <View
          style={[
            styles.wrapper,
            this.props.wrapperStyle,
          ]}
        >
          <View style={styles.textWrapper}>
            <View>
              {this.renderCustomView()}
              {messageHeader}
              <View row>
                {this.renderMessageImage()}
                {this.renderMessageText()}
                {this.renderActions()}
              </View>
              {this.renderReactions()}
            </View>
          </View>
          {this.renderCaret()}
        </View>
      </View>
    )
  }
}

// Note: Everything is forced to be "left" positioned with this component.
// The "right" position is only used in the default Bubble.
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  wrapper: {
    marginRight: 60,
    minHeight: 20,
    flex:1,
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  textWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    maxWidth: "90%",
  },
  standardFont: {
    fontSize: 15,
  },
  slackMessageText: {
    marginLeft: 0,
    marginRight: 0,
  },
  caret: {
    alignSelf: 'center',
  },
  username: {
    fontWeight: 'bold',
  },
  time: {
    textAlign: 'left',
    fontSize: 12,
  },
  timeContainer: {
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
  },
  headerItem: {
    marginRight: 10,
  },
  headerView: {
    // Try to align it better with the avatar on Android.
    marginTop: Platform.OS === 'android' ? -2 : 0,
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  /* eslint-disable react-native/no-color-literals */
  tick: {
    backgroundColor: 'transparent',
    color: 'white',
  },
  /* eslint-enable react-native/no-color-literals */
  tickView: {
    flexDirection: 'row',
  },
  slackImage: {
    borderRadius: 3,
    marginLeft: 0,
    marginRight: 0,
  },
})

SlackBubble.contextTypes = {
  actionSheet: PropTypes.func,
}

SlackBubble.defaultProps = {
  touchableProps: {},
  onLongPress: null,
  renderMessageImage: null,
  renderMessageText: null,
  renderCustomView: null,
  renderTime: null,
  currentMessage: {
    text: null,
    createdAt: null,
    image: null,
  },
  nextMessage: {},
  previousMessage: {},
  containerStyle: {},
  wrapperStyle: {},
  tickStyle: {},
  containerToNextStyle: {},
  containerToPreviousStyle: {},
}

SlackBubble.propTypes = {
  touchableProps: PropTypes.object,
  onPress: PropTypes.func,
  onLongPress: PropTypes.func,
  renderMessageImage: PropTypes.func,
  renderMessageText: PropTypes.func,
  renderCustomView: PropTypes.func,
  renderUsername: PropTypes.func,
  renderTime: PropTypes.func,
  renderTicks: PropTypes.func,
  currentMessage: PropTypes.object,
  nextMessage: PropTypes.object,
  previousMessage: PropTypes.object,
  user: PropTypes.object,
  containerStyle: PropTypes.shape({
    left: ViewPropTypes.style,
    right: ViewPropTypes.style,
  }),
  wrapperStyle: PropTypes.shape({
    left: ViewPropTypes.style,
    right: ViewPropTypes.style,
  }),
  messageTextStyle: Text.propTypes.style,
  usernameStyle: Text.propTypes.style,
  tickStyle: Text.propTypes.style,
  containerToNextStyle: PropTypes.shape({
    left: ViewPropTypes.style,
    right: ViewPropTypes.style,
  }),
  containerToPreviousStyle: PropTypes.shape({
    left: ViewPropTypes.style,
    right: ViewPropTypes.style,
  }),
}
