import React, { useState } from 'react'
import axios from 'axios'

import * as Sentry from 'sentry-expo'
import routes from '../../helpers/routes'
import Switch from '../../components/ui/Switch'
import { useQueryClient } from 'react-query';
import { trackCreditKey } from '../tracks/credits/useTrackCredits';

const ParticipationSwitch = ({ disabled, user, participation, attribute }) => {
  const currentState = (participation || user)[attribute]
  const [isEnabled, setIsEnabled] = useState(currentState)
  // controller accepts both user_id and participation
  // as this component might not be aware of the participation.
  const key = participation?.id || user.participation_id
  const route = routes.api.creativeSession.participations.update(key)
  const queryClient = useQueryClient()
  const onToggle = async () => {
    try{
      setIsEnabled(!isEnabled)
      await axios.put(route, {
        participation: { [attribute]: !isEnabled },
      })
      queryClient.invalidateQueries(trackCreditKey(participation.track_id), { refetchInactive: true })
    }catch(err){
      setIsEnabled(isEnabled)
      console.log(err)
      Sentry.captureException(err)
    }
  }

  return <Switch onValueChange={onToggle} value={isEnabled} disabled={disabled} />
}

export default ParticipationSwitch
