export default {
  placeholders: {
    splits: {
      songwriting_splits: {
        empty: {
          title: 'Songwriting Splits',
          cta: '+ Propose Splits',
          more: 'Set up publishing splits with co-writers to register your song',
        },
      },
      master_points: {
        empty: {
          title: 'Master Points',
          cta: '+ Propose Points',
          more: 'Set up master recording points with your collaborators',
        },
      },
    },
    credits: {
      empty: {
        title: 'Credits',
        cta: '+ Add Your First Credit',
        more: 'Keep track of every contribution made to the track. By songwriters, musicians, engineers and producers',
      },
    },
  },
}
