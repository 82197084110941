import React from 'react'
import { Pressable, StyleSheet, ActivityIndicator } from 'react-native'
import Colors from '@reveel/constants/Colors'
import { Text } from '@reveel/components/ui'
import View from '@reveel/components/ui/View'
import { useDesktop } from '@reveel/helpers/MediaQueries';

type IProps = {
  title: string,
  onPress: () => void,
  style?: any,
  disabled?: boolean,
  small?: boolean,
  xs?: boolean,
  bordered?: boolean,
  noChrome?: boolean,
  iconLeft,
  block?: boolean,
  margin?: boolean,
  loading?: boolean,
  textStyle?: any,
  buttonStyle?: any,
  cancel?: boolean,
}

const Button = ({
  title,
  onPress,
  style,
  disabled,
  small,
  xs,
  bordered,
  noChrome,
  iconLeft,
  block,
  margin,
  loading = false,
  textStyle,
  buttonStyle,
  ...otherProps
}: IProps) => {
  const buttonStyles = [styles.commonStyles.button]
  const textStyles = [styles.commonStyles.text]
  const { testID } = otherProps
  if(xs){
    buttonStyles.push(styles.commonStyles.xsButton)
    textStyles.push(styles.commonStyles.xsText)
  }
  if(small){
    buttonStyles.push(styles.commonStyles.smallButton)
    textStyles.push(styles.commonStyles.smallText)
  }
  const isDesktop = useDesktop()
  if(block){
    if(!isDesktop){
      buttonStyles.push(styles.commonStyles.block)
    }
    buttonStyles.push(styles.commonStyles.blockDesktop)
  }
  if(margin){
    buttonStyles.push(styles.commonStyles.margin)
  }
  
  let loaderColor = bordered ? Colors.tintColor : 'white'
  const styleKey = (() => {
    if(bordered || noChrome) return 'bordered'
    return 'fill'
  })()
  
  buttonStyles.push(styles[styleKey].button.base)
  textStyles.push(styles[styleKey].text.base)

  Object.keys(otherProps).forEach((name) => {
    if(otherProps[name]){
      delete otherProps[name]
      buttonStyles.push(styles[styleKey].button[name])
      textStyles.push(styles[styleKey].text[name])
      if(styles[styleKey].text[name]){
        loaderColor = styles[styleKey].text[name].color || loaderColor
      }
    }
  })
  if(disabled && !loading){
    buttonStyles.push(styles[styleKey].button.disabled)
    textStyles.push(styles[styleKey].text.disabled)
  }
  if (noChrome) {
    buttonStyles.push(styles.commonStyles.noChrome)
  }
  textStyles.push(textStyle)
  buttonStyles.push(textStyle)
  const inside = (
    <View>
      <Text center style={textStyles}>{title}</Text>
    </View>
  )

  return (
    <Pressable 
      style={({ 
        hovered, focused, pressed
       }) => [
         buttonStyles,
         (pressed || hovered || focused) && { opacity: .8 },
         style,
        ]
      }
      disabled={disabled || loading}
      onPress={(...evt) => onPress?.(...evt)} 
      {...otherProps}
      testID={testID || title}
    >
      {loading ? <ActivityIndicator size="small" color={loaderColor} animating={loading} style={tw.mR1}/> : iconLeft}
      {inside}
    </Pressable>
  )
}

const styles = {
  commonStyles: StyleSheet.create({
    noChrome: {
      borderColor: 'transparent'
    },
    button: {
      backgroundColor: Colors.tintColor,
      borderColor: Colors.tintColor,
      borderRadius: 57,
      borderWidth: null,
      height: 45,
      flexDirection: 'row',
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 120,
      paddingHorizontal: 20,
    },
    text: {
      fontWeight: 'bold',
      alignSelf: 'center',
    },
    smallButton: {
      height: 30,
      minWidth: 0,
    },
    smallText: {
      fontSize: 14,
      fontWeight: 'normal',
    },
    xsButton: {
      height: 25,
      minWidth: 0,
      paddingHorizontal: 10,
    },
    xsText: {
      fontSize: 12,
      fontWeight: 'normal',
    },
    block: {
      alignSelf: 'stretch',
    },
    blockDesktop: {
      minWidth: 160,
      paddingHorizontal: 20,
    },
    margin: {
      marginHorizontal: 15,
    },
  }),
  fill: {
    button: StyleSheet.create({
      base: {
        backgroundColor: Colors.tintColor,
      },
      danger: {
        backgroundColor: Colors.errorBackground,
      },
      disabled: {
        backgroundColor: Colors.disabledBackground,
      },
      // eslint-disable-next-line react-native/no-color-literals
      cancel: {
        backgroundColor: 'transparent',
      },
    }),
    text: StyleSheet.create({
      base: {
        color: Colors.white,
      },
      cancel: {
        color: Colors.lightBlack,
      },
    }),
  },
  bordered: {
    button: StyleSheet.create({
      // eslint-disable-next-line react-native/no-color-literals
      base: {
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: Colors.tintColor,
      },
      light: {
        borderColor: Colors.white,
      },
      danger: {
        borderColor: Colors.errorBackground,
      },
      disabled: {
        borderColor: Colors.disabledBackground,
      },
      cancel: {
        borderColor: Colors.disabledBackground,
      },
    }),
    text: StyleSheet.create({
      base: {
        color: Colors.tintColor,
      },
      danger: {
        color: Colors.errorBackground,
      },
      light: {
        color: 'white',
      },
      disabled: {
        color: Colors.disabledBackground,
      },
      cancel: {
        color: Colors.lightBlack,
      },
    }),
  },
}

export default Button
