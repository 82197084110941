import React, { useState, useEffect } from 'react'
import {
  View, TextInput, StyleSheet, ScrollView,
} from 'react-native'
import RNPickerSelect from 'react-native-picker-select'
import _ from 'lodash'
import { Text } from '@reveel/components/ui'
import Colors from '@reveel/constants/Colors'
import { SESSION_TYPES } from '../../constants/ddex/index'

import variables from '../../config/variables'
import { SessionAttachmentField } from './sessions/SessionAttachmentForm'
import { useAsync } from '../../hooks/useRemoteState'
import { useAuth } from '../../stores/useAuth'

import ScreenTitle from '../../components/ScreenTitle'
import Button from '../../components/ui/Button'
import NumberPicker from '../../components/ui/NumberPicker'
import humanize from '../../utils/humanize'
import routes from '../../helpers/routes'

const useTmpCreativeSession = (initialState) => {
  const [creativeSession, setCreativeSession] = useState(initialState)
  return [creativeSession, setCreativeSession]
}

const SessionForm = ({
  keyboardShouldPersistTaps = 'always',
  buttonStyle,
  creativeSession,
  setCreativeSession,
  setIntermediaryView,
  navigation,
}) => {
  const { user } = useAuth()

  useEffect(() => {
    navigation.setOptions({ title: 'Confirm End' })
  }, [navigation])
  const minParticipantsCount = creativeSession.participants.length + creativeSession.invitations.filter((i) => i.status === 'pending').length
  const declared_contributors_count = Math.max(creativeSession.declared_contributors_count || minParticipantsCount)

  const [tmpCreativeSession, setTmpCreativeSession] = useTmpCreativeSession({
    ...creativeSession,
    declared_contributors_count,
  })

  const { loading, run } = useAsync({
    url: routes.api.creativeSession.end(creativeSession.id),
    method: 'put',
    handleResponse: ({ data: newCreativeSession }) => {
      setCreativeSession(newCreativeSession, { overwrite: true })
      setIntermediaryView()
    },
  })
  const confirmEndPressed = async () => run({
    creative_session: {
      track_attributes: tmpCreativeSession.track,
      ...tmpCreativeSession,
    },
  })

  const setField = (name, value) => {
    const newCS = { ...tmpCreativeSession }
    _.set(newCS, name, value)
    setTmpCreativeSession(newCS)
  }

  const countField = (
    <NumberPicker
      value={tmpCreativeSession.declared_contributors_count}
      onChange={(v) => setField("declared_contributors_count", v)}
      min={minParticipantsCount}
      style={styles.numberPicker}
    />
  )

  const formContent = (
    <>
      <ScrollView keyboardShouldPersistTaps={keyboardShouldPersistTaps} style={styles.container} contentContainerStyle={{ paddingBottom: 65 }}>
        <ScreenTitle title={["Review \ndetails and confirm"]} style={{ marginHorizontal: 0, marginVertical: 0 }} />
        {/* <View style={styles.formWrapper}>
          <Text style={styles.label}>
            How many people contributed in this session?
          </Text>
          {countField}
        </View> */}
        <View style={styles.separator} />
        <View style={styles.titleWrapper}>
          <Text small style={{ paddingBottom: 10 }}>
            Track title
          </Text>
          <TextInput
            style={styles.textinput}
            onChangeText={(value) => setField('track.title', value)}
            defaultValue={_.get(tmpCreativeSession, 'track.title')}
          />
        </View>
        <View style={styles.separator} />
        <View style={styles.titleWrapper}>
          <Text small style={{ paddingBottom: 10 }}>
            Session Name
          </Text>
          <TextInput
            style={styles.textinput}
            onChangeText={(value) => setField('name', value)}
            defaultValue={_.get(tmpCreativeSession, 'name')}
            placeholder={"eg. Vocals first test"}
          />
        </View>
        <View style={styles.separator} />
        <View style={styles.titleWrapper}>
          <Text small style={{ paddingBottom: 10 }}>
            Session Venue
          </Text>
          <TextInput
            style={styles.textinput}
            placeholder="Where did you hold this session?"
            onChangeText={(value) => setField('venue_name', value)}
            defaultValue={tmpCreativeSession.venue_name}
          />
        </View>
        <View style={styles.titleWrapper}>
          <Text small style={{ paddingBottom: 10 }}>
            Session Type
          </Text>
          <RNPickerSelect
            onValueChange={(value) => {
              setField('session_type', value)
            }}
            defaultValue={tmpCreativeSession.session_type}
            placeholder={{label: "Select the session type", value: ''}}
            items={SESSION_TYPES.map(s => ({label: humanize(s), value: s, key: s}))}
            style={pickerStyles}
          />
        </View>
        {/* disabled for now as it doesn't get attached */}
        {/* <View>
          <SessionAttachmentField
            defaultValue={_.get(tmpCreativeSession, 'attachments_attributes[0].url')}
            setValue={(v) => setField('attachments_attributes[0].url', v)}
          />
        </View> */}
        {user.admin && (
        <View style={styles.titleWrapper}>
          <Text small style={tw.pB2}>
            Notes
          </Text>
          <TextInput
            style={styles.textinput}
            placeholder="Thoughts, instruments used, or more..."
            onChangeText={(value) => setField('notes', value)}
            defaultValue={tmpCreativeSession.notes}
          />
        </View>
        )}
      </ScrollView>
    </>
  )

  return (
    <View style={{ flex: 1 }}>
      {formContent}
      <Button block title="Confirm End" onPress={confirmEndPressed} style={buttonStyle} loading={loading} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 15,
    flex: 1,
    paddingTop: 30,
    paddingBottom: 15,
  },
  titleWrapper: {
    justifyContent: 'center',
    paddingTop: 40,
    paddingBottom: 30,
  },
  formWrapper: {
    paddingTop: 30,
  },
  numberPicker: {
    borderColor: 'transparent',
  },
  separator: {
    borderBottomColor: Colors.borderGrey,
    borderBottomWidth: 0.5,
    paddingTop: 10,
  },
  inputWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  input: {
    margin: 10,
    flex: 1,
    height: '100%',
    backgroundColor: Colors.lightBackground,
    borderRadius: 30,
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 'bold',
  },
  textinput: {
    fontSize: 18,
    marginTop: 5,
  },
  icon: {
    backgroundColor: Colors.lightBackground,
    borderRadius: 20,
    height: 40,
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
    color: Colors.lightBackground,
  },
  label: {
    marginBottom: 20,
  },
})

// RNPicker expects one style per OS
const sharedPickerStyles = {
  color: 'black',
  fontSize: variables.fontSizeBase,
}
const pickerStyles = StyleSheet.create({
  inputIOS: sharedPickerStyles,
  inputAndroid: sharedPickerStyles,
})

export default SessionForm
