import React from 'react'
import { View } from 'react-native'
import Separator from '../ui/Separator'

const WithSeparators = ({ children, ml }) => (
  children ? (
    <>
      {children.map((c, i) => (i === children.length - 1 ? c : (
        <View key={i}>
          {c}
          <Separator ml={ml} />
        </View>
      )))}
    </>
  ) : null
)

export default WithSeparators
