const changelogMD = `

_19 mars 2021_
ENHANCEMENT

- Faster loading of track 🚀
- Added twitter and discogs to your profile 🕺🏼
- Editing 'equal' splits automatically disbles the toggle 🛠

BUG FIXES

- refresh track info when you save ⚡️
- accept decimal splits 🤑

_2 march 2021_
ENHANCEMENT

- automatically disable equal splits when you edit them 💸

_14 february 2021_
ENHANCEMENT

- enter details and credits for someone else! 👯
- Collaborators is now Manage Access 

BUG FIXES

_2 february 2021_
ENHANCEMENT

- much better design to invite collaborators! 🔥
- larger avatars 🔎
- opens up the beta to everyone! 🎊


BUG FIXES

- display sidebar on medium-sized devices 💻
- show entire last message of the chat

_27 january 2021_
ENHANCEMENT

- various UX improvements 👨‍🏫
- [beta] introduction of profiles 👨‍👩‍👧‍👦
- copy changes 📝

BUG FIXES

- temporarily disabled the sessions field in the new attachment page(we will restore ASAP!) 🤕

_6 january 2021_
ENHANCEMENT
- adds sign in link to footer in home page 🚉

BUG FIXES
- be notified when you must confirm your email address before continuing 🧟‍♂️

_04 january 2021_
ENHANCEMENT

- replace fingerprint icon with avatar 👤
- adds success message when you validate all credits 🎉

BUG FIXES

- restores pending credits banner in track 👀
- fixes cramped contributes in track credits 📂

_21 december 2020_
ENHANCEMENTS

- Much better navigation to the different features 🗺
- Redesign of most track pages on the webapp 👨‍💻
- Faster load times! (50% smaller page size)  ⚡️

BUG FIXES

- fixed avatars not displayed

_9 december 2020_
ENHANCEMENTS

- Splits available to all! ⚖️
- New Track Onboarding 🚤
- Added in-app way to provide feedback! 🙋🏻‍♀️

BUG FIXES

- Uploading avatar on web now works ✔
- It's not confusing anymore to select a new skill 👌🏽
- You can use the tab key to navigate through fields in forms 👨‍🦽

_3 december 2020_
ENHANCEMENTS

- add quick link to provide feedback ⚡️
- remove rogue icon in header 🤦‍♂️

BUG FIXES

- update tracks in sidebar automatically 📄
- layout of profile page 🖌

_24 november 2020_
ENHANCEMENTS

- \[beta\] Create splits for your tracks! ⚖️
- Join our community on Discord via links in Settings 👨‍👩‍👧‍👦

_12 november 2020_
ENHANCEMENTS

- Suggest user's current location in country select 🇨🇭🇮🇳🇧🇷🇺🇸
- Receive track credits by PDF 🗂

BUG FIXES

- Highlight current track in sidebar 👀
- Sort track by last activity 📈
- Fixes user tracks count
- Remove ambiguity in fields in onboarding
- Fixes skills not appearing in onboarding
- Fixes referral when signing up on web
- Various styling and performance fixes
`

export default changelogMD