export default {
  dashboard: {
    ui: {
      toggle: {
        todo: 'To Do',
        done: 'Done',
      },
      score: {
        label: 'Ready for Release',
      },
      time_saved: {
        label: 'Hours Saved',
      },
    },
    todos: {
      songwriting_splits: {
        title: 'Get your splits ready for registration',
        description: 'Assign royalties for your composition before you register to your PRO ',
        cta: 'Add Splits',
      },
      master_points: {
        title: 'Distribute master points',
        description: 'Assign royalties for the recording before distribution',
        cta: 'Set Master Points',
      },
      credits: {
        title: 'Credit track contributors',
        description: 'Add songwriters, musician, producers, and management credits to credit you and your collaborators on streaming platforms',
        cta: 'Add Credits',
      },
      details: {
        title: 'Complete details about the track',
        description: 'Add the track title, genre, main artist and other details',
        cta: 'Edit Details',
      },
    },
    tasks: {
      details: {
        title: 'Details',
        description: 'The key information to distribute and register your music',
      },
      project_info: {
        title: 'Project Info',
        description: 'Add your Singles, EP or LP information',
      },
      track_info: {
        title: 'Track Info',
        description: 'All the necessary data about your Recording',
      },
      composition_info: {
        title: 'Composition Info',
        description: 'All the necessary data about your Composition',
      },

      credits: {
        title: 'Credits',
        description: 'Give credit to all your collaborators and your team',
      },
      songwriter: {
        title: 'Songwriter',
        description: 'Songwriter data is mandatory to register a song',
      },
      musicians: {
        title: 'Musicians',
        description: 'Musician data is important for neighboring rights',
      },
      engineers: {
        title: 'Engineers',
        description: 'Engineer data is requested by most disributors  ',
      },
      producers: {
        title: 'Producers',
        description: 'Producer data is mandatory for most distributors ',
      },
      
      royalties: {
        title: 'Royalties',
        description: 'Set up how royalties should be split between collaborators ',
      },
      songwriting_splits: {
        title: 'Songwriting Splits',
        description: 'Splits for the performance & mechnanical royalties of your composition',
      },
      master_points: {
        title: 'Master Points',
        description: 'Splits for the master recording royalties of your track ',
      },

      export: {
        title: 'Export your track data',
        description: 'Get a single document with all the metadata you need to register your track with PROs & send it to distributors and labels',
      },
    },
  },
}
