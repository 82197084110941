import React from 'react'
import { Text, View } from '@reveel/components/ui'
import { StyleSheet, Alert } from 'react-native'
import SearchUser from '../components/core/SearchUser'
import { SearchableDropdown } from '../components/ui'
import { ErrorBoundary } from '../helpers/withErrorHandler'

import SkillsDropdown from '../components/core/SkillsDropdown'

const components = {
  "Search User": (k) => (
    <SearchUser onSelect={(user, ref) => {
      Alert.alert('User clicked', JSON.stringify(user))
      ref.resetTerm()
    }}
    />
  ),
  "Search Skills": (k) => <SkillsDropdown onSelect={(a) => Alert.alert('Clicked', JSON.stringify(a))} />,
}

const PlaygroundScreen = ({}) => (
  <View style={mainStyles.wrapper}>
    {Object.keys(components).map((k, i) => <ErrorBoundary key={i}>{components[k](i)}</ErrorBoundary>)}
  </View>
)
export default PlaygroundScreen

const mainStyles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: 15,
  },
})
