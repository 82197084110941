/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-first-prop-new-line */
import React from 'react'
import { useCallback } from 'react'

import { PENDING_ONBOARDING, PROFILE_ONBOARDED, TRACK_ONBOARDED } from '@reveel/constants/Status'
import { useHandleNotifications } from '@reveel/helpers/onAppBoot'
import BWall from '@reveel/screens/BWallScreen'
import MyID from '@reveel/screens/MyID'
import OnboardingNavigator from '@reveel/screens/onboarding/OnboardingNavigator'
import { useAuth } from '@reveel/stores/useAuth'
import { useMediaQuery } from 'react-responsive'

import ConfirmEmailAddress from '../../screens/user/ConfirmEmailAddress'
import accountScreens from '../AccountSettingsNavigator'
import createScreenHeader from '../createScreenHeader'
import customStackNavigator from '../customStackNavigator'
import Home from '../Home'
import sessionScreens from './sessionScreens'
import trackScreens from './trackScreens'
import HomeScreen from '../../screens/home/HomeScreen'
import { useDesktop } from '@reveel/helpers/MediaQueries';

const Stack = customStackNavigator()
export const useInitialRoute = () => {
  const { user } = useAuth()
  switch(user?.onboard_status){
    case PENDING_ONBOARDING:
      return 'UserOnboarding'
    case PROFILE_ONBOARDED:
      return 'TrackOnboarding'
    case TRACK_ONBOARDED:
    default:
      return 'Home'
  }
}

const AuthenticatedStack = () => {
  useHandleNotifications()
  const { mustConfirm } = useAuth()
  const initialRouteName = useInitialRoute()
  const isDesktop = useDesktop()
  const header = createScreenHeader({ isDesktop })
  if(mustConfirm){
    return <ConfirmEmailAddress />
  }
  return (
    <Stack.Navigator
      initialRouteName={initialRouteName}
      screenOptions={{
        headerShown: true,
        headerBackTitleVisible: false,
        header,
        animationEnabled: !isDesktop,
      }}
    >
      {/* <Stack.Screen name="Onboarding" component={OnboardingNavigator}
        options={{
          headerShown: false,
          noSidebar: true,
        }}
      /> */}
      { fullAppScreens(isDesktop) }
      { OnboardingNavigator() }
      {accountScreens(Stack)}
    </Stack.Navigator>
  )
}

export default React.memo(AuthenticatedStack)

function fullAppScreens(isDesktop){
  return (
    <>
      {homeScreens(isDesktop)}
      {trackScreens(isDesktop, Stack)}
      {sessionScreens(Stack)}
      <Stack.Screen name="Profile" component={MyID}
        options={{
          displayTitle: ['My', 'ID'],
          title: 'My Creator ID',
        }}
      />
    </>
  )
}

function betaScreens(){
  return (
    <>
      <Stack.Screen name="Home" component={BWall}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen name="Profile" component={MyID}
        options={{
          headerShown: false,
        }}
      />
    </>
  )
}

function homeScreens(isDesktop){
  if(isDesktop){
    const header = createScreenHeader({ isDesktop })
    return <>
      <Stack.Screen key={'Home'} name={'Home'} component={HomeScreen}
        options={{ 
          headerShown: true,
          title: 'Home',
          displayTitle: [''],
          header,
        }}
      />
    </>
  }
  return <Stack.Screen name="Home" component={Home}
      options={{ headerShown: false }}
    />
}
