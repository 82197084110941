import React from 'react'
import InlineScreenActions from './desktop'
import { useDesktop } from '../../../../helpers/MediaQueries'
import HamburgerMore from './mobile'

const HeaderMore = (props) => {
  if(!props.options || props.options.length < 1){
    return null
  }
  const isDesktop = useDesktop()
  if(isDesktop){
    return <InlineScreenActions {...props} />
  }

  return <HamburgerMore {...props} />
}

export default HeaderMore
