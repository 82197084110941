import React from 'react'
import { View, StyleSheet } from 'react-native'
import Colors from '@reveel/constants/Colors'
import { PropTypes } from 'prop-types'
import { Text } from '../ui'

const BottomSheetTitle = ({ title, noBorder }) => (
  <>
    <View style={[styles.container, noBorder ? styles.noBorder : null]}>
      {(typeof title === 'string') ? <Text bold center>{title}</Text> : title}
    </View>
  </>
)

const styles = StyleSheet.create({
  container: {
    alignContent: 'center',
    paddingVertical: 20,
    paddingHorizontal: 10,
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderDarkGrey,
  },
  noBorder: {
    borderBottomWidth: 0,
  },
})

BottomSheetTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  noBorder: PropTypes.bool,
}
export default BottomSheetTitle
