import React, { useState, useEffect, useMemo } from 'react'
import { StyleSheet, Pressable } from 'react-native'

import { Ionicons } from '@expo/vector-icons'
import MyAvatar from '@reveel/components/core/user/MyAvatar'
import { Text } from '@reveel/components/ui'
import FormItems from '@reveel/components/ui/Form'
import View from '@reveel/components/ui/View'
import { firstNameField, lastNameField, emailField, industryIDFields, relationshipField } from '@reveel/helpers/fields/userFieldsList'
import EnterContributions from '@reveel/screens/contributions/EnterContributions'
import PropTypes from 'prop-types'

import Colors from '../../../constants/Colors'
import { ipiField, stageNameField } from '../../../helpers/fields/userFieldsList'
import { skillsField2 } from '../../../helpers/fields/userFieldsList/skillsField'

import BottomSheetTitle from '../../core/BottomSheetTitle'
import SearchUser from '../../core/SearchUser'
import UserMini from '../../core/user/UserMini'
import { Thumbnail } from '../../ui'
import newProfileHeader from './newProfileHeader'
import useSubmitCreditsForTrack from './useSubmitCreditsForTrack'
import { tw } from 'react-native-tailwindcss';

function AddForMyselfButton({ onPress }){
  return (
    <Pressable
      onPress={onPress}
      testID="addCreditsForMyself"
      style={[tw.flexRow, tw.itemsCenter]}
    >
      {({ pressed }) => (
        <>
          <MyAvatar style={tw.mR4} />
          <Text primary={pressed}>Add for myself</Text>
        </>
      )}
    </Pressable>
  )
}

const botImage = require('app/assets/images/bot.png')

function AddForOther({ onPress }){
  return (
    <Pressable
      onPress={onPress}
      testID="addCreditsForOther"
      style={[tw.flexRow, tw.itemsCenter, tw.mT6]}
    >
      {({ pressed }) => (
        <>
          <Thumbnail small style={[tw.mR4]} source={botImage} />
          <Text primary={pressed}>Add for someone else</Text>
        </>
      )}
    </Pressable>
  )
}
export enum SUBMISSION_STATE { INIT, FOR_MYSELF, FOR_OTHER, SUCCESS }

const ProfileSelectOrCreate = ({ onValueChange, onNewProfile }) => {
  const [profile, setProfile] = useState<any>()

  useEffect(() => {
    onValueChange(profile?.id)
  }, [profile, onValueChange])
  const renderSelected = () => (
    !profile ? null : (
      <View>
        <View style={[tw.flexRow, tw.justifyBetween]}>
          <Text light>Adding for:</Text>
          <Pressable onPress={() => setProfile(undefined)}>
            <Ionicons size={18} name="ios-close" color={Colors.lightBlack} />
          </Pressable>
        </View>
        <UserMini user={profile} style={tw.mT2} />
      </View>
    )
  )
  return (
    <>
      {!profile && (
      <>
        <Text>Select an existing profile:</Text>
        <SearchUser onSelect={setProfile} style={tw.mT2} />
        <Pressable onPress={onNewProfile} style={tw.pY4}>
          <Text light style={[tw.textSm]}>
            Can&apos;t find the profile?&nbsp;
            <Text light style={[tw.underline, tw.textSm]}>
              Enter the details yourself
            </Text>
          </Text>
        </Pressable>
      </>
      )}
      {renderSelected()}
    </>
  )
}
const selectOrCreateProfileField = ({ onNewProfile }) => ({
  name: 'profile_id',
  renderCustom: (props) => <ProfileSelectOrCreate {...props} onNewProfile={onNewProfile} />,
})

const SubmitContributionsForm = ({ trackID, creativeSessionID, onClose }) => {
  const onSuccess = () => setState(SUBMISSION_STATE.SUCCESS)
  const submitToTrackProps = useSubmitCreditsForTrack({ creativeSessionID, trackID, onSuccess })
  const [state, setState] = useState<SUBMISSION_STATE>(SUBMISSION_STATE.INIT)
  useEffect(() => () => setState(SUBMISSION_STATE.INIT), [setState])
  const [showProfileForm, setShowProfileForm] = useState(false)
  const onNewProfile = () => {
    setShowProfileForm(true)
  }
  const fields = useMemo(() => {
    const newProfileFields = [
      newProfileHeader,
      {
        ...emailField,
        required: false,
        helpText: "Add your collaborator's email to invite them to complete and claim their details",
      },
      firstNameField,
      lastNameField,
      stageNameField,
      ipiField,
      // industryIDFields,
      // relationshipField,
    ]
    return [
      {
        ...skillsField2,
        validate: (v) => Object.entries(v).length > 0,
      },
      !showProfileForm && selectOrCreateProfileField({ onNewProfile }),
      ...(showProfileForm ? newProfileFields : []),
    ].filter(Boolean)
  }, [showProfileForm])

  if (state === SUBMISSION_STATE.INIT){
    return (
      <>
        <AddForMyselfButton onPress={() => setState(SUBMISSION_STATE.FOR_MYSELF)} />
        <AddForOther onPress={() => setState(SUBMISSION_STATE.FOR_OTHER)} />
      </>
    )
  }
  if(state === SUBMISSION_STATE.FOR_MYSELF){
    return <EnterContributions {...submitToTrackProps} />
  }
  if(state === SUBMISSION_STATE.FOR_OTHER){
    return (
      <View style={[tw.flexGrow, tw.flex]}>
        <BottomSheetTitle title="Send credits for someone else" />
        <FormItems
          buttonText={i18n.t('profiles.form.submit.and_add_credits')}
          style={[tw.mT4]}
          fields={fields}
          onSubmit={submitToTrackProps.onSubmit}
          errors={submitToTrackProps.errors}
        />
      </View>
    )
  }
  return (
    <SubmittedSuccess
      onClose={() => {
        setState(SUBMISSION_STATE.INIT)
        onClose()
      }}
      newProfileCreated={showProfileForm}
    />
  )
}

let timer
const SubmittedSuccess = ({ onClose, newProfileCreated }) => {
  useEffect(() => {
    timer = setTimeout(onClose, 3500)
    return () => clearTimeout(timer)
  }, [onClose])

  return (
    <View style={tw.pY10}>
      <View style={tw.mB4}>
        <Text style={tw.textXl}>✍️</Text>
      </View>
      <Text>Your credits have been added!</Text>
      <Text light>They will appear once an admin validates it</Text>
      {/* {Boolean(newProfileCreated) && <Text light>The profile&apos;s details will be updated within the next 24 hours</Text>} */}
    </View>
  )
}

SubmitContributionsForm.propTypes = {
  creativeSessionID: PropTypes.number.isRequired,
  trackID: PropTypes.number.isRequired,
}
export default SubmitContributionsForm
