import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import queryString from 'query-string'
import { Alert } from 'react-native'
import processErrors from '../helpers/processErrors'

/**

function useAsync()

params:

| `url` | string or function | the endpoint |
| `method` | `get` | http method to use |
| `handleResponse` | `({ data, status })=>{}` | called when axios response succeeds |
| `immediate` | `false` | whether or not to call the callback on init |
| `alert` | `{ title, description }` | whether or not to call the callback on init |

returns:

| `loading` | true when the query runs on init|
| `refreshing` | whether or not the query is running |
| `errors` | errors returned by the server |
| `run` | callback to run the query. takes query params or obj to send as body(`run(postBody)` or `run({q: 'my-query'})`) |

*/

export const useAsync = ({
  handleResponse,
  url,
  method = 'get',
  immediate = false,
  alert,
  handleError,
}, dependencies = []) => {
  const [loading, setLoading] = useState(immediate)
  const [errors, setErrors] = useState()
  const [refreshing, setRefreshing] = useState(false)

  const fetchAndRunCallback = useCallback(async ({ urlParams, hideRefreshing = false, ...params } = {}) => {
    if(refreshing){ return }
    if(alert){ return cancellableAlert(alert, actuallyRun) }

    let success
    await actuallyRun()
    async function actuallyRun(){
      setRefreshing(!hideRefreshing)
      let url_ = (typeof url === 'function') ? url(urlParams) : url
      if(method === 'get' && params){
        url_ += `?${queryString.stringify(params)}`
      }
      try{
        success = await axios[method](url_, params)
        typeof handleResponse === 'function' && handleResponse(success)
      }catch(err){
        console.log(err, processErrors(err), 'err')
        setErrors(processErrors(err))
        typeof handleError === 'function' && handleError(error)
      }
      setRefreshing(false)
      setLoading(false)
    }
    return [success, errors]
  }, [handleResponse, url, method, alert])

  useEffect(() => {
    if(immediate){
      fetchAndRunCallback()
    }
  }, [...dependencies, immediate])

  return {
    refreshing,
    loading,
    errors,
    refresh: fetchAndRunCallback,
    run: fetchAndRunCallback,
  }
}

const cancellableAlert = ({
  title, description, confirmText, destructive = false,
}, onAccept) => {
  Alert.alert(
    title,
    description,
    [
      {
        text: "Cancel",
        onPress: () => console.log("Cancel Pressed"),
        style: "cancel",
      },
      { text: confirmText, onPress: onAccept, destructive },
    ],
    { cancelable: false },
  )
}

/**

function useAsync()

params:

| `initialState` | object | passed to `useState` |

See useAsync for the rest

returns:

| `data` | the state object |
| `setData` | the setState callback |

*/

const useRemoteState = ({
  initialState,
  url,
  handleResponse: customHandleResponse,
  immediate = true,
  ...rest
}, dependencies = []) => {
  const [data, setData] = useState(initialState)
  const handleResponse = useCallback(customHandleResponse || (({ data }) => setData(data)), dependencies)
  const asyncParams = useAsync({ url, handleResponse, immediate, ...rest }, dependencies)

  return {
    data,
    setData,
    ...asyncParams,
  }
}
export default useRemoteState
