import React, { useEffect } from 'react'
import { useDesktop } from '@reveel/helpers/MediaQueries'
import { StyleSheet, TouchableOpacity } from 'react-native'
import PageWrapper from '@reveel/screens/onboarding/PageWrapper'
import { Text } from '@reveel/components/ui'
import ScreenTitle from '@reveel/components/ScreenTitle'
import Logo from '@reveel-assets/images/logo/LogoColor.svg'
import Colors from '@reveel/constants/Colors'
import View from '@reveel/components/ui/View'
import { withAuth } from '../stores/useAuth'
import Button from '../components/ui/Button'

const BWall = withAuth(({ navigation, user, logout }) => {
  useEffect(() => {
    if(user?.beta_flags?.access){
      navigation.navigate('Onboarding')
    }
  }, [user, navigation])

  const visitProfile = () => navigation.navigate('Profile')
  const isDesktop = useDesktop()
  return (
    <PageWrapper>
      <View px>
        <ScreenTitle title={['Hi', `${user.first_name}!`]} />
        <Text larger center={!isDesktop} mt style={isDesktop ? styles.desktopText : styles.mobileText}>
          Thank you for joining our waiting list!
          {'\n'}
          {'\n'}
          Reveel can still help you get credited properly for your work.
          {'\n'}
          {'\n'}
          You can use your Creator ID to centralize all of your important information and share it with your collaborators.
        </Text>
      </View>
      <View flex my style={styles.header}>
        <Logo />
      </View>
      <View>
        <Button margin block title="View my Creator ID" onPress={visitProfile} />
        <TouchableOpacity onPress={logout} testID="logout">
          <Text bold larger center my-2 light>Log out</Text>
        </TouchableOpacity>
      </View>
    </PageWrapper>
  )
})

export default BWall

const styles = StyleSheet.create({
  container: {
    paddingBottom: 15,
    justifyContent: 'flex-start',
    backgroundColor: Colors.lightBackground,
  },
  mobileText: {
    maxWidth: 320,
    alignSelf: 'center',
  },
  desktopText: {
    paddingLeft: 20,
  },
  header: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 20,
  },
  content: {
    justifyContent: 'center',
    paddingHorizontal: 15,
  },
  logo: {
    alignSelf: 'center',
    height: 100,
    marginBottom: 50,
    marginTop: 25,
  },
  input: {
    borderBottomColor: Colors.white,
    borderBottomWidth: 1,
    maxHeight: 40,
    color: Colors.white,
    marginBottom: 10,
  },
  inputWrapper: {
    marginVertical: 40,
    flex: 1,
    justifyContent: 'center',
  },
})
