import * as Sentry from "sentry-expo"
import { isProd } from '../helpers/urls/index';

const environment = isProd ? 'production' : 'dev'

export const init = () => Sentry.init({
  dsn: 'https://a8295718a3694338b0eaa000285ed51d@sentry.io/1490242',
  enableInExpoDevelopment: false,
  debug: true,
  environment,
})
