import React from 'react'

import { REJECTED } from '../../constants/Status'
import { useValidationContext } from '../../screens/validation/validationContext'
import ValidationRejectionDialog from '../../screens/validation/ValidationRejectionDialog'

// Switch this to ModalButton.js

const ContributionReject = ({
  contribution,
  user,
}) => {
  const { submitValidationFor } = useValidationContext()
  const onReject = (text) => submitValidationFor(contribution, { status: REJECTED, text })

  return (
    <ValidationRejectionDialog
      user={user}
      onCancel={() => setModalOpen(false)}
      onReject={onReject}
      contribution={contribution}
    />
  )
}

export default ContributionReject
