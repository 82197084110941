import { useNavigation } from "@react-navigation/native"
import List from "@reveel/components/ui/List"
import SectionHeader from "@reveel/components/ui/List/SectionHeader"
import Colors from '@reveel/constants/Colors'
import {
  PAST,
  PENDING_CONFLICT_RESOLUTION,
  PENDING_CONTRIBUTION_SUBMISSIONS,
  PENDING_MISSING_PARTICIPANTS,
  PENDING_VALIDATION,
  RUNNING,
} from "@reveel/constants/Status"
import useRefreshScreen from "@reveel/helpers/useRefreshScreen"
import React, { useCallback } from "react"
import { StyleSheet } from "react-native"
import View from "../../components/ui/View"
import useStoreContext from "../../stores/useStoreContext"
import TrackListItem from "./TrackListItem"
import TracksIndexEmpty from "./TracksIndex/TracksIndexEmpty"
import { useQueryClient } from 'react-query';

const ACTIVE_STATUS = [
  PAST,
  RUNNING,
  PENDING_CONTRIBUTION_SUBMISSIONS,
  PENDING_CONFLICT_RESOLUTION,
  PENDING_VALIDATION,
  PENDING_MISSING_PARTICIPANTS,
]
const hasTrackActionRequired = (track) => ACTIVE_STATUS.includes(track.status)

// TODO: remove artifacts from useRemoteState
// to the ones returned by useQuery (see useRemoteTracks)

const TracksList = (props) => {
  const { renderListFooterComponent } = props
  const navigation = useNavigation()
  const { tracks, refreshing, loading, query, setQuery } = useStoreContext('tracks')
  const queryClient = useQueryClient()
  const fetchTracks = () => {
    queryClient.invalidateQueries(['tracks'])
  }
  // const callbackedFetchTracks = useCallback(() => fetchTracks({ hideRefreshing: true }), [fetchTracks])
  // useRefreshScreen(callbackedFetchTracks, [callbackedFetchTracks])

  const tracksWithActionRequired = []
  const tracksPendingOthers = []

  tracks.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)).forEach((track) => {
    const trackItem = {
      key: `${track.id}`,
      render: () => <TrackListItem track={track} navigation={navigation} />,
    }

    // eslint-disable-next-line no-unused-expressions
    hasTrackActionRequired(track)
      ? tracksWithActionRequired.push(trackItem)
      : tracksPendingOthers.push(trackItem)
  })

  const data = tracks.length
    ? [
      tracksWithActionRequired.length && {
        divider: "Active Tracks",
        key: "divider-active",
        title: "active-tracks",
        render: () => <SectionHeader text="Active Tracks" />,
      },
      ...tracksWithActionRequired,
      tracksPendingOthers.length && {
        divider: "All Tracks",
        key: "divider-all",
        title: "Archives",
        render: () => <SectionHeader text="All Tracks" />,
      },
      ...tracksPendingOthers,
    ].filter(Boolean)
    : []

  const ListFooterComponent = renderListFooterComponent?.(tracks)
  return (
    <View style={styles.container} testID="tracksList">
      <List
        emptyComponent={<TracksIndexEmpty />}
        data={data}
        refreshing={refreshing}
        onRefresh={fetchTracks}
        loading={loading}
        onSearch={setQuery}
        searchParams={query}
        withKeyboardSpacer
        ListFooterComponent={ListFooterComponent}
        {...props}
      />
    </View>
  )
}

TracksList.navigationOptions = {
  headerMode: "none",
  header: null,
}
export default TracksList

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    flex: 1,
  },
})
