export default {
  system: {
    update: {
      title: "Stay up to date",
      description: "New version available",
      buttonTitle: "Update now",
      changelog: {
        available: 'Get Latest Version',
        unavailable: 'App up to date',
      },
    },
    completeProfile: {
      title: "Complete Creator ID",
      buttonTitle: "Settings",
    },
  },
  ui: {
    inputs: {
      addFile: '',
    },
  },
}
