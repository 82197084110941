export default {
  system: {
    skills: {
      title: "**Welcome to Mediarite**! Tap here to add your skills!",
    },
  },
  participation: {
    new_participant: {
      title: "**%{name}** has joined **%{sessionId}**",
    },
    joined: {
      title: 'You joined **%{sessionId}**',
    },
  },
  contribution: {
    conflict_resolved: {
      title: 'Conflict resolved',
    },
    pending_conflict_resolution: {
      title: 'Conflict with this contribution',
    },
  },
  creative_session: {
    pending_contribution_submissions: {
      title: "Session **%{trackTitle}** has closed. Input your contributions.",
    },
    pending_validation: {
      title: "Validate contributions for **%{trackTitle}**",
    },
    pending_conflict_resolution: {
      title: "A contribution to **%{trackTitle}** has been declined. Resolve this issue.",
    },
    signed_off: {
      title: "Session **%{sessionId}** has been signed off.",
    },
    pending_missing_participants: {
      title: "Some participants are still missing in **%{trackTitle}**. Please invite them again",
    },
  },
}
