export default {
  contributions: {
    actions: {
      reject: {
        self: 'Withdraw',
        other: 'Reject',
      },
    },
  },
  credits: {
    index: {
      empty: {
        title: 'Add Credits for this track',
      },
    },
  },
}
