import React, { useState, useEffect } from 'react'
import { View, StyleSheet, SafeAreaView } from 'react-native'
import _ from 'lodash'

import Colors from '@reveel/constants/Colors'
import Collapsible from 'react-native-collapsible'
import {
  PAST,
  RUNNING,
  PENDING_VALIDATION,
  PENDING_CONFLICT_RESOLUTION,
  PENDING_CONTRIBUTION_SUBMISSIONS,
  PENDING_SIGN_OFF,
  PENDING_MISSING_PARTICIPANTS,
  SIGNED_OFF,
} from '../../constants/Status'

import Loading from '../../components/Loading'

import useCreativeSession from './useCreativeSession'
import { CreativeSessionProvider } from './useCreativeSessionContext'
import SessionStatus from './SessionStatus'

import Running from "./RunningSession"
import AddCreditsToSession from './AddCreditsToSessionScreen'
import MissingParticipants from './MissingParticipantsScreen'
import ValidateSession from '../validation/ValidateSession'
import SignedOff from './SignedOff'
import ConfirmSessionEnd from './ConfirmSessionEnd'
import SessionMetadata from './SessionMetadata'
import TouchableIcon from '../../components/ui/TouchableIcon'
import { useAuth } from '../../stores/useAuth'
import { registerForPushNotificationsAsync } from '../../config/PushNotifications'

import runCallbackIfNeeded from '../../helpers/useRunEveryXTime'
import SessionHeader from './SessionHeader'
import { useDesktop } from '../../helpers/MediaQueries'
import Breadcrumb from '../../navigation/createScreenHeader/Breadcrumb'
import TrackScreenTitle from '../tracks/TrackScreenTitle'
;
const screenOptions = {
  Running,
  AddCreditsToSession,
  ValidateSession,
  SignedOff,
  MissingParticipants,
}

const getScreenOptionsForCreativeSession = ({ status, can }) => {
  switch (status){
    case PAST:
      return screenOptions.Running
    case RUNNING:
      return screenOptions.Running
    case PENDING_MISSING_PARTICIPANTS:
      if(can.submit_contributions){
        return screenOptions.AddCreditsToSession
      }
      return screenOptions.MissingParticipants
    case PENDING_VALIDATION:
    case PENDING_CONFLICT_RESOLUTION:
      return screenOptions.ValidateSession
    case PENDING_CONTRIBUTION_SUBMISSIONS:
      if(can.submit_contributions){
        return screenOptions.AddCreditsToSession
      }
      return screenOptions.ValidateSession
    case PENDING_SIGN_OFF:
    case SIGNED_OFF:
      return screenOptions.SignedOff
    default:
      return screenOptions.Running
  }
}

const SessionScreen = (props) => {
  const { navigation, route } = props
  const id = route.params?.id
  const [intermediaryView, setIntermediaryView] = useState()

  // prevents double loading from accept invitation screen
  const dontLoadOnInit = route.params?.dontLoadOnInit
  const { user } = useAuth()
  const onFetched = ({ creativeSession }) => {
    navigation?.setParams({
      loading: false,
      intermediaryView: undefined,
      creativeSession,
    })
  }
  const onFetchError = () => {
    navigation?.goBack(null)
  }
  const { creativeSession, setCreativeSession, loading } = useCreativeSession({
    id,
    onFetched,
    onFetchError,
    dontLoadOnInit,
  })

  navigationSetOptions({ navigation, creativeSession })

  useEffect(() => {
    if(!creativeSession?.ghost){
      runCallbackIfNeeded(() => navigation.push('HowSessionsWork'), 'session-tutorial', [0])
    }
    registerForPushNotificationsAsync()
  }, [creativeSession, navigation])

  const isDesktop = useDesktop()
  if(loading){
    return <Loading style={styles.container} />
  }

  const getVisibleComponent = () => {
    if(intermediaryView === 'confirmEnd') return ConfirmSessionEnd
    if(intermediaryView === 'details') return SessionMetadata
    if(intermediaryView === 'enterContributions') return AddCreditsToSession.screen
    return getScreenOptionsForCreativeSession(creativeSession).screen
  }
  const Screen = getVisibleComponent(creativeSession)
  const renderContent = () => (
    <Screen
      buttonStyle={styles.buttonStyle}
      creativeSession={creativeSession}
      navigation={navigation}
      setIntermediaryView={setIntermediaryView}
      setCreativeSession={setCreativeSession}
      style={styles.innerSheet}
      user={user}
    />
  )

  const isIntermediaryView = intermediaryView !== undefined
  const closeIntermediaryView = () => isIntermediaryView && (
  <View style={styles.closeIntermediaryHeader}>
    <TouchableIcon name="ios-close" onPress={() => setIntermediaryView(undefined)} color={Colors.lightBlack} />
  </View>
  )
  return (
    <SafeAreaView style={[styles.container, isDesktop && { paddingHorizontal: 15 }]}>
      <CreativeSessionProvider creativeSession={creativeSession} setCreativeSession={setCreativeSession}>
        <Collapsible collapsed={isIntermediaryView}>
          <SessionStatus creativeSession={creativeSession} />
        </Collapsible>
        <View style={styles.contentContainer}>
          {closeIntermediaryView()}
          {renderContent()}
        </View>
      </CreativeSessionProvider>
    </SafeAreaView>
  )
}

const navigationSetOptions = ({ navigation, creativeSession }) => {
  const isDesktop = useDesktop()
  useEffect(() => {
    if(!creativeSession){
      return
    }

    if(creativeSession.ghost){
      navigation.setOptions({
        title: creativeSession.track_title,
      })
    }
    navigation.setOptions({
      // TODO: we should probably have more generic solution for this
      // maybe one that recognizes the route names and renders titles
      // accordingly.
      // also, this would probably require changing the Nav hierarchy
      // to have Tracks > Sessions
      headerTitle: () => (
        <Breadcrumb>
          <TrackScreenTitle
            noColor
            navigatable
            track={{
              title: creativeSession.track_title,
              id: creativeSession.track_id,
            }}
          />
          <SessionHeader creativeSession={creativeSession} />
        </Breadcrumb>
      ),
      headerLeft: isDesktop ? null : undefined,
      headerStyle: {
        height: 95,
        backgroundColor: Colors.lightBackground,
        borderBottomWidth: 1,
        borderBottomColor: Colors.borderGrey,
      },
    })
  }, [creativeSession])
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.lightBackground,
    flex: 1,
    alignItems: 'center',
  },
  headerStyle: {
    backgroundColor: Colors.lightBackground,
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderGrey,
  },
  closeIntermediaryHeader: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    right: 0,
    zIndex: 10000,
    position: 'absolute',
  },
  contentContainer: {
    borderTopColor: Colors.borderGrey,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    backgroundColor: 'white',
    flex: 1,
    overflow: "hidden",
    width: '100%',
    maxWidth: 860,
  },
  innerSheet: {
    paddingTop: 10,
  },
  buttonStyle: {
    marginBottom: 10,
    marginHorizontal: 15,
    marginTop: 10,
  },
})
export default SessionScreen
