import React, { useState } from 'react'
import { Pressable } from 'react-native'

import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Text } from '@reveel/components/ui'
import Colors from '@reveel/constants/Colors'
import { tw } from 'react-native-tailwindcss'
import Clipboard from 'expo-clipboard';

const Share = ({ style, url, onCopied }) => {
  const [copied, setCopied] = useState(false)
  const onPress = () => {
    Clipboard.setString(url)
    setCopied(true)
    setTimeout(() => setCopied(false), 3000)
    onCopied?.()
  }
  return (
    <Pressable onPress={onPress} style={[tw.flexRow, tw.itemsCenter, tw.pointer, style]}>
      {({ pressed, hovered }) => (
        <>
          <MaterialCommunityIcons
            name="link-variant"
            color={Colors.primary}
            size={20}
            style={tw.mR2}
          />
          <Text
            style={[tw.textPrimary, (hovered || pressed) && tw.underline]}
          >
            {copied ? 'Copied!' : 'Copy Link'}
          </Text>
        </>
      )}
    </Pressable>
  )
}

export default Share
