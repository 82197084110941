import React from 'react'

import PropTypes from 'prop-types'
import { CountryDropdown } from 'react-country-region-selector'
import './country-reset.css'

const RVCountrySelect = ({ onValueChange, value }) => (
  <>
    <CountryDropdown
      onChange={(v) => onValueChange(v)}
      value={value ?? 'undefined'}
      valueType="short"
    />
  </>
)

RVCountrySelect.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}
export default RVCountrySelect
