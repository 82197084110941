
import { Ionicons } from '@expo/vector-icons'
import { useFonts } from '@use-expo/font'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Platform } from 'react-native'
import { config as configAnalytics } from './src/analytics/index'
import routes from './src/helpers/routes'
import useAppState from './src/helpers/useAppState'
import HybridApp from './src/HybridApp'
import PleaseUpdate from './src/screens/PleaseUpdate'
require('./css')

configAnalytics()

const fonts = {
  ...Ionicons.font,
  Socicon: 'https://reveel-prod.s3.us-east-2.amazonaws.com/static/Socicon.ttf',
}

const EXPO_UPDATE_ENOUGH = 1
const APP_STORE_UPDATE_REQUIRED = 2

const useUpdateRequired = () => {
  const [updateRequiredStatus, setMustUpdate] = useState(false)

  const checkUpdate = async () => {
    if(Platform.OS === 'web'){
      return
    }
    try{
      await axios.get(routes.api.isDeprecated)
    }catch(err){
      if(err.response){
        if(err.response.status === 401){
          setMustUpdate(err.response.data.app_store_update_required
            ? APP_STORE_UPDATE_REQUIRED
            : EXPO_UPDATE_ENOUGH)
        }
      }
    }
  }

  useAppState({
    onForeground: checkUpdate,
  })
  useEffect(() => {
    checkUpdate()
  }, [])
  return updateRequiredStatus
}

const App = () => {
  const updateRequiredStatus = useUpdateRequired()
  const [fontsLoaded] = useFonts(fonts)

  // useEffect(() => {
  //   setLoading(!fontsLoaded)
  // }, [fontsLoaded]);

  if(updateRequiredStatus){
    return <PleaseUpdate appStoreUpdate={updateRequiredStatus === APP_STORE_UPDATE_REQUIRED} />
  }

  return <HybridApp />
}

export default App
// uncomment 
// import App from './AppStorybook';
// export default App