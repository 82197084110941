import React from 'react'
import { View, StyleSheet } from 'react-native'
import Colors from '@reveel/constants/Colors'
import RecIcon from "app/assets/images/record.svg"
import { RUNNING } from '../../constants/Status'

const reIconToDotRatio = 1.7
const Dot = ({
  status, color: backgroundColor = Colors.tracks[status], size = 15, style,
}) => (status === RUNNING ? <RecIcon height={size * reIconToDotRatio} width={size * reIconToDotRatio} /> : (
  <View style={[{
    width: size,
    height: size,
    borderRadius: size / 2,
    backgroundColor,
    marginHorizontal: size * (reIconToDotRatio - 1) / 2 - 0.3,
  }, style]}
  />
))

export default Dot
