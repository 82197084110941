import PropTypes from 'prop-types'
import React from 'react'
import { Image, StyleSheet, View } from 'react-native'
import Lightbox from 'react-native-lightbox'
import { Text } from 'react-native';
import ProgressiveImage from '../ui/ProgressiveImage';
import Colors from '@reveel/constants/Colors';
import { transparentize } from '../../constants/Colors';

const styles = StyleSheet.create({
  container: {
  },
  image: {
    width: 150,
    height: 100,
    borderRadius: 13,
    margin: 3,
    resizeMode: 'cover',
    backgroundColor: transparentize(Colors.primary),
  },
  imageActive: {
    flex: 1,
    resizeMode: 'contain',
  },
})
const MessageImage = (props) => {
  const { containerStyle, lightboxProps, imageProps, imageStyle, currentMessage } = props
  const renderContent = () => (
    <ProgressiveImage
      {...imageProps}
      style={[styles.imageActive, imageStyle]}
      thumbnailSource={{ uri: currentMessage.image.thumbnail }}
      source={{ uri: currentMessage.image.original }}
    />
  )
  if(currentMessage){
    return (
      <View style={[styles.container, containerStyle]}>
        <Lightbox
          activeProps={{
            style: styles.imageActive,
          }}
          {...lightboxProps}
          renderContent={renderContent}
        >
          <Image {...imageProps} style={[styles.image, imageStyle]} source={{ uri: currentMessage.image.thumbnail }} />
        </Lightbox>
      </View>
    )
  }
  return null
}
MessageImage.defaultProps = {
  currentMessage: {
    image: null,
  },
  containerStyle: {},
  imageStyle: {},
  imageProps: {},
  lightboxProps: {},
}
MessageImage.propTypes = {
  currentMessage: PropTypes.object,
  imageProps: PropTypes.object,
  lightboxProps: PropTypes.object,
}

export default MessageImage
