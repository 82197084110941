import { useState } from 'react';
import axios from 'axios';
import * as Sentry from 'sentry-expo';
import routes from '../../helpers/routes';
import processErrors from '../../helpers/processErrors';
import { useQueryClient } from 'react-query';
import { permissionsKey } from '../../screens/tracks/TrackPermissions/useTrackPermissions';
import useStoreContext from '@reveel/stores/useStoreContext';

const initialSendCTA = 'Resend Email';
const useInvitationActions = (invitation) => {
  const [sendingEmail, setSendingEmail] = useState(false);
  const [sendCTA, setSendCTA] = useState(initialSendCTA);
  const sendEmail = async () => {
    if (sendingEmail) { return; }
    setSendCTA('Sending...');
    setSendingEmail(true);
    try {
      await axios.put(routes.api.creativeSession.invitations.resend(invitation.id));
      setSendCTA('Sent!');
    } catch (err) {
      const error = processErrors(err);
      setSendCTA(error);
    }

    setTimeout(() => {
      setSendCTA(initialSendCTA);
      setSendingEmail(false);
    }, 1500);
  };

  // const queryClient = useQueryClient();
  const [track] = useStoreContext('currentTrack');
  const [deletingEmail, setDeletingEmail] = useState(false);
  const [deleted, setDeleted] = useState(false)
  const deleteEmail = async (queryClient_) => {
    if (deletingEmail) { return; }
    setDeletingEmail(true);
    try {
      // TODO: invalidateQueries doesn't retrigger the
      // tracks/:id/permissions request
      // (queryClient_ || queryClient).invalidateQueries(permissionsKey(track.id))
      await axios.delete(routes.api.creativeSession.invitations.show(invitation.id));
      setDeleted(true)
    } catch (err) {
      Sentry.captureException(err);
    }
    setDeletingEmail(false);
  };
  return {
    deleteEmail, sendEmail, sendCTA, sendingEmail, deletingEmail, deleted
  };
};

export default useInvitationActions